/* eslint-disable react/prop-types */
import {
  Datagrid,
  InfiniteList,
  TextField,
  useRecordContext,
} from "react-admin"

const PreField = ({ source }) => {
  const record = useRecordContext()
  if (!record) return null

  return <pre>{record[source]}</pre>
}

export const AuditLogList = (props) => (
  <InfiniteList
    resource="audit-logs"
    {...props}
    exporter={false}
    sort={{ field: "created_at", order: "DESC" }}
  >
    <Datagrid
      bulkActionButtons={false}
      sx={{ "& .RaDatagrid-rowCell": { verticalAlign: "top" } }}
    >
      <TextField source="item_type" />
      <TextField source="item_id" />
      <TextField source="event" />
      <TextField source="whodunnit" />
      <PreField source="object" />
      <PreField source="object_changes" />
    </Datagrid>
  </InfiniteList>
)
