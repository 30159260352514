import { useEffect, useState } from "react"
import { useDataProvider } from "react-admin"

import { TimezoneContext } from "../../context/timezone_context"

export const TimezoneProvider = ({ children }) => {
  const [timezones, setTimezones] = useState([])
  const dataProvider = useDataProvider()

  useEffect(() => {
    if (timezones.length === 0) {
      dataProvider
        .getList("timezones", {
          filter: {},
          pagination: { page: 1, perPage: 1000 },
          sort: { field: "id", order: "ASC" },
        })
        .then(({ data }) => setTimezones(data))
    }
  })

  return (
    <TimezoneContext.Provider value={timezones}>
      {children}
    </TimezoneContext.Provider>
  )
}
