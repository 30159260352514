import { Box } from "@mui/material"
import { Create, useCreateController } from "react-admin"

import { EmailReceiverList } from "../../components/lists/EmailReceiverList"

import { WizardForm } from "@react-admin/ra-form-layout"
import { EmailBatchExplanation } from "./EmailBatchExplanation"
import { EmailBatchSelectRecipients } from "./EmailBatchSelectRecipients"
import { EmailBatchTemplateForm } from "./EmailBatchTemplateForm"

const Sidebar = () => {
  const createController = useCreateController()

  if (createController && createController["record"]) {
    return (
      <Box sx={{ width: "100%", maxWidth: "380px" }}>
        <EmailReceiverList
          resourceName={createController["record"]["resource_name"]}
          resourceType={createController["record"]["resource_type"]}
          userIds={createController["record"]["resource_ids"]}
          defaultTitle=""
        />
      </Box>
    )
  }
}

export const EmailBatchCreate = () => {
  const removeUseTemplate = ({ ...data }) => {
    delete data["use_template"]
    return {
      ...data,
    }
  }

  return (
    <Create
      aside={<Sidebar />}
      title="Create E-mail Batch"
      redirect="list"
      transform={removeUseTemplate}
    >
      <WizardForm>
        <WizardForm.Step label="Explanation">
          <EmailBatchExplanation />
        </WizardForm.Step>
        <WizardForm.Step label="Select recipients">
          <EmailBatchSelectRecipients />
        </WizardForm.Step>
        <WizardForm.Step label="Create your email">
          <EmailBatchTemplateForm />
        </WizardForm.Step>
        <WizardForm.Step label="Confirm">Confirm</WizardForm.Step>
        <WizardForm.Step label="Send">Send</WizardForm.Step>
      </WizardForm>
    </Create>
  )
}
