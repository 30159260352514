import { Email } from "@mui/icons-material"
import { useState } from "react"
import { Button, Confirm, useRefresh, useUpdate } from "react-admin"

import ImportExportIcon from "@mui/icons-material/ImportExport"

export const ClientImportButton = ({ record }) => {
  const [open, setOpen] = useState(false)
  const refresh = useRefresh()

  const handleDialogClose = () => setOpen(false)
  const handleClick = () => setOpen(true)
  const onSettled = () => refresh()

  const [update, { isLoading, error }] = useUpdate(
    "client-imports",
    {
      id: record.id,
      data: { status: "confirmed" },
      previousData: record,
    },
    { onSettled: onSettled }
  )

  const handleConfirm = () => {
    update()
    setOpen(false)
  }

  if (!record) return null

  if (error) {
    return <p>ERROR</p>
  }

  return (
    <>
      <Button
        label="Import"
        startIcon={<ImportExportIcon />}
        onClick={handleClick}
        disabled={isLoading}
      >
        <Email />
      </Button>
      <Confirm
        isOpen={open}
        title={`Import clients`}
        content={`Are you sure you want to import these clients?`}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  )
}
