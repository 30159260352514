import { Grid } from "@mui/material"
import {
  BulkActionsToolbar,
  Empty,
  FilterForm,
  RecordContextProvider,
  useListContext,
} from "react-admin"

import { Box } from "@mui/system"

import { CoachCard } from "./CoachCard"
import { AddSelectedAssociatesToListButton } from "../../components/buttons/AddSelectedAssociatesToListButton"
import { CoachListFilterTopBar } from "../../components/filters/CoachListFilterTopBar"

export const CoachCardsList = ({ showSelectableToolbar = false }) => {
  const { selectedIds } = useListContext()

  return (
    <Box sx={{ maxWidth: "80vw" }}>
      <FilterForm
        className="filter_container"
        filters={CoachListFilterTopBar()}
        style={{ display: "flex", justifyContent: "space-between" }}
      />
      {showSelectableToolbar && (
        <BulkActionsToolbar selectedIds={selectedIds}>
          <>
            <AddSelectedAssociatesToListButton selectedIds={selectedIds} />
          </>
        </BulkActionsToolbar>
      )}
      <ResultBox />
    </Box>
  )
}

const ResultBox = () => {
  const { data, isLoading } = useListContext()

  if (data == undefined) return null
  if (data.length == 0) return <Empty />
  if (isLoading) return null

  return (
    <Box sx={{ padding: "1rem" }}>
      <Grid container spacing={2}>
        {data.map((record) => {
          return (
            <RecordContextProvider key={record.id} value={record}>
              <Grid item xs={12} md={6} lg={4}>
                <CoachCard />
              </Grid>
            </RecordContextProvider>
          )
        })}
      </Grid>
    </Box>
  )
}
