import { Box } from "@mui/material"
import { useEffect, useState } from "react"
import {
  AutocompleteInput,
  Edit,
  FormDataConsumer,
  ReferenceInput,
  SimpleForm,
  TextInput,
  useDataProvider,
  useRecordContext,
} from "react-admin"
import { MultiEmailInput } from "../../components/inputs/MultiEmailInput"
import { EmailReceiverList } from "../../components/lists/EmailReceiverList"
import { EmailTranslatableInput } from "../../components/inputs/EmailTranslatableInput"
import { EmailVariablesPreview } from "../../components/inputs/EmailVariablesPreview"

const Sidebar = () => {
  const record = useRecordContext()

  if (record) {
    return (
      <Box sx={{ width: "100%", maxWidth: "380px" }}>
        <EmailReceiverList
          resourceName={record.resource_name}
          resourceType={record.resource_type}
          userIds={record.resource_ids}
          defaultTitle=""
        />
      </Box>
    )
  }
}

export const EmailBatchEdit = () => {
  const record = useRecordContext()
  const dataProvider = useDataProvider()
  const [emailTemplateModelId, setEmailTemplateModelId] = useState(0)

  useEffect(() => {
    async function fetchData() {
      const response = await dataProvider.getOne("email-templates", {
        id: record.emailTemplateId,
      })
      setEmailTemplateModelId(response.data.email_template_model_id)
    }

    async function fetchDefault() {
      const response = await dataProvider.getList("email-template-models", {
        filter: {
          name: record.resource_type,
        },
        pagination: { page: 1, perPage: 1 },
        sort: { field: "name", order: "ASC" },
      })
      if (response.data.length > 0) {
        setEmailTemplateModelId(response.data[0].id)
      }
    }

    if (record && record.emailTemplateId != 0) {
      if (record && emailTemplateModelId == 0) {
        fetchData()
      }
    } else {
      if (record && record.resource_type) {
        fetchDefault()
      }
    }
  }, [dataProvider, emailTemplateModelId, record])

  return (
    <Edit aside={<Sidebar />} title="Edit E-mail Batch">
      <SimpleForm sx={{ p: 4 }}>
        <ReferenceInput
          source="email_template_id"
          reference="email-templates"
          sx={{ width: "100%", pt: 0 }}
        >
          <AutocompleteInput
            sx={{ width: "100%" }}
            variant="outlined"
            size="medium"
            margin="none"
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData }) =>
            !formData.email_template_id &&
            emailTemplateModelId != 0 && (
              <>
                <EmailVariablesPreview
                  emailTemplateModelId={emailTemplateModelId}
                />
                <MultiEmailInput
                  source="cc"
                  label="Cc"
                  emailTemplateModelId={emailTemplateModelId}
                  sx={{ mb: 2, width: "100%" }}
                />
                <MultiEmailInput
                  source="bcc"
                  label="Bcc"
                  emailTemplateModelId={emailTemplateModelId}
                  sx={{ mb: 2, width: "100%" }}
                />
                <MultiEmailInput
                  source="reply_to"
                  label="Reply To"
                  emailTemplateModelId={emailTemplateModelId}
                  sx={{ mb: 2, width: "100%" }}
                />
                <EmailTranslatableInput
                  emailTemplateModelId={emailTemplateModelId}
                />
              </>
            )
          }
        </FormDataConsumer>

        <TextInput source="resource_name" sx={{ display: "none" }} />
        <TextInput source="resource_type" sx={{ display: "none" }} />
        <TextInput source="resource_ids" sx={{ display: "none" }} />
      </SimpleForm>
    </Edit>
  )
}
