import { Edit } from "react-admin"
import { PermissionForm } from "./PermissionForm"

export const PermissionEdit = () => {
  return (
    <Edit title="Edit Permission" redirect="list">
      <PermissionForm />
    </Edit>
  )
}
