import { Avatar, Box, Typography } from "@mui/material"
import { User } from "api/models"
import { ReferenceField, useRecordContext, WithRecord } from "react-admin"
import { HighlightedField } from "./HighlightedField"

export const ProfileChip = ({ filters = null }) => {
  const user: User = useRecordContext()

  const query = filters?.filterValues?.q
  if (user) {
    return (
      <Box display="flex" alignItems="center" flexDirection="column">
        <Box sx={{ height: "64px" }} mb={1}>
          <ReferenceField source="profile_id" reference="profiles" link={false}>
            <WithRecord
              render={function (profile) {
                return (
                  <Avatar
                    src={profile.avatar_picture?.src}
                    alt={profile.name}
                    sx={{ width: 64, height: 64 }}
                  >
                    {user.first_name && user.first_name[0]}
                    {user.last_name && user.last_name[0]}
                  </Avatar>
                )
              }}
            />
          </ReferenceField>
        </Box>

        <Typography mb={1}>
          <HighlightedField query={query} textToHighlight={user.name} />
        </Typography>

        <Box sx={{ fontSize: "14px", fontStyle: "italic", color: "grey.600" }}>
          <HighlightedField query={query} textToHighlight={user.email} />
        </Box>
      </Box>
    )
  }
}
