import CloseIcon from "@mui/icons-material/Close"
import DownloadIcon from "@mui/icons-material/Download"
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material"
import { Box } from "@mui/system"
import { useState } from "react"
import { useRecordContext, useTranslate } from "react-admin"
import { FileIcon, defaultStyles } from "react-file-icon"
import { FilePreviewDialog } from "../dialogs/FilePreviewDialog"

export const FilePreviewField = () => {
  const record = useRecordContext()
  const extRegex = /(?:\.([^.]+))?$/
  const extension = extRegex.exec(record.title)[1]
  const translate = useTranslate()
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const downloadFile = () => {
    window.open(record.src, "_blank")
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "#fcfcfe",
            width: "inherit",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>{translate("react.admin.preview")}</h2>
          <Button onClick={downloadFile} startIcon={<DownloadIcon />}>
            {translate("download")}
          </Button>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ minHeight: "80vh", width: "100%" }}>
          <FilePreviewDialog url={record.src} />
        </DialogContent>
      </Dialog>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          flexGrow: 1,
        }}
        onClick={handleOpen}
      >
        <Box sx={{ width: "40px", marginRight: "20px" }}>
          <FileIcon extension={extension} {...defaultStyles[extension]} />
        </Box>
        <Box sx={{ maxWidth: "190px" }}>{record.title}</Box>
      </Box>
    </>
  )
}
