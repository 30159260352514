import { Button } from "@mui/material"
import {
  ExportButton,
  FilterButton,
  InfiniteList,
  SelectColumnsButton,
  TopToolbar,
  useRecordContext,
  useStore,
  useTranslate,
} from "react-admin"

import PortraitIcon from "@mui/icons-material/Portrait"

import { CoachCardsList } from "./CoachCardsList"
import { CoachDatagrid } from "./CoachDatagrid"
import { CoachListFilterTopBar } from "../../components/filters/CoachListFilterTopBar"

import Box from "@mui/material/Box"
import { LanguageLevelProvider } from "../../providers/LanguageLevelProvider"
import { LanguageProvider } from "../../providers/LanguageProvider"
import { CoachListFilterSidebar } from "../../components/filters/CoachListFilterSidebar"

export const CoachList = () => {
  const translate = useTranslate()
  const [coachViewMode, setCoachViewMode] = useStore("coachViewMode", "cards")

  const CoachListActions = () => (
    <TopToolbar>
      {coachViewMode === "cards" && (
        <Button onClick={() => setCoachViewMode("datagrid")}>
          {translate("react.coaches.list_view")}
        </Button>
      )}
      {coachViewMode === "datagrid" && (
        <Button onClick={() => setCoachViewMode("cards")}>
          {translate("react.coaches.cards_view")}
        </Button>
      )}
      <FilterButton
        key="coach_filter_button"
        filters={CoachListFilterTopBar()}
      />

      {coachViewMode === "datagrid" && <SelectColumnsButton />}

      <ExportButton />
    </TopToolbar>
  )

  return (
    <>
      <InfiniteList
        filterDefaultValues={{
          active: true,
          purposes: "both",
          coaches: "unloq",
        }}
        resource="coaches"
        actions={<CoachListActions />}
        aside={<CoachListFilterSidebar />}
        sx={{
          "& .RaList-content": {
            backgroundColor: "inherit",
            boxShadow: "none",
          },
        }}
      >
        <Box>
          <LanguageLevelProvider>
            <LanguageProvider>
              {coachViewMode === "cards" && <CoachCardsList />}
              {coachViewMode === "datagrid" && <CoachDatagrid />}
            </LanguageProvider>
          </LanguageLevelProvider>
        </Box>
      </InfiniteList>
    </>
  )
}

export const EditProfileButton = () => {
  const translate = useTranslate()
  const record = useRecordContext()
  if (!record) return null

  return (
    <Button
      href={`/#/coaches/${record.id}/profile/${record.profile_id}`}
      startIcon={<PortraitIcon />}
    >
      {translate("react.coaches.profile")}
    </Button>
  )
}
