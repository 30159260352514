import {
  Datagrid,
  EditButton,
  List,
  ReferenceManyCount,
  TextField,
  WrapperField,
} from "react-admin"

export const LearningPathList = () => (
  <List hasCreate>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" />
      <ReferenceManyCount
        label="Modules"
        reference="learning-modules"
        target="learning_path_id"
        link
      />
      <ReferenceManyCount
        label="Steps"
        reference="learning-steps"
        target="learning_path_id"
        link
      />
      <WrapperField textAlign="right">
        <EditButton />
      </WrapperField>
    </Datagrid>
  </List>
)
