import { useRef, useState } from "react"
import { Alert, Button, Grid, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { canAccess } from "@react-admin/ra-rbac"
import { usePermissions, useRecordContext, useTranslate } from "ra-core"
import { useQueryClient } from "react-query"

import { dataProvider } from "../../dataProvider"
import { PinturaEditorWrapper } from "../../components/fields/PinturaEditorWrapper"

export const ProfilePhotoPreview = () => {
  const record = useRecordContext()
  const { permissions } = usePermissions()
  const translate = useTranslate()
  const queryClient = useQueryClient()

  let canUploadAvatars = false
  if (
    canAccess({
      permissions,
      action: "update_avatar",
      resource: "coaches",
    })
  ) {
    canUploadAvatars = true
  }

  const hasOriginal =
    record.avatar_picture_original != null && record.avatar_picture_original.src

  const [showPhotoEditor, setShowPhotoEditor] = useState(false)
  const [showAvatarEditor, setShowAvatarEditor] = useState(false)
  const [editedPhotoSrc, setEditedPhotoSrc] = useState(
    record.photo_picture?.src
  )
  const [editedAvatarSrc, setEditedAvatarSrc] = useState(
    record.avatar_picture_original?.src
  )
  const [statusText, setStatusText] = useState("")

  const photoEditorRef = useRef(null)
  const avatarEditorRef = useRef(null)

  const handlePhotoEdit = () => {
    setShowPhotoEditor(true)
  }

  const handleAvatarEdit = () => {
    setShowAvatarEditor(true)
  }

  const handleCancelEdit = (editorType: string) => {
    if (editorType === "photo") {
      setShowPhotoEditor(false)
    } else {
      setShowAvatarEditor(false)
    }
  }

  const mutate = async (dest: Blob | MediaSource, type: string) => {
    const url = URL.createObjectURL(dest)
    const blob = await fetch(url).then((r) => r.blob())

    const myFile = new File([blob], "image.jpeg", {
      type: blob.type,
    })

    let diff: object

    if (type === "avatar") {
      diff = { avatar_picture: { rawFile: myFile } }
    } else if (type === "photo") {
      diff = { photo_picture: { rawFile: myFile } }
    }

    try {
      await dataProvider
        .update("profiles", {
          id: record.id,
          data: diff,
          previousData: record,
        })
        .then(function (response) {
          if (type === "avatar") {
            setStatusText("Avatar updated")
            setEditedAvatarSrc(url)
            setShowAvatarEditor(false)
          } else {
            setStatusText("Profile photo updated")
            setEditedPhotoSrc(url)
            setShowPhotoEditor(false)
          }
          queryClient.invalidateQueries("profiles")
          return response
        })
    } catch (error) {
      console.error("Error updating image:", error)
    }
  }

  return (
    <>
      <Typography variant="h6" mb={1} fontWeight="bold">
        {translate("react.coaches.avatars.preview")}
      </Typography>
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        sx={{ textAlign: "center" }}
      >
        <Grid item xs={12} md={6}>
          {!showAvatarEditor && hasOriginal ? (
            <>
              <Typography variant="subtitle1">
                {translate("react.coaches.avatars.avatar.label")}
              </Typography>
              <Box maxWidth="200px" margin="0 auto">
                <img src={editedAvatarSrc} alt="" />
              </Box>
              {canUploadAvatars && (
                <Button onClick={handleAvatarEdit} sx={{ mt: 2 }}>
                  Edit Avatar
                </Button>
              )}
            </>
          ) : !showAvatarEditor ? (
            <>
              <Typography variant="subtitle1">
                {translate("react.coaches.avatars.avatar.no_avatar")}
              </Typography>
            </>
          ) : (
            <Box sx={{ height: "600px", marginTop: 2, marginBottom: 4 }}>
              <PinturaEditorWrapper
                ref={avatarEditorRef}
                cropSelectPresetFilter={"landscape"}
                src={editedAvatarSrc || record.avatar_picture_original?.src}
                onProcess={({ dest }) => mutate(dest, "avatar")}
                labelButtonExport="Save avatar"
              />
              <Button onClick={() => handleCancelEdit("avatar")}>Cancel</Button>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {!showPhotoEditor && editedPhotoSrc ? (
            <>
              <Typography variant="subtitle1">
                {translate("react.coaches.avatars.photo.label")}
              </Typography>
              <img src={editedPhotoSrc} alt="" />
              {canUploadAvatars && (
                <Button onClick={handlePhotoEdit} sx={{ mt: 2 }}>
                  Edit Photo
                </Button>
              )}
            </>
          ) : !showPhotoEditor ? (
            <>
              <Typography variant="subtitle1">
                {translate("react.coaches.avatars.photo.no_photo")}
              </Typography>
            </>
          ) : (
            <Box sx={{ height: "600px", marginTop: 2, marginBottom: 4 }}>
              <PinturaEditorWrapper
                ref={photoEditorRef}
                cropSelectPresetFilter={"landscape"}
                src={editedPhotoSrc || record.photo_picture?.src}
                onProcess={({ dest }) => mutate(dest, "photo")}
                labelButtonExport="Save photo"
              />
              <Button onClick={() => handleCancelEdit("photo")}>Cancel</Button>
            </Box>
          )}
        </Grid>
      </Grid>
      {statusText && (
        <Alert severity="success" sx={{ mt: 2 }}>
          {statusText}
        </Alert>
      )}
    </>
  )
}
