import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import { SimpleForm, TextInput, useTranslate } from "react-admin"

import { IRobinChatBubble } from "../../components/forms/IRobinChatBubble"
import { CvPurposeInput } from "../../components/inputs/CvPurposeInput"
import { AssociateUsersList } from "./AssociateUserList"

export const AssociateListForm = ({ showAssociateList = false }) => {
  const translate = useTranslate()

  return (
    <>
      <IRobinChatBubble>
        <Typography variant="h6" mb={1} fontWeight="bold">
          {translate("react.admin.associate_lists.title")}
        </Typography>

        <Typography my={1}>
          <Box
            component="span"
            dangerouslySetInnerHTML={{
              __html: translate("react.admin.associate_lists.intro"),
            }}
          />
        </Typography>
      </IRobinChatBubble>
      <SimpleForm sx={{ p: 4 }}>
        <TextInput source="name" fullWidth />
        <CvPurposeInput source="cv_purpose" />
        {showAssociateList && <AssociateUsersList />}
      </SimpleForm>
    </>
  )
}
