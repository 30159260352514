import {
  DateInput,
  Edit,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from "react-admin"

export const LearningStepProgressEdit = () => (
  <Edit>
    <SimpleForm>
      <ReferenceInput source="learning_step_id" reference="learning-steps" />
      <TextInput source="status" />
      <DateInput source="modified_at" />
      <DateInput source="created_at" />
      <DateInput source="updated_at" />
      <ReferenceInput
        source="program_membership_id"
        reference="program_memberships"
      />
    </SimpleForm>
  </Edit>
)
