/* eslint-disable react/prop-types */
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  EditButton,
  List,
  NumberField,
  ReferenceField,
  SearchInput,
} from "react-admin"

const filters = [
  <SearchInput key="search" source="email" alwaysOn sx={{ width: "100%" }} />,
  <BooleanInput
    label="Unmatched"
    key="unmatched"
    source="unmatched"
    alwaysOn
    sx={{
      height: "50px",
      display: "flex",
      justifyContent: "center",
      width: "100%",
    }}
  />,
]

export const ProgramMembershipList = (props) => {
  return (
    <List
      resource="program-memberships"
      disableSyncWithLocation
      filters={filters}
      perPage={50}
      {...props}
    >
      <Datagrid>
        <ReferenceField
          label="Client"
          source="user_id"
          reference="clients"
          link="show"
        />
        <ReferenceField
          label="Coach"
          source="main_coach_id"
          reference="coaches"
          link="show"
        />
        <BooleanField label="Active" source="active" />
        <BooleanField label="Rated" source="rating_enabled" />
        <NumberField label="Extra (min)" source="extra_minutes_for_client" />
        <DateField label="Matched" source="matched_at" />
        <DateField label="Created" source="created_at" />
        <EditButton />
      </Datagrid>
    </List>
  )
}
