import { Divider, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useTranslate } from "ra-core"

export const CoachCardField = ({ children, label }) => {
  const translate = useTranslate()

  return (
    <>
      <Divider sx={{ my: 2 }} />
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography fontWeight="700" mb={1}>
          {translate(label)}
        </Typography>
        {children}
      </Box>
    </>
  )
}
