import { Card, CardContent } from "@mui/material"
import {
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  SavedQueriesList,
} from "react-admin"

import MailIcon from "@mui/icons-material/MailOutline"

export const LearningPathListFilterSidebar = () => (
  <Card sx={{ order: -1, mr: 2, width: 275 }}>
    <CardContent>
      <FilterLiveSearch sx={{ marginTop: "0px" }} />
      <SavedQueriesList />
      <FilterList label="User status" icon={<MailIcon />}>
        <FilterListItem label="Active" value={{ active: true }} />
        <FilterListItem label="Inactive" value={{ active: false }} />
      </FilterList>
      <FilterList label="Program status" icon={<MailIcon />}>
        <FilterListItem
          label="Upcoming"
          value={{ program_status: "upcoming" }}
        />
        <FilterListItem label="Active" value={{ program_status: "active" }} />
        <FilterListItem label="Ended" value={{ program_status: "ended" }} />
      </FilterList>
    </CardContent>
  </Card>
)
