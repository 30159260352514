import { Chip } from "@mui/material"

export const emailVariableField = (attr) => {
  if (attr === null) {
    return
  }
  if (Array.isArray(attr)) {
    return attr.map((item) => {
      if (item == null) {
        return (
          <Chip
            key={item}
            label="Missing variable"
            sx={{ marginBottom: "8px", backgroundColor: "#F00", color: "#fff" }}
          />
        )
      }
      return <Chip key={item} label={item} sx={{ marginBottom: "8px" }} />
    })
  } else {
    return <Chip key={attr} label={attr} sx={{ marginBottom: "8px" }} />
  }
}
