import { Box, Typography } from "@mui/material"
import { IfCanAccess } from "@react-admin/ra-rbac"
import { Menu } from "react-admin"

import HourglassBottomIcon from "@mui/icons-material/HourglassBottom"
import TrendingUpIcon from "@mui/icons-material/TrendingUp"
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt"
import ListItemIcon from "@mui/material/ListItemIcon"

const headerStyle = {
  marginLeft: "10px",
  marginTop: "15px",
  color: "#616161",
  fontWeight: "bold",
}

const menuSx = {
  pb: 2,
  my: 0,
}

export const UnloqMenu = () => {
  return (
    <Box mb={4} width="100%">
      <Typography style={headerStyle}>Organization</Typography>
      <Menu sx={menuSx}>
        <IfCanAccess action="list" resource="programs">
          <Menu.ResourceItem name="programs" />
          <Menu.ResourceItem name="organizations" />
          <Menu.ResourceItem name="program-memberships" />
          <Menu.ResourceItem name="profiles" />
        </IfCanAccess>
        <IfCanAccess action="manage_associates" resource="coaches">
          <Menu.ResourceItem name="coaches" />
        </IfCanAccess>
        <IfCanAccess action="manage_associates" resource="coaches">
          <Menu.ResourceItem name="associate-lists" />
        </IfCanAccess>
      </Menu>

      <IfCanAccess action="list" resource="surveys">
        <Typography style={headerStyle}>People Analytics</Typography>
        <Menu sx={menuSx}>
          <Menu.ResourceItem name="surveys" />
          <Menu.ResourceItem name="questionnaires" />
        </Menu>
      </IfCanAccess>

      <IfCanAccess action="list" resource="emails">
        <Typography style={headerStyle}>Emails</Typography>
        <Menu sx={menuSx}>
          <Menu.ResourceItem name="emails" />
          <Menu.ResourceItem name="email-batches" />
          <Menu.ResourceItem name="email-templates" />
        </Menu>
      </IfCanAccess>

      <IfCanAccess action="list" resource="learning-paths">
        <Typography style={headerStyle}>Courses</Typography>
        <Menu sx={menuSx}>
          <Menu.ResourceItem name="learning-paths" />
          <Menu.ResourceItem name="learning-modules" />
          <Menu.ResourceItem name="learning-steps" />
          <Menu.Item
            to="/learning-path-progress"
            primaryText="Progress"
            leftIcon={<HourglassBottomIcon />}
          />
          <Menu.Item
            to="/learning-path-reporting"
            primaryText="Reporting"
            leftIcon={<TrendingUpIcon />}
          />
        </Menu>
      </IfCanAccess>

      <IfCanAccess action="list" resource="permissions">
        <Typography style={headerStyle}>Admin</Typography>
        <Menu sx={menuSx}>
          <IfCanAccess action="list" resource="audit-logs">
            <Menu.ResourceItem name="audit-logs" />
            {/*<Badge badgeContent={'new'} color="primary" overlap="circular" anchorOrigin={{*/}
            {/*  vertical: 'top',*/}
            {/*  horizontal: 'right',*/}
            {/*}} >*/}
            <Menu.Item to="/change-requests" primaryText="Change Requests">
              <ListItemIcon classes={{ root: "RaMenuItemLink-icon" }}>
                <SentimentSatisfiedAltIcon />
              </ListItemIcon>
              <Typography>Change Requests</Typography>
            </Menu.Item>
            {/*</Badge>*/}
          </IfCanAccess>
          <IfCanAccess action="list" resource="permissions">
            <Menu.ResourceItem name="permissions" />
          </IfCanAccess>
          <IfCanAccess action="list" resource="client-imports">
            <Menu.ResourceItem name="client-imports" />
          </IfCanAccess>
        </Menu>
      </IfCanAccess>

      <IfCanAccess action="manage" resource="profile-traits">
        <Typography style={headerStyle}>Profile Traits</Typography>
        <Menu sx={menuSx}>
          <IfCanAccess action="edit" resource="branch-experiences">
            <Menu.ResourceItem name="branch-experiences" />
          </IfCanAccess>
          <IfCanAccess action="edit" resource="certification-levels">
            <Menu.ResourceItem name="certification-levels" />
          </IfCanAccess>
          <IfCanAccess action="edit" resource="group-works">
            <Menu.ResourceItem name="group-works" />
          </IfCanAccess>
          <IfCanAccess action="edit" resource="language-levels">
            <Menu.ResourceItem name="language-levels" />
          </IfCanAccess>
          <IfCanAccess action="edit" resource="professions">
            <Menu.ResourceItem name="professions" />
          </IfCanAccess>
          <IfCanAccess action="edit" resource="regions">
            <Menu.ResourceItem name="regions" />
          </IfCanAccess>
          <IfCanAccess action="edit" resource="specializations">
            <Menu.ResourceItem name="specializations" />
          </IfCanAccess>
        </Menu>
      </IfCanAccess>
    </Box>
  )
}
