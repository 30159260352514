import { Grid } from "@mui/material"

import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  Edit,
  NumberInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
} from "react-admin"

export const ProgramEdit = () => {
  const transform = (data) => ({
    record: data.record,
    description: data.description,
    organization_id: data.organization_id,
    questionnaire_id: data.questionnaire_id,
    learning_path_id: data.learning_path_id,
    minutes_per_client: data.minutes_per_client,
    reminder_days: data.reminder_days,
    enrollment_open: data.enrollment_open,
    start_date: data.start_date,
    end_date: data.end_date,
  })
  return (
    <Edit transform={transform}>
      <SimpleForm>
        <TextInput source="name" validate={[required()]} fullWidth />
        <TextInput source="description" fullWidth multiline />
        <ReferenceInput
          source="organization_id"
          reference="organizations"
          validate={[required()]}
        >
          <AutocompleteInput sx={{ width: "100%" }} />
        </ReferenceInput>
        <ReferenceInput
          source="questionnaire_id"
          reference="questionnaires"
          validate={[required()]}
        >
          <AutocompleteInput sx={{ width: "100%" }} />
        </ReferenceInput>
        <ReferenceInput source="learning_path_id" reference="learning-paths">
          <AutocompleteInput sx={{ width: "100%" }} />
        </ReferenceInput>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <NumberInput source="reminder_days" sx={{ width: "100%" }} />
          </Grid>
          <Grid item xs={3}>
            <NumberInput source="minutes_per_client" sx={{ width: "100%" }} />
          </Grid>
          <Grid item xs={3}>
            <BooleanInput source="enrollment_open" sx={{ width: "100%" }} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DateInput source="start_date" sx={{ width: "100%" }} />
          </Grid>
          <Grid item xs={6}>
            <DateInput source="end_date" sx={{ width: "100%" }} />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  )
}
