/* eslint-disable react/prop-types */
import { Avatar } from "@mui/material"
import { IfCanAccess } from "@react-admin/ra-rbac"
import {
  Datagrid,
  DateField,
  EmailField,
  InfiniteList,
  ReferenceField,
  SelectField,
  TextInput,
  useRecordContext,
} from "react-admin"
import { RematchClientButton } from "../buttons/RematchClientButton"

const languages = [
  { id: "nl", name: "Dutch" },
  { id: "en", name: "English" },
  { id: "fr", name: "French" },
  { id: "es", name: "Spanish" },
]

const filters = [
  <TextInput
    key="search"
    label="Email"
    source="email"
    alwaysOn
    sx={{ width: "100%" }}
  />,
]

export const SimpleClientList = ({ programId }) => {
  const record = useRecordContext()

  return (
    <InfiniteList
      resource="clients"
      disableSyncWithLocation
      filters={filters}
      filter={{ program_id: programId }}
      filterDefaultValues={{ program_id: programId }}
    >
      <Datagrid>
        {record && <Avatar src={record.avatar_url} alt={record.name} />}
        <ReferenceField
          label="Name"
          source="profile_id"
          reference="profiles"
          link="show"
        />
        <EmailField source="email" />
        <SelectField source="language" choices={languages} />
        <DateField label="Last login" source="last_login_at" />
        <DateField label="Created" source="created_at" />
        <IfCanAccess action="rematch" resource="clients">
          <RematchClientButton oldProgramId={programId} />
        </IfCanAccess>
      </Datagrid>
    </InfiniteList>
  )
}
