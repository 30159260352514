import ChatBubbleIcon from "@mui/icons-material/ChatBubble"
import { IconButton, Tooltip } from "@mui/material"
import { I18n } from "i18n-js"
import translations from "../locales.json"
import UnloqTheme from "./unloq_theme"

const i18n = new I18n(translations)

const CommentsDisabledIcon = () => {
  return (
    <UnloqTheme>
      <Tooltip title={i18n.t("comments.disabled")}>
        <IconButton>
          <ChatBubbleIcon />
        </IconButton>
      </Tooltip>
    </UnloqTheme>
  )
}
export default CommentsDisabledIcon
