import {
  Button,
  CreateButton,
  Datagrid,
  DateField,
  FunctionField,
  List,
  ReferenceField,
  ReferenceManyCount,
  useRecordContext,
} from "react-admin"

import { Card, CardContent, Link, Tooltip, Typography } from "@mui/material"

import EmailIcon from "@mui/icons-material/Email"
import { Box } from "@mui/system"

import { IconOnlyDeleteButton } from "../../components/buttons/IconOnlyDeleteButton"
import { IconOnlyEditButton } from "../../components/buttons/IconOnlyEditButton"
import { EmailBodyField } from "../../components/fields/EmailBodyField"
import { EmailSubjectField } from "../../components/fields/EmailSubjectField"
import { emailVariableField } from "../../components/fields/EmailVariableField"

const ShowMorePanel = () => {
  return (
    <Box display="flex" gap={2} py={2}>
      <Box>
        <Card>
          <CardContent>
            <Typography fontWeight="bold" sx={{ mb: 1 }}>
              Reply-to
            </Typography>
            <FunctionField
              label="Reply To"
              source="reply_to"
              render={(record) => emailVariableField(record.reply_to)}
            />
            <Typography fontWeight="bold" sx={{ mb: 1 }}>
              CC
            </Typography>
            <FunctionField
              label="CC"
              source="cc"
              render={(record) =>
                record.cc.length > 0
                  ? emailVariableField(record.cc)
                  : "Undefined"
              }
            />
            <Typography fontWeight="bold" sx={{ mb: 1 }}>
              BCC
            </Typography>
            <FunctionField
              label="BCC"
              source="bcc"
              render={(record) =>
                record.bcc?.length > 0
                  ? emailVariableField(record.bcc)
                  : "Undefined"
              }
            />
          </CardContent>
        </Card>
      </Box>
      <Box>
        <Card>
          <CardContent sx={{ maxWidth: "500px" }}>
            <EmailBodyField source="body_preview" label="Body" />
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
}

export const EmailBatchList = () => {
  return (
    <List
      actions={<CreateButton />}
      hasCreate={true}
      sort={{ field: "created_at", order: "DESC" }}
    >
      <Datagrid
        rowClick="expand"
        bulkActionButtons={false}
        expand={<ShowMorePanel />}
      >
        <EmailSubjectField source="subject_preview" label="Subject" />

        <ReferenceField
          label="Template"
          source="email_template_id"
          reference="email-templates"
        />
        <ReferenceManyCount
          label="E-mails"
          reference="emails"
          target="email_batch_id"
          link={false}
        />
        <ReferenceManyCount
          label="Delivered"
          reference="emails"
          filter={{ status: "delivered" }}
          target="email_batch_id"
          link={false}
        />
        <DateField label="Created" source="created_at" showTime />
        <Box display="flex">
          <PreviewButton />
          <IconOnlyEditButton />
          <IconOnlyDeleteButton />
        </Box>
      </Datagrid>
    </List>
  )
}

const PreviewButton = () => {
  const record = useRecordContext()
  if (!record) return null

  return (
    <Tooltip title="Preview & Send">
      <Link href={`#/emails?filter={"email_batch_id":"${record.id}"}`}>
        <Button
          label=""
          startIcon={<EmailIcon />}
          sx={{
            minWidth: 0,
            padding: "4px",
            "& .MuiButton-startIcon": { margin: 0, padding: 0 },
          }}
        />
      </Link>
    </Tooltip>
  )
}
