import { useDataProvider, useRefresh } from "ra-core"
import { DatagridBody, DatagridBodyProps } from "ra-ui-materialui"
import { useCallback } from "react"
import Sortable from "sortablejs"

export const AssociateDatagridBody = (props: DatagridBodyProps) => {
  const dataProvider = useDataProvider()
  const refresh = useRefresh()

  const dataGridRef = useCallback(
    (node: HTMLElement) => {
      if (node !== null) {
        new Sortable(node, {
          handle: ".handle",
          draggable: "tr",
          animation: 150,
          easing: "cubic-bezier(1, 0, 0, 1)",
          onEnd: (event) => {
            updatePosition(
              dataProvider,
              refresh,
              event.item.childNodes[1].innerText,
              event.newIndex
            )
          },
        })
      }
    },
    [dataProvider, refresh]
  )

  return <DatagridBody {...props} ref={dataGridRef} />
}

const updatePosition = (dataProvider, refresh, id, position) => {
  dataProvider.getOne("associate-list-users", { id: id }).then(({ data }) => {
    const diff = { position: position }
    dataProvider
      .update("associate-list-users", {
        id: id,
        data: diff,
        previousData: data,
      })
      .then(() => {
        refresh()
      })
  })
}
