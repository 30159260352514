import {
  AutocompleteArrayInput,
  AutocompleteInput,
  ReferenceInput,
  SimpleForm,
  useRecordContext,
  useResourceDefinitions,
} from "react-admin"

export const PermissionForm = () => {
  const record = useRecordContext()
  const resourceDefinitions = useResourceDefinitions()
  const resourceChoices = []

  Object.keys(resourceDefinitions)
    .sort()
    .map((resourceName) => {
      resourceChoices.push({
        id: resourceName,
        name: resourceName,
      })
    })

  const actionChoices = [
    { id: "*", name: "*" },
    { id: "list", name: "list" },
    { id: "read", name: "read" },
    { id: "create", name: "create" },
    { id: "edit", name: "edit" },
    { id: "show", name: "show" },
    { id: "export", name: "export" },
  ]

  if (record) {
    record.actions.map((action) => {
      actionChoices.push({ id: action, name: action })
    })
  }

  return (
    <SimpleForm sx={{ p: 4 }}>
      <ReferenceInput source="user_id" reference="users" sx={{ width: "100%" }}>
        <AutocompleteInput optionText="email" sx={{ width: "100%" }} />
      </ReferenceInput>
      <AutocompleteInput
        source="resource"
        choices={resourceChoices}
        translateChoice={false}
        sx={{ width: "100%" }}
        onCreate={(currentValue) => {
          const newResource = {
            id: currentValue,
            name: currentValue,
          }
          resourceChoices.push(newResource)
          return newResource
        }}
      />
      <AutocompleteArrayInput
        source="actions"
        choices={actionChoices}
        translateChoice={false}
        onCreate={(currentValue) => {
          const newAction = {
            id: currentValue,
            name: currentValue,
          }
          actionChoices.push(newAction)
          return newAction
        }}
        fullWidth
      />
    </SimpleForm>
  )
}
