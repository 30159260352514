import { Tooltip } from "@mui/material"
import { DeleteButton } from "ra-ui-materialui"

export const IconOnlyDeleteButton = () => {
  return (
    <Tooltip title="Delete">
      <div>
        {/* Extra div required to make the tooltip work */}
        <DeleteButton
          label=""
          sx={{
            minWidth: 0,
            padding: "4px",
            margin: "4px",
            "& .MuiButton-startIcon": { margin: 0 },
          }}
        />
      </div>
    </Tooltip>
  )
}
