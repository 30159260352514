import styled from "@emotion/styled"
import { mergeAttributes, Node } from "@tiptap/core"
import {
  NodeViewContent,
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from "@tiptap/react"

const EmailContent = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 20px;
  }
  .is-empty::before {
    content: attr(data-placeholder);
    float: left;
    color: #979ca1;
    pointer-events: none;
    height: 0;
  }
  .mention {
    border: 1px solid #000;
    border-radius: 0.4rem;
    padding: 0.1rem 0.3rem;
    box-decoration-break: clone;
  }
`

export const NodeWrapper = () => {
  return (
    <NodeViewWrapper>
      <EmailContent>
        <NodeViewContent />
      </EmailContent>
    </NodeViewWrapper>
  )
}

export const EmailTemplateSubjectNode = Node.create({
  name: "emailSubject",
  group: "block",
  content: "inline*",

  parseHTML() {
    return [
      {
        tag: "email-subject",
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ["email-subject", mergeAttributes(HTMLAttributes), 0]
  },

  addNodeView() {
    return ReactNodeViewRenderer(NodeWrapper)
  },
})
