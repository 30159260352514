import { Box } from "@mui/material"
import { useRecordContext } from "ra-core"

export const ColorField = ({ source }) => {
  const record = useRecordContext()

  if (!record) {
    return null
  }

  return (
    <Box
      sx={{
        width: "20px",
        height: "20px",
        backgroundColor: record[source],
        margin: "0 auto",
      }}
    />
  )
}
