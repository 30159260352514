import { Create } from "react-admin"
import { ClientImportForm } from "./ClientImportForm"

export const ClientImportCreate = () => {
  return (
    <Create>
      <ClientImportForm />
    </Create>
  )
}
