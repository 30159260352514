/* eslint-disable react/prop-types */
import { Email } from "@mui/icons-material"
import { Button, useListContext, useRedirect } from "react-admin"

export const BulkEmailBatchAction = ({ resourceName, resourceType }) => {
  const { selectedIds } = useListContext()
  const redirect = useRedirect()

  const handleOpen = () => {
    const resourceIds = selectedIds.join(",")
    redirect(
      `/email-batches/create?source={"resource_name":"${resourceName}","resource_type":"${resourceType}","resource_ids":[${resourceIds}]}`,
    )
  }

  return (
    <Button label="Create e-mail batch" onClick={handleOpen}>
      <Email />
    </Button>
  )
}
