import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  EditButton,
  EmailField,
  InfiniteList,
  ReferenceField,
  TextField,
  TextInput,
} from "react-admin"

import { ProgramSelect } from "../../components/inputs/ProgramSelect"

const filters = [
  <TextInput
    key="search"
    label="Email"
    source="email"
    alwaysOn
    sx={{ width: "100%" }}
  />,
  <ProgramSelect key="program" />,
  <BooleanInput
    key="active"
    source="active"
    label="Active"
    alwaysOn
    sx={{
      height: "50px",
      display: "flex",
      justifyContent: "center",
      width: "100%",
    }}
  />,
]

export const UserList = (props) => (
  <InfiniteList resource="users" filters={filters} {...props}>
    <Datagrid rowClick="edit">
      <EmailField source="email" />
      <TextField source="roles" />
      <ReferenceField source="profile_id" reference="profiles" />
      <TextField source="language" />
      <DateField source="last_login_at" />
      <BooleanField source="active" />
      <DateField source="created_at" />
      <EditButton label="Edit" />
    </Datagrid>
  </InfiniteList>
)
