import {
  DeleteWithConfirmButton,
  Edit,
  ReferenceField,
  SimpleForm,
  TextField,
  Toolbar,
  useDataProvider,
  WithRecord,
} from "react-admin"
import { useEffect, useState } from "react"
import { Typography } from "@mui/material"
import CancelIcon from "@mui/icons-material/Cancel"
import { Link as ReactRouterLink } from "react-router-dom"
import { Button } from "ra-ui-materialui"

import { ApproachDiff } from "./ApproachDiff"
import { AvatarDiff } from "./AvatarDiff"

export const ChangeRequestToolbar = () => (
  <Toolbar>
    <DeleteWithConfirmButton
      confirmColor="warning"
      label="Close change request and delete it"
      icon={<CancelIcon />}
      confirmContent={
        "If you want to reject this change request, please also contact the coach and explain them why it was rejected."
      }
    />
  </Toolbar>
)

const ShowCoachButton = ({ changeRequest }) => {
  const [curriculumVitae, setCurriculumVitae] = useState(null)
  const dataProvider = useDataProvider()

  useEffect(() => {
    if (changeRequest.changeable_type === "CurriculumVitae") {
      try {
        dataProvider
          .getOne("curriculum-vitaes", {
            id: changeRequest.changeable_id,
          })
          .then(({ data }) => {
            setCurriculumVitae(data)
          })
      } catch (error) {
        setCurriculumVitae(null)
      }
    }
  }, [changeRequest.changeable_id, changeRequest.changeable_type, dataProvider])

  const changeable_url = function (changeRequest) {
    if (changeRequest.changeable_type == "Profile") {
      return `/profiles/${changeRequest.changeable_id}/personal-information`
    } else if (changeRequest.changeable_type == "CurriculumVitae") {
      if (curriculumVitae) {
        if (curriculumVitae.purpose == "default") {
          return `/coaches/${curriculumVitae.user_id}/default-cv/job-information`
        } else if (curriculumVitae.purpose == "commercial") {
          return `/coaches/${curriculumVitae.user_id}/commercial-cv/job-information`
        }
      }
    }
  }

  return (
    <ReactRouterLink to={changeable_url(changeRequest)} target="_blank">
      <Button label="Show coach" />
    </ReactRouterLink>
  )
}

export function ChangeRequestEdit() {
  return (
    <Edit actions={false}>
      <SimpleForm toolbar={<ChangeRequestToolbar />}>
        <Typography variant="h5" mb={3} fontWeight="bold">
          Approve or reject
        </Typography>

        <UserInfo />
      </SimpleForm>
    </Edit>
  )
}

export const UserInfo = () => (
  <>
    <WithRecord
      render={(changeRequest) => {
        let reference: string

        if (changeRequest.changeable_type == "Profile") {
          reference = "profiles"
        } else if (changeRequest.changeable_type == "CurriculumVitae") {
          reference = "curriculum-vitaes"
        }
        return (
          <>
            <ReferenceField
              reference={reference}
              source="changeable_id"
              link={false}
            >
              <>
                Coach name:&nbsp;
                <TextField source="name" mb={3} fontSize="1em" />
                &nbsp; (
                <ReferenceField reference="users" source="user_id" link={false}>
                  <TextField source="email" mb={3} fontSize="1em" />
                </ReferenceField>
                ) &nbsp;
                <ShowCoachButton changeRequest={changeRequest} />
              </>
            </ReferenceField>

            {changeRequest.field_changed == "avatar" && <AvatarDiff />}

            {changeRequest.field_changed == "approach_en" && <ApproachDiff />}
          </>
        )
      }}
    />
  </>
)
