import { useState } from "react"
import {
  BooleanInput,
  BulkExportButton,
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  TextInput,
} from "react-admin"

import { BulkTranslateAction } from "../../components/bulk_actions/BulkTranslateAction"
import { TranslatableTextField } from "../../components/fields/TranslatableTextField"

export const ProfileTraitList = ({ model, plural, ...rest }) => {
  const [isTranslating, setIsTranslating] = useState(false)

  const filters = [
    <TextInput
      source="name"
      name="name"
      key="name"
      label="react.admin.name"
      alwaysOn
      sx={{ width: "500px" }}
    />,
    <BooleanInput
      label="react.admin.untranslated"
      key="untranslated"
      source="untranslated"
      alwaysOn
      sx={{
        height: "50px",
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    />,
  ]

  return (
    <List
      hasCreate
      resource={plural}
      filters={filters}
      sort={{ field: "name.en", order: "ASC" }}
      perPage={25}
      {...rest}
    >
      <Datagrid
        rowClick="toggleSelection"
        bulkActionButtons={
          <>
            <BulkTranslateAction
              model={model}
              plural={plural}
              setIsTranslating={setIsTranslating}
            />
            <BulkExportButton />
          </>
        }
      >
        {["en", "es", "fr", "nl"].map((locale) => (
          <TranslatableTextField
            key={`name.${locale}`}
            source={`name.${locale}`}
            label={`Name (${locale})`}
            locale={locale}
            isTranslating={isTranslating}
          />
        ))}
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  )
}
