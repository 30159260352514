import Highlighter from "react-highlight-words"

export const HighlightedField = ({ query, textToHighlight }) => {
  return (
    <Highlighter
      highlightStyle={{ backgroundColor: "yellow" }}
      searchWords={[query]}
      autoEscape={true}
      textToHighlight={textToHighlight}
    />
  )
}
