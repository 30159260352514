import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button as MuiButton,
  Tooltip,
} from "@mui/material"
import {
  FormDataConsumer,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
} from "ra-core"
import { Button, SimpleForm } from "ra-ui-materialui"
import { useState } from "react"
import { useMutation } from "react-query"
import { ProgramSelect } from "../inputs/ProgramSelect"
import { CoachSelect } from "../inputs/CoachSelect"

export const RematchClientButton = ({ oldProgramId }) => {
  const record = useRecordContext()
  const translate = useTranslate()
  const [confirmOpen, setConfirmOpen] = useState(false)

  const openConfirmDialog = () => {
    setConfirmOpen(true)
  }

  return (
    <>
      <Tooltip title={translate("react.admin.rematch_client")}>
        <Button
          label="react.admin.rematch"
          onClick={openConfirmDialog}
          sx={{ margin: "0 0.5rem" }}
        />
      </Tooltip>
      <ConfirmDialog
        title={translate("react.admin.rematch_client")}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        clientId={record.id}
        oldProgramId={oldProgramId}
      />
    </>
  )
}

const ConfirmDialog = (props) => {
  const translate = useTranslate()

  const { title, open, setOpen, clientId, oldProgramId } = props

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="rematch-dialog"
    >
      <DialogTitle id="rematch-dialog">{title}</DialogTitle>
      <SimpleForm toolbar={null}>
        <ProgramSelect />
        <FormDataConsumer>
          {({ formData }) => {
            if (formData.program_id != null) {
              return (
                <>
                  <CoachSelect
                    source="main_coach_id"
                    filters={{ program: formData.program_id, role: "coach" }}
                  />
                  <FormDataConsumer>
                    {({ formData }) => {
                      if (formData.main_coach_id != null) {
                        return (
                          <DialogActions sx={{ width: "100%" }}>
                            <MuiButton
                              variant="contained"
                              onClick={() => setOpen(false)}
                              color="error"
                            >
                              {translate("btn.cancel")}
                            </MuiButton>
                            <ConfirmButton
                              clientId={clientId}
                              oldProgramId={oldProgramId}
                              newProgramId={formData.program_id}
                              newCoachId={formData.main_coach_id}
                              setOpen={setOpen}
                            />
                          </DialogActions>
                        )
                      }
                    }}
                  </FormDataConsumer>
                </>
              )
            }
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Dialog>
  )
}

const ConfirmButton = ({
  clientId,
  oldProgramId,
  newProgramId,
  newCoachId,
  setOpen,
}) => {
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const translate = useTranslate()
  const refresh = useRefresh()

  const match = async () => {
    return await dataProvider.rematch(
      clientId,
      oldProgramId,
      newProgramId,
      newCoachId
    )
  }

  const { mutate: rematchClient, isLoading } = useMutation(match, {
    onSuccess: (data) => {
      if (data.status == 200) {
        notify(`react.clients.rematch_success`, { type: "success" })
        setOpen(false)
        refresh()
      } else {
        notify(`react.clients.rematch_failed`, { type: "error" })
      }
    },
  })

  const handleClick = () => {
    rematchClient()
  }

  return (
    <MuiButton
      variant="contained"
      onClick={handleClick}
      disabled={isLoading}
      color="success"
    >
      {translate("btn.save")}
    </MuiButton>
  )
}
