import { ReferenceManyToManyField } from "@react-admin/ra-relationships"
import {
  ChipField,
  ReferenceOneField,
  SingleFieldList,
  WithRecord,
} from "react-admin"

export const SpecializationsField = ({ filters }) => {
  return (
    <ReferenceOneField
      label="react.coaches.cv.specializations"
      reference="curriculum-vitaes"
      target="user_id"
    >
      <ReferenceManyToManyField
        reference="specializations"
        through="cv-specializations"
        using="curriculum_vitae_id,specialization_id"
      >
        <SingleFieldList sx={{ gap: "0", justifyContent: "center" }}>
          <WithRecord
            render={(specialization) => (
              <ChipField
                source="name.en"
                sx={{
                  fontWeight: filters.filterValues.specializations?.includes(
                    specialization.id
                  )
                    ? "bold"
                    : "normal",
                }}
              />
            )}
          />
        </SingleFieldList>
      </ReferenceManyToManyField>
    </ReferenceOneField>
  )
}
