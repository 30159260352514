import { SaveButton, Toolbar } from "ra-ui-materialui"

export const FormToolbar = ({ section = null }, ...props: never[]) => {
  const showSaveButton = function(section: string) {
    return !(section == "short-bio" || section == "photos");
  }
  return (
    <Toolbar
      style={{
        position: "sticky",
        bottom: 0,
        zIndex: 2,
        display: "flex",
        justifyContent: "end",
        minHeight: "60px",
        borderTop: "1px solid #ccc",
      }}
      {...props}
    >
      {showSaveButton(section) && <SaveButton label="react.admin.save" />}
    </Toolbar>
  )
}
