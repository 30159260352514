import { Box, Divider, Typography } from "@mui/material"
import { useRecordContext, useTranslate } from "ra-core"
import { List, TopToolbar } from "ra-ui-materialui"
import { useListContext } from "react-admin"

import { AddCoachToListButton } from "../../components/buttons/AddCoachToListButton"
import { AssociateDatagrid } from "./AssociateDatagrid"

export const ListActions = () => {
  const record = useRecordContext()
  const { data } = useListContext()

  return (
    <TopToolbar>
      <AddAssociateToList listId={record.id} coachesIds={data?.map(item => item.user_id)} />
    </TopToolbar>
  )
}

export const AssociateUsersList = () => {
  const record = useRecordContext()
  const translate = useTranslate()

  return (
    <>
      <Divider
        sx={{ mt: 1, mb: 3, borderTop: "1px solid #eee", width: "100%" }}
      />
      <Typography variant="h4" mb={2}>
        {translate("react.admin.associate_lists.coach_count")}
      </Typography>
      <List
        resource="associate-list-users"
        sort={{ field: "position", order: "ASC" }}
        hasCreate={false}
        actions={<ListActions />}
        empty={<EmptyList record={record} />}
        filterDefaultValues={{ associate_list_id: record.id }}
        perPage={200}
      >
        <AssociateDatagrid />
      </List>
    </>
  )
}

const EmptyList = ({ record }) => {
  const translate = useTranslate()

  return (
    <Box>
      <Box mb={2}>
        {translate("react.admin.associate_lists.no_coaches_in_list")}
      </Box>
      <AddAssociateToList listId={record.id} />
    </Box>
  )
}

const AddAssociateToList = ({ listId, coachesIds = [] }) => {
  return <AddCoachToListButton listId={listId} coachesIds={coachesIds} />
}
