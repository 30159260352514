import { required, useRecordContext } from "ra-core"
import { AutocompleteInput, ReferenceInput } from "ra-ui-materialui"

export const ProgramSelect = () => {
  const OptionRenderer = () => {
    const record = useRecordContext()

    return `${record.organization_name} - ${record.name}`
  }

  const inputText = (choice) => `${choice.organization_name} - ${choice.name}`

  return (
    <ReferenceInput
      source="program_id"
      reference="programs"
      perPage={100}
      sort={{ field: "organization.name", order: "ASC" }}
      label="Programme"
    >
      <AutocompleteInput
        size="medium"
        margin="none"
        optionText={<OptionRenderer />}
        inputText={inputText}
        label="react.select.program"
        validate={required()}
        fullWidth
        sx={{ minWidth: "548px" }}
      />
    </ReferenceInput>
  )
}
