import FileCopyIcon from "@mui/icons-material/FileCopy"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material"
import { Box } from "@mui/system"
import {
  EditBase,
  useDataProvider,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
} from "ra-core"
import { SimpleForm, TextInput } from "ra-ui-materialui"
import { useState } from "react"
import { Button as ReactAdminButton } from "react-admin"
import { AffiliationFields } from "../../resources/coaches/AffiliationFields"
import { BioFields } from "../../resources/coaches/BioFields"
import { CertificationFields } from "../../resources/coaches/CertificationFields"
import { EducationFields } from "../../resources/coaches/EducationFields"
import { JobInformationFields } from "../../resources/coaches/JobInformationFields"
import { PublicationFields } from "../../resources/coaches/PublicationFields"
import { WorkExperienceFields } from "../../resources/coaches/WorkExperienceFields"
import { FormToolbar } from "../forms/FormToolbar"

export const CvCardTab = ({ user, section, cvType }) => {
  const redirect = useRedirect()
  const notify = useNotify()
  const refresh = useRefresh()

  const onSuccess = () => {
    redirect(false)
    refresh()
    notify(`accounts.edit.flash_success`, { type: "success" })
  }

  return (
    <EditBase
      resource="curriculum-vitaes"
      id={
        cvType == "default"
          ? user.curriculum_vitae_id
          : user.commercial_curriculum_vitae_id
      }
      redirect={false}
      mutationMode="optimistic"
      mutationOptions={{ onSuccess }}
    >
      <SimpleForm toolbar={<FormToolbar section={section} />}>
        <CvCardFields
          coachId={user.id}
          user={user}
          section={section}
          cvType={cvType}
        />
      </SimpleForm>
    </EditBase>
  )
}

const CvCardFields = ({ coachId, user, section, cvType }) => {
  return (
    <>
      <TextInput
        source="user_id"
        defaultValue={coachId}
        style={{ display: "none" }}
      />

      <TextInput
        source="purpose"
        defaultValue={cvType}
        style={{ display: "none" }}
      />

      {cvType == "commercial" && !user.commercial_curriculum_vitae_id && (
        <CommercialCvInformation coachId={user.id} />
      )}

      <Box pt={4} sx={{ width: "100%" }}>
        <TabbedFields section={section} />
      </Box>
    </>
  )
}

const TabbedFields = ({ section }) => {
  if (section == "job-information") {
    return <JobInformationFields />
  }
  if (section == "short-bio") {
    return <BioFields />
  }
  if (section == "work-experience") {
    return <WorkExperienceFields />
  }
  if (section == "certifications") {
    return <CertificationFields />
  }
  if (section == "education") {
    return <EducationFields />
  }
  if (section == "affiliations") {
    return <AffiliationFields />
  }
  if (section == "publications") {
    return <PublicationFields />
  }
}

const CommercialCvInformation = ({ coachId }) => {
  const translate = useTranslate()
  const refresh = useRefresh()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const [confirmOpen, setConfirmOpen] = useState(false)

  const openConfirmDialog = () => {
    setConfirmOpen(true)
  }

  const handleCloneCv = () => {
    dataProvider
      .cloneCv(coachId)
      .then(() => {
        refresh()
        notify(`accounts.curriculum_vitae.copy_from_default_success`, {
          type: "success",
        })
      })
      .catch((error: string) => {
        notify(error, { type: "error" })
      })
  }

  return (
    <Box mt={4}>
      <Typography mb={2}>
        {translate("react.admin.curriculum_vitae_intro")}
      </Typography>

      <ReactAdminButton
        label="react.coaches.cv.copy_from_default_button"
        onClick={openConfirmDialog}
        id="copy-from-default-btn"
        startIcon={<FileCopyIcon />}
      />

      <ConfirmDialog
        title={translate("react.coaches.cv.copy_default_cv")}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleCloneCv}
      >
        <Box
          dangerouslySetInnerHTML={{
            __html: translate("react.coaches.cv.confirm_copy_cv"),
          }}
        />
      </ConfirmDialog>
    </Box>
  )
}

const ConfirmDialog = (props) => {
  const translate = useTranslate()
  const { title, children, open, setOpen, onConfirm } = props

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            setOpen(false)
            onConfirm()
          }}
        >
          {translate("btn.ok")}
        </Button>
        <Button variant="contained" onClick={() => setOpen(false)}>
          {translate("btn.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
