import { useDataProvider, useRecordContext } from "react-admin"

import { Box, Typography } from "@mui/material"
import { useEffect, useState } from "react"

export const EmailVariablesPreview = ({ emailTemplateModelId }) => {
  const dataProvider = useDataProvider()
  const recordContext = useRecordContext()
  const [emailVariables, setEmailVariables] = useState([])

  useEffect(() => {
    async function fetchData() {
      const response = await dataProvider.getOne("email-template-models", {
        id: emailTemplateModelId,
      })
      setEmailVariables(response.data.email_variables)
    }

    fetchData()
  }, [dataProvider, emailTemplateModelId, recordContext])

  return (
    <>
      <Typography fontStyle="italic" fontSize="12px">
        Possible variables
      </Typography>
      <Box display="flex" flexWrap="wrap" gap={1} sx={{ mb: 3 }}>
        {emailVariables.map((value, index) => (
          <Box
            sx={{
              fontSize: "14px",
              border: "1px solid #000",
              borderRadius: "0.4rem",
              padding: "0.1rem 0.3rem",
              boxDecorationBreak: "clone",
            }}
            key={`ev-${index}`}
          >
            @{value.id}
          </Box>
        ))}
      </Box>
    </>
  )
}
