import {
  AutocompleteArrayInput,
  BulkExportButton,
  Button,
  Datagrid,
  DateInput,
  List,
  NumberField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceManyCount,
  TextInput,
  useLocaleState,
  useRecordContext,
  WrapperField,
} from "react-admin"

import VisibilityIcon from "@mui/icons-material/Visibility"

import { BulkEmailBatchAction } from "../../components/bulk_actions/BulkEmailBatchAction"
import { UserField } from "../../components/fields/UserField"
import { LearningPathListFilterSidebar } from "../../components/filters/LearningPathListFilterSidebar"

import { Link } from "@mui/material"
import { ProgramField } from "../../components/fields/ProgramField"

const filters = [
  <ReferenceArrayInput
    key="program"
    source="program_ids"
    reference="programs"
    filter={{ with_learning_path: true }}
    sort={{ field: "start_date", order: "ASC" }}
  >
    <AutocompleteArrayInput label="Programs" sx={{ width: "500px" }} />
  </ReferenceArrayInput>,
  <TextInput
    source="client_name"
    name="client_name"
    key="client_name"
    label="User name"
    sx={{ width: "400px" }}
  />,
  <TextInput
    source="email"
    name="email"
    key="email"
    label="E-mail"
    sx={{ width: "400px" }}
  />,
  <TextInput
    source="organization_name"
    name="organization_name"
    key="organization_name"
    label="Organization name"
    sx={{ width: "400px" }}
  />,
  <DateInput
    source="start_date"
    name="start_date"
    key="start_date"
    label="Started before"
    sx={{ width: "200px" }}
  />,
]

export const LearningPathProgressList = (props) => (
  <List
    resource="program-memberships"
    filters={filters}
    aside={<LearningPathListFilterSidebar />}
    title="Course progress"
    filter={{
      role: "client",
      with_learning_path: true,
    }}
    filterDefaultValues={{ active: true }}
    perPage={25}
    {...props}
  >
    <Datagrid
      size="medium"
      bulkActionButtons={
        <>
          <BulkEmailBatchAction
            resourceType="Program::Membership"
            resourceName={"program-memberships"}
          />
          <BulkExportButton />
        </>
      }
      rowClick={(id) =>
        `/learning-step-progresses?filter={"program_membership_id":"${id}"}`
      }
    >
      <ReferenceField source="user_id" reference="users" link={false}>
        <UserField />
      </ReferenceField>
      <ReferenceField source="program_id" reference="programs">
        <ProgramField />
      </ReferenceField>
      <ReferenceField
        source="learning_path_id"
        reference="learning-paths"
        label="Path"
      />
      <ReferenceManyCount
        label="Steps"
        reference="learning-step-progresses"
        target="program_membership_id"
      />
      <ReferenceField
        source="course_progress_id"
        reference="course-progresses"
        sortBy="course_progress.campus_score"
        label="Campus (score)"
        link={false}
      >
        <NumberField source="campus_score" />
      </ReferenceField>
      <ReferenceField
        source="course_progress_id"
        reference="course-progresses"
        sortBy="course_progress.teams_score"
        label="Attendance (score)"
        link={false}
      >
        <NumberField source="teams_score" />
      </ReferenceField>
      <ReferenceField
        source="course_progress_id"
        reference="course-progresses"
        sortBy="course_progress.welearn_score"
        label="WeLearn (score)"
        link={false}
      >
        <NumberField source="welearn_score" />
      </ReferenceField>
      <ReferenceField
        source="course_progress_id"
        reference="course-progresses"
        sortBy="course_progress.total_score"
        label="Total (score)"
        link={false}
      >
        <NumberField source="total_score" />
      </ReferenceField>
      <ReferenceField
        source="program_id"
        reference="programs"
        sortable={false}
        label="Max possible (score)"
        link={false}
      >
        <NumberField source="max_possible_score" />
      </ReferenceField>
      <ReferenceField
        source="course_progress_id"
        reference="course-progresses"
        sortBy="course_progress.percentage_done"
        label="Completed"
        link={false}
      >
        <>
          <NumberField source="percentage_done" />%
        </>
      </ReferenceField>
      <WrapperField textAlign="center">
        <PreviewButton />
      </WrapperField>
    </Datagrid>
  </List>
)

const PreviewButton = () => {
  const record = useRecordContext()
  const [locale] = useLocaleState()

  return (
    <Link
      href={`/${locale}/admin/program_memberships/${record.id}/progress`}
      target="_blank"
    >
      <Button label="Preview" startIcon={<VisibilityIcon />} />
    </Link>
  )
}
