import { Chip } from "@mui/material"

export const ArrayChipField = ({ items }) => {
  if (items === null) {
    return
  }
  return items.map((item) => {
    return (
      <Chip
        key={item}
        label={item}
        sx={{ marginBottom: "8px", marginRight: "8px" }}
      />
    )
  })
}
