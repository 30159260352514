import { Datagrid, List, ReferenceManyCount, TextField } from "react-admin"

export const QuestionnaireList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <ReferenceManyCount
        label="Programs"
        reference="programs"
        target="questionnaire_id"
        link
      />
      <ReferenceManyCount
        label="Surveys"
        reference="surveys"
        target="questionnaire_id"
        link
      />
    </Datagrid>
  </List>
)
