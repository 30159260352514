import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useGetOne } from "ra-core"

const ClientProfileFields = ({ profileId }) => {
  const {
    data: profile,
    isLoading,
    error,
  } = useGetOne("profiles", { id: profileId })
  if (isLoading) {
    return null
  }
  if (error) {
    return <p>ERROR</p>
  }

  return (
    <>
      <Box mb={1}>
        <Typography fontWeight="bold">Job title</Typography>
        {profile.job_title?.en && <i>{profile.job_title.en}</i>}
        {!profile.job_title?.en && <i>Unknown</i>}
      </Box>

      <Box mb={1}>
        <Typography fontWeight="bold">Remarks</Typography>
        {profile.remarks && <i>{profile.remarks}</i>}
        {!profile.remarks && <i>Unknown</i>}
      </Box>

      <Box mb={1}>
        <Typography fontWeight="bold">Miscellaneous</Typography>
        {profile.miscellaneous && <i>{profile.miscellaneous}</i>}
        {!profile.miscellaneous && <i>Unknown</i>}
      </Box>

      <Box mb={1}>
        <Typography fontWeight="bold">Office location</Typography>
        {profile.office_location && <i>{profile.office_location}</i>}
        {!profile.office_location && <i>Unknown</i>}
      </Box>
    </>
  )
}

export const ClientCard = ({ user }) => {
  return (
    <>
      <Box mb={2}>
        <Typography variant="h5" mb={2}>
          {user.name}
        </Typography>
        <hr />
      </Box>
      <ClientProfileFields profileId={user.profile_id} />
    </>
  )
}
