import {
  AutocompleteArrayInput,
  BooleanInput,
  Datagrid,
  InfiniteList,
  ReferenceArrayInput,
  ReferenceField,
  TextInput,
} from "react-admin"

import { UserField } from "../../components/fields/UserField"

import { countries } from "countries-list"
import { Typography } from "@mui/material"
import { Box } from "@mui/system"

const countryChoices = []
Object.keys(countries).forEach(function (key) {
  countryChoices.push({ id: key, name: countries[key].name })
})

const filters = [
  <BooleanInput
    key="active"
    source="active"
    label="Active"
    checked
    alwaysOn
    sx={{
      height: "50px",
      display: "flex",
      justifyContent: "center",
      width: "100%",
    }}
  />,
  <TextInput
    key="search"
    label="Email"
    source="email"
    sx={{ width: "100%" }}
  />,
  <ReferenceArrayInput key="program" source="program_ids" reference="programs">
    <AutocompleteArrayInput label="Programs" sx={{ width: "300px" }} />
  </ReferenceArrayInput>,
  <AutocompleteArrayInput
    source="countries"
    key="countries"
    label="Countries"
    choices={countryChoices}
    sx={{ width: "500px" }}
  />,
  <TextInput
    source="client_name"
    name="client_name"
    key="client_name"
    label="User name"
    sx={{ width: "400px" }}
  />,
  <TextInput
    source="organization_name"
    name="organization_name"
    key="organization_name"
    label="Organization name"
  />,
]

export const EmailBatchSelectRecipients = (props) => {
  const recipientCount = 0

  return (
    <>
      <Box mx={2} mb={3}>
        <Typography variant="h5" sx={{ marginBottom: "1rem" }}>
          Select recipients
        </Typography>
        <Typography sx={{ maxWidth: "650px", marginBottom: "2rem" }}>
          Use the list below to select the recipients you want to email.
          <br />
          <br />
          You can use the filters by clicking on the <b>Add filter</b> button to
          find the recipients you want to email.
          <br />
          <br />
          Once you have found the people you want to email you can use the{" "}
          <b>Add to batch</b> button or use the selectboxes to select multiple
          people and then use the <b>Add all to batch</b> button.
        </Typography>
        <Typography variant="h6">
          Selected recipients: <b>{recipientCount}</b>
        </Typography>
      </Box>
      <InfiniteList
        resource="program-memberships"
        filters={filters}
        sx={{ margin: "0px 1rem" }}
        {...props}
      >
        <Datagrid
          rowClick="toggleSelection"
          size="medium"
          bulkActionButtons={false}
        >
          <ReferenceField source="user_id" reference="users" link={false}>
            <UserField />
          </ReferenceField>
          <ReferenceField
            source="main_coach_id"
            reference="coaches"
            label="Coach"
            link={false}
          >
            <UserField />
          </ReferenceField>
          <ReferenceField
            source="program_id"
            reference="programs"
            link={false}
          />
        </Datagrid>
      </InfiniteList>
    </>
  )
}
