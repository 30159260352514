import {
  createDefaultImageReader,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,
  CropPresetOption,
  findNode,
  locale_en_gb,
  markup_editor_defaults,
  PinturaNode,
  plugin_crop_locale_en_gb,
  plugin_filter_defaults,
  plugin_filter_locale_en_gb,
  plugin_finetune_defaults,
  plugin_finetune_locale_en_gb,
} from "@pqina/pintura"

export const cropSelectPresetOptions: CropPresetOption[] = [
  [undefined, "Custom"],
  [1, "Square"],

  // shown when cropSelectPresetFilter is set to 'landscape'
  [2 / 1, "2:1"],
  [3 / 2, "3:2"],
  [4 / 3, "4:3"],
  [16 / 10, "16:10"],
  [16 / 9, "16:9"],

  // shown when cropSelectPresetFilter is set to 'portrait'
  [1 / 2, "1:2"],
  [2 / 3, "2:3"],
  [3 / 4, "3:4"],
  [10 / 16, "10:16"],
  [9 / 16, "9:16"],
]

export const createEditorDefaults = (labelButtonExport: string = "Done") => ({
  cropEnableButtonFlipVertical: true,
  cropEnableInfoIndicator: true,
  cropEnableButtonRotateRight: true,
  cropEnableFilterMatchAspectRatio: true,
  cropEnableSelectPreset: true,
  imageReader: createDefaultImageReader(),
  imageWriter: createDefaultImageWriter(),
  shapePreprocessor: createDefaultShapePreprocessor(),
  ...plugin_finetune_defaults,
  ...plugin_filter_defaults,
  ...markup_editor_defaults,
  locale: {
    ...locale_en_gb,
    ...plugin_crop_locale_en_gb,
    ...plugin_finetune_locale_en_gb,
    ...plugin_filter_locale_en_gb,
    labelButtonExport,
  },
})
export const willRenderToolbar = (toolbar: PinturaNode) => {
  const exportButton = findNode("export", toolbar)

  const exportButtonOptions = exportButton[2] as {
    hideLabel: boolean
    icon: boolean
    class: string
  }

  exportButtonOptions.hideLabel = false
  exportButtonOptions.class =
    "MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall css-1ytq5j0-MuiButtonBase-root-MuiButton-root-RaButton-root"

  exportButtonOptions.icon = false

  return toolbar
}
