import { Divider } from "@mui/material"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import { useTranslate } from "ra-core"
import { useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router"

export const ProfileTabs = ({ profileId }) => {
  const location = useLocation()
  const translate = useTranslate()
  const [value, setValue] = useState(0)

  const pages = useMemo(
    () => [
      {
        tab: 0,
        path: "personal-information",
        title: translate("react.admin.personal_information"),
      },
      { tab: 1, path: "photos", title: translate("react.admin.profile_photo") },
      { tab: 2, path: "languages", title: translate("react.admin.languages") },
      {
        tab: 3,
        path: "business-details",
        title: translate("react.admin.business_details"),
      },
      {
        tab: 4,
        path: "group-work",
        title: translate("react.admin.group_work"),
      },
    ],
    [translate]
  )

  useEffect(() => {
    pages.forEach((page) => {
      if (location.pathname == `/profiles/${profileId}/${page.path}`) {
        setValue(page.tab)
      }
    })
  }, [location.pathname, profileId, pages])

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        aria-label="nav tabs example"
        role="navigation"
        TabIndicatorProps={{
          style: { transition: "none" },
        }}
      >
        {pages.map((page) => (
          <Tab
            onClick={(
              event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
            ) => {
              event.preventDefault()
              if (event.target instanceof HTMLAnchorElement) {
                document.location.hash = event.target.getAttribute("href")
              }
            }}
            key={page.tab}
            label={page.title}
            href={`#/profiles/${profileId}/${page.path}`}
          />
        ))}
      </Tabs>
      <Divider />
    </Box>
  )
}
