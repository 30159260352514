import {
  Datagrid,
  DateField,
  DeleteButton,
  List,
  NumberField,
  ReferenceManyCount,
  TextField,
} from "react-admin"

export const AssociateListList = () => (
  <List
    perPage={25}
    hasCreate={true}
    exporter={false}
    sort={{ field: "name", order: "ASC" }}
  >
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <NumberField source="id" label="ID" />
      <TextField source="name" />
      <TextField source="cv_purpose" sortable={false} label="Purpose" />
      <ReferenceManyCount
        label="react.admin.associate_lists.coach_count"
        reference="users"
        target="associate_list_id"
        link
      />
      <DateField source="created_at" />
      <DateField source="updated_at" />
      <DeleteButton />
    </Datagrid>
  </List>
)
