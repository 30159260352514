import { ReferenceManyToManyField } from "@react-admin/ra-relationships"
import { WithRecord } from "ra-core"
import { ChipField, ReferenceOneField, SingleFieldList } from "ra-ui-materialui"

export const LanguagesField = ({ filters }) => {
  return (
    <ReferenceOneField reference="profiles" target="user_id">
      <ReferenceManyToManyField
        reference="languages"
        through="profile-languages"
        using="profile_id,language_id"
      >
        <SingleFieldList sx={{ gap: "0", justifyContent: "center" }}>
          <WithRecord
            render={(language) => (
              <ChipField
                source="common_name"
                sx={{
                  fontWeight: filters.filterValues.languages?.includes(
                    language.id
                  )
                    ? "bold"
                    : "normal",
                }}
              />
            )}
          />
        </SingleFieldList>
      </ReferenceManyToManyField>
    </ReferenceOneField>
  )
}
