import { ShowInDialogButton } from "@react-admin/ra-form-layout"
import Image from "mui-image"

export const ProfileAvatarTipButton = () => {
  const imageUrl =
    document.location.port == "5173"
      ? `http://${document.location.hostname}:3000/profile_photo_examples.png`
      : "/profile_photo_examples.png"

  return (
    <ShowInDialogButton
      fullWidth
      label="react.coaches.cv.help.avatars.help"
      ButtonProps={{
        sx: {
          backgroundColor: "#fff !important",
          borderColor: "#000 !important",
          marginTop: "1rem",
        },
      }}
      sx={{
        "& .MuiDialogTitle-root": {
          display: "none",
        },
        "& .MuiDialog-paper": {
          padding: "1rem",
          maxWidth: "75%",
        },
      }}
    >
      <Image
        animationDuration={1000}
        src={imageUrl}
        style={{
          animation:
            "250ms cubic-bezier(0.7, 0, 0.6, 1) 0s 1 normal none running materialize",
        }}
      />
    </ShowInDialogButton>
  )
}
