import { ReactRenderer } from "@tiptap/react"
import tippy from "tippy.js"

import { EmailTemplateMentionList } from "./EmailTemplateMentionList"

const getEmailVariables = (emailVariables, query) => {
  return Promise.resolve(
    emailVariables
      .filter((item) => item.toLowerCase().startsWith(query.toLowerCase()))
      .slice(0, 15),
  )
}

export const emailTemplateVariables = (emailVariables) => {
  return {
    items: ({ query }) => {
      return getEmailVariables(emailVariables, query)
    },
    render: () => {
      let reactRenderer
      let popup

      return {
        onStart: (props) => {
          reactRenderer = new ReactRenderer(EmailTemplateMentionList, {
            props,
            editor: props.editor,
          })

          if (!props.clientRect) {
            return
          }

          popup = tippy("body", {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: reactRenderer.element,
            showOnCreate: true,
            interactive: true,
            trigger: "manual",
            placement: "bottom-start",
            theme: "light",
          })
        },

        onUpdate(props) {
          reactRenderer.updateProps(props)

          if (!props.clientRect) {
            return
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          })
        },

        onKeyDown(props) {
          if (props.event.key === "Escape") {
            popup[0].hide()

            return true
          }

          return reactRenderer.ref?.onKeyDown(props)
        },

        onExit() {
          if (popup) {
            popup[0].destroy()
            reactRenderer.destroy()
          }
        },
      }
    },
  }
}
