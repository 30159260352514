import { ImageField, ImageInput, SaveButton } from "ra-ui-materialui"
import { Grid, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { canAccess } from "@react-admin/ra-rbac"
import {
  usePermissions,
  useRecordContext,
  useTranslate,
  WithRecord,
} from "ra-core"

import { IRobinChatBubble } from "../forms/IRobinChatBubble"
import { ProfileAvatarTipButton } from "./ProfileAvatarTipButton"
import { ProfilePhotoChangeRequest } from "./ProfilePhotoChangeRequest"
import { ProfilePhotoPreview } from "../../components/fields/ProfilePhotoPreview"

export const ProfilePhotoFields = () => {
  const record = useRecordContext()
  const { permissions } = usePermissions()
  const translate = useTranslate()

  let canUploadAvatars = false
  if (
    canAccess({
      permissions,
      action: "update_avatar",
      resource: "coaches",
    })
  ) {
    canUploadAvatars = true
  }

  return (
    <Box mt={2}>
      <IRobinChatBubble>
        <Typography variant="h6" mb={1} fontWeight="bold">
          {translate("react.admin.avatars")}
        </Typography>

        <>
          <Typography my={1}>
            <Box
              component="span"
              dangerouslySetInnerHTML={{
                __html: translate("react.coaches.intro.avatars"),
              }}
            />
          </Typography>

          <ProfileAvatarTipButton />
        </>
      </IRobinChatBubble>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          marginTop: "1rem",
          marginBottom: "2rem",
          gap: 2,
        }}
      >
        {canUploadAvatars && (
          <>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <ImageInput
                      className="qwerty"
                      source="avatar_picture"
                      maxSize={5000000}
                      label={false}
                      multiple={false}
                      fullWidth={false}
                      labelSingle="react.coaches.avatars.avatar.upload"
                      accept="image/png,image/jpg,image/jpeg"
                      sx={imageInputStyles}
                    >
                      <ImageField source="src" title="title" className="qwe" />
                    </ImageInput>
                  </Grid>
                  <Grid item xs={6}>
                    <ImageInput
                      source="photo_picture"
                      maxSize={5000000}
                      label={false}
                      multiple={false}
                      fullWidth={false}
                      labelSingle="react.coaches.avatars.upload"
                      accept="image/png,image/jpg,image/jpeg"
                      sx={imageInputStyles}
                    >
                      <ImageField source="src" title="title" />
                    </ImageInput>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} m={2}>
                <SaveButton label="Save new avatar and photo" />
              </Grid>
            </Grid>
          </>
        )}

        {!canUploadAvatars && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: 2,
            }}
          >
            <WithRecord
              render={function (profile) {
                return <ProfilePhotoChangeRequest profileId={profile.id} />
              }}
            />
          </Box>
        )}
      </Box>

      {record.avatar_picture != null &&
        record.avatar_picture.src &&
        record.photo_picture != null &&
        record.photo_picture.src && <ProfilePhotoPreview />}
    </Box>
  )
}

export const imageInputStyles = {
  display: "flex",
  flexDirection: "row",
  marginTop: "1rem",
  "& .previews": {
    width: "100px",
    height: "100px",
    backgroundColor: "#fcfcfe",
    marginLeft: "1rem",
    border: "2px dashed #eeeeee",
    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" fill="grey"><path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z"/></svg>')`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    overflow: "hidden",
    "& .RaImageField-image": {
      maxWidth: "89px",
      maxHeight: "89px",
    },
    "& .RaFileInput-removeButton .MuiButtonBase-root": {
      display: "none",
    },
  },
  "& .RaFileInput-dropZone": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100px",
    "& p": { fontSize: "1rem", fontWeight: "400", margin: "0px" },
  },
  "& .RaFileInput-dropZone:has(+ p.Mui-error)": {
    borderColor: "#d32f2f",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    position: "absolute",
    marginTop: "105px",
    marginLeft: "15px",
  },
}
