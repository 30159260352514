import { useEffect, useState } from "react"
import { useDataProvider } from "react-admin"

import { LanguageContext } from "../../context/language_context"

export const LanguageProvider = ({ children }) => {
  const [languages, setLanguages] = useState([])
  const dataProvider = useDataProvider()

  useEffect(() => {
    if (languages.length === 0) {
      dataProvider
        .getList("languages", {
          filter: {},
          pagination: { page: 1, perPage: 1000 },
          sort: { field: "id", order: "ASC" },
        })
        .then(({ data }) => setLanguages(data))
    }
  })

  return (
    <LanguageContext.Provider value={languages}>
      {children}
    </LanguageContext.Provider>
  )
}
