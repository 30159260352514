import { RadioButtonGroupInput } from "ra-ui-materialui"

export const CvPurposeInput = ({ source = "purpose" }) => {
  return (
    <RadioButtonGroupInput
      source={source}
      defaultValue={"default"}
      choices={[
        { id: "default", name: "Default CV" },
        { id: "commercial", name: "Commercial CV" },
      ]}
      label="Default or Commercial"
    />
  )
}
