import CloseIcon from "@mui/icons-material/Close"
import { Button } from "@mui/base/Button"
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material"
import { I18n } from "i18n-js"
import { useState } from "react"
import translations from "../locales.json"
import UnloqTheme from "./unloq_theme"
import App from "react_admin/App"

const i18n = new I18n(translations)

const dialogTitleStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}

const MatchClientModal = ({ client_id }) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    window.location.hash = `#/coaches/${client_id}/match`
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  return (
    <UnloqTheme>
      <Button
        className="bg-unloq-brightgreen hover:bg-white py-2 px-4 font-medium border-t border-b border-l"
        onClick={handleOpen}
        disabled={open}
      >
        {i18n.t("coaches.find_coach")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        sx={{ "& .MuiDialog-paper": { backgroundColor: "#fcfcfe" } }}
      >
        <DialogTitle sx={dialogTitleStyle}>
          <h2>{i18n.t("coaches.modal_title")}</h2>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ minHeight: "80vh", width: "100%" }}>
          <App inline />
        </DialogContent>
      </Dialog>
    </UnloqTheme>
  )
}

export default MatchClientModal
