import { GetListParams, required, useTranslate } from "ra-core"
import { useQuery } from "react-query"
import { Alert, Grid } from "@mui/material"
import { ImageField, ImageInput } from "ra-ui-materialui"
import { Box } from "@mui/system"
import { RecordContextProvider } from "react-admin"
import { useEffect, useState } from "react"
import HourglassBottom from "@mui/icons-material/HourglassBottom"

import { dataProvider } from "../../dataProvider"
import { imageInputStyles } from "./ProfilePhotoFields"

export const ProfilePhotoChangeRequest = ({ profileId }) => {
  const translate = useTranslate()

  const [statusText, setStatusText] = useState<string>("")

  const handleAvatarUpload = async (file: never) => {
    await dataProvider
      .create("change-requests", {
        data: {
          profile_id: profileId,
          avatar: { rawFile: file },
          changeable_type: "Profile",
          field_changed: "avatar",
          field_type: "attachment",
        },
      })
      .then(function (response) {
        setStatusText(translate("react.coaches.avatars.uploaded"))
        return response
      })
  }

  const withAdditionalArg =
    (callback: CallableFunction, additionalArg: object) => (file: never) => {
      callback(file, additionalArg)
    }

  const inputElement = (
    <Grid container direction="column">
      <Grid item>
        <ImageInput
          source="avatar"
          maxSize={5000000}
          label={false}
          multiple={false}
          fullWidth={false}
          labelSingle="react.coaches.avatars.upload"
          accept="image/png,image/jpg,image/jpeg"
          validate={required()}
          sx={imageInputStyles}
          onChange={withAdditionalArg(handleAvatarUpload, profileId)}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </Grid>
      {statusText && (
        <Grid item>
          <Alert icon={<HourglassBottom fontSize="inherit" />} severity="warning">
            {statusText}
          </Alert>
        </Grid>
      )}
    </Grid>
  )

  const queryParams = {
    pagination: { page: 1, perPage: 1 },
    sort: { field: "created_at", order: "DESC" },
    filter: {
      changeable_id: profileId,
      changeable_type: "Profile",
      field_changed: "avatar",
    },
  }

  const { data, isLoading, error } = useQuery(
    ["change-requests", "getList", queryParams],
    () => dataProvider.getList("change-requests", queryParams as GetListParams),
  )

  useEffect(() => {
    if (!isLoading) {
      if (data && data.data && data.data.length > 0 && data.data[0].status) {
        if (data.data[0].status == "pending") {
          setStatusText(translate("react.coaches.avatars.not_approved_yet"))
        } else if (data.data[0].status == "rejected") {
          setStatusText(translate("react.coaches.avatars.rejected"))
        } else if (data.data[0].status == "approved") {
          setStatusText(translate("react.coaches.avatars.approved"))
        }
      }
    }
  }, [data, isLoading, translate])

  if (isLoading) return <Box>Loading!</Box>
  if (error) return <Box>Error!</Box>

  if (!data || !data.data || !data.data[0]) {
    return inputElement
  } else {
    return (
      <RecordContextProvider key={data.data[0].id} value={data.data[0]}>
        {inputElement}
      </RecordContextProvider>
    )
  }
}
