import { Box, Card, CardContent, Tooltip, Typography } from "@mui/material"
import { ListLive } from "@react-admin/ra-realtime"
import {
  AutocompleteInput,
  Button,
  Datagrid,
  DateField,
  FunctionField,
  ReferenceField,
  ReferenceInput,
  TextField,
  useRecordContext,
  useUpdate,
} from "react-admin"

import EmailIcon from "@mui/icons-material/Email"

import { BulkSendAction } from "../../components/bulk_actions/BulkSendAction"
import { EmailBodyField } from "../../components/fields/EmailBodyField"
import { EmailSubjectField } from "../../components/fields/EmailSubjectField"
import { emailVariableField } from "../../components/fields/EmailVariableField"

const filters = [
  <ReferenceInput key="batch" source="email_batch_id" reference="email-batches">
    <AutocompleteInput label="Batch" />
  </ReferenceInput>,
]

const ShowMorePanel = () => {
  return (
    <Box display="flex" gap={2} py={2}>
      <Box>
        <Card>
          <CardContent>
            <Typography fontWeight="bold" sx={{ mb: 1 }}>
              Reply-to
            </Typography>
            <FunctionField
              label="Reply To"
              source="reply_to"
              render={(record) => emailVariableField(record.reply_to)}
            />
            <Typography fontWeight="bold" sx={{ mb: 1 }}>
              CC
            </Typography>
            <FunctionField
              label="CC"
              source="cc"
              render={(record) =>
                record.cc.length > 0
                  ? emailVariableField(record.cc)
                  : "Undefined"
              }
            />
            <Typography fontWeight="bold" sx={{ mb: 1 }}>
              BCC
            </Typography>
            <FunctionField
              label="BCC"
              source="bcc"
              render={(record) =>
                record.bcc.length > 0
                  ? emailVariableField(record.bcc)
                  : "Undefined"
              }
            />
          </CardContent>
        </Card>
      </Box>
      <Box>
        <Card>
          <CardContent>
            <EmailBodyField source="rendered_body" label="Body" />
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
}

const SendButton = () => {
  const record = useRecordContext()

  const diff = { status: "queued" }
  const [update, { isLoading, error }] = useUpdate("emails", {
    id: record.id,
    data: diff,
    previousData: record,
  })

  if (record.status != "created") return null

  const send = (e) => {
    e.stopPropagation()
    update()
  }

  if (error) {
    return <p>ERROR</p>
  }

  return (
    <Tooltip title="Send">
      <div>
        <Button
          label=""
          startIcon={<EmailIcon />}
          onClick={send}
          disabled={isLoading}
          sx={{
            minWidth: 0,
            padding: "4px",
            "& .MuiButton-startIcon": { margin: 0, padding: 0 },
          }}
        />
      </div>
    </Tooltip>
  )
}

export const EmailList = () => (
  <ListLive perPage={50} filters={filters} hasCreate={false} actions={false}>
    <Datagrid
      rowClick="expand"
      expand={<ShowMorePanel />}
      bulkActionButtons={
        <>
          {/* <BulkSendAction btnName="Test" /> */}
          <BulkSendAction btnName="Send" test={false} />
        </>
      }
    >
      <EmailSubjectField source="rendered_subject" label="Subject" />
      <TextField source="to" />
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
      <TextField source="status" />
      <ReferenceField
        label="Batch"
        source="email_batch_id"
        reference="email-batches"
      />
      <SendButton />
    </Datagrid>
  </ListLive>
)
