import { getPermissionsFromRoles } from "@react-admin/ra-rbac"

let permissionsExpiresAt = 0

const roleDefinitions = {
  client: [],
  coach: [],
  organization_manager: [{ action: "contract_remarks", resource: "coaches" }],
  admin: [{ action: "*", resource: "*" }],
}

const fetchPermissions = async () => {
  const apiUrl =
    document.location.port == "5173"
      ? `http://${document.location.hostname}:3000/api/v1/users/me`
      : "/api/v1/users/me"

  const request = new Request(apiUrl, {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/vnd.api+json",
    }),
  })
  permissionsExpiresAt = Date.now() + 1000 * 60 * 5
  const response = await fetch(request, { credentials: "include" })

  if (response.status < 200 || response.status >= 300) {
    return Promise.resolve({ redirectTo: "/en/login" })
  }

  const json = await response.json()
  const permissions = getPermissionsFromRoles({
    roleDefinitions,
    userPermissions: json.attributes.permissions,
    userRoles: json.attributes.roles,
  })

  localStorage.setItem("permissions", JSON.stringify(permissions))
  localStorage.setItem("userId", JSON.stringify(json.id))
  localStorage.setItem("RaStore.locale", json.attributes.language)
}

export const authProvider = {
  checkError: (error) => {
    console.error(error)
    return Promise.resolve()
  },
  login: () => {
    console.warn("Not implemented.")
    return Promise.resolve()
  },
  checkAuth: async () => {
    if (
      Date.now() > permissionsExpiresAt ||
      localStorage.getItem("permissions") == null
    ) {
      await fetchPermissions()
    }
    return Promise.resolve()
  },
  logout: () => {
    localStorage.removeItem("permissions")
    return Promise.resolve()
  },
  getIdentity: () => {
    const userId = JSON.parse(localStorage.getItem("userId"))
    return Promise.resolve({ id: parseInt(userId) })
  },
  getPermissions: async () => {
    if (Date.now() > permissionsExpiresAt) {
      await fetchPermissions()
    }
    const permissions = JSON.parse(localStorage.getItem("permissions"))
    return Promise.resolve(permissions)
  },
}
