import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { Box } from "@mui/system"

import { ClientImportButton } from "./ClientImportButton"

export const ClientImportTable = ({ record }) => {
  const errors = []

  return (
    <Box p={3}>
      <TableContainer component={Paper}>
        <Table sx={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell>First name</TableCell>
              <TableCell>Last name</TableCell>
              <TableCell>Client email</TableCell>
              <TableCell>Coach email</TableCell>
              <TableCell>Cohort</TableCell>
              <TableCell>Language</TableCell>
              <TableCell>Office location</TableCell>
              <TableCell>Remarks</TableCell>
              <TableCell>Gender</TableCell>
              <TableCell>Job title</TableCell>
              <TableCell>Error</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record.import_lines.map((line) => {
              const rowStyles = {
                "&:last-child td, &:last-child th": { border: 0 },
              }
              if (line.error) {
                errors.push(line.error)

                Object.assign(rowStyles, {
                  backgroundColor: "#f00",
                  td: { color: "#fff" },
                })
              }

              return (
                <TableRow key={line.email} sx={rowStyles}>
                  <TableCell>{line.email}</TableCell>
                  <TableCell>{line.first_name}</TableCell>
                  <TableCell>{line.last_name}</TableCell>
                  <TableCell>{line.coach_email}</TableCell>
                  <TableCell>{line.cohort}</TableCell>
                  <TableCell>{line.language}</TableCell>
                  <TableCell>{line.office_location}</TableCell>
                  <TableCell>{line.remarks}</TableCell>
                  <TableCell>{line.gender}</TableCell>
                  <TableCell>{line.job_title}</TableCell>
                  <TableCell>{line.error}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {errors.length == 0 && (
        <Box display="flex" justifyContent="center" mt={3}>
          <ClientImportButton record={record} />
        </Box>
      )}
      {errors.length > 0 && (
        <Box display="flex" justifyContent="center" mt={3} color="#f00">
          Can&apos;t import with errors
        </Box>
      )}
    </Box>
  )
}
