import {
  Datagrid,
  EditButton,
  List,
  ReferenceField,
  TextField,
} from "react-admin"

export const CountryList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="common_name" />
      <TextField source="code" />
      <ReferenceField source="region_id" reference="regions" link={false} />
      <EditButton />
    </Datagrid>
  </List>
)
