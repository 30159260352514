import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material"
import {
  FormDataConsumer,
  useDataProvider,
  useNotify,
  useRefresh,
  useTranslate,
} from "ra-core"
import { Button, SimpleForm } from "ra-ui-materialui"
import { useState } from "react"
import { useMutation } from "react-query"

import { AssociateListSelect } from "../inputs/AssociateListSelect"

export const AddSelectedAssociatesToListButton = ({ selectedIds }) => {
  const translate = useTranslate()
  const [confirmOpen, setConfirmOpen] = useState(false)

  const openConfirmDialog = () => {
    setConfirmOpen(true)
  }

  return (
    <>
      <Button
        label="react.admin.add_to_associate_list"
        onClick={openConfirmDialog}
        sx={{ margin: "0 0.5rem" }}
      />
      <ConfirmDialog
        selectedIds={selectedIds}
        title={translate("react.admin.add_to_associate_list")}
        open={confirmOpen}
        setOpen={setConfirmOpen}
      />
    </>
  )
}

const ConfirmDialog = (props) => {
  const translate = useTranslate()
  const { title, open, setOpen, selectedIds } = props

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle id="add-to-associate-list">{title}</DialogTitle>
      <SimpleForm toolbar={null}>
        <AssociateListSelect />
        <FormDataConsumer>
          {({ formData }) => {
            if (formData.associate_list_id != null) {
              return (
                <>
                  <DialogActions sx={{ width: "100%" }}>
                    <MuiButton
                      variant="contained"
                      onClick={() => setOpen(false)}
                      color="error"
                    >
                      {translate("btn.cancel")}
                    </MuiButton>
                    <ConfirmButton
                      associateListId={formData.associate_list_id}
                      selectedIds={selectedIds}
                      setOpen={setOpen}
                    />
                  </DialogActions>
                </>
              )
            }
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Dialog>
  )
}

const ConfirmButton = ({ associateListId, selectedIds, setOpen }) => {
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const refresh = useRefresh()

  const add = async () => {
    selectedIds.forEach((id) => {
      dataProvider
        .create("associate-list-users", {
          data: {
            associate_list_id: associateListId,
            user_id: id,
          },
        })
        .then(() => {
          notify(`react.associate_lists.added`, { type: "success" })
          setOpen(false)
          refresh()
        })
    })
  }

  const { mutate: addToAssociateList, isLoading } = useMutation(add)

  const handleClick = () => {
    addToAssociateList()
  }

  return (
    <MuiButton
      variant="contained"
      onClick={handleClick}
      disabled={isLoading}
      color="success"
    >
      Add to the List
    </MuiButton>
  )
}
