import { ReferenceManyToManyField } from "@react-admin/ra-relationships"
import React from "react"
import {
  BulkExportButton,
  ChipField,
  DatagridConfigurable,
  EditButton,
  Empty,
  FilterForm,
  FunctionField,
  ReferenceField,
  ReferenceManyField,
  SingleFieldList,
  TextField,
  WithListContext,
} from "react-admin"

import Chip from "@mui/material/Chip"
import Divider from "@mui/material/Divider"
import { Box } from "@mui/system"

import { BulkEmailBatchAction } from "../../components/bulk_actions/BulkEmailBatchAction"
import { LanguageField } from "../../components/fields/LanguageField"
import { LanguageLevelField } from "../../components/fields/LanguageLevelField"
import { ProfileChip } from "../../components/fields/ProfileChipField"
import { CoachListFilterTopBar } from "../../components/filters/CoachListFilterTopBar"
import { ProfileShowForm } from "../../components/forms/ProfileShowForm"
import { EditProfileButton } from "../../resources/coaches/CoachList"
import { User } from "api/models"

const FieldWrapper = ({ children, label }) =>
  React.cloneElement(React.Children.only(children), { label })

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DatagridColumn = ({ children, label }) => <div>{children}</div>
const CvColumn = ({ label, reference, through, target }) => (
  <FunctionField
    label={label}
    render={(record: User) => {
      const curriculum_vitae_id = record.curriculum_vitae_id
      const commercial_curriculum_vitae_id =
        record.commercial_curriculum_vitae_id
      return (
        <>
          {curriculum_vitae_id ? (
            <ReferenceManyToManyField
              reference={reference}
              through={through}
              using={`curriculum_vitae_id,${target}`}
              source="curriculum_vitae_id"
              sort={{ field: "position", order: "ASC" }}
            >
              <SingleFieldList linkType={false} empty={<p>No {reference}</p>}>
                <ChipField source="name.en" />
              </SingleFieldList>
            </ReferenceManyToManyField>
          ) : (
            <p>No default CV</p>
          )}

          <Divider sx={{ my: 2 }} />

          {commercial_curriculum_vitae_id ? (
            <ReferenceManyToManyField
              reference={reference}
              through={through}
              using={`commercial_curriculum_vitae_id,${target}`}
              source="commercial_curriculum_vitae_id"
              sort={{ field: "position", order: "ASC" }}
            >
              <SingleFieldList
                linkType={false}
                empty={<p>No specializations</p>}
              >
                <ChipField source="name.en" />
              </SingleFieldList>
            </ReferenceManyToManyField>
          ) : (
            <p>No commercial CV</p>
          )}
        </>
      )
    }}
  />
)

export const CoachDatagrid = () => {
  return (
    <>
      <Box>
        <FilterForm
          className="filter_container"
          filters={CoachListFilterTopBar()}
          style={{ display: "flex", justifyContent: "space-between" }}
        />
      </Box>
      <DatagridConfigurable
        empty={<Empty />}
        bulkActionButtons={
          <>
            <BulkEmailBatchAction
              resourceType="User"
              resourceName={"coaches"}
            />
            <BulkExportButton />
          </>
        }
        expand={<ProfileShowForm />}
        expandSingle
      >
        <DatagridColumn label="Avatar">
          <ProfileChip />
        </DatagridColumn>

        <DatagridColumn label="Job title">
          <ReferenceField
            source="curriculum_vitae_id"
            reference="curriculum-vitaes"
            link={false}
          >
            <TextField source="job_title.en" />
          </ReferenceField>

          <Divider sx={{ my: 2 }} />

          <ReferenceField
            source="commercial_curriculum_vitae_id"
            reference="curriculum-vitaes"
            link={false}
          >
            <TextField source="job_title.en" />
          </ReferenceField>
        </DatagridColumn>

        <DatagridColumn label="Languages">
          <ReferenceManyField reference="profile-languages" target="profile_id">
            <WithListContext
              render={({ isLoading, data }) =>
                !isLoading && (
                  <>
                    {data.map((profileLanguage) => (
                      <Box key={profileLanguage.id}>
                        <Chip
                          sx={{ mb: 1 }}
                          label={
                            <>
                              <LanguageField
                                record={profileLanguage.language_id}
                              />
                              &nbsp;(
                              <LanguageLevelField
                                record={profileLanguage.language_level_id}
                              />
                              )
                            </>
                          }
                        />
                      </Box>
                    ))}
                  </>
                )
              }
            />
          </ReferenceManyField>
        </DatagridColumn>

        <ReferenceField
          label="Country"
          source="residence_country_id"
          reference="countries"
          link={false}
        />

        <ReferenceField
          label="Organization"
          source="profile_id"
          reference="profiles"
          link={false}
        >
          <TextField source="organization_name" />
        </ReferenceField>

        <ReferenceField
          label="Remarks"
          source="profile_id"
          reference="profiles"
          link={false}
        >
          <TextField source="remarks" />
        </ReferenceField>

        <ReferenceField
          label="Miscellaneous"
          source="profile_id"
          reference="profiles"
          link={false}
        >
          <TextField source="miscellaneous" />
        </ReferenceField>

        <CvColumn
          label="Specializations"
          reference="specializations"
          through="cv-specializations"
          target="specialization_id"
        />

        <CvColumn
          label="Occupations"
          reference="professions"
          through="cv-professions"
          target="profession_id"
        />

        <EditButton label="Edit" />

        <FieldWrapper label="Profile">
          <EditProfileButton />
        </FieldWrapper>
      </DatagridConfigurable>
    </>
  )
}
