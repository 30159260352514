import { Box } from "@mui/system"
import { Filter } from "ra-ui-materialui"
import { OrganizationWithProgramSelect } from "./OrganizationWithProgramSelect"

export const OrganizationWithProgramFilter = (props) => {
  return (
    <Box
      sx={{
        color: "#F00",
        width: "100%",
        "& .RaFilterForm-filterFormInput": {
          flexGrow: 1,
        },
      }}
    >
      <Filter {...props}>
        <OrganizationWithProgramSelect />
      </Filter>
    </Box>
  )
}
