import React, { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import CommentsList from "./comments_list"
import { getComments } from "repository/comments_repository"
import CommentsForm from "./comments_form"
import CommentsIcon from "./comments_icon"
import { I18n } from "i18n-js"
import translations from "../locales.json"
import UnloqTheme from "./unloq_theme"

const i18n = new I18n(translations)

const dialogTitleStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}

const CommentsModal = ({
  label = "",
  current_user_id,
  commentable_id,
  commentable_type,
  show_public_only = "true",
}) => {
  const [open, setOpen] = React.useState(false)
  const [comments, setComments] = useState([])
  const [currentUserId] = useState(current_user_id)
  const [commentableId] = useState(commentable_id)
  const [commentableType] = useState(commentable_type)
  const [replyComment, setReplyComment] = useState("")
  const [unreadCommentsCount, setUnreadCommentsCount] = useState(0)

  useEffect(() => {
    const fetchComments = async () => {
      const filters = {
        commentable_id: commentable_id,
        commentable_type: commentable_type,
      }

      if (show_public_only == "true") {
        filters["shared"] = ["public", "coach_only"]
      }
      const fetchedComments = await getComments({ filters: filters })
      if (fetchedComments.length > 0) {
        setComments(fetchedComments)
        setUnreadCommentsCount(calculateUnreadCommentCount(fetchedComments))
      }
    }

    if (comments.length == 0) {
      fetchComments().catch(console.error)
    }
  }, [comments, commentable_id, commentable_type, show_public_only])

  const handleOpen = () => {
    setOpen(true)
    setUnreadCommentsCount(0)
  }
  const handleClose = () => setOpen(false)

  function calculateUnreadCommentCount(comments) {
    return comments.filter((comment) => !comment.readByMe).length
  }

  return (
    <UnloqTheme>
      <CommentsIcon
        total_comments={comments.length}
        unread_comments={unreadCommentsCount}
        click={handleOpen}
        location="modal"
      />

      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle sx={dialogTitleStyle}>
          <h2>{label}</h2>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ minHeight: "80vh", width: "100%" }}>
          <Box sx={{ fontSize: "0.85em", color: "grey" }}>
            {i18n.t("comments.explanation")}
          </Box>
          <Box sx={{ overflow: "auto", height: "calc(80vh - 270px)" }}>
            <CommentsList
              i18n={i18n}
              comments={comments}
              setComments={setComments}
              currentUserId={currentUserId}
              setReplyComment={setReplyComment}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              backgroundColor: "#FFF",
              bottom: "10px",
              width: "80%",
              height: "240px",
            }}
          >
            <CommentsForm
              i18n={i18n}
              setComments={setComments}
              commentable_type={commentableType}
              commentable_id={commentableId}
              setReplyComment={setReplyComment}
              replyComment={replyComment}
              showPublicOnly={show_public_only}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </UnloqTheme>
  )
}
export default CommentsModal
