import { useState } from "react"
import * as toastr from "toastr"
import { EmailOutlined, InfoOutlined } from "@mui/icons-material"
import { useSelector } from "react-redux"
import { I18n } from "i18n-js"

import translations from "../locales.json"
import { RootState } from "lib/store"
import { Box, CircularProgress } from "@mui/material"

const i18n = new I18n(translations)

const SendReportEmailButton = () => {
  const [isSending, setIsSending] = useState(false)
  const filePath = useSelector(
    (state: RootState) => state.programMemberships.filePath
  )
  const isLoading = useSelector(
    (state: RootState) => state.programMemberships.isLoading
  )

  const sendReport = async (): Promise<void> => {
    const form = document.getElementById(
      "people_analytics_membership"
    ) as HTMLFormElement
    const formData = new FormData(form)

    setIsSending(true)

    return await fetch(form.action + "/send_client_report_mail", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          toastr.error("An unknown error occurred.")
        } else {
          toastr.success(i18n.t("toastrs.sent"))
          return
        }
      })
      .finally(() => {
        setTimeout(() => setIsSending(false), 2000)
      })
  }

  return (
    <Box
      sx={{ m: 0, position: "relative" }}
      className="flex items-center justify-center"
    >
      {!isLoading && filePath && (
        <>
          <button
            className="btn btn--primary"
            disabled={isSending}
            onClick={async (e) => {
              e.preventDefault()
              await sendReport()
            }}
          >
            <EmailOutlined fontSize="small" sx={{ marginRight: 1 }} />
            {isSending
              ? i18n.t("people_analytics.btn_send_report_link_email_active")
              : i18n.t("people_analytics.btn_send_report_link_email")}
          </button>
          {isSending && (
            <CircularProgress
              color="inherit"
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
          <div className="tooltip flex relative cursor-pointer ml-3">
            <InfoOutlined fontSize="small" sx={{ marginRight: 1 }} />
            <span className="tooltiptext tooltiptext-top">
              {" "}
              {i18n.t("people_analytics.btn_send_report_link_email_hint")}{" "}
            </span>
          </div>
        </>
      )}
    </Box>
  )
}

SendReportEmailButton.displayName = "SendReportEmailButton"

export default SendReportEmailButton
