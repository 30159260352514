export const states = [
  "Absent",
  "Completed",
  "Failed",
  "No-show",
  "Not started",
  "Pending",
  "Pending feedback",
  "Pending attendance feedback",
  "Started",
]
