import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp"
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary"
import { styled } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import * as React from "react"
import { CvCardTab } from "../../components/fields/CvCardTab"

export const CvAccordion = ({ user, cvType }) => {
  const [expanded, setExpanded] = React.useState<string | false>("")

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  return (
    <div>
      <Accordion
        expanded={expanded === "job-information"}
        onChange={handleChange("job-information")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Job Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CvCardTab user={user} cvType={cvType} section="job-information" />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "short-bio"}
        onChange={handleChange("short-bio")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Shot Bio</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CvCardTab user={user} cvType={cvType} section="short-bio" />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "work-experience"}
        onChange={handleChange("work-experience")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Work Experience</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CvCardTab user={user} cvType={cvType} section="work-experience" />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "education"}
        onChange={handleChange("education")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Education</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CvCardTab user={user} cvType={cvType} section="education" />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "certifications"}
        onChange={handleChange("certifications")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Certifications</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CvCardTab user={user} cvType={cvType} section="certifications" />
        </AccordionDetails>
        <Accordion
          expanded={expanded === "affiliations"}
          onChange={handleChange("affiliations")}
        >
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography>Affiliations</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CvCardTab user={user} cvType={cvType} section="affiliations" />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "publications"}
          onChange={handleChange("publications")}
        >
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography>Publications</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CvCardTab user={user} cvType={cvType} section="publications" />
          </AccordionDetails>
        </Accordion>
      </Accordion>
    </div>
  )
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: "0px",
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}))
