import { defaultTheme } from "react-admin"

// const unloqRed = "#f94137"
// const unloqDark = "#262421"

const unloqBeige = "#c29e94"
const unloqBrown = "#ad771d"
const unloqBrightgreen = "#37e178"
const darkGrey = "#999999"
const lightestGrey = "#f9f9f9"

export const lightTheme = {
  ...defaultTheme,
  sidebar: {
    width: 200,
    closedWidth: 48,
  },
  palette: {
    primary: {
      main: unloqBrown,
    },
    secondary: {
      main: unloqBrightgreen,
      light: lightestGrey,
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontSize: 12,
    fontFamily: ["NewGrotesk", "system-ui", "sans-serif"].join(","),
  },
  components: {
    ...defaultTheme.components,
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: unloqBeige,
          fontSize: "17px",
          fontWeight: "400",
          color: "#000",
        },
      },
    },
    RaButton: {
      styleOverrides: {
        root: {
          color: "#000",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "9999px",
          color: "#000 !important",
          padding: ".3rem 1rem",
          ":not(.Mui-disabled)": {
            backgroundColor: `${unloqBrightgreen}`,
            border: `2px solid ${unloqBrightgreen}`,
            "&:hover": {
              color: "#000",
              backgroundColor: "#FFF",
            },
          },
          "& .Mui-disabled": {
            backgroundColor: "#FFF",
            border: `2px solid ${darkGrey}`,
            color: `${darkGrey}`,
          },
        },
        outlined: {
          backgroundColor: "#FFF !important",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: `${unloqBrightgreen}`,
        },
      },
    },
    RaTopToolbar: {
      styleOverrides: {
        root: {
          "& .MuiButton-textPrimary": {
            backgroundColor: unloqBrightgreen,
            borderRadius: "9999px",
            color: "#000",
            padding: ".4rem 1rem",
            border: `2px solid ${unloqBrightgreen}`,
          },
        },
      },
    },
    RaLayout: {
      styleOverrides: {
        root: {
          "& .RaLayout-content": {
            boxShadow: "0px 5px 15px -10px rgba(0,0,0,0.75)",
            padding: "2rem 0px",
          },
          "& .RaSidebar-docked": {
            width: "200px",
          },
        },
      },
    },
    RaMenuItemCategory: {
      styleOverrides: {
        root: {
          "& .RaMenuItemCategory-container": {
            padding: 0,
          },
          "& .RaMenuItemCategory-link": {
            width: "100%",
            padding: "8px",
          },
        },
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          "& .RaDatagrid-headerCell": {
            backgroundColor: lightestGrey,
          },
          "& .RaDatagrid-expandedPanel": {
            backgroundColor: lightestGrey,
          },
        },
      },
    },
    RaBreadcrumb: {
      styleOverrides: {
        root: {
          paddingLeft: "32px",
        },
      },
    },
    RaFileInput: {
      styleOverrides: {
        root: {
          "& .RaFileInput-dropZone": {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
            borderWidth: "2px;",
            borderRadius: "2px",
            borderColor: "#eeeeee",
            borderStyle: "dashed",
            backgroundColor: "#fafafa",
            color: "#bdbdbd",
            outline: "none",
            transition: "border .24s ease-in-out",
          },
        },
      },
    },
    RaImageField: {
      styleOverrides: {
        root: {
          "& .RaImageField-image": {
            maxWidth: 100,
            maxHeight: 100,
            objectFit: "contain",
          },
        },
      },
    },
    RaReferenceField: {
      styleOverrides: {
        root: {
          "& .RaReferenceField-link >*": {
            color: "#000",
            textDecoration: "underline",
            textUnderlineOffset: "4px",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#000",
          textDecoration: "underline",
          textUnderlineOffset: "4px",
          margin: "4px",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#000 !important",
        },
      },
    },
    RaEditButton: {
      styleOverrides: {
        root: {
          margin: "4px",
        },
      },
    },
    RaDeleteButton: {
      styleOverrides: {
        root: {
          padding: "4px",
          margin: "4px",
        },
      },
    },
    RaBulkActionsToolbar: {
      styleOverrides: {
        root: {
          "& .MuiTypography-root": {
            color: "#000",
          },
          "& .RaBulkActionsToolbar-toolbar": {
            backgroundColor: "#eaeaea",
          },
        },
      },
    },
    RaNotification: {
      styleOverrides: {
        root: {
          opacity: 0.9,
          "& .RaNotification-success": {
            backgroundColor: unloqBrightgreen,
            color: "#000",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiFormHelperText-root": {
            fontSize: "0.85em",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          "& .filter_container > div": {
            flexGrow: 1,
            width: "100%",
          },
        },
      },
    },
  },
}
