import {
  Datagrid,
  InfiniteList,
  ReferenceField,
  TextField,
} from "react-admin"
import { UserField } from "../../components/fields/UserField"

export const ChangeRequestList = (props) => (
  <InfiniteList resource="change-requests" {...props} exporter={false}>
    <Datagrid bulkActionButtons={false} rowClick="edit">
      <ReferenceField source="user_id" reference="users" link={false} sortable={false}>
        <UserField />
      </ReferenceField>

      <TextField source="field_changed" />
      <TextField source="status" />
      <TextField source="created_at" />
    </Datagrid>
  </InfiniteList>
)
