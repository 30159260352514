import styled from "@emotion/styled"
import { mergeAttributes, Node } from "@tiptap/core"
import {
  NodeViewContent,
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from "@tiptap/react"
import { useTranslatableContext } from "react-admin"

const EmailContainer = styled.div`
  background-color: #f6f6f6;
  font: 16px/24px sans-serif;
  color: #222;
  padding-top: 2em;
  padding-bottom: 2em;
  height: 100%;
  border: 1px solid silver;
`

const EmailWrapper = styled.div`
  background-color: #fff;
  width: 90%;
  padding: 2em;
  border: 1px solid #ddd;
  margin: 0 auto;
  max-width: 600px;
`

const EmailHr = styled.hr`
  border: none;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  height: 0;
  display: block;
`

const EmailSmall = styled.small`
  color: #999;
`

const EmailContent = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 20px;
  }
  .is-empty::before {
    content: attr(data-placeholder);
    float: left;
    color: #979ca1;
    pointer-events: none;
    height: 0;
  }
  .mention {
    border: 1px solid #000;
    border-radius: 0.4rem;
    padding: 0.1rem 0.3rem;
    box-decoration-break: clone;
  }
`

export const NodeWrapper = () => {
  const { selectedLocale } = useTranslatableContext()

  // TODO: Replace with real i18n translations coming from rails
  const messages = {
    en: "Note: this message is confidential.",
    es: "Nota: este mensaje es confidencial.",
    fr: "Attention : ce message est confidentiel.",
    nl: "Let op: dit bericht is vertrouwelijk.",
  }

  return (
    <NodeViewWrapper>
      <EmailContainer>
        <EmailWrapper>
          <EmailContent>
            <NodeViewContent />
          </EmailContent>
          <div contentEditable={false}>
            <EmailHr />
            <EmailSmall>{messages[selectedLocale]}</EmailSmall>
          </div>
        </EmailWrapper>
      </EmailContainer>
    </NodeViewWrapper>
  )
}

export const EmailTemplateContentNode = Node.create({
  name: "emailContent",
  group: "block",
  content: "block heading block*",

  parseHTML() {
    return [
      {
        tag: "email-content",
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ["email-content", mergeAttributes(HTMLAttributes), 0]
  },

  addNodeView() {
    return ReactNodeViewRenderer(NodeWrapper)
  },
})
