import {
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  DateField,
  EditButton,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  TextField,
} from "react-admin"

import { List } from "@react-admin/ra-rbac"

const programFilters = [
  <SearchInput key="search" source="name" alwaysOn />,
  <BooleanInput
    key="active"
    source="active"
    label="Hide inactive"
    alwaysOn
    sx={{
      height: "50px",
      display: "flex",
      justifyContent: "center",
      width: "100%",
    }}
  />,
  <ReferenceInput
    key="organization"
    source="organization_id"
    reference="organizations"
  >
    <AutocompleteInput sx={{ width: "100%" }} />
  </ReferenceInput>,
]

export const ProgramList = () => {
  return (
    <List
      filters={programFilters}
      perPage={25}
      sort={{ field: "name", order: "ASC" }}
    >
      <Datagrid rowClick="show">
        <TextField source="name" />
        <TextField source="client_count" label="Clients" sortable={false} />
        <TextField source="coach_count" label="Coaches" sortable={false} />
        <TextField source="minutes_per_client" label="Minutes" />
        <DateField source="start_date" label="Start" />
        <DateField source="end_date" label="End" />
        <ReferenceField
          label="Organization"
          source="organization_id"
          reference="organizations"
          link="show"
        />
        <ReferenceField
          label="Questionnaire"
          source="questionnaire_id"
          reference="questionnaires"
          link="show"
        />
        <ReferenceField
          label="Learning path"
          source="learning_path_id"
          reference="learning-paths"
          link="show"
        />
        <EditButton label="Edit" />
      </Datagrid>
    </List>
  )
}
