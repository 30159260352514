import { Box } from "@mui/system"
import Image from "mui-image"

export const IRobinChatBubble = ({ children }) => {
  const size = "80px"

  return (
    <Box display="flex" sx={{ maxWidth: "800px" }}>
      <Box
        sx={{
          width: size,
          minWidth: size,
          height: size,
          minHeight: size,
        }}
      >
        <Image
          animationDuration={50}
          src="/irobin-avatar.png"
          style={{
            transitionDuration: "50ms",
            animation: "none",
          }}
        />
      </Box>
      <Box alignSelf="center" ml={1} pl={1}>
        <Box
          sx={{
            border: "1px solid #eee",
            padding: "1rem 1.5rem",
            borderRadius: "1rem",
            position: "relative",
          }}
        >
          {children}
          <Box
            sx={{
              backgroundColor: "#fff",
              borderColor: "#eee",
              borderLeftWidth: "1px",
              borderBottomWidth: "1px",
              transform:
                "translate(-50%, 0) rotate(45deg) skewX(0) skewY(0) scaleX(1) scaleY(1)",
              width: "1.25rem",
              height: "1.25rem",
              top: "2.5rem",
              left: "-1px",
              position: "absolute",
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}
