import { Create } from "react-admin"
import { ProgramBreakForm } from "./ProgramBreakForm"

export const ProgramBreakCreate = () => {
  return (
    <Create>
      <ProgramBreakForm />
    </Create>
  )
}
