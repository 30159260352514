import {
  Datagrid,
  List,
  ReferenceManyCount,
  SearchInput,
  TextField,
} from "react-admin"

const filters = [<SearchInput key="search" source="name" alwaysOn />]

export const OrganizationList = () => (
  <List filters={filters}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <ReferenceManyCount
        label="Programs"
        reference="programs"
        target="organization_id"
        link
      />
      <ReferenceManyCount
        label="Clients"
        reference="clients"
        target="organization_id"
        link
      />
      <ReferenceManyCount
        label="Coaches"
        reference="coaches"
        target="organization_id"
        link
      />
    </Datagrid>
  </List>
)
