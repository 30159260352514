/* eslint-disable */

import React, { useEffect, useState } from "react"
import { Box, Skeleton, Tab } from "@mui/material"
import { TabContext, TabList, TabPanel } from "@mui/lab"

import { getAssignments } from "repository/assignments_repository"

const QuestionnairePreview = ({ id }) => {
  const [value, setValue] = useState("")
  const [content, setContent] = useState("")
  const [assignments, setAssignments] = useState([])

  useEffect(() => {
    if (assignments.length == 0) {
      const fetchAssignments = async () => {
        const filters = [
          { name: "questionnaire", value: id },
          { name: "target_group", value: "clients" },
        ]
        const assignments = await getAssignments({ filters: filters })
        setAssignments(assignments)
        setValue(assignments[0].previewUrl)
      }

      fetchAssignments().catch(console.error)
    }
  }, [assignments])

  useEffect(() => {
    if (value != "") {
      setHtmlResponse(value)
    }
  }, [value])

  const setHtmlResponse = async (previewUrl) => {
    const response = await fetch(previewUrl)
    const html = await response.text()
    setContent(html)
  }

  const handleChange = async (
    event: React.SyntheticEvent,
    newValue: string,
  ) => {
    setContent("")
    setValue(newValue)
  }

  const styles = {
    border: "none",
    width: "100%",
    height: "calc(100vh - 100px)",
  }

  const RenderHTML = (props) => {
    return (
      <>
        {content.length == 0 ? (
          <Box className="rounded w-5/6 sm:w-5/6 md:w-5/6 lg:w-2/3 xl:w-1/2 mx-auto p-6 text-grey-darker spaced-paragraphs">
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem", py: 3 }}
              animation="wave"
            />
            <Skeleton
              variant="rectangular"
              height={200}
              sx={{ py: 3, mb: 3 }}
              animation="wave"
            />
            <Skeleton
              variant="rectangular"
              height={200}
              sx={{ py: 3, mb: 3 }}
              animation="wave"
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "1rem", py: 3 }}
              animation="wave"
            />
            <Skeleton
              variant="rectangular"
              height={200}
              sx={{ py: 3, mb: 3 }}
              animation="wave"
            />
            <Skeleton
              variant="rectangular"
              height={200}
              sx={{ py: 3, mb: 3 }}
              animation="wave"
            />
          </Box>
        ) : (
          <span dangerouslySetInnerHTML={{ __html: props.HTML }}></span>
        )}
      </>
    )
  }

  return (
    <Box sx={{ width: "100%", typography: "body1" }} className="container">
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="Questionnaire preview"
            centered
          >
            {assignments.map(function (assignment) {
              return (
                <Tab
                  key={assignment.hashid}
                  label={assignment.name}
                  value={assignment.previewUrl}
                />
              )
            })}
          </TabList>
        </Box>
        {assignments.map(function (assignment) {
          return (
            <TabPanel key={assignment.hashid} value={assignment.previewUrl}>
              <RenderHTML HTML={content} />
            </TabPanel>
          )
        })}
      </TabContext>
    </Box>
  )
}

export default QuestionnairePreview
