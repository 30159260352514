import { Translate } from "@mui/icons-material"
import { useState } from "react"
import {
  Button,
  Confirm,
  useDataProvider,
  useListContext,
  useNotify,
  useRefresh,
  useUnselectAll,
} from "react-admin"

export const BulkTranslateAction = ({ model, plural, setIsTranslating }) => {
  const [open, setOpen] = useState(false)
  const { selectedIds } = useListContext()
  const refresh = useRefresh()
  const unselectAll = useUnselectAll(plural.toLowerCase())
  const notify = useNotify()
  const dataProvider = useDataProvider()

  const handleDialogClose = () => setOpen(false)
  const handleClick = () => setOpen(true)

  const handleConfirm = () => {
    setIsTranslating(true)
    setOpen(false)
    dataProvider
      .translateMany(model, selectedIds)
      .then(() => {
        refresh()
        notify(`${plural} translated`, { type: "success" })
        unselectAll()
        setIsTranslating(false)
      })
      .catch((error: string) => {
        notify(error, { type: "error" })
      })
  }

  return (
    <>
      <Button label="Translate" onClick={handleClick}>
        <Translate />
      </Button>
      <Confirm
        isOpen={open}
        title={`Translate ${plural}`}
        content={`Are you sure you want to translate these ${plural}?`}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  )
}
