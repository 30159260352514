import { ReactNode, useEffect } from "react"
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  TextInput,
  useListContext, useTranslate
} from "react-admin"
import { OrganizationWithProgramFilter } from "../inputs/OrganizationWithProgramFilter"

export const CoachListFilterTopBar = (): ReactNode[] => {
  const { filterValues, displayedFilters, setFilters } = useListContext()
  const translate = useTranslate()

  // His effect makes sure that the url is updated correctly when you clear
  // the "organization with program" filter, as that is a special filter consisting of
  // 2 linked inputs
  useEffect(() => {
    if (!displayedFilters["programme"]) {
      if (filterValues.organization_id || filterValues.program_id) {
        delete filterValues.organization_id
        delete filterValues.program_id
        setFilters(filterValues, displayedFilters)
      }
    }
  }, [filterValues, displayedFilters, setFilters])

  return [
    <TextInput
      source="job_title"
      name="job_title"
      key="job_title"
      label="react.filters.job_title"
      sx={{ flexGrow: 1 }}
    />,

    <ReferenceArrayInput
      key="country"
      source="residence_country_id"
      reference="countries"
      sort={{ field: "common_name", order: "ASC" }}
      perPage={300}
    >
      <AutocompleteArrayInput
        label="react.filters.countries"
        optionText={(record) => record.common_name}
        sx={{ flexGrow: 1 }}
      />
    </ReferenceArrayInput>,

    <ReferenceArrayInput
      key="languages"
      source="languages"
      reference="languages"
      sort={{ field: "common_name", order: "ASC" }}
    >
      <AutocompleteArrayInput
        label="react.filters.languages"
        optionText={(record) => record.common_name}
        sx={{ flexGrow: 1 }}
      />
    </ReferenceArrayInput>,

    <ReferenceArrayInput
      key="timezone"
      source="timezones"
      reference="timezones"
      sort={{ field: "name", order: "ASC" }}
    >
      <AutocompleteArrayInput
        sx={{ minWidth: "400px", flexGrow: 1 }}
        label="react.filters.timezones"
        optionText={(record) => `${record.name} (${record.offset})`}
      />
    </ReferenceArrayInput>,

    <ReferenceInput
      key="organization"
      source="organization"
      reference="organizations"
      perPage={100}
      sort={{ field: "name", order: "ASC" }}
    >
      <AutocompleteInput
        size="medium"
        margin="none"
        label="react.filters.organization"
        sx={{ minWidth: "400px", flexGrow: 1 }}
      />
    </ReferenceInput>,

    <OrganizationWithProgramFilter
      key="organization_with_program"
      source="programme"
    />,

    <ReferenceArrayInput
      key="specialization"
      source="specializations"
      reference="specializations"
      sort={{ field: "name.en", order: "ASC" }}
      perPage={200}
    >
      <AutocompleteArrayInput
        sx={{ minWidth: "400px", flexGrow: 1 }}
        label="react.filters.specializations"
        optionText={(record) => record.name.en}
      />
    </ReferenceArrayInput>,

    <ReferenceArrayInput
      key="profession"
      source="professions"
      reference="professions"
      sort={{ field: "name.en", order: "ASC" }}
    >
      <AutocompleteArrayInput
        sx={{ minWidth: "400px", flexGrow: 1 }}
        label="react.filters.professions"
        optionText={(record) => record.name.en}
      />
    </ReferenceArrayInput>,

    <ReferenceArrayInput
      key="branch_experience"
      source="branch_experiences"
      reference="branch-experiences"
      sort={{ field: "name.en", order: "ASC" }}
    >
      <AutocompleteArrayInput
        sx={{ minWidth: "400px", flexGrow: 1 }}
        label="react.filters.branch_experiences"
        optionText={(record) => record.name.en}
      />
    </ReferenceArrayInput>,

    <SelectArrayInput
      source="contract_type"
      name="contract_type"
      key="contract_type"
      choices={[
        { id: "core_team", name: "Core Team" },
        { id: "fixed_hours", name: "Fixed hours" },
        { id: "project_basis", name: "Project based" },
      ]}
    />,

    <SelectArrayInput
      source="level_ecoaching"
      name="level_ecoaching"
      key="level_ecoaching"
      choices={[
        { id: "level_i", name: "Level I" },
        { id: "level_ii", name: "Level II" },
        { id: "level_iii", name: "Level III" },
      ]}
    />,

    <SelectArrayInput
      source="level_coaching"
      name="level_coaching"
      key="level_coaching"
      choices={[
        { id: "executive", name: "Executive" },
        { id: "leadership_coaching", name: "Leadership coaching" },
        { id: "top_executive", name: "Top executive" },
        { id: "young_talent_professional", name: "Young talent professional" },
      ]}
    />,

    <SelectArrayInput
      source="coach_type"
      name="coach_type"
      key="coach_type"
      choices={[
        { id: "a", name: translate("react.coaches.coach_types.a") },
        { id: "t", name: translate("react.coaches.coach_types.t") },
      ]}
    />,
  ]
}
