import { FunctionField } from "ra-ui-materialui"
import { ArrayChipField } from "./ArrayChipField"

export const JsonApiArrayField = ({ source, label }) => {
  return (
    <FunctionField
      label={label}
      render={(record) => <ArrayChipField items={record[source]} />}
    />
  )
}
