import FileCopyIcon from "@mui/icons-material/FileCopy"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material"
import { Box } from "@mui/system"
import { useDataProvider, useNotify, useRefresh, useTranslate } from "ra-core"
import { TextInput } from "ra-ui-materialui"
import { useState } from "react"
import { Button as ReactAdminButton } from "react-admin"
import { AffiliationFields } from "../../resources/coaches/AffiliationFields"
import { BioFields } from "../../resources/coaches/BioFields"
import { CertificationFields } from "../../resources/coaches/CertificationFields"
import { CvTabs } from "../../resources/coaches/CvTabs"
import { EducationFields } from "../../resources/coaches/EducationFields"
import { JobInformationFields } from "../../resources/coaches/JobInformationFields"
import { PublicationFields } from "../../resources/coaches/PublicationFields"
import { WorkExperienceFields } from "../../resources/coaches/WorkExperienceFields"

export const CvFields = ({ coachId, record, section, cvType }) => {
  const humanReadableCvType = cvType.charAt(0).toUpperCase() + cvType.slice(1)

  return (
    <>
      <Typography variant="h4" component="h6" sx={{ mb: 3, ml: 1 }}>
        {humanReadableCvType} CV
      </Typography>

      <CvTabs coachId={coachId} cvType={cvType} />

      <TextInput
        source="user_id"
        defaultValue={coachId}
        style={{ display: "none" }}
      />

      <TextInput
        source="purpose"
        defaultValue={cvType}
        style={{ display: "none" }}
      />

      {cvType == "commercial" && !record.commercial_curriculum_vitae_id && (
        <CommercialCvInformation coachId={record.id} />
      )}

      <Box pt={4} sx={{ width: "100%" }}>
        <TabbedFields section={section} />
      </Box>
    </>
  )
}

const TabbedFields = ({ section }) => {
  if (section == "job-information") {
    return <JobInformationFields />
  }
  if (section == "short-bio") {
    return <BioFields />
  }
  if (section == "work-experience") {
    return <WorkExperienceFields />
  }
  if (section == "certifications") {
    return <CertificationFields />
  }
  if (section == "education") {
    return <EducationFields />
  }
  if (section == "affiliations") {
    return <AffiliationFields />
  }
  if (section == "publications") {
    return <PublicationFields />
  }
}

const CommercialCvInformation = ({ coachId }) => {
  const translate = useTranslate()
  const refresh = useRefresh()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const [confirmOpen, setConfirmOpen] = useState(false)

  const openConfirmDialog = () => {
    setConfirmOpen(true)
  }

  const handleCloneCv = () => {
    dataProvider
      .cloneCv(coachId)
      .then(() => {
        refresh()
        notify(`accounts.curriculum_vitae.copy_from_default_success`, {
          type: "success",
        })
      })
      .catch((error: string) => {
        notify(error, { type: "error" })
      })
  }

  return (
    <Box mt={4}>
      <Typography mb={2}>
        {translate("react.admin.curriculum_vitae_intro")}
      </Typography>

      <ReactAdminButton
        label="react.coaches.cv.copy_from_default_button"
        onClick={openConfirmDialog}
        id="copy-from-default-btn"
        startIcon={<FileCopyIcon />}
      />

      <ConfirmDialog
        title={translate("react.coaches.cv.copy_default_cv")}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleCloneCv}
      >
        <Box
          dangerouslySetInnerHTML={{
            __html: translate("react.coaches.cv.confirm_copy_cv"),
          }}
        />
      </ConfirmDialog>
    </Box>
  )
}

const ConfirmDialog = (props) => {
  const translate = useTranslate()
  const { title, children, open, setOpen, onConfirm } = props

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            setOpen(false)
            onConfirm()
          }}
        >
          {translate("btn.ok")}
        </Button>
        <Button variant="contained" onClick={() => setOpen(false)}>
          {translate("btn.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
