import { Box, Divider } from "@mui/material"
import { RootState } from "lib/store"
import { useSelector } from "react-redux"
import dayjs from "dayjs"
import "dayjs/locale/nl"
import { I18n } from "i18n-js"

import translations from "../locales.json"
import PdfRenderer from "components/pdf_renderer"
import GenerateReportButton from "components/generate_report_button"

const i18n = new I18n(translations)

const PdfPreview = () => {
  const filePath = useSelector(
    (state: RootState) => state.programMemberships.filePath,
  )
  const reportSentAt = useSelector(
    (state: RootState) => state.programMemberships.reportSentAt,
  )
  const reportOpenedAt = useSelector(
    (state: RootState) => state.programMemberships.reportOpenedAt,
  )
  const isLoading = useSelector(
    (state: RootState) => state.programMemberships.isLoading,
  )
  const isPdfSuccessful = useSelector(
    (state: RootState) => state.programMemberships.isPdfSuccessful,
  )

  dayjs.locale("nl")

  return (
    <Box sx={{ textAlign: "center" }}>
      {!isLoading && reportSentAt && (
        <h3 className="text-grey-darker text-center">
          {i18n.t("people_analytics.report_generated_at", {
            date: dayjs(reportSentAt).format("L LT"),
          })}
        </h3>
      )}
      {!isLoading && reportOpenedAt && (
        <h3 className="text-grey-darker text-center">
          {i18n.t("people_analytics.report_opened_by_client_at", {
            date: dayjs(reportOpenedAt).format("L LT"),
          })}
        </h3>
      )}
      {!isLoading && (reportSentAt || reportOpenedAt) && (
        <Divider sx={{ marginBottom: 1 }} />
      )}

      {!reportSentAt && !isLoading && (
        <Box>
          <h1 className="text-grey-darker text-center">
            {i18n.t("people_analytics.generate_report_prompt")}
          </h1>
          <GenerateReportButton />
        </Box>
      )}

      {reportSentAt && !isLoading && !isPdfSuccessful && (
        <h1 className="text-red-light text-center">
          {i18n.t("people_analytics.generate_report_error")}
        </h1>
      )}

      {filePath && (
        <PdfRenderer
          file_path={filePath}
          can_regenerate={true}
          loader_text={i18n.t("people_analytics.report_is_loading")}
        />
      )}
    </Box>
  )
}

PdfPreview.displayName = "PdfPreview"

export default PdfPreview
