import React from "react"
import { PinturaEditor } from "@pqina/react-pintura"
import {
  plugin_crop,
  plugin_filter,
  plugin_finetune,
  setPlugins,
} from "@pqina/pintura"
import "@pqina/pintura/pintura.css"

import {
  createEditorDefaults,
  cropSelectPresetOptions,
  willRenderToolbar,
} from "./PinturaConfig"

interface PinturaEditorWrapperProps {
  src: string
  onProcess: (result: { dest: Blob | MediaSource }) => void
  labelButtonExport?: string
  cropSelectPresetFilter: false | "landscape" | "portrait"
  ref: React.MutableRefObject<any>
}

export const PinturaEditorWrapper: React.FC<PinturaEditorWrapperProps> = ({
  src,
  onProcess,
  labelButtonExport = "Done",
  cropSelectPresetFilter = "landscape",
  ref,
}) => {
  const editorDefaults = createEditorDefaults(labelButtonExport)

  setPlugins(plugin_crop, plugin_finetune, plugin_filter)

  return (
    <PinturaEditor
      {...editorDefaults}
      src={src}
      ref={ref}
      willRenderToolbar={willRenderToolbar}
      cropSelectPresetFilter={cropSelectPresetFilter}
      cropSelectPresetOptions={cropSelectPresetOptions}
      onProcess={onProcess}
    />
  )
}
