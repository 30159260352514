import { buildI18nProvider } from "@react-admin/ra-enterprise"

import en from 'ra-language-english';
import fr from 'ra-language-french';
import es from '@blackbox-vision/ra-language-spanish/dist-web';
import nl from 'ra-language-dutch';

const packageTranslations = { en, fr, nl, es };

import translations from "../locales.json"

const languages = [
  { locale: "en", name: "English" },
  { locale: "es", name: "Español" },
  { locale: "fr", name: "Français" },
  { locale: "nl", name: "Nederlands" },
]

const mergedTranslations = Object.keys(translations).reduce((acc, locale) => {
  acc[locale] = { ...translations[locale], ...packageTranslations[locale] };
  return acc;
}, {});

export const getStoredLocale = () => {
  let locale = localStorage.getItem("RaStore.locale")

  const validLocale = languages.some((language) => language.locale === locale)

  if (!validLocale) {
    locale = "en"
  }

  return locale
}

export const i18nProvider = buildI18nProvider(
  mergedTranslations,
  getStoredLocale(),
  languages,
)
