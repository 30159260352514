import { Card, CardContent } from "@mui/material"
import { ClientCard } from "../../components/cards/ClientCard"

export const CoachSidebar = ({ user }) => {
  return (
    <Card sx={{ order: -1, mr: 2, minWidth: "225px" }}>
      <CardContent>
        <ClientCard user={user} />
      </CardContent>
    </Card>
  )
}
