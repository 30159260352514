import { Typography } from "@mui/material"
import { ReferenceManyInput } from "@react-admin/ra-relationships"
import { required, useLocaleState, useTranslate } from "ra-core"
import {
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
} from "ra-ui-materialui"
import { UnloqRemoveButton } from "../buttons/UnloqRemoveButton"
import { ValidateBeforeAddButton } from "../buttons/ValidateBeforeAddButton"
import { IRobinChatBubble } from "../forms/IRobinChatBubble"
import { Box } from "@mui/system"

export const GroupWorkFields = () => {
  const translate = useTranslate()
  const [locale] = useLocaleState()

  const groupWorkLocations = [
    {
      id: "online",
      name: translate("react.coaches.group_work_locations.online"),
    },
    {
      id: "offline",
      name: translate("react.coaches.group_work_locations.offline"),
    },
    { id: "both", name: translate("react.coaches.group_work_locations.both") },
  ]

  const groupWorkTypes = [
    {
      id: "design",
      name: translate("react.coaches.group_work_types.design"),
    },
    {
      id: "delivery",
      name: translate("react.coaches.group_work_types.delivery"),
    },
    { id: "both", name: translate("react.coaches.group_work_types.both") },
    { id: "na", name: translate("not_applicable") },
  ]

  return (
    <Box mt={2} width="100%">
      <IRobinChatBubble>
        <Typography variant="h6" mb={1} fontWeight="bold">
          {translate("react.admin.group_work")}
        </Typography>

        <Typography my={1}>
          {translate("react.coaches.intro.group_work")}
        </Typography>
      </IRobinChatBubble>

      <ReferenceManyInput
        label={false}
        reference="profile-group-works"
        target="profile_id"
        sort={{ field: "id", order: "ASC" }}
        defaultValue={[
          {
            "@@ra-many/profiles/profile-group-works/profile_id.0.profile-group-works.0.group_work_id":
              "",
          },
        ]}
        sx={{ marginTop: "2rem" }}
      >
        <SimpleFormIterator
          inline
          fullWidth
          disableClear
          disableReordering
          sx={{
            "& .RaSimpleFormIterator-line": { border: "none" },
          }}
          addButton={
            <ValidateBeforeAddButton
              label="react.coaches.group_work.add"
              sx={{ marginTop: "0.5rem", color: "#000" }}
            />
          }
          removeButton={
            <UnloqRemoveButton referenceName="react.admin.group_work" />
          }
        >
          <ReferenceInput
            source="group_work_id"
            reference="group-works"
            perPage={100}
            sort={{ field: `name.${locale}`, order: "ASC" }}
          >
            <AutocompleteInput
              size="medium"
              margin="none"
              optionText={`name.${locale}`}
              label="react.coaches.group_work.select"
              validate={required()}
              sx={{
                flexGrow: 1,
                "& .MuiAutocomplete-clearIndicator": {
                  display: "none",
                },
              }}
            />
          </ReferenceInput>

          <SelectInput
            emptyText={translate("none")}
            source="location"
            label="react.coaches.group_work_location.label"
            choices={groupWorkLocations}
            size="medium"
            validate={required()}
            sx={{
              flexGrow: 1,
              "& .MuiAutocomplete-clearIndicator": {
                display: "none",
              },
              margin: 0,
              maxWidth: "150px",
            }}
          />

          <SelectInput
            emptyText={translate("none")}
            source="work_type"
            label="react.coaches.group_work_type.label"
            choices={groupWorkTypes}
            size="medium"
            validate={required()}
            sx={{
              flexGrow: 1,
              "& .MuiAutocomplete-clearIndicator": {
                display: "none",
              },
              margin: 0,
              maxWidth: "150px",
            }}
          />
        </SimpleFormIterator>
      </ReferenceManyInput>
    </Box>
  )
}
