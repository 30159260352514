// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `bin/rails generate channel` command.

import { createConsumer } from "@rails/actioncable"

let cableUrl = `ws://${document.location.hostname}/websocket`
if (document.location.port == "5173" || document.location.port == "3000") {
  cableUrl = `ws://${document.location.hostname}:3000/websocket`
}

export default createConsumer(cableUrl)
