import { useContext } from "react"

import { LanguageLevelContext } from "../../../context/language_level_context"
import { LanguageLevel } from "api/models"

export const LanguageLevelField = ({ record }) => {
  const languageLevels = useContext(LanguageLevelContext)

  const matchedLanguageLevel = languageLevels.find(
    (languageLevel: LanguageLevel) => languageLevel.id === `${record}`,
  )

  return matchedLanguageLevel ? `${matchedLanguageLevel.name.en}` : ""
}
