import { useSimpleFormIterator, Button } from "ra-ui-materialui"
import { useFormState } from "react-hook-form"

export const ValidateBeforeAddButton = (rest) => {
  const { isValid } = useFormState()
  const { add } = useSimpleFormIterator()

  const validateForm = () => {
    if (isValid) {
      add()
    } else {
      // This is not the correct way to trigger form validation but at the time
      // of writing this I could not get react-hook-form's "trigger" to work
      // and also using the logic in react-admin's SaveButton didn't seem to
      // trigger form validation
      const element: HTMLElement = document.querySelector(
        'button[type="submit"]'
      ) as HTMLElement
      element.click()
    }
  }

  return <Button {...rest} onClick={validateForm} />
}
