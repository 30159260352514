import { Box } from "@mui/material"
import { AppLocationContext } from "@react-admin/ra-navigation"
import { TourProvider } from "@react-admin/ra-tour"
import { Layout } from "react-admin"
import { UnloqMenu } from "./UnloqMenu"

import { profileEditTour } from "./tours/profileEditTour"

import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

import nl from "date-fns/locale/nl"

const UnloqAppBar = () => <Box sx={{ mb: 0 }} />

export const UnloqLayout = ({ children, ...rest }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nl}>
      <AppLocationContext>
        <TourProvider
          tours={{
            "profiles-edit": profileEditTour,
          }}
        >
          <Layout {...rest} appBar={UnloqAppBar} menu={UnloqMenu}>
            <Box
              sx={{
                px: 4,
                height: "100%",
                paddingBottom: "64px",
              }}
            >
              {children}
            </Box>
          </Layout>
        </TourProvider>
      </AppLocationContext>
    </LocalizationProvider>
  )
}
