import { Grid } from "@mui/material"
import TextField from "@mui/material/TextField"
import { AutocompleteInput, ReferenceInput } from "ra-ui-materialui"
import { useEffect, useState } from "react"
import { required, useDataProvider, useInput, useTranslate } from "react-admin"

export const CountryWithRegionInput = (props) => {
  const dataProvider = useDataProvider()
  const translate = useTranslate()
  const { field } = useInput({ source: `${props.source}.country_id` })
  const [countryId, setCountryId] = useState(null)
  const [region, setRegion] = useState("")

  useEffect(() => {
    async function fetchData() {
      if (
        field.value === null ||
        field.value === undefined ||
        field.value == ""
      ) {
        setRegion("")
      } else {
        const response = await dataProvider.getList("regions", {
          filter: { country_id: field.value },
          pagination: { page: 1, perPage: 2 },
          sort: { field: "id", order: "ASC" },
        })
        if (response.data[0]) {
          setRegion(response.data[0].name.en)
        }
      }
    }

    if (field.value !== countryId) {
      setCountryId(field.value)
      fetchData()
    }
  }, [region, field, countryId, dataProvider])

  return (
    <>
      <Grid item xs={12} md={6}>
        <ReferenceInput
          source={`${props.source}.country_id`}
          reference="countries"
          fullWidth
          sort={{ field: "common_name", order: "ASC" }}
          perPage={300}
        >
          <AutocompleteInput
            label="react.coaches.address.country"
            validate={required()}
            fullWidth
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label={translate("react.coaches.address.region")}
          variant="standard"
          disabled
          fullWidth
          value={region}
        />
      </Grid>
    </>
  )
}
