import { Edit } from "react-admin"

import { AssociateListForm } from "./AssociateListForm"

export const AssociateListEdit = () => {
  return (
    <Edit title="react.admin.associate_lists.edit">
      <AssociateListForm showAssociateList={true} />
    </Edit>
  )
}
