import { Edit } from "ra-ui-materialui"
import { LearningPathForm } from "../../components/forms/LearningPathForm"

export const LearningPathEdit = () => {
  return (
    <Edit>
      <LearningPathForm />
    </Edit>
  )
}
