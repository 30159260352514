import { CloneButton } from "ra-ui-materialui"

export const IconOnlyCloneButton = () => {
  return (
    <CloneButton
      label=""
      sx={{ minWidth: 0, "& .MuiButton-startIcon": { margin: 0 } }}
    />
  )
}
