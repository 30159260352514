import { Create } from "react-admin"
import { PermissionForm } from "./PermissionForm"

export const PermissionCreate = () => {
  return (
    <Create title="Create Permission" redirect="list">
      <PermissionForm />
    </Create>
  )
}
