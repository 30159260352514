import { canAccess } from "@react-admin/ra-rbac"
import {
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  DateField,
  List,
  ReferenceField,
  ReferenceInput,
  ReferenceManyCount,
  useListContext,
  usePermissions,
} from "react-admin"
import { BulkSurveySendReportAction } from "../../components/bulk_actions/BulkSurveySendReportAction"
import { UserField } from "../../components/fields/UserField"

const OrganizationFilter = ({ alwaysOn }) => {
  const { showFilter } = useListContext()

  return (
    <ReferenceInput
      key="organization"
      source="organization_id"
      reference="organizations"
      sort={{ field: "name", order: "ASC" }}
      alwaysOn={alwaysOn}
    >
      <AutocompleteInput sx={{ width: "400px" }} onChange={() => showFilter("program_id", 0) } />
    </ReferenceInput>
  )
}

const ProgramFilter = ({ alwaysOn }) => {
  const { filterValues } = useListContext()
  const organizationId = filterValues["organization_id"]

  if (organizationId) {
    return (
      <ReferenceInput
        key="program"
        source="program_id"
        reference="programs"
        sort={{ field: "name", order: "ASC" }}
        perPage={50}
        filter={{ organization_id: organizationId }}
        alwaysOn={alwaysOn}
      >
        <AutocompleteInput sx={{ width: "400px" }} />
      </ReferenceInput>
    )
  }
}

const filters = [
  <OrganizationFilter key="organization" alwaysOn />,
  <ProgramFilter key="program" alwaysOn />,
  <ReferenceInput
    key="client"
    source="user_id"
    reference="clients"
    sort={{ field: "email", order: "ASC" }}
  >
    <AutocompleteInput sx={{ width: "400px" }} />
  </ReferenceInput>,
  <BooleanInput
    key="without_respondents"
    source="without_respondents"
    alwaysOn
    sx={{
      height: "50px",
      display: "flex",
      justifyContent: "center",
      width: "100%",
    }}
  />,
]

const BulkActions = () => {
  const { isLoading, permissions } = usePermissions()
  if (isLoading) return false
  if (
    canAccess({
      permissions,
      action: "bulk_send_pdf_reports",
      resource: "surveys",
    })
  ) {
    return <BulkSurveySendReportAction />
  }
  return false
}

export const SurveyList = () => (
  <List
    filters={filters}
    perPage={10}
    sort={{ field: "created_at", order: "DESC" }}
  >
    <Datagrid rowClick="toggleSelection" bulkActionButtons={<BulkActions />}>
      <ReferenceField
        label="Client"
        source="user_id"
        reference="clients"
        link="show"
      >
        <UserField />
      </ReferenceField>
      <ReferenceField
        label="Questionnaire"
        source="questionnaire_id"
        reference="questionnaires"
        link="show"
      />
      <ReferenceManyCount
        label="Respondents"
        reference="peers"
        target="survey_id"
        link
      />
      <ReferenceManyCount
        label="Assignments"
        reference="assignments"
        target="survey_id"
        link
      />
      <ReferenceManyCount
        label="Submissions"
        reference="assignment-submissions"
        target="survey_id"
        link
      />
      <DateField label="Created" source="created_at" />
      <DateField label="Updated" source="updated_at" />
    </Datagrid>
  </List>
)
