import { Box } from "@mui/system"
import { TranslatableInputs } from "ra-ui-materialui"
import { EmailTemplateInput } from "./EmailTemplateInput"
import { TextWithEmailVariablesInput } from "./TextWithEmailVariablesInput"

export const EmailTranslatableInput = ({ emailTemplateModelId }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <TranslatableInputs
        locales={["en", "es", "fr", "nl"]}
        defaultLocale="en"
        fullWidth
      >
        <TextWithEmailVariablesInput
          source="subject"
          variant="outlined"
          size="large"
          emailTemplateModelId={emailTemplateModelId}
          fullWidth
        />
        <EmailTemplateInput
          source="body"
          size="large"
          emailTemplateModelId={emailTemplateModelId}
        />
      </TranslatableInputs>
    </Box>
  )
}
