import jsonapiClient from "ra-jsonapi-client-with-delete-many"
import { withLifecycleCallbacks } from "react-admin"
import consumer from "./consumer"
import { getStoredLocale } from "./i18nProvider"

const settings = {
  total: "record_count",
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
    "Accept-Language": getStoredLocale(),
  },
}

const apiUrl =
  document.location.port == "5173"
    ? `http://${document.location.hostname}:3000/api/v1`
    : "/api/v1"

const jsonDataProvider = jsonapiClient(apiUrl, settings)

let subscriptions = []

const unloqDataProvider = {
  // Mapping legacy ra-jsonapi-client methods
  getList: (resource: string, params: object) =>
    jsonDataProvider("GET_LIST", resource, params),
  getOne: (resource: string, params: object) =>
    jsonDataProvider("GET_ONE", resource, params),
  getMany: (resource: string, params: object) =>
    jsonDataProvider("GET_MANY", resource, params),
  getManyReference: (resource: string, params: object) =>
    jsonDataProvider("GET_MANY_REFERENCE", resource, params),
  update: (resource: string, params: object) =>
    jsonDataProvider("UPDATE", resource, params),
  updateMany: (resource: string, params: object) =>
    jsonDataProvider("UPDATE_MANY", resource, params),
  create: (resource: string, params: object) =>
    jsonDataProvider("CREATE", resource, params),
  delete: (resource: string, params: object) =>
    jsonDataProvider("DELETE", resource, params),
  deleteMany: (resource: string, params: object) =>
    jsonDataProvider("DELETE_MANY", resource, params),
  // Pub/Sub for ra-realtime
  subscribe: async (topic, subscriptionCallback) => {
    const hasAlreadySubscribed = !!subscriptions.find(
      (subscription) =>
        subscription.topic === topic &&
        subscription.subscriptionCallback === subscriptionCallback
    )
    if (hasAlreadySubscribed) {
      return Promise.resolve({ data: true })
    }

    subscriptions.push({ topic, subscriptionCallback })

    consumer.subscriptions.create(
      { channel: "ReactAdminChannel", topic: topic },
      {
        received: function (data) {
          subscriptions.forEach((subscription) => {
            if (subscription.topic === topic) {
              subscription.subscriptionCallback(data)
            }
          })
        },
      }
    )
    return Promise.resolve({ data: null })
  },
  unsubscribe: async (topic, subscriptionCallback) => {
    subscriptions = subscriptions.filter(
      (subscription) =>
        subscription.topic !== topic ||
        subscription.subscriptionCallback !== subscriptionCallback
    )
    return Promise.resolve({ data: null })
  },
  publish: (topic, event) => {
    if (!topic) {
      return Promise.reject(new Error("missing topic"))
    }
    if (!event.type) {
      return Promise.reject(new Error("missing event type"))
    }
    subscriptions.map(
      (subscription) =>
        topic === subscription.topic && subscription.subscriptionCallback(event)
    )
    return Promise.resolve({ data: null })
  },
  // UNLOQ's custom methods
  translateMany: (resource: string, params: object) => {
    const request = new Request(`${apiUrl}/deepl/translate_records`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ model: resource, ids: params }),
    })
    return fetch(request, { credentials: "include" })
  },
  surveySendReportMany: (params: object) => {
    const request = new Request(`${apiUrl}/surveys/send_pdf_reports`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ids: params }),
    })
    return fetch(request, { credentials: "include" })
  },
  cloneCv: (userId: string) => {
    const request = new Request(`${apiUrl}/users/${userId}/clone_cv`, {
      method: "POST",
    })
    return fetch(request, { credentials: "include" })
  },
  match: (membershipId: number, coachId: number) => {
    const request = new Request(`${apiUrl}/coaches/${membershipId}/match`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ coach_id: coachId }),
    })
    return fetch(request, { credentials: "include" })
  },
  rematch: (
    clientId: number,
    oldProgramId: number,
    newProgramId: number,
    newCoachId: number
  ) => {
    const request = new Request(`${apiUrl}/clients/${clientId}/rematch`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        old_program_id: oldProgramId,
        new_program_id: newProgramId,
        new_coach_id: newCoachId,
      }),
    })
    return fetch(request, { credentials: "include" })
  },
  deleteCertificateFile: (fileId: number) => {
    const request = new Request(`${apiUrl}/certifications/delete_file`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ file_id: fileId }),
    })
    return fetch(request, { credentials: "include" })
  },
}

const serializeFiles = async (params) => {
  let files = []

  if (params.data.files) {
    const promises = params.data.files.map(function (file) {
      if ("rawFile" in file) {
        return convertFileToBase64(file).then(function (result) {
          return { src: result, title: file.rawFile.name }
        })
      }
    })

    files = await Promise.all(promises)
  }

  return {
    ...params,
    data: {
      ...params.data,
      files: files,
    },
  }
}

export const dataProvider = withLifecycleCallbacks(unloqDataProvider, [
  {
    resource: "profiles",
    beforeUpdate: async (params) => {
      let picture = null
      let profilePhotoPicture = null

      delete params.data.avatar_picture_original

      if (
        params.data.avatar_picture &&
        "rawFile" in params.data.avatar_picture
      ) {
        picture = [
          {
            src: await convertFileToBase64(params.data.avatar_picture),
            title: params.data.avatar_picture.rawFile.name,
          },
        ]
      }

      if (params.data.photo_picture && "rawFile" in params.data.photo_picture) {
        profilePhotoPicture = [
          {
            src: await convertFileToBase64(params.data.photo_picture),
            title: params.data.photo_picture.rawFile.name,
          },
        ]
      }

      return {
        ...params,
        data: {
          ...params.data,
          avatar_picture: picture,
          photo_picture: profilePhotoPicture,
        },
      }
    },
  },
  {
    resource: "change-requests",
    beforeCreate: async (params) => {
      let avatarPicture = null

      if (params.data.avatar && "rawFile" in params.data.avatar) {
        avatarPicture = {
          src: await convertFileToBase64(params.data.avatar),
          title: params.data.avatar.rawFile.name,
        }
      }

      return {
        ...params,
        data: {
          ...params.data,
          avatar: avatarPicture,
        },
      }
    },
  },
  {
    resource: "client-imports",
    beforeCreate: async (params) => {
      console.log(params)
      let excelFile = null

      if (params.data.excel_file?.rawFile instanceof File) {
        excelFile = {
          src: await convertFileToBase64(params.data.excel_file),
          title: params.data.excel_file.rawFile.name,
        }
      }

      return {
        ...params,
        data: {
          ...params.data,
          excel_file: excelFile,
        },
      }
    },
  },
  {
    resource: "certifications",
    beforeUpdate: (params) => {
      return serializeFiles(params)
    },
    beforeCreate: (params) => {
      return serializeFiles(params)
    },
  },
])

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(file.rawFile)
  })
