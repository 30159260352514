import {
  CreateBase,
  EditBase,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
} from "ra-core"
import { Edit, SimpleForm } from "ra-ui-materialui"
import { useParams } from "react-router"
import { CvFields } from "../../components/fields/CvFields"
import { FormToolbar } from "../../components/forms/FormToolbar"

export const CommercialCv = () => {
  const { coachId } = useParams()

  return (
    <Edit
      resource="coaches"
      id={coachId}
      redirect={false}
      mutationMode="optimistic"
      actions={null}
      sx={{ "& .MuiPaper-root": { overflow: "visible" } }}
    >
      <CommercialCvBase />
    </Edit>
  )
}

const CommercialCvBase = () => {
  const record = useRecordContext()
  const { section } = useParams()
  const redirect = useRedirect()
  const notify = useNotify()
  const refresh = useRefresh()

  const onSuccess = () => {
    redirect(false)
    refresh()
    notify(`accounts.edit.flash_success`, { type: "success" })
  }

  if (!record) return null

  if (!record.commercial_curriculum_vitae_id) {
    return (
      <CreateBase
        resource="curriculum-vitaes"
        redirect={false}
        mutationOptions={{ onSuccess }}
      >
        <SimpleForm toolbar={<FormToolbar />}>
          <CvFields
            coachId={record.id}
            record={record}
            section={section}
            cvType="commercial"
          />
        </SimpleForm>
      </CreateBase>
    )
  } else {
    return (
      <EditBase
        resource="curriculum-vitaes"
        id={record.commercial_curriculum_vitae_id}
        redirect={false}
        mutationMode="optimistic"
        mutationOptions={{ onSuccess }}
      >
        <SimpleForm toolbar={<FormToolbar />}>
          <CvFields
            coachId={record.id}
            record={record}
            section={section}
            cvType="commercial"
          />
        </SimpleForm>
      </EditBase>
    )
  }
}

// const CoachEditActions = () => {
//   const [{ running }, { start }] = useTour()
//   const [tourState, setTourState] = useStore("profiles-edit-tour-done", false)
//   const translate = useTranslate()

//   useEffect(() => {
//     if (!tourState && !running) {
//       setTourState(true)
//       start("profiles-edit")
//     }
//   }, [tourState, setTourState, start, running])

//   return (
//     <TopToolbar>
//       <Button onClick={() => start("profiles-edit")} id="help">
//         <>
//           <ContactSupportIcon sx={{ mr: 1 }} />
//           {translate("react.admin.help")}
//         </>
//       </Button>
//     </TopToolbar>
//   )
// }
