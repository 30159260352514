import { Card, CardContent, CardMedia } from "@mui/material"
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import Zoom from "@mui/material/Zoom"
import { styled } from "@mui/material/styles"
import { Box } from "@mui/system"
import { useRecordContext } from "ra-core"
import { ReferenceField } from "ra-ui-materialui"

export const ProgramField = () => {
  const record = useRecordContext()

  if (!record) {
    return null
  }

  return (
    <HtmlTooltip
      TransitionComponent={Zoom}
      title={
        <Card sx={{ display: "flex" }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CardContent sx={{ flex: "1 0 auto" }}>
              <Typography sx={{ fontWeight: "bold", mb: 2 }}>
                {record.name}
              </Typography>
              <Box display="flex" gap={1} sx={{ mb: 2 }}>
                <Box sx={{ fontStyle: "italic" }}>{record.start_date}</Box> -
                <Box sx={{ fontStyle: "italic" }}>{record.end_date}</Box>
              </Box>
              <Box>{record.client_count} clients</Box>
              <Box>{record.coach_count} coaches</Box>
            </CardContent>
            <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }} />
          </Box>
          <Box display="flex" alignItems="center">
            <ReferenceField source="organization_id" reference="organizations">
              <OrganizationLogo />
            </ReferenceField>
          </Box>
        </Card>
      }
    >
      <Typography>{record.name}</Typography>
    </HtmlTooltip>
  )
}

const OrganizationLogo = () => {
  const record = useRecordContext()

  if (!record) {
    return null
  }

  return (
    <CardMedia
      component="img"
      sx={{ width: 120, m: 3 }}
      src={record.logo}
      alt={record.name}
    />
  )
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(13),
    maxWidth: 380,
  },
}))
