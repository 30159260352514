import { ReferenceInput, TextInput } from "ra-ui-materialui"
import { FormDataConsumer, required } from "react-admin"
import { MultiEmailInput } from "../../components/inputs/MultiEmailInput"
import { EmailTemplateModelInput } from "../../components/inputs/EmailTemplateModelInput"
import { EmailTranslatableInput } from "../../components/inputs/EmailTranslatableInput"
import { EmailVariablesPreview } from "../../components/inputs/EmailVariablesPreview"

export const EmailTemplateFormFields = () => {
  return (
    <>
      <TextInput
        source="name"
        variant="outlined"
        size="medium"
        validate={required()}
        fullWidth
      />
      <ReferenceInput
        source="email_template_model_id"
        reference="email-template-models"
        sx={{ width: "100%", pt: 0 }}
      >
        <EmailTemplateModelInput
          label="Receiver type"
          sx={{ width: "100%" }}
          variant="outlined"
          size="medium"
          margin="none"
          validate={required()}
        />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData }) =>
          formData.email_template_model_id && (
            <>
              <EmailVariablesPreview
                emailTemplateModelId={formData.email_template_model_id}
              />
              <MultiEmailInput
                source="cc"
                label="Cc"
                emailTemplateModelId={formData.email_template_model_id}
                sx={{ mb: 2, width: "100%" }}
              />
              <MultiEmailInput
                source="bcc"
                label="Bcc"
                emailTemplateModelId={formData.email_template_model_id}
                sx={{ mb: 2, width: "100%" }}
              />
              <MultiEmailInput
                source="reply_to"
                label="Reply-To"
                emailTemplateModelId={formData.email_template_model_id}
                sx={{ mb: 2, width: "100%" }}
              />
              <EmailTranslatableInput
                emailTemplateModelId={formData.email_template_model_id}
              />
            </>
          )
        }
      </FormDataConsumer>
    </>
  )
}
