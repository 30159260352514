import { Grid, Typography } from "@mui/material"
import { ReferenceManyInput } from "@react-admin/ra-relationships"
import {
  FormDataConsumer,
  required,
  useLocaleState,
  useTranslate,
} from "ra-core"
import {
  AutocompleteArrayInput,
  ReferenceArrayInput,
  SimpleFormIterator,
  TextInput,
} from "ra-ui-materialui"
import { UnloqRemoveButton } from "../../components/buttons/UnloqRemoveButton"
import { ValidateBeforeAddButton } from "../../components/buttons/ValidateBeforeAddButton"
import { EndDateInput } from "../../components/fields/EndDateInput"
import { StartDateInput } from "../../components/fields/StartDateInput"
import { IRobinChatBubble } from "../../components/forms/IRobinChatBubble"
import { Box } from "@mui/system"

export const WorkExperienceFields = () => {
  const translate = useTranslate()

  return (
    <Box mt={1} width="100%">
      <IRobinChatBubble>
        <Typography variant="h6" mb={1} fontWeight="bold">
          {translate("react.admin.work_experience")}
        </Typography>

        <Typography mb={1}>
          {translate("react.coaches.cv.intro.work_experience")}
        </Typography>
      </IRobinChatBubble>

      <ReferenceManyInput
        label={false}
        reference="work-experiences"
        target="curriculum_vitae_id"
        sort={{ field: "start_date", order: "ASC" }}
        defaultValue={[
          {
            "@@ra-many/curriculum-vitaes/work-experiences/curriculum_vitae_id.0.work-experiences.0.title":
              "",
          },
        ]}
        sx={{ marginTop: "2rem" }}
      >
        <SimpleFormIterator
          inline
          fullWidth
          disableClear
          disableReordering
          sx={{ ["& .RaSimpleFormIterator-line"]: { pb: 3, mb: 3 } }}
          addButton={
            <ValidateBeforeAddButton
              label="react.coaches.cv.fields.work_experience.add"
              sx={{ color: "#000" }}
            />
          }
          removeButton={
            <UnloqRemoveButton referenceName="react.admin.work_experience" />
          }
        >
          <WorkExperienceGrid />
        </SimpleFormIterator>
      </ReferenceManyInput>
    </Box>
  )
}

const WorkExperienceGrid = (props) => {
  const [locale] = useLocaleState()

  return (
    <Grid
      container
      spacing={2}
      sx={{
        borderColor: "#f3f4f6",
        borderRadius: "8px",
      }}
    >
      <Grid item xs={12} md={6}>
        <TextInput
          source={`${props.source}.title`}
          label="react.coaches.cv.fields.work_experience.role"
          validate={required()}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          source={`${props.source}.company_name`}
          label="react.coaches.cv.fields.work_experience.company_name"
          validate={required()}
          fullWidth
        />
      </Grid>

      <FormDataConsumer>
        {({ getSource }) => {
          return (
            <>
              <Grid item xs={12} md={6}>
                <StartDateInput
                  source={`${props.source}.start_date`}
                  label="react.coaches.cv.fields.work_experience.start_date"
                  slotProps={{
                    field: {
                      id: "start_date",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <EndDateInput
                  source={`${props.source}.end_date`}
                  startSource={getSource(`${props.source}.start_date`)}
                  label="react.coaches.cv.fields.work_experience.end_date"
                  slotProps={{
                    field: {
                      id: "end_date",
                    },
                  }}
                />
              </Grid>
            </>
          )
        }}
      </FormDataConsumer>

      <Grid item xs={12} md={6}>
        <TextInput
          source={`${props.source}.description`}
          multiline
          label="react.coaches.cv.fields.work_experience.description"
          validate={required()}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ReferenceArrayInput
          source={`${props.source}.branch_experience_ids`}
          reference="branch-experiences"
          sort={{ field: `name.${locale}`, order: "ASC" }}
          perPage={300}
          perPageChoices={300}
        >
          <AutocompleteArrayInput helperText="react.coaches.multiple_answers_possible" />
        </ReferenceArrayInput>
      </Grid>
    </Grid>
  )
}
