import { DateInput } from "@react-admin/ra-form-layout"
import { required } from "ra-core"

export const StartDateInput = ({
  source,
  label = null,
  sx = {},
  helperText = "",
  slotProps = null,
}) => {
  if (label === null) label = "react.admin.start_date"

  const today = new Date()

  const validateStartDateNotInFuture = (value) => {
    if (!value) return "react.admin.validation.start_date_required"

    if (value > today) {
      return "react.admin.validation.start_date_invalid"
    }

    return undefined
  }

  const validateStartDate = [required(), validateStartDateNotInFuture]

  return (
    <DateInput
      desktopModeMediaQuery="@media(min-width: 90px)"
      source={source}
      label={label}
      helperText={helperText}
      validate={validateStartDate}
      fullWidth
      disableFuture
      sx={sx}
      slotProps={{
        actionBar: {
          actions: ["today", "clear"],
        },
        field: {
          clearable: true,
        },
        ...slotProps,
      }}
    />
  )
}
