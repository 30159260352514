import { Typography } from "@mui/material"
import { WithRecord } from "ra-core"
import { ReferenceField } from "ra-ui-materialui"

export const ResidenceCountryField = ({ filters }) => {
  return (
    <ReferenceField
      source="residence_country_id"
      reference="countries"
      link={false}
    >
      <WithRecord
        render={(country) => (
          <>
            <Typography
              sx={{
                textAlign: "center",
                backgroundColor:
                  filters.filterValues.residence_country_id?.includes(
                    country.id
                  )
                    ? "yellow"
                    : null,
              }}
            >
              {country.common_name}
            </Typography>
          </>
        )}
      />
    </ReferenceField>
  )
}
