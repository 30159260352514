import { Email } from "@mui/icons-material"
import { useState } from "react"
import { Button, Confirm, useListContext, useNotify } from "react-admin"

// TODO: rename this component as BulkSend is too generic
export const BulkSendAction = ({ btnName, test = true }) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)

  const handleConfirm = () => {
    sendEmail()
    setOpen(false)
  }

  const { selectedIds } = useListContext()
  const notify = useNotify()

  const dataProvider = {
    submit: async (selectedIds) => {
      await fetch(`/api/v1/emails/submit`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ids: selectedIds, test: test }),
      })
        .then(() => {
          notify(`Emails sent`, { type: "success" })
        })
        .catch(function (error) {
          notify(error, { type: "error" })
        })
    },
  }

  const sendEmail = () => {
    dataProvider.submit(selectedIds)
  }

  const action = test ? handleConfirm : handleClick

  return (
    <>
      <Button label={btnName} onClick={action}>
        <Email />
      </Button>
      <Confirm
        isOpen={open}
        title={`Send e-mails`}
        content="Are you sure to send an e-mail to all of these users?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  )
}
