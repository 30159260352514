import { Edit, SimpleForm, TranslatableInputs } from "react-admin"
import { DeeplTranslatableTextInput } from "../../components/inputs/DeeplTranslatableTextInput"

export const ProfileTraitEdit = () => (
  <Edit>
    <SimpleForm>
      <TranslatableInputs
        locales={["en", "es", "fr", "nl"]}
        defaultLocale="en"
        fullWidth
      >
        <DeeplTranslatableTextInput
          sourceField="name"
          source="name"
          sx={{ marginTop: "2rem" }}
        />
      </TranslatableInputs>
    </SimpleForm>
  </Edit>
)
