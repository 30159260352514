import { Edit, SimpleForm } from "react-admin"
import { LearningStepFormFields } from "./LearningStepFormFields"

export const LearningStepEdit = () => (
  <Edit redirect="list">
    <SimpleForm>
      <LearningStepFormFields />
    </SimpleForm>
  </Edit>
)
