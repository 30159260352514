/* eslint-disable react/prop-types */
import { Chip } from "@mui/material"
import {
  Datagrid,
  DeleteButton,
  EditButton,
  FunctionField,
  List,
  ReferenceField,
  TextField,
} from "react-admin"

import { EmailSubjectField } from "../../components/fields/EmailSubjectField"
import { EmailBodyField } from "../../components/fields/EmailBodyField"

export const EmailTemplateList = () => (
  <List sort={{ field: "created_at", order: "DESC" }}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" />
      <ReferenceField
        label="Model"
        source="email_template_model_id"
        reference="email-template-models"
        link={false}
      />
      <FunctionField
        label="CC"
        source="cc"
        render={(record) =>
          record.cc.map((item) => (
            <Chip key={item} label={item} sx={{ marginBottom: "8px" }} />
          ))
        }
      />
      <FunctionField
        label="BCC"
        source="bcc"
        render={(record) =>
          record.bcc.map((item) => (
            <Chip key={item} label={item} sx={{ marginBottom: "8px" }} />
          ))
        }
      />
      <FunctionField
        label="Reply-to"
        source="reply_to"
        render={(record) =>
          record.reply_to.map((item) => (
            <Chip key={item} label={item} sx={{ marginBottom: "8px" }} />
          ))
        }
      />
      <EmailSubjectField source="subject.en" label="Subject" />
      <EmailBodyField source="body.en" label="Body" />
      <EditButton />
      <DeleteButton mutationMode="pessimistic" />
    </Datagrid>
  </List>
)
