import { GetListParams, useInput, useTranslate } from "ra-core"
import { useQuery } from "react-query"
import { Alert, Grid, Typography } from "@mui/material"
import { TextInput } from "ra-ui-materialui"
import { Box } from "@mui/system"
import { Button, RecordContextProvider } from "react-admin"
import { useEffect, useState } from "react"

import { dataProvider } from "../../dataProvider"
import HourglassBottom from "@mui/icons-material/HourglassBottom"

export const ProfileBioChangeRequest = ({ curriculumVitae }) => {
  const translate = useTranslate()

  const { field } = useInput({ source: "new_value" })
  const [statusText, setStatusText] = useState<string>("")
  const [errorText, setErrorText] = useState<string | null>(null)
  const [count, setCount] = useState(field.value.length)
  const [isEditing, setIsEditing] = useState(false)

  const validateEnglishApproach = (value) => {
    if (value === undefined || value == 0) {
      return translate("accounts.curriculum_vitae.approach_required")
    }

    if (value.length < 400) {
      return translate("accounts.curriculum_vitae.approach_too_short")
    }

    if (value.length > 800) {
      return translate("accounts.curriculum_vitae.approach_too_long")
    }

    return undefined
  }
  const handleBioSave = async () => {
    const error = validateEnglishApproach(field.value)

    if (error) {
      setErrorText(error)
    } else {
      setErrorText(null)

      await dataProvider
        .create("change-requests", {
          data: {
            changeable_id: curriculumVitae.id,
            changeable_type: "CurriculumVitae",
            new_value: field.value,
            field_changed: "approach_en",
            field_type: "translated",
          },
        })
        .then(function (response) {
          setStatusText(translate("react.coaches.bio.updated"))
          return response
        })
    }
  }

  const readOnlyElement = (
    <>
      <TextInput
        readOnly
        source="approach.en"
        label="react.coaches.cv.fields.approach_current"
        fullWidth
        multiline
        sx={{
          marginTop: "2rem",
          "& .MuiFilledInput-root.Mui-disabled": {
            backgroundColor: "white",
          },
        }}
      />
      {!isEditing && (
        <Button label={translate("react.admin.edit")} onClick={() => setIsEditing(!isEditing)} />
      )}
    </>
  )

  const inputElement = (
    <>
      <TextInput
        source="new_value"
        label="react.coaches.cv.fields.approach"
        helperText="react.coaches.cv.fields.approach_helper_text"
        fullWidth
        rows={15}
        multiline
        onChange={(event) => setCount(event.target.value.length)}
        sx={{ marginTop: "2rem" }}
        error={true}
      />
      {errorText && (
        <Grid item>
          <Typography
            variant="subtitle1"
            sx={{ marginRight: "1rem", color: "red" }}
          >
            {errorText}
          </Typography>
        </Grid>
      )}
      <Box
        component="span"
        sx={{
          width: "100%",
          position: "relative",
          marginTop: "-50px",
          height: "50px",
          paddingRight: "0.5rem",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <span>{`${count} / ${800}`}</span>
      </Box>
      {statusText && !errorText && (
        <Grid item sx={{ marginBottom: "1rem" }}>
          <Alert icon={<HourglassBottom fontSize="inherit" />} severity="warning">
            {statusText}
          </Alert>
        </Grid>
      )}
      <Button onClick={handleBioSave} label="react.admin.save" />
    </>
  )

  const queryParams = {
    pagination: { page: 1, perPage: 1 },
    sort: { field: "created_at", order: "DESC" },
    filter: {
      changeable_id: curriculumVitae.id,
      changeable_type: "CurriculumVitae",
      field_changed: "approach_en",
    },
  }

  const { data, isLoading, error } = useQuery(
    ["change-requests", "getList", queryParams],
    () => dataProvider.getList("change-requests", queryParams as GetListParams),
  )

  useEffect(() => {
    if (!isLoading) {
      if (data && data.data && data.data.length > 0 && data.data[0].status) {
        if (data.data[0].status == "pending") {
          setStatusText(translate("react.coaches.bio.not_approved_yet"))
        } else if (data.data[0].status == "rejected") {
          setStatusText(translate("react.coaches.bio.rejected"))
        } else if (data.data[0].status == "approved") {
          setStatusText(translate("react.coaches.bio.approved"))
        }
      }
    }
  }, [data, isLoading, translate, field.value])

  if (isLoading) return <Box>Loading!</Box>
  if (error) return <Box>Error!</Box>

  if (!data || !data.data || !data.data[0]) {
    return (
      <>
        {curriculumVitae.approach["en"] && readOnlyElement}
        {(isEditing || !curriculumVitae.approach["en"]) && inputElement}
      </>
    )
  } else {
    return (
      <>
        {curriculumVitae.approach["en"] && readOnlyElement}
        <RecordContextProvider key={data.data[0].id} value={data.data[0]}>
          {inputElement}
        </RecordContextProvider>
      </>
    )
  }
}
