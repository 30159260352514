import { Box } from "@mui/system"
import { WithRecord } from "ra-core"
import { ReferenceField } from "ra-ui-materialui"
import { HighlightedField } from "./HighlightedField"

export const JobTitleField = ({ filters }) => {
  const query = filters.filterValues.job_title?.toLowerCase()

  return (
    <Box display="flex" justifyContent="center">
      <ReferenceField
        source="curriculum_vitae_id"
        reference="curriculum-vitaes"
        link={false}
      >
        <WithRecord
          render={(curriculum_vitae) => (
            <HighlightedField
              query={query}
              textToHighlight={curriculum_vitae.job_title.en}
            />
          )}
        />
      </ReferenceField>
    </Box>
  )
}
