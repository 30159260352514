import {
  LinearProgress,
  TextField,
  useListContext,
  useRecordContext,
} from "react-admin"

export const TranslatableTextField = ({ locale, isTranslating, ...rest }) => {
  const record = useRecordContext()
  const { selectedIds } = useListContext()
  const isSelected = selectedIds.includes(record.id)
  if (isSelected && isTranslating && locale != "en") {
    return <LinearProgress />
  }
  return <TextField {...rest} />
}
