import { Link } from "@mui/material"
import {
  AutocompleteInput,
  BooleanInput,
  Button,
  Datagrid,
  DateField,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  TextField,
  useLocaleState,
  useRecordContext,
  WrapperField,
} from "react-admin"

import VisibilityIcon from "@mui/icons-material/Visibility"

const filters = [
  <SearchInput key="search" source="name" alwaysOn />,
  <BooleanInput
    key="active"
    source="active"
    label="Hide inactive"
    alwaysOn
    sx={{
      height: "50px",
      display: "flex",
      justifyContent: "center",
      width: "100%",
    }}
  />,
  <ReferenceInput
    key="organization"
    source="organization_id"
    reference="organizations"
  >
    <AutocompleteInput sx={{ width: "100%" }} />
  </ReferenceInput>,
]

export const LearningPathReportingList = () => (
  <List
    resource="programs"
    filters={filters}
    perPage={25}
    sort={{ field: "name", order: "ASC" }}
    title="Reporting"
  >
    <Datagrid rowClick={false}>
      <TextField source="name" />
      <TextField source="client_count" label="Clients" sortable={false} />
      <DateField source="start_date" label="Start" />
      <DateField source="end_date" label="End" />
      <ReferenceField
        label="Organization"
        source="organization_id"
        reference="organizations"
        link="show"
      />
      <ReferenceField
        label="Questionnaire"
        source="questionnaire_id"
        reference="questionnaires"
        link="show"
      />
      <ReferenceField
        label="Learning path"
        source="learning_path_id"
        reference="learning-paths"
        link="show"
      />
      <WrapperField textAlign="center">
        <ReportButton />
        <DownloadButton />
      </WrapperField>
    </Datagrid>
  </List>
)

const ReportButton = () => {
  const record = useRecordContext()
  const [locale] = useLocaleState()

  return (
    <Link
      href={`/${locale}/admin/organizations/${record.organization_hashid}/programs/${record.hashid}/learning_path`}
      target="_blank"
    >
      <Button label="Report" startIcon={<VisibilityIcon />} />
    </Link>
  )
}

const DownloadButton = () => {
  const record = useRecordContext()
  const [locale] = useLocaleState()

  return (
    <Link
      href={`/${locale}/admin/organizations/${record.organization_hashid}/programs/${record.hashid}/learning_path.xlsx`}
      target="_blank"
    >
      <Button label="Download" startIcon={<VisibilityIcon />} />
    </Link>
  )
}
