import { Box } from "@mui/system"
import { useDataProvider, useTranslate } from "ra-core"
import { FileInput } from "ra-ui-materialui"
import { FilePreviewField } from "../../components/fields/FilePreviewField"

export const CertificationFileUploadField = ({ source }) => {
  const translate = useTranslate()
  const dataProvider = useDataProvider()

  return (
    <>
      <FileInput
        source={`${source}.files`}
        multiple
        label={false}
        fullWidth
        helperText="react.coaches.cv.fields.certification_dropzone_helpertext"
        validateFileRemoval={(file) => {
          return dataProvider.deleteCertificateFile(file.id)
        }}
        sx={{
          "& .previews": {
            marginTop: "1rem",
          },
          "& .RaFileInput-removeButton": {
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "start",
            alignItems: "center",
            padding: "1rem",
            border: "2px solid rgb(238, 238, 238)",
            marginBottom: "0.5rem",
            "& .RaFileInputPreview-removeButton": {
              width: "2em",
              height: "2em",
              display: "none",
            },
            ":hover": {
              backgroundColor: "#ccc",
              "& .RaFileInputPreview-removeButton": {
                display: "block",
              },
            },
          },
        }}
        accept="application/pdf,image/png,image/jpg,image/jpeg"
        placeholder={
          <Box
            sx={{
              fontSize: "1rem",
              color: "grey",
              fontWeight: "400",
            }}
          >
            {translate("react.coaches.cv.fields.certification_dropzone")}
          </Box>
        }
      >
        <FilePreviewField />
      </FileInput>
    </>
  )
}
