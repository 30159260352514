import { Grid, Typography } from "@mui/material"
import { ReferenceManyInput } from "@react-admin/ra-relationships"
import { required, useTranslate } from "ra-core"
import { SimpleFormIterator, TextInput } from "ra-ui-materialui"
import { SelectInput } from "react-admin"
import { useFormContext } from "react-hook-form"
import { UnloqRemoveButton } from "../../components/buttons/UnloqRemoveButton"
import { ValidateBeforeAddButton } from "../../components/buttons/ValidateBeforeAddButton"
import { YearInput } from "../../components/inputs/YearInput"
import { IRobinChatBubble } from "../../components/forms/IRobinChatBubble"
import { Box } from "@mui/system"

export const PublicationFields = () => {
  const translate = useTranslate()

  return (
    <Box mt={1} width="100%">
      <IRobinChatBubble>
        <Typography variant="h6" mb={1} fontWeight="bold">
          {translate("react.coaches.cv.publications")}
        </Typography>

        <Typography mb={1}>
          {translate("react.coaches.cv.intro.publications")}
        </Typography>
      </IRobinChatBubble>

      <ReferenceManyInput
        label={false}
        reference="publications"
        target="curriculum_vitae_id"
        sx={{ width: "100%", marginTop: "2rem" }}
        defaultValue={[
          {
            "@@ra-many/curriculum-vitaes/publications/curriculum_vitae_id.0.publications.0.publication_type":
              "",
          },
        ]}
      >
        <SimpleFormIterator
          inline
          fullWidth
          disableClear
          sx={{ ["& .RaSimpleFormIterator-line"]: { pb: 2, mb: 2 } }}
          addButton={
            <ValidateBeforeAddButton label="react.coaches.cv.fields.publication.add" />
          }
          removeButton={
            <UnloqRemoveButton referenceName="react.admin.publication" />
          }
        >
          <PublicationGrid />
        </SimpleFormIterator>
      </ReferenceManyInput>
    </Box>
  )
}

const PublicationGrid = (props) => {
  const { watch } = useFormContext()
  const publicationType = watch(`${props.source}.publication_type`)
  const authors = watch(`${props.source}.author`)
  const title = watch(`${props.source}.title`)
  const year = watch(`${props.source}.year`)
  const publisher = watch(`${props.source}.publisher`)
  const publisher_city = watch(`${props.source}.publisher_city`)
  const url = watch(`${props.source}.url`)
  const journal_title = watch(`${props.source}.journal_title`)
  const volume_number = watch(`${props.source}.volume_number`)
  const page_range = watch(`${props.source}.page_range`)
  const medium = watch(`${props.source}.medium`)

  const isFormEmpty = () => {
    const fields = [
      authors,
      title,
      year,
      publisher,
      publisher_city,
      url,
      journal_title,
      volume_number,
      page_range,
      medium,
    ]
    return !fields.some((field) => field && field !== "")
  }

  return (
    <Grid container columnSpacing={2} sx={{ width: "100%" }}>
      <Grid item xs={12}>
        <SelectInput
          label="react.coaches.cv.fields.publication_type"
          emptyText="react.coaches.cv.fields.publication.choose"
          source={`${props.source}.publication_type`}
          choices={[
            {
              id: "article",
              name: "react.coaches.cv.fields.publication.article",
            },
            { id: "book", name: "react.coaches.cv.fields.publication.book" },
            {
              id: "various",
              name: "react.coaches.cv.fields.publication.various",
            },
          ]}
          validate={required()}
          helperText="react.coaches.cv.examples.publications.type"
          fullWidth
        />
      </Grid>

      {publicationType != null && publicationType === "article" && (
        <>
          <Grid item xs={12} md={6}>
            <TextInput
              source={`${props.source}.author`}
              label="react.coaches.cv.fields.publication.authors"
              helperText="react.coaches.cv.examples.publication.authors"
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <YearInput
              source={`${props.source}.year`}
              label="react.coaches.cv.fields.publication.year"
              helperText="react.coaches.cv.examples.publication.year"
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              source={`${props.source}.title`}
              label="react.coaches.cv.fields.publication.title_article"
              helperText="react.coaches.cv.examples.publication.title"
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              source={`${props.source}.journal_title`}
              label="react.coaches.cv.fields.publication.journal_title"
              helperText="react.coaches.cv.examples.publication.journal_title"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              source={`${props.source}.volume_number`}
              label="react.coaches.cv.fields.publication.volume_number"
              helperText="react.coaches.cv.examples.publication.volume_number"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              source={`${props.source}.page_range`}
              label="react.coaches.cv.fields.publication.page_range"
              helperText="react.coaches.cv.examples.publication.page_range"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              source={`${props.source}.publisher`}
              label="react.coaches.cv.fields.publication.publisher"
              helperText="react.coaches.cv.examples.publication.publisher"
              fullWidth
            />
          </Grid>

          {!isFormEmpty() && (
            <LegendFieldset>
              <Typography>
                {authors != null && authors.length > 0 && <>{authors}&nbsp;</>}

                {year != null && <>({year}).&nbsp;</>}

                {title != null && title.length > 0 && (
                  <>
                    <i>{title}</i>;.&nbsp;
                  </>
                )}

                {journal_title != null && journal_title.length > 0 && (
                  <>{journal_title},&nbsp;</>
                )}

                {volume_number != null && volume_number.length > 0 && (
                  <>{volume_number},&nbsp;</>
                )}

                {page_range != null && page_range.length > 0 && (
                  <>{page_range}.&nbsp;</>
                )}

                {publisher != null && publisher.length > 0 && (
                  <>{publisher}.&nbsp;</>
                )}
              </Typography>
            </LegendFieldset>
          )}
        </>
      )}

      {publicationType != null && publicationType === "book" && (
        <>
          <Grid item xs={12} md={6}>
            <TextInput
              source={`${props.source}.author`}
              label="react.coaches.cv.fields.publication.authors"
              helperText="react.coaches.cv.examples.publication.authors"
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <YearInput
              source={`${props.source}.year`}
              label="react.coaches.cv.fields.publication.year"
              helperText="react.coaches.cv.examples.publication.year"
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              source={`${props.source}.title`}
              label="react.coaches.cv.fields.publication.title_book"
              helperText="react.coaches.cv.examples.publication.title"
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              source={`${props.source}.publisher`}
              label="react.coaches.cv.fields.publication.publisher"
              helperText="react.coaches.cv.examples.publication.publisher"
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              source={`${props.source}.publisher_city`}
              label="react.coaches.cv.fields.publication.publisher_city"
              helperText="react.coaches.cv.examples.publication.publisher_city"
              validate={required()}
              fullWidth
            />
          </Grid>

          {!isFormEmpty() && (
            <LegendFieldset>
              <Typography>
                {authors != null && authors.length > 0 && <>{authors}&nbsp;</>}

                {year != null && <>({year}).&nbsp;</>}

                {title != null && title.length > 0 && (
                  <>
                    <i>{title}</i>.&nbsp;
                  </>
                )}

                {publisher != null && publisher.length > 0 && <>{publisher}</>}

                {publisher_city != null && publisher_city.length > 0 && (
                  <>,&nbsp;{publisher_city}.&nbsp;</>
                )}
              </Typography>
            </LegendFieldset>
          )}
        </>
      )}

      {publicationType != null && publicationType === "various" && (
        <>
          <Grid item xs={12} md={6}>
            <TextInput
              source={`${props.source}.author`}
              label="react.coaches.cv.fields.publication.authors"
              helperText="react.coaches.cv.examples.publication.authors"
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <YearInput
              source={`${props.source}.year`}
              label="react.coaches.cv.fields.publication.year"
              helperText="react.coaches.cv.examples.publication.year"
              validate={required()}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              source={`${props.source}.title`}
              label="react.coaches.cv.fields.publication.title"
              helperText="react.coaches.cv.examples.publication.various_title"
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              source={`${props.source}.medium`}
              label="react.coaches.cv.fields.publication.medium"
              helperText="react.coaches.cv.examples.publication.medium"
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              source={`${props.source}.publisher`}
              label="react.coaches.cv.fields.publication.publisher"
              helperText="react.coaches.cv.examples.publication.publisher"
              validate={required()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              source={`${props.source}.url`}
              label="react.coaches.cv.fields.publication.url"
              helperText="react.coaches.cv.examples.publication.url"
              fullWidth
            />
          </Grid>

          {!isFormEmpty() && (
            <LegendFieldset>
              <Typography>
                {authors != null && authors.length > 0 && <>{authors}&nbsp;</>}

                {year != null && <>({year}).&nbsp;</>}

                {title != null && title.length > 0 && (
                  <>
                    <i>{title}</i>;.&nbsp;
                  </>
                )}

                {medium != null && medium.length > 0 && <>{medium}.&nbsp;</>}

                {publisher != null && publisher.length > 0 && (
                  <>{publisher}.&nbsp;</>
                )}

                {url != null && url.length > 0 && <>{url}&nbsp;</>}
              </Typography>
            </LegendFieldset>
          )}
        </>
      )}
    </Grid>
  )
}

const LegendFieldset = ({ children }) => {
  const translate = useTranslate()

  return (
    <fieldset className="border border-solid border-gray-300 p-2 mx-auto my-1">
      <legend className="px-1 font-bold">
        {translate("react.admin.preview")}
      </legend>
      <Typography>{children}</Typography>
    </fieldset>
  )
}
