/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AutocompleteArrayInput,
  useDataProvider,
  useRecordContext,
} from "react-admin"

import * as EmailValidator from "email-validator"

import {
  Box,
  Chip,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material"
import { useEffect, useState } from "react"

const SpinnerAdornment = () => <CircularProgress size={20} />

function uniqBy(a, key) {
  const seen = new Set()
  return a.filter((item) => {
    const k = key(item)
    return seen.has(k) ? false : seen.add(k)
  })
}

const OptionRenderer = () => {
  const record = useRecordContext()
  const color = record.name.startsWith("@") ? "primary.main" : "primary.default"
  return <Box sx={{ color: color }}>{record.name}</Box>
}
const optionText = <OptionRenderer />
const inputText = (choice) => choice.name

const matchSuggestion = (filter, choice) => {
  if (filter && filter.startsWith("@")) {
    return (
      choice.name.startsWith("@") &&
      choice.name.toLowerCase().includes(filter.toLowerCase())
    )
  } else {
    return choice.id.toLowerCase().includes(filter.toLowerCase())
  }
}

export const MultiEmailInput = ({
  emailTemplateModelId,
  source,
  label,
  ...rest
}) => {
  const dataProvider = useDataProvider()
  const recordContext = useRecordContext()
  const [emails, setEmails] = useState([])
  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    async function fetchData() {
      // Get users
      const usersResponse = await dataProvider.getList("users", {
        filter: { q: searchText },
        pagination: { page: 1, perPage: 10 },
        sort: { field: "email", order: "ASC" },
      })
      // Get email variables
      const emailVariablesResponse = await dataProvider.getOne(
        "email-template-models",
        { id: emailTemplateModelId },
      )

      // User choices
      const userChoices = usersResponse.data.map((attributes) => {
        return {
          id: attributes.email,
          name: `${attributes.name} <${attributes.email}>`,
        }
      })

      // Email variable choices
      if (emailVariablesResponse.data.email_variables) {
        const emailVariables =
          emailVariablesResponse.data.email_variables.filter(
            (emailVariable) => emailVariable.type === "email",
          )
        const emailVariableChoices = emailVariables.map((emailVariable) => {
          return {
            id: `@${emailVariable.id}`,
            name: `@${emailVariable.id}`,
          }
        })

        // Current record choices
        const currentRecordChoices =
          recordContext && recordContext[source]
            ? recordContext[source].map((value) => ({ id: value, name: value }))
            : []

        // Combine choices
        const combinedChoices = uniqBy(
          emailVariableChoices
            .concat(emails)
            .concat(userChoices)
            .concat(currentRecordChoices),
          (choice) => choice.id,
        )

        if (JSON.stringify(combinedChoices) !== JSON.stringify(emails)) {
          setEmails(combinedChoices)
        }
      }
    }

    fetchData()
  }, [
    dataProvider,
    emailTemplateModelId,
    recordContext,
    searchText,
    emails,
    source,
  ])

  return (
    <MultiEmailInputEditor
      source={source}
      label={label}
      emails={emails}
      setEmails={setEmails}
      searchText={searchText}
      setSearchText={setSearchText}
      renderInput={(params) => {
        if (emails.length > 0) {
          return (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              placeholder="Enter an email address, type @ for a list of variables"
            />
          )
        } else {
          return (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              placeholder="Loading..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SpinnerAdornment />
                  </InputAdornment>
                ),
              }}
            />
          )
        }
      }}
      {...rest}
    />
  )
}

const MultiEmailInputEditor = ({
  emails,
  setEmails,
  searchText,
  setSearchText,
  ...props
}) => {
  return (
    <AutocompleteArrayInput
      {...props}
      choices={emails}
      translateChoice={false}
      onCreate={(value) => {
        const newChoice = { id: value, name: value }
        const newChoices = emails
        newChoices.push(newChoice)
        setEmails(newChoices)
        return newChoice
      }}
      setFilter={(value) => {
        setSearchText(value)
      }}
      shouldRenderSuggestions={(val) => {
        return val.trim().length > 2 || (val.trim().length > 0 && val[0] == "@")
      }}
      optionText={optionText}
      inputText={inputText}
      matchSuggestion={matchSuggestion}
      renderTags={(value: readonly any[], getTagProps) =>
        value.map((option: any, index: number) => {
          const validationColor = EmailValidator.validate(option.id)
            ? "default"
            : "error"
          return (
            <Chip
              key={index}
              color={option.name.startsWith("@") ? "primary" : validationColor}
              label={option.name}
              sx={{ p: 0 }}
              {...getTagProps({ index })}
            />
          )
        })
      }
      noOptionsText="Add email address"
      createItemLabel={`Add ${searchText}`}
    />
  )
}
