import { useResetStore } from "react-admin"
import { ErrorBoundary } from "react-error-boundary"

function Fallback({ error, resetErrorBoundary }) {
  // TODO: send error to Sentry
  console.log(error)

  return (
    <div
      style={{
        backgroundColor: "#fff",
        fontFamily: "arial, Helvetica Neue, sans-serif",
      }}
    >
      <div
        style={{
          textAlign: "center",
          marginBottom: "2rem",
          paddingTop: "4rem",
        }}
      >
        <span style={{ display: "block", fontSize: "2rem", color: "#333333" }}>
          UNLOQ
        </span>
        <div
          style={{
            marginTop: "-5px",
            marginRight: "-40px",
            fontSize: "1.2rem",
            color: "#37e178",
          }}
        >
          Campus
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <div
          style={{
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "#dae1e7",
            borderRadius: ".5rem",
            margin: "0 auto",
            boxShadow:
              "0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08)",
            textAlign: "center",
            padding: "3em 6em",
          }}
        >
          <div style={{ marginBottom: "3em", fontWeight: "bold" }}>
            500. That’s an error.
          </div>
          <div style={{ marginBottom: "3em" }}>
            We&apos;re sorry, but something went wrong.
          </div>
          <a
            onClick={resetErrorBoundary}
            style={{
              border: "2px solid #37e178",
              whiteSpace: "nowrap",
              textAlign: "center",
              fontSize: "15px",
              borderRadius: ".5rem",
              textDecoration: "none",
              cursor: "pointer",
              display: "inline-block",
              backgroundColor: "#37e178",
              padding: "0.5rem 1rem",
              transition: "all .1s ease-in-out",
              color: "#000",
            }}
          >
            Try again
          </a>
        </div>
      </div>
    </div>
  )
}

export const UnloqErrorBoundary = (props) => {
  const reset = useResetStore()

  return (
    <ErrorBoundary
      FallbackComponent={Fallback}
      onReset={(details) => {
        console.warn(details)
        localStorage.clear()
        reset()
        document.location.href = "/"
      }}
    >
      {props.children}
    </ErrorBoundary>
  )
}
