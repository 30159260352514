{
  "en": {
    "account_only": "Account only",
    "accounts": {
      "app_verify_label": "Enter the six-digit code from the",
      "curriculum_vitae": {
        "approach_required": "Approach are required for the English language",
        "approach_too_long": "Your bio is too long, maximum is 800 characters",
        "approach_too_short": "Your bio is too short, minimum is 400 characters",
        "copy_from_default_success": "Default CV has successfully been copied into commercial CV",
        "job_title_and_approach_required": "Functietitel en Aanpak zijn vereist voor de Engelse taal",
        "job_title_required": "Please provide your current job title"
      },
      "disable": "Disable",
      "disable_title": "Below you can disable this 2-factor authentication method or switch to the SMS method.",
      "edit": {
        "flash_success": "Your profile was updated successfully",
        "language_label": "This account's default language for e-mails, and preferred language for site presentation:"
      },
      "edit_2fa": {
        "authenticator_title": "Scan this QR code with your authenticator app:",
        "choose_below": "Choose below which method you want to use. You can always switch methods later on.",
        "description": "There are two ways to use 2 Factor Authentication:",
        "no_app_description": "Google Authenticator is a free 2FA app. Download it on your mobile device by searching for 'Google Authenticator' on the Play Store or App Store.",
        "no_app_title": "Don't have the app?",
        "option1": "via an authentication app",
        "option2": "by text message",
        "phone_number_label": "Enter phone number:",
        "sms_description": "With this 2 Factor Authentication (2FA) method you will automatically receive a new SMS code to your mobile phone each time you log in. Enter your mobile number below to activate this method (note: add '+' followed by your country code, and take off the first 0 of your phone number).",
        "sms_explanation": "Click the button below to receive a SMS code on your mobile phone in order to disable 2FA.",
        "sms_flash_error": "Phone number is invalid or code could not be sent.",
        "sms_flash_success": "Your token has been sent to your phone number.",
        "sms_send_btn": "Check phone number",
        "sms_verify_btn": "Verify code",
        "sms_warning": "<b>Important:</b> depending on which country you are receiving messages, local regulations may restrict the sending of Campus SMS messages to prevent spam. Therefore, we recommend enabling 2FA using Google Authenticator instead.",
        "title": "2-Factor Authentication adds extra security to your account, besides your personal password you also need a second security way by which you log-in."
      },
      "edit_password": {
        "at_least": "At least",
        "capital": "capital letter",
        "characters": "characters",
        "description": "Enter the current user password and two times the new password to change the current password.",
        "letter": "letter",
        "new_password": "Password",
        "not_matching": "Passwords are matching",
        "number": "number",
        "old_password": "Old password",
        "special": "special character",
        "strength_title": "Password(s) must meet the following requirements:",
        "title": "Change password"
      },
      "enable": "Enable",
      "flash_2fa_disabled": "Two Factor Authentication has been disabled.",
      "flash_2fa_enabled": "Two Factor Authentication has been enabled.",
      "flash_2fa_error": "Two Factor Authentication code was incorrect. Please try again.",
      "show_phone_number_verification": {
        "label": "Code:",
        "resend_link": "new SMS code",
        "resend_title": "UNLOQ Campus has just sent a text message with a code to your mobile phone, enter this code here to validate. Didn't receive a code within 2 minutes? Then request a",
        "title": "Verify SMS code"
      },
      "success_2fa": {
        "description": {
          "disabled": "You have successfully disabled two-factor authentication for your account. Click on the button 'Finish' to finalize or '2FA' to return to the 2-step verification (2FA) menu.",
          "enabled": "You have successfully enabled two-factor authentication for your account. Click on the button below to continue."
        }
      },
      "update_password": {
        "flash_error": "Your current password is invalid.",
        "flash_success": "Your password was updated successfully"
      }
    },
    "actions": "Actions",
    "admin": {
      "breadcrumbs": {
        "home": "Home"
      },
      "buttons": {
        "delete": "Delete %{model_name}",
        "edit": "Edit %{model_name}",
        "new": "New %{model_name}",
        "ok": "OK",
        "save": "Save %{model_name}",
        "show": "Show %{model_name}"
      },
      "datepicker": {
        "formats": {
          "date": "m/d/Y",
          "datetime": "m/d/Y h:i K",
          "time": "h:i K"
        }
      },
      "flash": {
        "create": {
          "failure": {
            "message": "Please correct the errors below.",
            "title": "Warning!"
          },
          "success": {
            "message": "The %{lowercase_model_name} was successfully created.",
            "title": "Success!"
          }
        },
        "destroy": {
          "failure": {
            "message": "Could not delete %{lowercase_model_name}.",
            "title": "Warning!"
          },
          "success": {
            "message": "The %{lowercase_model_name} was successfully deleted.",
            "title": "Success!"
          }
        },
        "update": {
          "failure": {
            "message": "Please correct the errors below.",
            "title": "Warning!"
          },
          "success": {
            "message": "The %{lowercase_model_name} was successfully updated.",
            "title": "Success!"
          }
        }
      },
      "form": {
        "select": {
          "prompt": "- Select %{attribute_name} -"
        }
      },
      "format": {
        "blank": "None"
      },
      "search": {
        "placeholder": "Search",
        "results": "Search Results"
      },
      "table": {
        "headers": {
          "id": "ID"
        }
      },
      "titles": {
        "edit": "Editing %{model_name}",
        "index": "Listing %{pluralized_model_name}",
        "new": "New %{model_name}"
      }
    },
    "are_you_sure": "Are you sure?",
    "assignments": {
      "edit": {
        "add_question": "Add question",
        "title": "Edit assignment"
      },
      "new": {
        "description": "Create a new exercise, reflection or other learning activity here. Creating an assignment consists of two separate steps:",
        "step1": "Give the assignment a clear Name (*mandatory), write down the introduction text, explanation or objective of the assignment and choose 'Save' button.",
        "step2": "Add one or more question(s) to be answered by the client and choose 'Finalize' button.",
        "title": "Add assignment"
      },
      "questions": {
        "hint": "This is the question shown to the client.",
        "prompt": "Add a question to be answered by the client.",
        "title": "Add / edit a question",
        "type": "Type of question"
      },
      "submission": "submission",
      "title": "Assignment",
      "waiting_for": "Waiting for"
    },
    "auth_mailer": {
      "password_reset": {
        "message": "We've received a request to reset your password for UNLOQ Campus. Was that you? Great, just click the link below and you can set a new password for your account. This link will work for a couple of hours.",
        "no_email": "Didn't you request a new password? Don't worry, just ignore this email and nothing changes. Don't hesitate to contact us if you have any questions.",
        "subject": "Password reset"
      }
    },
    "avatars": {
      "new": {
        "btn_later": "Do this later",
        "flash_success": "You profile is set up and you are ready to go.",
        "title": "Make your account more personal by displaying a profile picture."
      }
    },
    "btn": {
      "back": "Back",
      "cancel": "Cancel",
      "change": "Change",
      "finalize": "Finalize",
      "finish": "Finish",
      "ok": "OK",
      "save": "Save",
      "select_file": "Select file",
      "select_logo": "Select logo",
      "send": "Send"
    },
    "by": "by",
    "client": {
      "bank_account_number": "Bank account number",
      "chamber_of_commerce": "Chamber of commerce",
      "client_details": "PDF signup details",
      "client_details_error": "Must be a PDF file",
      "country": "Country",
      "dialogs": "Dialogs",
      "dossier": "Client session logs",
      "edit": {
        "title": "Client settings"
      },
      "gender": "Gender",
      "job_title": "Job title",
      "location": "City",
      "miscellaneous": "Miscellaneous",
      "office_location": "Office location",
      "phone_number": "Phone number",
      "remarks": "Remarks",
      "show": {
        "title": "Client details"
      },
      "time_zone": "Time zone",
      "title": "Client",
      "vat_number": "VAT number",
      "zip_code": "Zip code"
    },
    "client_mailer": {
      "existing_user_invitation": {
        "and_program": "and the program",
        "btn": "Accept invitation",
        "message": "Click here to join",
        "subject": "invited you to a program in UNLOQ Campus"
      },
      "new_user_invitation": {
        "and_program": "and the program",
        "btn": "Accept invitation",
        "message": "Click here to join",
        "subject": "invited you to a program in UNLOQ Campus"
      },
      "rating_enabled": {
        "btn": "Click here to open UNLOQ Campus",
        "footer": "Please note:<br/> - This is a request, not an obligation.<br/> - You can modify a review retrospectively at will.<br/> - You can specify who is allowed to see the review.<br/>",
        "intro": "would like to ask you a question. Would you be so kind to write a review on how you experienced the coaching?",
        "message": "There is a special function for this within UNLOQ Campus that is now available. <br/>Click here to open the dialog within UNLOQ Campus and then indicate how many stars you would like to give your coach plus write a review or recommendation.",
        "subject": "would like you to review"
      },
      "remind_user_invitation": {
        "and_program": "and the program",
        "btn": "Accept invitation",
        "message": "Click here to join",
        "reminder": "Reminder: ",
        "subject": "re-invited you to a program in UNLOQ Campus"
      }
    },
    "client_sessions": {
      "all_programs_ended": {
        "body": "All the programs that this client is in have ended, no more sessions can be added. If you have any questions about this please contact your programme manager.",
        "title": "All programs have ended"
      },
      "available": "Available",
      "btn_new": "Add session",
      "client": "Client",
      "client_archived": {
        "archived_at": "This clients was archived at %{datetime}",
        "body": "This client has been archived, no more sessions can be added. If you have any questions about this please contact your programme manager.",
        "title": "Client archived"
      },
      "date_exceeded": "or session log content is unavailable. After the end of a month, you have %{days} days to create, edit or delete a session log. This period has now expired.",
      "date_not_in_future": "can't be in the future",
      "duration": "Billable",
      "duration_nonbillable": "Non-billable",
      "file": "Attachment",
      "file_explanation": "Note: Only 1 file per session is allowed. If this session log already contains an attachment, it will be overridden when uploading a new one.",
      "from_date": "From date",
      "heading": "Client session logs from:",
      "in_minutes": "In minutes",
      "new_client_session": "New client session log for:",
      "new_client_session_description": "The data below is not visible to the client. The program name, type, date and duration are available to the organisation manager for administrative purposes. Content-related (confidential) information is your eyes only.",
      "notes": "Session notes",
      "number_of": "Sessions",
      "other": "Other / miscellaneous",
      "remaining": "Remaining",
      "session": "Session",
      "sessions": "session(s)",
      "title": "Title",
      "to_date": "To date",
      "tooltip_filter_first": "Please filter on program or coach<br/> before you are able to export",
      "type": "Type",
      "type__360_assessments": "360 Assessments",
      "type_administration": "Administration",
      "type_call": "Call",
      "type_campus": "UNLOQ Campus",
      "type_cancellation_legit": "Cancellation (legit)",
      "type_cancellation_not_legit": "Cancellation (not legit)",
      "type_face2face": "Face2Face",
      "type_group_coaching": "Group coaching",
      "type_noshow": "Cancellation (no-show)",
      "type_other": "Other",
      "type_rescheduled": "Rescheduled",
      "type_sms": "SMS",
      "type_technical_difficulties": "Technical difficulties",
      "type_videocall": "Video call",
      "used": "Used"
    },
    "client_signup": {
      "new": {
        "explanation": "This signup form is specifically meant for %{name}. Signing up is required to receive e-mails containing links to our 360 assessment (i.e. questionnaire). Also, you will be able to add respondents."
      },
      "new_signup": {
        "btn": "Add respondents",
        "message": "Click here to add respondents",
        "subject": "You have been registered successfully for"
      }
    },
    "clients": {
      "archive": "Archive",
      "minutes_per_client": "# Min. per client",
      "reactivate": "Re-activate",
      "tab_active": "Active",
      "tab_archive": "Archive",
      "title": "Clients",
      "toggle_active": {
        "flash_success": "The client was successfully"
      },
      "tooltip_active_and_registered": "Show dialog (client is fully registered)",
      "tooltip_minutes": "Number of billable minutes logged for this client",
      "tooltip_not_active": "Show dialog (client has to receive an invitation email)",
      "tooltip_not_registered": "Show dialog (client is not registered yet)"
    },
    "coach_mailer": {
      "batched_new_clients": {
        "footer": "If you have any questions about this, please contact the Program Manager. Wishing you a successful and inspiring journey.",
        "message": "New client(s) have been added for you to the program: ",
        "subject": "You have new client(s) for the"
      },
      "client_rematched": {
        "footer": "If you have any questions about this, please contact the Program Manager. Wishing you a successful and inspiring journey.",
        "message": "A client has been rematched for you to the program: ",
        "subject": "You have a client who is rematched to you for the"
      },
      "existing_user_invitation": {
        "click": "Click here to join",
        "link": "Go to program",
        "message": "added you to the program %{program} from %{organization} on UNLOQ Campus",
        "subject": "invited you to a program"
      },
      "extra_minutes_for_client": {
        "footer": "This extra time has been added by: ",
        "message": "The total amount of coaching time for <b>%{client}</b> in program <b>%{program}</b> has been changed to:",
        "subject": "UNLOQ Campus: extra minutes added for client"
      },
      "new_client_matched": {
        "footer": "If you have any questions about this, please contact the Program Manager. Wishing you a successful and inspiring journey.",
        "message": "A new client has been added for you to the program: ",
        "subject": "You have a new client for the"
      },
      "new_user_invitation": {
        "click": "Click here to join",
        "link": "Accept invitation",
        "message": "%{organization} and the program %{program} on UNLOQ Campus:",
        "subject": "invited you to a program"
      },
      "rating_done": {
        "btn": "Click here to open UNLOQ Campus",
        "message": "Click here to show the review(s):",
        "subject": "One of your clients has created a review for you."
      }
    },
    "coaches": {
      "find_coach": "Find coach",
      "match_failed": "And error occurred and the client could not be matched to this coach",
      "match_success": "Client matched successfully",
      "modal_title": "Match client to a coach",
      "title": "Coaches"
    },
    "comments": {
      "disabled": "Notes are disabled for this program or you do not have permissions.",
      "email_explanation": "Ten minutes after a note has been placed by someone, an e-mail notification will be sent. Please note: every 10 minutes there is an automatic check for notifications, if there are any, 1 email message to notify will then be sent. This is to prevent spam.",
      "explanation": "Notes: internal communication between coach and L&D programme managers.",
      "new_messages_emails": "New notes emails",
      "shared": {
        "coach_only": "Coach only",
        "private": "L&D Programme managers only",
        "public": "Coaches and L&D Programme managers",
        "title": "Share with"
      },
      "subject": "Subject",
      "title": "Notes",
      "title_placeholder": "Write your notes about this client..."
    },
    "cookies": {
      "cookiebar_link_text": "cookie policy",
      "cookiebar_link_url": "/en/pages/cookies",
      "cookiebar_text": "We use cookies to deliver the best possible experience. Please read our %{link} for more information about how and why we use cookies."
    },
    "copy": "Copy",
    "date": {
      "abbr_day_names": [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
      ],
      "abbr_month_names": [
        null,
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      "day_names": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "formats": {
        "default": "%Y-%m-%d",
        "long": "%e %b %Y at %H:%M",
        "notime": "%e %b %Y",
        "short": "%b %d",
        "trestle_calendar": "%-m/%-d/%Y"
      },
      "month_names": [
        null,
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      "order": [
        "year",
        "month",
        "day"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "about %{count} hour",
          "other": "about %{count} hours"
        },
        "about_x_months": {
          "one": "about %{count} month",
          "other": "about %{count} months"
        },
        "about_x_years": {
          "one": "about %{count} year",
          "other": "about %{count} years"
        },
        "almost_x_years": {
          "one": "almost %{count} year",
          "other": "almost %{count} years"
        },
        "half_a_minute": "half a minute",
        "less_than_x_minutes": {
          "one": "less than a minute",
          "other": "less than %{count} minutes"
        },
        "less_than_x_seconds": {
          "one": "less than %{count} second",
          "other": "less than %{count} seconds"
        },
        "over_x_years": {
          "one": "over %{count} year",
          "other": "over %{count} years"
        },
        "x_days": {
          "one": "%{count} day",
          "other": "%{count} days"
        },
        "x_minutes": {
          "one": "%{count} minute",
          "other": "%{count} minutes"
        },
        "x_months": {
          "one": "%{count} month",
          "other": "%{count} months"
        },
        "x_seconds": {
          "one": "%{count} second",
          "other": "%{count} seconds"
        },
        "x_years": {
          "one": "%{count} year",
          "other": "%{count} years"
        }
      },
      "prompts": {
        "day": "Day",
        "hour": "Hour",
        "minute": "Minute",
        "month": "Month",
        "second": "Second",
        "year": "Year"
      }
    },
    "deactivated": "Deactivated",
    "delete": "Delete",
    "dialog_successfully_archived": "Dialog successfully archived",
    "dialog_title": {
      "label": "Group dialog",
      "placeholder": "Write group title here..."
    },
    "dialogs": {
      "a_group_dialog": "a group dialog",
      "add_participant": "Add participant to this dialog",
      "add_profile_picture": "Personalize your account by adding a profile picture and other relevant information. Go to",
      "already_present": "A dialog has already been created before",
      "are_you_sure": {
        "reactivate": "Are you sure you want to reactivate this participant?",
        "remove": "Are you sure you want to remove this participant?"
      },
      "are_you_sure_activate": "Are you sure you want to activate this dialog and the participants receive an invite by e-mail?",
      "assignment": {
        "completed_description": "You've completed all questions. Write an accompanying dialog message and submit the assignment.",
        "continue": "Continue",
        "hand_in": "Hand in",
        "hand_in_success": "Your assignment has been successfully handed in.",
        "open": "Open",
        "view_answers": "View answers"
      },
      "autosaved": "Saved as concept",
      "btn": {
        "attachment": "Add toolbox content",
        "new": "New client / new dialog"
      },
      "edited": "edited",
      "email_explanation": "Ten minutes after a new dialog message has been placed by someone, an e-mail notification will be sent. Please note: every 10 minutes there is an automatic check for notifications, if there are any, 1 email message to notify will then be sent. This is to prevent spam.",
      "empty": "No dialogs match your query.",
      "explanation": "Client notifications: dialogue messages.",
      "group_success": "User has successfully been added to the group dialog.",
      "invitation_pending": "Invitation pending",
      "invite_someone": "start the first one",
      "new": {
        "btn": {
          "create_account": "Create account",
          "send": "Send invitation"
        },
        "description": "You can choose from the different options below:",
        "enter_email": "Enter the email address of the person you wish to start a dialog with to verify if they already have an account with UNLOQ Campus.",
        "extra_information_account_only": "After creation the account without invitation, this account will not be available for the client. At a later stage, you can (optionally) activate the dialog.",
        "extra_information_already_account": "After sending this invitation you can (optionally) add a welcome message in the dialog window.",
        "extra_information_no_account": "After sending this invitation you can (optionally) add a welcome message in the dialog window. Attention: the client has to accept the invitation (link in e-mail) before the client can participate.",
        "flash_success_create_account": "The account has been successfully created.",
        "flash_success_current": "All participants have been invited and you are ready to send your first message.",
        "flash_success_invited": "The dialog has been created and people have been invited.",
        "no_account": "It seems this person does not have an account yet. We'll send them an invitation to create an account. Would you like to include a name in the invitation?",
        "options": "<ul><li>A) Start dialog with an existing client</li><li>B) Start dialog with a new client</li><li>C) Create account without a dialog</li></ul>",
        "participant": {
          "already_account": "already has an account! Choose the button 'Send invitation' to add this person to this Group dialog.",
          "description": "You can choose from two different options below:",
          "enter_email": "Enter the email address of the person you wish to add to the Group dialog to verify if they already have an account with UNLOQ Campus.",
          "extra_information_no_account": "The new participant has to accept the invitation first (via link in email) before they can participate in this dialog.",
          "no_account": "This person does not have an account yet. Fill in some basic information below to create an account. Choose the button 'Send invitation' to add this person to this group dialog.",
          "options": "<ul><li>A) Add existing account to this Group dialog</li><li>B) Add a new account to this Group dialog </li></ul>",
          "title": "Who do you want to add to this Group dialog?"
        },
        "title": "Who do you want to invite?"
      },
      "new_messages_emails": "New dialog message emails",
      "no_dialogs": "You have no dialogs yet.",
      "no_messages": "No messages (yet)",
      "no_program_membership": "Coach has not been assigned to the program (yet).",
      "not_logged_in": "Has not logged in (yet)",
      "others": "other(s)",
      "remove_participant": "Remove participant",
      "table": {
        "participants": "Participants"
      },
      "unread_message": {
        "one": "unread message",
        "other": "unread messages"
      },
      "welcome": "Welcome to your UNLOQ Campus account.",
      "why_not": "Here you can",
      "with": "Dialog with",
      "you": "You:",
      "your_responses": "Responses"
    },
    "download": "Download",
    "edit": "Edit",
    "email": {
      "delivery_failure": "E-mail delivery failure"
    },
    "export": "Export",
    "false": "No",
    "flash_archived_success": "was archived successfully",
    "flash_created_success": "was created successfully",
    "flash_deleted_success": "was deleted successfully",
    "flash_enabled_success": "was enabled successfully",
    "flash_error": "Something went wrong or please correct the highlighted errors.",
    "flash_updated_success": "was updated successfully",
    "footer_text": "With more than 10 years of experience, UNLOQ Campus has become a trusted partner for national and international organizations that demand high levels of confidentiality and security. UNLOQ Campus is fully GDPR compliant and ISO 27001 certified for development, maintenance and secure hosting activities.",
    "for": "for",
    "forms": {
      "add_program": "Add program",
      "all_coaches": "All coaches",
      "choose": "Choose...",
      "choose_program": "All programs",
      "confirm_password": "Password confirmation",
      "consent": "I understand and agree to the recording of personal information as described in the <a href='/en/pages/privacy_regulations' target='_blank'>Privacy Regulation</a>",
      "description": "Description",
      "email": "Email",
      "first_name": "First name",
      "joined": "Created on",
      "language": "Language",
      "last_name": "Last name",
      "last_seen": "Last logged on",
      "name": "Name",
      "new_message": "New message",
      "optional_attachment": "Attachment",
      "organization_name": "Organization name",
      "organization_name_unloq": "Organization name (as known UNLOQ Campus)",
      "password": "Password",
      "picture": "Picture",
      "select_file": {
        "one": "Select file",
        "other": "Select files"
      },
      "select_from_date": "Select from-date",
      "select_to_date": "Select to-date",
      "terms": "I have reviewed and agree to the <a href='/en/pages/terms' target='_blank'>terms and conditions</a>",
      "title": "Title",
      "type": "Type...",
      "type_here": "Write a dialog message..."
    },
    "helpers": {
      "page_entries_info": {
        "entry": {
          "one": "entry",
          "other": "entries",
          "zero": "entries"
        },
        "more_pages": {
          "display_entries": "Displaying %{entry_name} <b>%{first}&nbsp;-&nbsp;%{last}</b> of <b>%{total}</b> in total"
        },
        "one_page": {
          "display_entries": {
            "one": "Displaying <b>1</b> %{entry_name}",
            "other": "Displaying <b>all %{count}</b> %{entry_name}",
            "zero": "No %{entry_name} found"
          }
        }
      },
      "select": {
        "prompt": "Please select"
      },
      "submit": {
        "create": "Create %{model}",
        "submit": "Save %{model}",
        "update": "Update %{model}"
      }
    },
    "how_do_i_enable_javascript": "How do I enable javascript?",
    "i18n": {
      "plural": {
        "keys": [
          "one",
          "other"
        ]
      }
    },
    "i18n_tasks": {
      "add_missing": {
        "added": {
          "one": "Added %{count} key",
          "other": "Added %{count} keys"
        }
      },
      "cmd": {
        "args": {
          "default_text": "Default: %{value}",
          "desc": {
            "all_locales": "Do not expect key patterns to start with a locale, instead apply them to all locales implicitly.",
            "config": "Override config location",
            "confirm": "Confirm automatically",
            "data_format": "Data format: %{valid_text}.",
            "keep_order": "Keep the order of the keys",
            "key_pattern": "Filter by key pattern (e.g. 'common.*')",
            "key_pattern_to_rename": "Full key (pattern) to rename. Required",
            "locale": "i18n_tasks.common.locale",
            "locale_to_translate_from": "Locale to translate from",
            "locales_filter": "Locale(s) to process. Special: base",
            "missing_types": "Filter by types: %{valid}",
            "new_key_name": "New name, interpolates original name as %{key}. Required",
            "nostdin": "Do not read from stdin",
            "out_format": "Output format: %{valid_text}",
            "pattern_router": "Use pattern router: keys moved per config data.write",
            "strict": "Avoid inferring dynamic key usages such as t(\"cats.#{cat}.name\"). Takes precedence over the config setting if set.",
            "translation_backend": "Translation backend (google or deepl)",
            "value": "Value. Interpolates: %{value}, %{human_key}, %{key}, %{default}, %{value_or_human_key}, %{value_or_default_or_human_key}"
          }
        },
        "desc": {
          "add_missing": "add missing keys to locale data, optionally match a pattern",
          "check_consistent_interpolations": "verify that all translations use correct interpolation variables",
          "check_normalized": "verify that all translation data is normalized",
          "config": "display i18n-tasks configuration",
          "data": "show locale data",
          "data_merge": "merge locale data with trees",
          "data_remove": "remove keys present in tree from data",
          "data_write": "replace locale data with tree",
          "eq_base": "show translations equal to base value",
          "find": "show where keys are used in the code",
          "gem_path": "show path to the gem",
          "health": "is everything OK?",
          "irb": "start REPL session within i18n-tasks context",
          "missing": "show missing translations, optionally match a pattern",
          "mv": "rename/merge the keys in locale data that match the given pattern",
          "normalize": "normalize translation data: sort and move to the right files",
          "remove_unused": "remove unused keys",
          "rm": "remove the keys in locale data that match the given pattern",
          "translate_missing": "translate missing keys with Google Translate or DeepL Pro, optionally match a pattern",
          "tree_convert": "convert tree between formats",
          "tree_filter": "filter tree by key pattern",
          "tree_merge": "merge trees",
          "tree_mv_key": "rename/merge/remove the keys matching the given pattern",
          "tree_set_value": "set values of keys, optionally match a pattern",
          "tree_subtract": "tree A minus the keys in tree B",
          "tree_translate": "Google Translate a tree to root locales",
          "unused": "show unused translations"
        },
        "encourage": [
          "Good job!",
          "Well done!",
          "Perfect!"
        ],
        "enum_list_opt": {
          "invalid": "%{invalid} is not in: %{valid}."
        },
        "enum_opt": {
          "invalid": "%{invalid} is not one of: %{valid}."
        },
        "errors": {
          "invalid_format": "invalid format: %{invalid}. valid: %{valid}.",
          "invalid_locale": "invalid locale: %{invalid}",
          "invalid_missing_type": {
            "one": "invalid type: %{invalid}. valid: %{valid}.",
            "other": "unknown types: %{invalid}. valid: %{valid}."
          },
          "pass_forest": "pass locale forest"
        }
      },
      "common": {
        "continue_q": "Continue?",
        "key": "Key",
        "locale": "Locale",
        "n_more": "%{count} more",
        "value": "Value"
      },
      "data_stats": {
        "text": "has %{key_count} keys across %{locale_count} locales. On average, values are %{value_chars_avg} characters long, keys have %{key_segments_avg} segments, a locale has %{per_locale_avg} keys.",
        "text_single_locale": "has %{key_count} keys in total. On average, values are %{value_chars_avg} characters long, keys have %{key_segments_avg} segments.",
        "title": "Forest (%{locales})"
      },
      "deepl_translate": {
        "errors": {
          "no_api_key": "Setup DeepL Pro API key via DEEPL_AUTH_KEY environment variable or translation.deepl_api_key in config/i18n-tasks.yml. Get the key at https://www.deepl.com/pro.",
          "no_results": "DeepL returned no results."
        }
      },
      "google_translate": {
        "errors": {
          "no_api_key": "Set Google API key via GOOGLE_TRANSLATE_API_KEY environment variable or translation.google_translate_api_key in config/i18n-tasks.yml. Get the key at https://code.google.com/apis/console.",
          "no_results": "Google Translate returned no results. Make sure billing information is set at https://code.google.com/apis/console."
        }
      },
      "health": {
        "no_keys_detected": "No keys detected. Check data.read in config/i18n-tasks.yml."
      },
      "inconsistent_interpolations": {
        "none": "No inconsistent interpolations found."
      },
      "missing": {
        "details_title": "Value in other locales or source",
        "none": "No translations are missing."
      },
      "remove_unused": {
        "confirm": {
          "one": "%{count} translation will be removed from %{locales}.",
          "other": "%{count} translation will be removed from %{locales}."
        },
        "noop": "No unused keys to remove",
        "removed": "Removed %{count} keys"
      },
      "translate_missing": {
        "translated": "Translated %{count} keys"
      },
      "unused": {
        "none": "Every translation is in use."
      },
      "usages": {
        "none": "No key usages found."
      },
      "yandex_translate": {
        "errors": {
          "no_api_key": "Set Yandex API key via YANDEX_API_KEY environment variable or translation.yandex_api_key in config/i18n-tasks.yml. Get the key at https://tech.yandex.com/translate.",
          "no_results": "Yandex returned no results."
        }
      }
    },
    "javascript_required": "Javascript is required to use this application.",
    "jsonapi_swagger": {
      "associate_data": "Associate to Date",
      "associate_list_link": "Associate to %{model} Link",
      "attributes": "Attributes",
      "create": "Create",
      "data": "Data",
      "delete": "Delete",
      "detail": "Detail",
      "detail_link": "Detail Link",
      "display_field": "Display Field",
      "filter_field": "Filter Field",
      "first_page_link": "First Page",
      "get_detail": "Fetch Detail",
      "get_list": "Fetch List",
      "include_related_data": "Include Related Data",
      "last_page_link": "Last Page",
      "list": "List",
      "meta": "Meta",
      "next_page_link": "Next Page",
      "page_count": "Page Count",
      "page_links": "Page Link",
      "page_num": "Page Number",
      "page_size": "Page Size",
      "patch": "Patch",
      "record_count": "Total Record Count",
      "related_id": "Related %{model} ID",
      "related_ids": "Related %{model} IDs",
      "related_link": "Related %{model} Link",
      "related_model": "Related %{model}",
      "request_body": "Request Body",
      "sortable_fields": "Sortable Fields"
    },
    "language": {
      "en": "English",
      "es": "Spanish",
      "fr": "French",
      "nl": "Dutch"
    },
    "last_login": "Last login",
    "last_modified": "Last activity",
    "learning_path": "Learning path",
    "learning_path_mailer": {
      "progress": {
        "subject": "Your course progress for the %{program}"
      }
    },
    "library": {
      "add": "Add",
      "are_you_sure": "Are you sure? Please note that this item will also be removed from all dialogs where it may be used.",
      "assignment": "Assignment",
      "delete_folder_confirmation": "Are you sure you want to delete this folder including its contents?",
      "document": "Document",
      "empty": "There are no items in your toolbox yet. Choose the '+' button to add a document, video, exercise or other content.",
      "empty_folder": "This folder does not contain any (visible) items yet. Choose the '+' button to add a document, video, exercise or other content.",
      "filters": {
        "assignments": "Assignments",
        "documents": "Documents",
        "embeds": "Videos",
        "folders": "Folders",
        "images": "Images",
        "uploads": "Documents",
        "videos": "Videos"
      },
      "folder": "Folder",
      "image": "Image",
      "questions": "Questions",
      "video": "Video"
    },
    "license": {
      "description": {
        "business": "Sign up this Business license a € 83,25 per month (€ 999,- a year, excl. VAT).<br/><br/> ✔️ Including 3 Expert accounts and 150 Client accounts.<br/><br/>\nComplete the requested details below and start today with your own Business account.",
        "education": "Sign up this 60-day All-In-One Education license.<br/><br/> ✔️ Including 3 Expert accounts and 150 Client accounts.<br/> The costs for this license are included in your training program.<br/><br/>\nComplete the requested details below and start today with your own Education account.",
        "enterprise": "Sign up this Enterprise license a € 832,50 per month (€ 9.999,- a year, excl. VAT)<br/><br/> ✔️ Including unlimited Expert accounts and unlimited Client accounts.<br/><br/>\nComplete the requested details below and start today with your own Enterprise account",
        "transition": "Sign up this special Transition license.<br/><br/> ✔️ Including 3 Expert accounts and 150 Client accounts.<br/> You can use this UNLOQ Campus license free of charge as long as you have a valid license for UNLOQ Campus.<br/><br/>\nComplete the requested details below and start today with your own UNLOQ Campus account.",
        "trial": "Sign up this 30-day All-In-One free trial.<br/><br/> ✔️ No strings attached.<br/> ✔️ No credit card required.<br/> ✔️ Including 3 Expert accounts and 150 Client accounts.<br/><br/>\nComplete the requested details below and start today with your own free account."
      }
    },
    "license_expired": "This account is locked, adjustments not possible.",
    "lit": {
      "common": {
        "browse_incoming": "Browse incoming localizations",
        "delete": "Delete",
        "edit": "Edit",
        "previous_versions": "Previous versions",
        "show": "Show",
        "synchronize": "Synchronize",
        "touch": "Touch (update) last_updated_at",
        "you_sure": "Are you sure?"
      },
      "not_translated_header": "Not translated localization keys",
      "order_options": {
        "created_at_asc": "Created at asc",
        "created_at_desc": "Created at desc",
        "lit/incomming_localization": {
          "localization": "Localization",
          "localization_key": "Localization key"
        },
        "localization_key_asc": "Localization key asc",
        "localization_key_desc": "Localization key desc",
        "updated_at_asc": "Updated at asc",
        "updated_at_desc": "Updated at desc"
      }
    },
    "login": {
      "btn": "Login",
      "password_forgotten": "Forgot your password?"
    },
    "manager": "Manager",
    "menu": {
      "associate_profile": "Associate Profile",
      "dialogs": "Dialogs",
      "logout": "Logout",
      "manage": "Manage",
      "my_profile": "My Profile",
      "notifications": "Notification settings",
      "pages": "Pages",
      "pending_clients": "Pending clients",
      "profile_settings": "Profile settings",
      "questionnaires": "Questionnaires",
      "translations": "Translations",
      "users": "Users"
    },
    "new": "New",
    "none": "None",
    "not_applicable": "N/A",
    "not_authenticated": "You need to be logged in to perform this action.",
    "not_authorized": "You are not authorized to perform this action.",
    "notification_mailer": {
      "batched_comments": {
        "btn": "Click here to open UNLOQ Campus",
        "message": "There are new notes about a client in Campus which might be relevant for you.",
        "subject": "UNLOQ Campus: new note(s) about a client"
      },
      "batched_dialog_messages": {
        "link_description": "To see the entire message and to reply:",
        "link_text": "visit UNLOQ Campus",
        "message": "You have received a new message from",
        "subject": "UNLOQ Campus: new message(s)"
      },
      "unsubscribe": "Click here if you want to unsubscribe for these e-mails."
    },
    "notifications": {
      "description": "Move the button to 'ON' to receive notifications. Move the button to 'OFF' if you don't want to receive notifications from UNLOQ Campus.<br/><br/>Basic notifications about new clients, password reset and other system messages cannot be turned off. The following types of emails that can be turned off are:",
      "title": "Here you can indicate if you want to receive emails from UNLOQ Campus when there are new e-mail messages available for you."
    },
    "number": {
      "currency": {
        "format": {
          "delimiter": ",",
          "format": "%u%n",
          "precision": 2,
          "separator": ".",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "$"
        }
      },
      "format": {
        "delimiter": ",",
        "precision": 3,
        "round_mode": "default",
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": "Billion",
            "million": "Million",
            "quadrillion": "Quadrillion",
            "thousand": "Thousand",
            "trillion": "Trillion",
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "Byte",
              "other": "Bytes"
            },
            "eb": "EB",
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "pb": "PB",
            "tb": "TB"
          }
        }
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "number_short": "No.",
    "order_by": "Order by",
    "ordinalize_full": {
      "n_1": "first",
      "n_10": "tenth",
      "n_100": "one hundredth",
      "n_11": "eleventh",
      "n_12": "twelfth",
      "n_13": "thirteenth",
      "n_14": "fourteenth",
      "n_15": "fifteenth",
      "n_16": "sixteenth",
      "n_17": "seventeenth",
      "n_18": "eighteenth",
      "n_19": "nineteenth",
      "n_2": "second",
      "n_20": "twentieth",
      "n_21": "twenty first",
      "n_22": "twenty second",
      "n_23": "twenty third",
      "n_24": "twenty fourth",
      "n_25": "twenty fifth",
      "n_26": "twenty sixth",
      "n_27": "twenty seventh",
      "n_28": "twenty eighth",
      "n_29": "twenty ninth",
      "n_3": "third",
      "n_30": "thirtieth",
      "n_31": "thirty first",
      "n_32": "thirty second",
      "n_33": "thirty third",
      "n_34": "thirty fourth",
      "n_35": "thirty fifth",
      "n_36": "thirty sixth",
      "n_37": "thirty seventh",
      "n_38": "thirty eighth",
      "n_39": "thirty ninth",
      "n_4": "fourth",
      "n_40": "fortieth",
      "n_41": "forty first",
      "n_42": "forty second",
      "n_43": "forty third",
      "n_44": "forty fourth",
      "n_45": "forty fifth",
      "n_46": "forty sixth",
      "n_47": "forty seventh",
      "n_48": "forty eighth",
      "n_49": "forty ninth",
      "n_5": "fifth",
      "n_50": "fiftieth",
      "n_51": "fifty first",
      "n_52": "fifty second",
      "n_53": "fifty third",
      "n_54": "fifty fourth",
      "n_55": "fifty fifth",
      "n_56": "fifty sixth",
      "n_57": "fifty seventh",
      "n_58": "fifty eighth",
      "n_59": "fifty ninth",
      "n_6": "sixth",
      "n_60": "sixtieth",
      "n_61": "sixty first",
      "n_62": "sixty second",
      "n_63": "sixty third",
      "n_64": "sixty fourth",
      "n_65": "sixty fifth",
      "n_66": "sixty sixth",
      "n_67": "sixty seventh",
      "n_68": "sixty eighth",
      "n_69": "sixty ninth",
      "n_7": "seventh",
      "n_70": "seventieth",
      "n_71": "seventy first",
      "n_72": "seventy second",
      "n_73": "seventy third",
      "n_74": "seventy fourth",
      "n_75": "seventy fifth",
      "n_76": "seventy sixth",
      "n_77": "seventy seventh",
      "n_78": "seventy eighth",
      "n_79": "seventy ninth",
      "n_8": "eighth",
      "n_80": "eightieth",
      "n_81": "eighty first",
      "n_82": "eighty second",
      "n_83": "eighty third",
      "n_84": "eighty fourth",
      "n_85": "eighty fifth",
      "n_86": "eighty sixth",
      "n_87": "eighty seventh",
      "n_88": "eighty eighth",
      "n_89": "eighty ninth",
      "n_9": "ninth",
      "n_90": "ninetieth",
      "n_91": "ninety first",
      "n_92": "ninety second",
      "n_93": "ninety third",
      "n_94": "ninety fourth",
      "n_95": "ninety fifth",
      "n_96": "ninety sixth",
      "n_97": "ninety seventh",
      "n_98": "ninety eighth",
      "n_99": "ninety ninth"
    },
    "organization": "Organization",
    "organization_manager_mailer": {
      "new_signup": {
        "message": "A new client has been signed up for the program: %{program}. Please assign the coach to this client in order to start their coaching journey.",
        "subject": "A new client has been signed up."
      }
    },
    "organizations": {
      "choose_term": "Choose terminology",
      "flexible_term_description": "You can adjust the terminology below and therefore choose the terms that suit your organization, position and target group well. Select the terms you want to use and click Save. These terms will then be implemented throughout the account of your organization UNLOQ Campus and will also be visible to clients.",
      "flexible_term_title": "Flexible terminology",
      "flexible_term_warning": "Please note that changes in terminology are visible after 1 hour.",
      "license": "License",
      "licensed_to": "Licensed to",
      "logo_explanation": "We recommend to upload a PNG logo with transparent background.",
      "settings_for": "Settings for",
      "term_assignment": "Assignment",
      "term_client": "Client",
      "term_coach": "Coach",
      "term_for": "Term for: "
    },
    "other_organizations": "Other organization(s):",
    "passwords": {
      "edit": {
        "btn": "Reset password",
        "description": "Enter your new password and we'll send you right along.",
        "label_password": "New password",
        "title": "Password reset"
      },
      "new": {
        "description": "Enter your email address and we'll send you instructions for setting a new password. If you didn't receive an e-mail, please check your spam folder or try again.",
        "placeholder_email": "Your email address",
        "title": "Don't worry, it happens to all of us."
      }
    },
    "pdf_report_ready": "PDF report ready",
    "pending_clients": {
      "description": "Below you can overview of the clients who need to be matched (or are matched already). On the right hand side an appropriate coach can be selected."
    },
    "people_analytics": {
      "360_degree": "360-degree",
      "aborted_signature": "Kind regards,<br/><br/>Campus People Analytics | part of UNLOQ<br/><a href='https://unloq.org/' target='_blank'>UNLOQ.ORG</a>",
      "aborted_success": "Thank you for your cooperation. This questionnaire will not be taken any further as it can only be done by acceding to the terms and conditions.<br> <br> You can now close this page. If you want to resume the questionnaire please use the button below.",
      "aborted_success_title": "Questionnaire closed",
      "add_respondent": "add a respondent",
      "add_respondent_button": "Add respondent",
      "admin": {
        "choose_program": "Choose program",
        "choose_questionnaire": "Choose questionnaire",
        "filter_first": "Please filter first to see results.",
        "measure_moments": "Measure moments",
        "not_enough_results": "There are not enough results to show (minimum of 3).",
        "question": "Question",
        "title": "People Analytics results",
        "topic": "Topic(s)"
      },
      "analytical_thinking": "Analytical thinking",
      "analytical_thinking_description": "I promote resilience and responsiveness to emerging strategic issues and opportunities for the Department/Division.",
      "answer": "Answer",
      "assessment": "Assessment",
      "assessment_leadership_organizational": "Organizational Leadership",
      "assessment_leadership_self": "Personal / Self Leadership",
      "assessment_leadership_team": "Team Leadership",
      "assessment_report": "Assessment report",
      "attention_objectives": "<b>Attention:</b> If you wish to add more objectives, use this button before you continue to the next page.",
      "autonomy": "Autonomy",
      "autonomy_description": "The freedom and authority to act for oneself within the job.",
      "average_objectives": "Average no. coaching objectives",
      "average_objectives_score": "Average client objectives score",
      "basicdata": "Basic client data",
      "btn_abort": "Close",
      "btn_add_goal": "Add objective",
      "btn_continue": "Continue",
      "btn_copy_report_link": "Copy link to report",
      "btn_copy_report_link_hint": "Copy the URL to the report for you to share",
      "btn_download_report": "Download PDF",
      "btn_download_report_active": "Downloading...",
      "btn_download_report_hint": "Download the PDF file of your report",
      "btn_generate_for": "Generate coaching report for",
      "btn_generate_report": "Generate report",
      "btn_generate_report_hint": "Generates new report",
      "btn_regenerate_report": "Re-generate report",
      "btn_regenerate_report_hint": "Re-generates the report, cannot be undone",
      "btn_remove_goal": "Remove objective",
      "btn_resume": "Resume questionnaire",
      "btn_send_report_link_dialog": "Send report by dialog",
      "btn_send_report_link_dialog_hint": "Sends the link to the report to your client in the dialogue",
      "btn_send_report_link_email": "Send report by email",
      "btn_send_report_link_email_active": "Sending...",
      "btn_send_report_link_email_hint": "Sends automatic email to the client with the link to report",
      "btn_submit": "Submit",
      "can_add_peers_explanation": "This questionnaire is configured not to be able to add respondents after a certain moment in the assessment timeline",
      "choose_role": "Choose role",
      "client_option": {
        "anonymous": "Anonymous",
        "fullname": "Full name from client"
      },
      "closed": "Closed",
      "closed_on": "Closed on",
      "coaching_objectives": "Coaching Objectives",
      "coachprocedure": "Evaluation of the coaching process",
      "coachprocedure_description": "This questionnaire surveys the client's opinion regarding the coaching process. The questions are fed back as completed by the client.",
      "coachprocedure_explanation": "This questionnaire surveys the client's opinion regarding the coaching.",
      "coachrelation": "Coaching relationship",
      "coachrelation_explanation": "A coach and client collaborate to achieve the client's goals. This professional collaboration is called the 'coaching alliance'. Three aspects are distinguished regarding the working alliance: Affective bond, Task orientation and Objective orientation.",
      "coachrelation_only_one": "Please note that the questions from this section were taken only once, so only 1 measurement moment is visible in the graphs below.",
      "coachrelation_total": "Total score",
      "coachrelation_total_description": "Total scores of affective bond, task orientation and objectives.",
      "communication": "Communication",
      "communication_description": "I have presence and bearing as an effective advocate of the mission and strategic direction of the programme.",
      "competence": "Competence",
      "competence_description": "Utilizing one's own capabilities and being effective in interactions with others at work.",
      "confidence": "Confidence",
      "confidence_description": "How much I am confident to achieve the coaching objective",
      "confidential": "Confidential",
      "confirm_objectives_modal": {
        "back": "Back to objectives",
        "body": "You are about to finish the Objectives section. If you are sure that you have added all your objectives, click \"Continue\". If you wish to add more objectives, then click \"Back to objectives\"",
        "continue": "Continue",
        "title": "Have you added all your objectives?"
      },
      "consent": "You must agree to the terms to continue.",
      "copy_link_error": "Can't copy link, client not active for current user",
      "copy_link_success": "Link successfully copied to your clipboard",
      "current": "Current status",
      "current_description": "The current objective status",
      "deactivate": "Deactivate",
      "description": "Description",
      "evaluation_report": "Evaluation report",
      "explanation": "Explanation",
      "final_remarks": "Final remarks",
      "finished_signature": "Kind regards,<br/><br/>Campus People Analytics | part of UNLOQ<br/><a href='https://unloq.org/' target='_blank'>UNLOQ.ORG</a>",
      "finished_success": "All your answers are successfully (and securely) stored. Thank you for your cooperation.",
      "finished_success_title": "Well done! You have successfully finished all your questions.",
      "five": "Five",
      "generate_report_error": "There was a problem with displaying this report",
      "generate_report_prompt": "Start with generating a report",
      "goal": "Coaching objective",
      "goal_explanation": "Scales run from 0 to 10, where 0 stands for a very low score and 10 for the highest possible score. The lower the score, the more growth is possible towards the objective. The higher the score, the more the objective is reached.",
      "goaloriented": "Objectives",
      "goaloriented_description": "The extent to which a client is satisfied about the objectives to be achieved.",
      "independent": "Independence",
      "independent_description": "How much I am able to achieve this objective independently",
      "information": "Information",
      "integrity": "Integrity",
      "integrity_description": "I demonstrate and abide by the Agency's core values in activities and behaviour.",
      "leadership_assessment_title": "Leadership Assessment Results",
      "leading_and_supervising": "Leading and supervising",
      "leading_and_supervising_description": "I ensure that power and authority are not abused and demonstrate leadership and credibility in interactions with all partners and stakeholders.",
      "legend": {
        "aborted": "Aborted",
        "completed": "Completed",
        "not_sent_yet": "Not sent",
        "partially_completed": "Started",
        "reminder_sent": "Reminded",
        "sent": "Sent"
      },
      "legend_description": {
        "aborted": "The participant / respondent has declined to privacy policy.",
        "completed": "The participant / respondent has completed the assignment.",
        "not_sent_yet": "The assignment has not been sent to the participant / respondent yet.",
        "partially_completed": "The participant / respondent has started the assignment but has not fully completed it yet.",
        "reminder_sent": "An automatic reminder to fill in the questionnaire has been sent to the participant / respondent.",
        "sent": "The assignment has been sent to the participant / respondent."
      },
      "measure_moment": "Measure moment",
      "menu": {
        "background": "Background",
        "clients_progress": "Clients progress",
        "example_report": "Example report"
      },
      "mindset": "Views on people (Mindset)",
      "mindset_description": "The extent to which a person believes that people can change and develop.",
      "mindset_explanation": "The extent to which a person believes that people (including themselves) can change their behaviour. The higher the score, the more the person believes that people can change (growth mindset). The lower the score, the more the person is convinced that people cannot change (fixed mindset).",
      "mindset_scale": "This is expressed on a scale of minimum 8 and maximum 48.",
      "motivation": "Motivation",
      "motivation_description": "How much I am motivated to achieve the objective",
      "new": "New",
      "new_client": "New client",
      "new_from": "New from",
      "no_goals": "You need to specify at least one active objective to continue this questionnaire.",
      "no_respondents_yet": "No respondents added yet. Go ahead and",
      "not_all_questions_answered": "Not all questions have been answered.",
      "objective": "Objective",
      "objective_closed": "Closed",
      "objective_no": "Objective no.",
      "objective_relevant": "Relevant",
      "pdf_confidential": "The information contained in these documents is confidential, privileged and only for the information of the intended recipient and may not be used, published or redistributed without consent of the intended recipient. This document has been generated by UNLOQ People Analytics, an instrument of UNLOQ.",
      "pdf_share_answer": {
        "private": "Coach",
        "public": "Coach and respondent(s)"
      },
      "peer": "Respondent",
      "peers": "Respondents",
      "planning": "Planning",
      "planning_and_organizing": "Planning and organizing",
      "planning_and_organizing_description": "I convince and engage others by using compelling arguments.",
      "planning_description": "I resist all undue pressures in decision making and in the performance of duties.",
      "preview": "Preview",
      "previous_peer_answer": "In the previous evaluation from this objective, you gave %{client} a score: %{number} (scale 0 - 10).",
      "private_objectives_modal": {
        "body": "When you share your objectives with your coach <i>only</i>, your respondents will not be involved in this 360-degree survey.",
        "title": "Only share objectives with your coach?"
      },
      "professionalism": "Professionalism",
      "professionalism_description": "I take prompt and effective action to deal with unprofessional or unethical behaviour.",
      "program": "Program",
      "psychological_needs": "Basic psychological needs at work",
      "psychological_needs_explanation": "Every human being strives to fulfill their own psychological basic needs. The more these basic needs are fulfilled through and on the job the more a person is motivated to do their work better. Three basic needs are distinguished, namely: Autonomy, Competence and Relatedness.",
      "question": "Question",
      "questionnaire": "Questionnaire",
      "relatedness": "Relatedness",
      "relatedness_description": "The feeling of belonging and close contact with others at work.",
      "relation": "Affective bond",
      "relation_description": "The extent to which there is a good working alliance between coach and client.",
      "reminded_at": "Reminder e-mail sent at",
      "report": {
        "assessment_intro": "This section presents the results of the Leadership Assessment.<br /><br />\nThe scores are shown in the graph for each leadership behavior. Your score (Self) is presented at this first assessment level (M1). Below the (Self) display, the average respondents' scores for the Peer, Direct Report and Manager categories are shown respectively.<br /><br />",
        "assessment_intro_m1": "The second assessment level (M2) is not applicable to this report. (M2) becomes available only if a second assessment is completed. When (M2) is available, the difference between (M1) and (M2) will measure your level of growth.",
        "assessment_intro_m2": "The second assessment level (M2) is also visible in the graph (2nd bar) and also in the table. The difference between (M1) and (M2) will measure your level of growth.",
        "assessment_legend": "The score is displayed on a 5-point Likert scale, meaning that the score runs from 1) strongly disagree to 5) strongly agree.",
        "assessment_scale": "Scale",
        "assessment_scale_explanation": "1: strongly disagree / 2: disagree / 3: neither agree nor disagree / 4: agree / 5: strongly agree",
        "average": "Average",
        "average_overall": "Overall Average",
        "averages": "Averages",
        "highest": "highest",
        "lowest": "lowest",
        "open_ended_intro": "This section presents the answers given to various open-ended questions (Q).<br /><br />\nFirst the answers given by self are shown, followed by the answers given by the respondents.",
        "open_ended_title": "Responses to open-ended questions",
        "overview_of": "Overview of",
        "section": "Section",
        "supervisory_assessment_intro": "This section presents the results of the Supervisory Leadership Assessment.<br /><br />\nThe scores are shown in the graph for each supervisory leadership skill and/or behaviour. Your score (Self) is presented at this first assessment level (M1). Below the (Self) display, the average respondents' scores for the Manager, Direct Report and/or Peer categories are shown respectively.",
        "supervisory_top_results_intro": "This section presents five highest-rated supervisory leadership skills and behaviours and the five lowest-rated supervisory leadership skills and behaviours (rated by Self).",
        "supervisory_top_results_top_rated": "rated supervisory skills on average",
        "table_results_intro": "This section shows the responses for each of the three leadership dimensions of feedback collected from multiple sources (Self, Managers, Direct Reports and Peers).",
        "table_results_intro_explanation": "<ul class=\"list-check ml-3\">\n  <li>The \"Average Self\" column shows the overall average responses you gave yourself for each dimension.</li>\n  <li>The respondents' columns show the averages of your raters' responses.</li>\n  <li>The \"Overall Average\" column shows the averages of the raters' responses.</li>          \n  <li>Responses range from 1-Strongly Disagree to 5-Strongly Agree.</li>\n</ul>",
        "top_results_extra": "First, the scores from the first measurement time point (M1) are displayed and, if available, also those of the second measurement time point (M2).<br /><br />",
        "top_results_intro": "This section presents five highest-rated leadership behaviors and also the five lowest-rated leadership behaviors (rated by Self).<br /><br />",
        "top_results_top_rated": "rated leadership behaviors on average"
      },
      "report_create": "Create a report",
      "report_epilogue": "Report epilogue",
      "report_generated_at": "This report was generated at %{date}",
      "report_is_loading": "Loading generated report",
      "report_measure": "Measurement",
      "report_opened_at": "Last report opened at",
      "report_opened_by_client_at": "Client opened their report at %{date}",
      "report_preface": "Report preface",
      "report_sent_at": "Last report sent at",
      "reset_last_submission": "Reset last submitted questionnaire",
      "respect_for_diversity": "Respect for diversity",
      "respect_for_diversity_description": "I create an environment that fosters open communication within and across organizational boundaries and convert controversial or sensitive situations into opportunities",
      "respondents_overview": "Respondents overview",
      "result_score": "Result score",
      "role": {
        "board_member": "Board member",
        "colleague": "Peer",
        "customer": "Customer",
        "family": "Family",
        "friends": "Friends / acquaintances",
        "manager": "Manager",
        "staff_member": "Direct report",
        "supplier": "Supplier"
      },
      "score": "Score",
      "self": "Self",
      "send_coach_questionnaire": "Send %{next} coach questionnaire",
      "send_via_dialog": "Send %{next} questionnaire (Dialog)",
      "send_via_email": "Send %{next} questionnaire (E-mail)",
      "send_via_link": "Send %{next} questionnaire (Copy link)",
      "share_answer": {
        "private": "Share only with coach",
        "public": "Share with both coach and respondent(s)"
      },
      "shared_with": "Shared with",
      "status_description": "Below you find the details from the coaching progress as well as the client's respondents.",
      "step_show_client_name": "Show client name",
      "step_show_private_objectives": "Show coaching objectives which are only shared with the coach",
      "step_write_epilogue": "Write epilogue",
      "step_write_preface": "Write preface",
      "still_relevant": "Is this objective still relevant?",
      "submit_success": "An e-mail containing a link to the people analytics questionnaire has been sent.",
      "submit_too_many": "The e-mail with the questionnaire has already been sent. Please try again later.",
      "supervisory_leadership_assessment_title": "Supervisory Leadership Assessment Results",
      "supervisory_mindset_explanation": "This section presents the results of how you think people can change over time. The higher the score, the more a person believes that people can change (growth mindset). The lower the score, the more a person is convinced that people cannot change (fixed mindset).",
      "supervisory_top_highest_and_lowest_title": "Five highest and five lowest rated of Supervisory Skills",
      "taskoriented": "Task",
      "taskoriented_description": "The extent to which a client is satisfied about the coaching approach and method.",
      "teamwork": "Teamwork",
      "teamwork_description": "I make myself visible to staff by listening to them and by communicating at all levels within the Agency and with external counterparts.",
      "title": "People Analytics",
      "top_highest_and_lowest_title": "Five highest and five lowest rated leadership behaviors",
      "total_objectives": "Total coaching objectives",
      "view_results": "View results"
    },
    "people_analytics_mailer": {
      "client_finished": {
        "btn": "Go to UNLOQ Campus",
        "message": "has filled in the questionnaire for the program <b>%{program}</b>.",
        "subject": "Questionnaire has been filled in"
      },
      "peer_finished": {
        "btn": "Go to UNLOQ Campus",
        "message": "Respondent %{peer} has filled in the questionnaire for the program %{program}.",
        "subject": "Questionnaire has been filled in by respondent"
      },
      "remind_client": {
        "message": "kindly requests that you fill in your self-assessment questionnaire for the program <b>%{program}</b>. <br><br> <b>Attention:</b> the questionnaire is only available for a limited time, and this link will soon expire.",
        "subject": "Reminder: People Analytics questionnaire"
      },
      "remind_coach": {
        "message": "kindly requests that you fill in your self-assessment questionnaire for the program <b>%{program}</b> from your role as <b>coach</b>. <br><br> <b>Attention:</b> the questionnaire is only available for a limited time, and this link will soon expire.",
        "subject": "Reminder: People Analytics coach questionnaire"
      },
      "remind_peer": {
        "message": "kindly requests that you fill in your self-assessment questionnaire for the <b>%{program}</b> programme from the perspective of your role as <b>%{peertype}</b>. <br><br> <b>Attention:</b> the questionnaire is only available for a limited time, and this link will soon expire.",
        "subject": "Reminder: People Analytics respondent questionnaire"
      },
      "submit_client": {
        "btn": "Go to questionnaire",
        "explanation": "By clicking on the link, you will access UNLOQ Campus' tool called People Analytics and start the questionnaire, which will take approximately 10-15 minutes to fill in. After completing every questionnaire page you should click on the 'continue' button at the bottom.<br/> <br/> <b>Please be aware that:</b><br/> When filling in the questionnaire, you cannot click back once you've clicked on 'continue'.<br/> All the questions on each page need to be answered, otherwise, the 'continue' button doesn't work.<br/><br/>",
        "message": "kindly requests that you fill in your self-assessment questionnaire for the program <b>%{program}</b>.",
        "subject": "People Analytics questionnaire"
      },
      "submit_client_report": {
        "btn": "Go to your report",
        "message": "wants to share your report for the program <b>%{program}</b>.",
        "subject": "People Analytics report"
      },
      "submit_coach": {
        "btn": "Go to questionnaire",
        "message": "kindly requests that you fill in your self-assessment questionnaire for the program <b>%{program}</b> from your role as <b>coach</b>.",
        "subject": "People Analytics coach questionnaire"
      },
      "submit_peer": {
        "btn": "Go to questionnaire",
        "explanation": "By clicking on the link, you will access UNLOQ Campus' tool called People Analytics and start the questionnaire, which will take approximately 10-15 minutes to fill in. After completing every questionnaire page you should click on the 'continue' button at the bottom.<br/> <br/> <b>Please be aware that:</b><br/> When filling in the questionnaire, you cannot click back once you've clicked on 'continue'.<br/> All the questions on each page need to be answered, otherwise, the 'continue' button doesn't work.<br/><br/>",
        "message": "kindly requests that you complete your self-assessment questionnaire for the <b>%{program}</b> programme from the perspective of your role as <b>%{peertype}</b>.",
        "subject": "People Analytics respondent questionnaire"
      }
    },
    "profile": {
      "change_password": "Change password",
      "information": "Profile information",
      "people_analytics_emails": "People Analytics e-mails",
      "people_analytics_emails_explanation": "These e-mails are sent when for example a participant or respondent has submitted a questionnaire.",
      "two_factor_authentication": "Two Factor Authentication"
    },
    "program": {
      "days_after_month_closure": "Session logs closed (period in days that session logs can be modified after last day of month)",
      "minutes_per_client": "Maximum time available per client (in minutes)",
      "title": "Program"
    },
    "program_breaks": {
      "create": "Add program break"
    },
    "program_questionnaire_explanation": "Note: the questionnaire for People Analytics can only be configured once.",
    "program_sessions": {
      "activities": {
        "delivery": "Delivery",
        "design": "Design",
        "other": "Other",
        "preparation": "Preparation",
        "projectmanagement": "Project management",
        "reporting": "Reporting"
      },
      "activity": "Activity",
      "activity_not_enabled": ": not enabled. Choose a different category.",
      "activity_type": "Activity",
      "btn_new": "New group & training activity",
      "category_type": "Category",
      "charts_explanation": "Totals of program minutes",
      "choose_activity": "All activities",
      "description": "Below you can administer your hours related to any group or training activity for a specific program. Be alert, these hours are not individual coaching hours.",
      "hourly_rate": "Hourly rate",
      "minutes_exceeded": ": maximum exceeded",
      "notes": "Notes",
      "sum_duration": "Billable",
      "sum_duration_nonbillable": "Non-billable",
      "title": "Group & Training activities"
    },
    "programs": "Programs",
    "progress": "Progress",
    "progress_explanation": "Select a program to view its progress.<br/><br/> - Blue = number of program minutes used<br/> - Green 100% = all program minutes have been used<br/> - Red > 100% = more minutes were used than agreed<br/> <br/> Need extra minutes? Ask the Organisation Manager to add them.<br/>",
    "question": {
      "input_type": {
        "checkboxes": "Multiple choice, multiple answers (checkboxes)",
        "radio_buttons": "Multiple choice, 1 answer (radio buttons)",
        "rating": "Rating question (1-10)",
        "sortable": "Sortable question",
        "text_area": "Open answer (multiple lines of text)",
        "text_field": "Short answer (1 line of text)"
      }
    },
    "questionnaire": "Questionnaire",
    "ra": {
      "action": {
        "save": "Save"
      },
      "page": {
        "empty": "No %{name} found."
      }
    },
    "ransack": {
      "all": "all",
      "and": "and",
      "any": "any",
      "asc": "ascending",
      "attribute": "attribute",
      "combinator": "combinator",
      "condition": "condition",
      "desc": "descending",
      "or": "or",
      "predicate": "predicate",
      "predicates": {
        "blank": "is blank",
        "cont": "contains",
        "cont_all": "contains all",
        "cont_any": "contains any",
        "does_not_match": "doesn't match",
        "does_not_match_all": "doesn't match all",
        "does_not_match_any": "doesn't match any",
        "end": "ends with",
        "end_all": "ends with all",
        "end_any": "ends with any",
        "eq": "equals",
        "eq_all": "equals all",
        "eq_any": "equals any",
        "false": "is false",
        "gt": "greater than",
        "gt_all": "greater than all",
        "gt_any": "greater than any",
        "gteq": "greater than or equal to",
        "gteq_all": "greater than or equal to all",
        "gteq_any": "greater than or equal to any",
        "in": "in",
        "in_all": "in all",
        "in_any": "in any",
        "lt": "less than",
        "lt_all": "less than all",
        "lt_any": "less than any",
        "lteq": "less than or equal to",
        "lteq_all": "less than or equal to all",
        "lteq_any": "less than or equal to any",
        "matches": "matches",
        "matches_all": "matches all",
        "matches_any": "matches any",
        "not_cont": "doesn't contain",
        "not_cont_all": "doesn't contain all",
        "not_cont_any": "doesn't contain any",
        "not_end": "doesn't end with",
        "not_end_all": "doesn't end with all",
        "not_end_any": "doesn't end with any",
        "not_eq": "not equal to",
        "not_eq_all": "not equal to all",
        "not_eq_any": "not equal to any",
        "not_in": "not in",
        "not_in_all": "not in all",
        "not_in_any": "not in any",
        "not_null": "is not null",
        "not_start": "doesn't start with",
        "not_start_all": "doesn't start with all",
        "not_start_any": "doesn't start with any",
        "null": "is null",
        "present": "is present",
        "start": "starts with",
        "start_all": "starts with all",
        "start_any": "starts with any",
        "true": "is true"
      },
      "search": "search",
      "sort": "sort",
      "value": "value"
    },
    "rating": {
      "empty": "There are no reviews yet.",
      "empty_explanation_step1": "Enable the reviews per client on the Clients overview page:",
      "empty_explanation_step2": "Click on the star icon behind the client name to enable the review for a specific client.",
      "empty_explanation_step3": "The client is now able to write a review for you as a coach on the dialog page.",
      "placeholder": "Write review...",
      "private": "Private (share only with coach)",
      "public": "Public (visible to everyone)",
      "semi_private": "Semi-private (share with relevant stakeholders)",
      "shared_placeholder": "Choose sharing level...",
      "title": "Review"
    },
    "react": {
      "admin": {
        "add_to_associate_list": "Add to the associates list",
        "address": "Address",
        "addresses": "Business details",
        "affiliation": "Affiliation",
        "affiliations": "Affiliations",
        "are_you_sure_delete": "Are you sure you want to delete this %{referenceName}?",
        "associate_lists": {
          "add_coach_button": "Add coach",
          "add_coach_to_list_dialog_title": "Add coach to list",
          "add_to_list_failed": "Coach could not be added to the list",
          "add_to_list_success": "Coach was added to the list",
          "coach_count": "Coaches",
          "confirm_delete": "Delete this user from the list?",
          "create": "Create coach list",
          "edit": "Edit coach list",
          "intro": "Here you can create lists of coaches. These lists can be used in CoachDesks, the UNLOQ website and to quickly export all the CV's of a group of coaches.",
          "no_coaches_in_list": "There are no coaches in this list yet.",
          "title": "Coach lists"
        },
        "avatars": "Profile photo",
        "bio": {
          "new": "User has updated their bio"
        },
        "business_address": "Business Address",
        "business_details": "Business Details",
        "certification": "Certification",
        "certifications": "Certifications",
        "client_imports": {
          "fields": {
            "excel_file": {
              "helper_text": "Only Excel files with a .xslx extension are supported",
              "placeholder": "Drop your Excel import file here"
            }
          }
        },
        "coaching_preferences": "Coaching preferences",
        "curriculum_vitae": "Curriculum Vitae",
        "curriculum_vitae_intro": "The commercial CV differs from the default CV because it does not include NGO, non-profit or not-for-profit related activities. Advice: copy the default CV and then delete all activities that are not commercial.",
        "edit": "Edit",
        "education": "Education",
        "end_date": "End date",
        "files": {
          "preview_loading": "Preview loading"
        },
        "group_work": "Group Work",
        "help": "Help",
        "internal_only": "Internal use only, not publicly visible",
        "job_information": "Job Information",
        "language": "Language",
        "languages": "Languages",
        "name": "Name",
        "personal_information": "Personal information",
        "preview": "Preview",
        "profile_photo": "Profile Photo",
        "prompt": "Approve or reject",
        "publication": "Publication",
        "publications": "Publications",
        "reject": "Reject",
        "rematch": "Rematch",
        "rematch_client": "Rematch client",
        "remove": "Remove",
        "save": "Save",
        "short_bio": "Short bio",
        "unloq_only_contract_fields": {
          "body": "The fields below are managed by UNLOQ and are <b>NOT</b> visible and editable by the associates themselves. These fields are used in the associate selection procedure.",
          "title": "Fields managed by UNLOQ"
        },
        "untranslated": "Missing translations",
        "validation": {
          "date_of_birth": {
            "not_adult": "You must provide a date of birth that is at least 16 years old"
          },
          "end_date_after_start_date": "End date must be later than start date",
          "linkedin": {
            "invalid": "Must be a valid LinkedIn URL"
          },
          "start_date_invalid": "Start date is not valid",
          "start_date_required": "Start date is required"
        },
        "work_experience": "Work Experience"
      },
      "associate_lists": {
        "add_failed": "Failed to add to the associates list",
        "added": "Added to the associates list"
      },
      "clients": {
        "rematch_failed": "Client could not be rematched, please contact support",
        "rematch_success": "Client rematched successfully"
      },
      "coaches": {
        "address": {
          "account_holder_name": "Name account holder",
          "address_line": "Address",
          "bank_account_number": "Bank account number",
          "bank_identification": "Bank identification (BIC/SWIFT)",
          "bank_name": "Bank name",
          "city": "City",
          "company": "Company name",
          "country": "Country",
          "helper_texts": {
            "bank_account_number": "IBAN or other bank account number",
            "bank_identification": "Required if account number is not IBAN",
            "chamber_of_commerce": "Chamber of Commerce"
          },
          "house_number": "House number",
          "region": "Region",
          "zip_code": "Zip code"
        },
        "all": "All registered coaches",
        "associate_status": {
          "active": "Active",
          "label": "Status",
          "none": "No status",
          "offboard": "Offboard",
          "on_hold": "On hold"
        },
        "avatars": {
          "approved": "Your new profile photo has been approved",
          "avatar": {
            "label": "Avatar",
            "no_avatar": "There is no avatar",
            "upload": "Upload new avatar"
          },
          "not_approved_yet": "Your new profile photo hasn't been approved yet. We will review it as soon as possible",
          "photo": {
            "label": "Profile photo",
            "no_photo": "There is no profile photo"
          },
          "preview": "Preview",
          "rejected": "Your new profile photo has been rejected, please upload another",
          "upload": "Upload new profile photo",
          "uploaded": "New photo was uploaded, we will review it as soon as possible"
        },
        "bio": {
          "approved": "Your new bio has been approved",
          "not_approved_yet": "Your new bio hasn't been approved yet",
          "rejected": "Your new bio has been rejected, please write another",
          "updated": "Your bio has been updated and is awaiting approval"
        },
        "both": "Both",
        "cards_view": "The cards view",
        "chamber_of_commerce": "Business register number",
        "coach_type": "Coach type",
        "coach_types": {
          "a": "Senior Coach & Trainer",
          "t": "Senior Trainer & Facilitator"
        },
        "coaching_level": "Level coaching",
        "coaching_levels": {
          "executive": "Executive",
          "leadership_coaching": "Leadership coaching",
          "top_executive": "Top executive",
          "young_talent_professional": "Young talent professional"
        },
        "commercial_cv": "Commercial CV",
        "contract_remarks": "Remarks",
        "contract_type": "Contract type",
        "contracts": {
          "core_team": "Core team member",
          "fixed_hours": "Associate with fixed hours",
          "project_basis": "Associate on project basis"
        },
        "core_team": "Core team",
        "cv": {
          "affiliations": "Affiliations",
          "approach": "Approach",
          "branch_experiences": "Branch experiences",
          "certifications": "Certifications",
          "confirm_copy_cv": "Are you sure you want to copy all information from your default CV?<br /><br />Please note <b>YOU CAN ONLY DO THIS ONCE</b>, after which the button will disappear!",
          "contract_type": "Contract type",
          "copy_default_cv": "Copy your Default CV?",
          "copy_from_default_button": "Copy from default CV",
          "education": "Education",
          "examples": {
            "affiliation": {
              "acronym": "Example: EMCC",
              "name": "Example: EMCC Global",
              "number": "Example: 100"
            },
            "certification": {
              "end_date": "Example: Date until certification was valid (empty = indefinitely)",
              "institution": "Example: International Coaching Federation",
              "name": "Example: ICF Professional Certified Coach",
              "start_year": "Example: Year from which the certification holds",
              "topic": "Example: Coaching Course"
            },
            "education": {
              "end_date": "Example: Date until education was valid (empty = indefinitely)",
              "field_of_study": "Example: Clinical Psychology",
              "school": "Example: Harvard",
              "specialisation": "Example: Group dynamics",
              "start_year": "Example: Year from which the education holds"
            },
            "publication": {
              "authors": "Example: Doe, J. /  Doe. J. & Doedel, K.P.",
              "journal_title": "Example: Journal of Awareness",
              "medium": "Example: Television",
              "page_range": "Example: 201-220",
              "publisher": "Example: Routledge",
              "publisher_city": "Example: New York",
              "title": "Example: Self-recognition in everyday life",
              "url": "Example: https://www.cbs.com/shows/the-late-show-with-stephen-colbert/",
              "various_title": "Example: Guest at The Late Show with Stephen Colbert",
              "volume_number": "Example: 5(2)",
              "year": "Example: (2022)"
            },
            "publications": {
              "type": "Choose the type of the publication"
            }
          },
          "fields": {
            "affiliation": {
              "acronym": "Acronym",
              "add": "Add another affiliation",
              "name": "Name",
              "number": "Affiliated Number"
            },
            "approach": "Short bio",
            "approach_current": "Current bio",
            "approach_helper_text": "Please write your job bio in English",
            "certification": {
              "add": "Add another certification",
              "end_date": "End date",
              "institution": "Issuing organization",
              "level": "Level",
              "name": "Certificate name",
              "start_year": "Start year",
              "topic": "Topic / Theme"
            },
            "certification_dropzone": "Drop your diploma / certification / testimonial files here",
            "certification_dropzone_helpertext": "Only PDF, PNG or JPG files can be uploaded",
            "education": {
              "add": "Add another education",
              "degree": "Degree",
              "end_date": "End date",
              "field_of_study": "Field of study",
              "school": "School",
              "specialisation": "Specialisation",
              "start_year": "Start year"
            },
            "job_title": "Current job title",
            "job_title_helper_text": "Please write your job title in English",
            "occupations": "Occupations",
            "publication": {
              "add": "Add another publication",
              "article": "Article",
              "authors": "Author(s)",
              "book": "Book",
              "choose": "Choose publication type",
              "journal_title": "Journal name",
              "medium": "Medium",
              "page_range": "Page range",
              "publisher": "Publisher",
              "publisher_city": "Publisher's branch City",
              "title": "Title",
              "title_article": "Article title",
              "title_book": "Book title",
              "url": "Internet link",
              "various": "Various",
              "volume_number": "Volume nr, (Issue nr)",
              "year": "Year"
            },
            "publication_type": "Publication type",
            "specializations": "Specializations",
            "work_experience": {
              "add": "Add another work experience",
              "branch_experience": "Branch experiences",
              "company_name": "Company name",
              "description": "Work description",
              "end_date": "End date (empty = indefinitely)",
              "role": "Role / title",
              "start_date": "Start date"
            }
          },
          "headers": {
            "certifications": "Educations / Certifications"
          },
          "help": {
            "avatars": {
              "help": "Show examples of good profile photos"
            }
          },
          "intro": {
            "affiliations": "List all professional associations you are affiliated to.",
            "certifications": "List here all your Licenses & Certificates for methods, approaches, assessments, tests and other matters related to learning, development and guidance activities. These should be your (professionally oriented) education, training, courses and workshops with an official certificate / licence / hallmark.",
            "contract_type": "This section is for internal use and is maintained by a Programme Manager. You cannot make adjustments yourself here.",
            "education": "List here all your educations and training courses that you have followed (including those you have not completed). These should be your officially government-recognised Educations/Qualifications/Degrees.",
            "publications": "List all publications you have written or contributed to. Please note, for uniform display we use the international APA rules. For each field we provide an example, use these to correctly fill in each field.",
            "work_experience": "List your relevant work experience here, not only related to coaching & training but all your work experience."
          },
          "job_experience": "Job & work experience",
          "job_information": "Job information",
          "job_title": "Job title",
          "level_coaching": "Coaching level",
          "level_ecoaching": "E-coaching level",
          "professions": "Occupations",
          "publications": "Publications",
          "specializations": "Specializations",
          "work_experiences": "Work experiences"
        },
        "date_of_birth": "Date of birth",
        "default_cv": "Default CV",
        "ecoaching_level": "Level ecoaching",
        "ecoaching_levels": {
          "level_i": "Level I",
          "level_ii": "Level II",
          "level_iii": "Level III",
          "none": "None"
        },
        "first_name": "First name",
        "group_work": {
          "add": "Add another group work experience",
          "select": "Select type of group work"
        },
        "group_work_location": {
          "label": "Online / Offline"
        },
        "group_work_locations": {
          "both": "Both",
          "offline": "Offline",
          "online": "Online"
        },
        "group_work_type": {
          "label": "Design / Delivery"
        },
        "group_work_types": {
          "both": "Both",
          "delivery": "Delivery",
          "design": "Design"
        },
        "id_number": "ID number (passport of other ID)",
        "intro": {
          "addresses": "Please provide your business details below. Please note that only if you do <b>not</b> have an IBAN bank account number is it mandatory to fill in the 'BIC/SWIFT' field as well.",
          "avatars": "Please upload your profile photo. We are looking for powerful portraits where the people take centre stage. A clean look & feel without losing the warmth of the human aspect. The photos are also characterised by depth of field and the use of natural light.<br /><br />Please keep in mind we are looking for clean photos with not much bright colours or distracting objects in the background.<br /><br />Think of your LinkedIn picture, but a more relaxed version. Your picture should showcase who you are without forgetting this is a business environment. Ask yourself: which picture would you post on your LinkedIn vs. what would you post while on holidays?<br /><br />Try avoiding: Shorts, Sleeveless or flashy tops, Sandals<br /><br />The photo that you upload here will be displayed on the ”Our Team” page on the UNLOQ website and (if applicable) on the website pages of client CoachDesks.<br /><br /> <b>Note:</b> once you upload, the photo will need to be approved by UNLOQ before it becomes visible on the UNLOQ website and CoachDesks and as avatar within Campus. As soon as approval is complete this photo will appear in your profile.",
          "bio": "Please write a short bio about yourself. This bio will be added to your CV and will also be visible on the <a href=\"https://unloq.org/our-team/\" target=\"_blank\">Our Team</a> page on the UNLOQ website and (if applicable) on the CoachDesks.<br /><br />The following requirements are set:<ul><li>Min 400 characters</li><li>Max 800 characters</li><li>Written in third person</li><li>Written in English</li></ul><i>EXAMPLE: Marcel has 15 years of coaching experience in leadership development and transition processes. He is interested in topics related to diversity, equity and inclusion and his practical approach is based on Eastern and Western psychology. Clients and peers say he is open-minded and flexible, gets to the point quickly and has a keen sense of what is needed.</i><br /><br /> <b>Note:</b> once you update your bio, it will need to be approved by UNLOQ before it becomes visible on the UNLOQ website and CoachDesks. As soon as approval is complete this bio will appear in our profile.",
          "bio_no_cv_yet": "Please provide information about your current job first before writing up your short bio.",
          "group_work": "Please list your experience and preference with different kinds of group work here. You can specify for each type of group work if you prefer delivering offline, online or both and if you have experience in designing or delivering or both.",
          "job_information": "Please provide information about your current job",
          "languages": "Please add all the languages that you speak along with the proficiency level.",
          "personal_information": "In this section of your profile you can enter all your basic personal information. Please note that some of this information will be visible on the <a href=\"https://unloq.org/our-team/\" target=\"_blank\">\"Our Team\"</a> page on the UNLOQ website and on the CoachDesks. The fields marked as \"internal use only\" will not be publicly visible."
        },
        "languages": {
          "add": "Add another language",
          "select": "Select language",
          "select_level": "Select language level"
        },
        "last_name": "Last name",
        "linkedin": "LinkedIn",
        "list_view": "list view",
        "multiple_answers_possible": "Multiple answers possible",
        "nationalities": "Nationalities",
        "native_background": "Native background / roots",
        "organization_name": "Organization name",
        "phone_number": "Phone number",
        "place_of_birth": "Place of Birth",
        "profile": "Profile",
        "residence_country": "Residence country",
        "search_in": "Search in",
        "secondary_phone_number": "Secondary phone number",
        "select_one_answer": "Select one answer",
        "timezone": "Timezone",
        "training_level": "Level facilitation",
        "training_levels": {
          "level_i": "Level I",
          "level_ii": "Level II",
          "level_iii": "Level III",
          "none": "None"
        },
        "unloq": "UNLOQ associates",
        "unloq_only": "UNLOQ associates only?"
      },
      "filters": {
        "branch_experiences": "Branch experiences",
        "countries": "Countries",
        "job_title": "Job title",
        "languages": "Languages",
        "miscellaneous": "Miscellaneous",
        "name_or_email": "Name or email",
        "organization": "Organization",
        "professions": "Occupations",
        "programs": "Programs",
        "remarks": "Remarks",
        "specializations": "Specializations",
        "timezones": "Timezones"
      },
      "select": {
        "associate_list": "Select associate list",
        "coach": "Select coach",
        "program": "Select programme"
      },
      "tours": {
        "coach_edit": {
          "step_coach": {
            "text_1": "Welcome to the profile edit page!",
            "text_2": "In this tab your main information should be filled in.",
            "text_3": "The information you enter here will be used to match you to a client and to generate your curriculum vitae."
          },
          "step_commercial_cv": {
            "text_1": "The <b>%{commercial_cv}</b> tab is similar to the Default CV tab except that the information you enter here is meant for commercial customers specifically."
          },
          "step_copy": {
            "text_1": "You can use the <b>%{button_name}</b> button if no commercial CV has been created yet.",
            "text_2": "The button copies all the information from your default CV after which you can change or remove certain parts."
          },
          "step_default_cv": {
            "text_1": "In this tab your default curriculum vitae is composed.",
            "text_2": "Please fill in this data as much as possible, so UNLOQ will be able to better match your abilities to our clients.",
            "text_3": "We also use this data to automatically generate CV documents.",
            "text_4": "The generated CV documents will only be shared internally or with our customers."
          },
          "step_help": {
            "text_1": "This button opens up this help tour again. So, if you would like some explanations later on, please click on this button."
          }
        }
      }
    },
    "recaptcha": {
      "errors": {
        "recaptcha_unreachable": "Oops, we failed to validate your reCAPTCHA response. Please try again.",
        "verification_failed": "reCAPTCHA verification failed, please try again."
      }
    },
    "register": {
      "create": {
        "flash_email_invalid": "Single sign-on login failed because no valid email was found. Please try the normal login instead.",
        "flash_error": "Single sign-on login failed, please try the normal login instead.",
        "flash_success": "Your account has been created"
      },
      "new": {
        "btn": "Create my account",
        "description": "You're only a minute away from making a positive change in your life.",
        "edit_name": "Edit name",
        "flash_error": "Your account could not be created. ",
        "flash_success": "Please confirm your email address to continue",
        "title": "Sign up for your UNLOQ Campus account."
      }
    },
    "reply": "Reply",
    "results": "Results",
    "reviews": "Reviews",
    "role": "Role",
    "roles": {
      "admin": "Admin",
      "client": "Client",
      "coach": "Coach",
      "organization_manager": "Organization Manager"
    },
    "search": {
      "clear": "clear search",
      "placeholder": "Search..."
    },
    "search_client": "Search client",
    "search_coach": "Search coach",
    "search_respondent": "Search respondent",
    "select_client": "Select client",
    "select_coach": "Select coach",
    "select_language": "Select language",
    "select_language_level": "Select proficiency",
    "select_program": "Select program",
    "select_questionnaire": "Select questionnaire",
    "select_respondent": "Select respondent",
    "send_pdf_reports": "Send PDF Reports",
    "send_pdf_reports_failed": "Could not send PDF reports, not all participants have completed the questionnaires",
    "send_pdf_reports_success": "PDF reports sent successfully",
    "sessions": {
      "create": {
        "captcha_invalid": "Captcha invalid: please make sure you are human and not a robot.",
        "invalid": "Invalid email or password. Please try again."
      },
      "verify_2fa_code": {
        "btn": "Verify",
        "label": "Enter Two Factor Authentication code"
      }
    },
    "settings": "Settings",
    "shared_by": "Shared by: ",
    "shared_mailer": {
      "dear": "Dear",
      "note": "Note: this confidential message was automatically generated.",
      "regards": "Kind regards,",
      "sender": "The UNLOQ Campus team"
    },
    "show_all": "show all",
    "sidemenu": {
      "clients": "Clients",
      "dialogs": "Dialogs",
      "language": "Language",
      "library": "Toolbox",
      "privacy": "Privacy Policy",
      "privacy_policy": "Privacy Policy",
      "privacy_regulations": "Privacy Regulations",
      "support": "Support",
      "terms": "Terms & Conditions",
      "user_terms": "User Terms & Conditions"
    },
    "signup": {
      "confirm_email": {
        "description": "Please check your inbox for a confirmation email. Click the link in the email to confirm your e-mail address.<br><br>If you didn't receive an e-mail, please check your spam-folder.",
        "flash_success": "Your account is activated.",
        "title": "Confirm your e-mail address"
      },
      "new": {
        "already_account": "Already have an account?",
        "btn": "Sign up now",
        "description": "Sign up to use UNLOQ Campus for more engaging, more impactful and secure coaching conversations.",
        "email_already_exists": "E-mail address already exists.",
        "organization_description": "This registration form is specifically meant for %{name}. You can only register using a @%{domain} e-mail address.",
        "organization_domain_error": "E-mail is invalid: the domain from the e-mail address should be part of the organization / institution.",
        "title": "Level up your coaching."
      }
    },
    "simple_form": {
      "error_notification": {
        "default_message": "Please review the problems below:"
      },
      "no": "No",
      "required": {
        "mark": "*",
        "text": "Required"
      },
      "yes": "Yes"
    },
    "spinner": {
      "title": "Please wait..."
    },
    "state": "State",
    "status": "Status",
    "support": {
      "array": {
        "last_word_connector": ", and ",
        "two_words_connector": " and ",
        "words_connector": ", "
      },
      "check_manual": "Welcome to the support page. In the manual you will find a lot of extra background information, click here: ",
      "help_text": "If your question is not answered there, please send me an e-mail via this address ",
      "help_text2": "or call me during office hours on",
      "help_text3": "I will be happy to help you!",
      "manual": "Manual",
      "title": "Support"
    },
    "time": {
      "am": "am",
      "formats": {
        "default": "%a, %d %b %Y %H:%M:%S %z",
        "dialogue": "%a %e %b %Y %H:%M %Z",
        "long": "%e %b %Y at %H:%M",
        "no_timezone": "%a, %d %b %Y %H:%M:%S",
        "notime": "%e %b %Y",
        "short": "%d %b %H:%M",
        "trestle_time": "%-l:%M %p",
        "trestle_time_with_seconds": "%l:%M:%S %p"
      },
      "pm": "pm"
    },
    "timesheets": {
      "clients_title": "Client sessions",
      "description": "Below you can create overviews of the total number of registered hours per program, client and time period. Adding or modifying hours can only via a client session log.",
      "export": {
        "all_figures": "All figures",
        "confirm": "You are downloading confidential information, are you sure?",
        "details": "Session details",
        "key_figures": "Key figures"
      },
      "title": "Timesheet"
    },
    "title_date": "Date",
    "toastrs": {
      "copied": "Copied!",
      "sent": "Sent!"
    },
    "total": "Total",
    "total_clients": "Total number of clients",
    "total_costs": "Total costs",
    "trestle": {
      "confirmation": {
        "cancel": "Cancel",
        "delete": "Delete",
        "title": "Are you sure?"
      },
      "dialog": {
        "error": "The request could not be completed."
      },
      "file": {
        "browse": "Browse",
        "choose_file": "Choose file..."
      },
      "footer": "Powered by Trestle",
      "helpers": {
        "page_entries_info": {
          "more_pages": {
            "display_entries": "Displaying %{entry_name} <strong>%{first}&nbsp;-&nbsp;%{last}</strong> of <b>%{total}</b>"
          },
          "one_page": {
            "display_entries": {
              "one": "Displaying <strong>1</strong> %{entry_name}",
              "other": "Displaying <strong>all %{count}</strong> %{entry_name}",
              "zero": "No %{entry_name} found"
            }
          }
        }
      },
      "onboarding": {
        "no_admins": "To begin, please create an admin within <code>app/admin</code>.",
        "no_form": "Please define a form block or create a <code>_form.html</code> partial.",
        "no_template": "To customize this template, please create <code>%{path}</code>.",
        "welcome": "Welcome to Trestle"
      },
      "title": "Trestle Admin",
      "ui": {
        "toggle_dropdown": "Toggle dropdown",
        "toggle_navigation": "Toggle navigation",
        "toggle_sidebar": "Toggle sidebar"
      },
      "version": "Version"
    },
    "true": "Yes",
    "user_mailer": {
      "email_confirmation": {
        "btn": "Confirm your e-mail",
        "message": "Before we can activate your new account, we need to confirm your e-mail address. Please click the link below to confirm your e-mail and continue at UNLOQ Campus.",
        "subject": "UNLOQ Campus: confirm your e-mail address",
        "thanks": "Thanks for joining UNLOQ Campus!"
      }
    },
    "users": {
      "active_explanation": "Not ticked = inactive = archived on clients overview page (for the coach).",
      "already_account": "already has an account! We'll send them an invitation to join your conversation.",
      "coach_for_client": "Coach for this client",
      "copy_to_clipboard_tooltip": "Copy invitation link and send this link to the client with <br/>an external e-mail application (do not use this link yourself).",
      "edit_user": "Edit user",
      "empty": "No clients found.",
      "enable_rating": "This enables this client to rate you<br/> and write an accompanying testimonial text.",
      "organizations_label": "Organization manager(s) for:",
      "programs_label": "Program(s): User account can be assigned one or more programs and select a role per program.",
      "remove_membership": "Remove membership",
      "remove_program": "Remove program",
      "resend_confirm": "Are you sure you want to (re)send registration or confirmation e-mail?",
      "resend_tooltip": "Resends registration or <br/>confirmation e-mail to this user.",
      "roles_empty": "User account has to be assigned one or more programs."
    },
    "views": {
      "pagination": {
        "first": "First",
        "last": "Last",
        "next": "Next",
        "previous": "Previous",
        "truncate": "..."
      }
    },
    "your_coach": "Your coach is:"
  },
  "es": {
    "account_only": "Sólo cuenta",
    "accounts": {
      "app_verify_label": "Introduzca el código de seis dígitos del",
      "curriculum_vitae": {
        "approach_required": "Aproximación son necesarios para el idioma Inglés",
        "approach_too_long": "Su biografía es demasiado larga, el máximo son 800 caracteres",
        "approach_too_short": "Su biografía es demasiado corta, el mínimo son 400 caracteres",
        "copy_from_default_success": "El CV por defecto se ha copiado correctamente en el CV comercial",
        "job_title_and_approach_required": "El título del puesto y el Enfoque se exigen en inglés",
        "job_title_required": "Indique su cargo actual"
      },
      "disable": "Desactivar",
      "disable_title": "A continuación puede desactivar este método de autenticación de 2 factores o cambiar al método SMS.",
      "edit": {
        "flash_success": "Su perfil se ha actualizado correctamente",
        "language_label": "El idioma predeterminado de esta cuenta para los correos electrónicos y el idioma preferido para la presentación del sitio:"
      },
      "edit_2fa": {
        "authenticator_title": "Escanee este código QR con su aplicación de autenticación:",
        "choose_below": "Elija a continuación el método que desea utilizar. Siempre puede cambiar de método más adelante.",
        "description": "Hay dos formas de utilizar la autenticación de 2 factores:",
        "no_app_description": "Google Authenticator es una aplicación 2FA gratuita. Descárguela en su dispositivo móvil buscando \"Google Authenticator\" en Play Store o App Store.",
        "no_app_title": "¿No tiene la aplicación?",
        "option1": "a través de una aplicación de autenticación",
        "option2": "por SMS",
        "phone_number_label": "Introduzca el número de teléfono:",
        "sms_description": "Con este método de autenticación de 2 factores (2FA) recibirá automáticamente un nuevo código SMS en su teléfono móvil cada vez que inicie sesión. Introduzca su número de móvil a continuación para activar este método (nota: añada '+' seguido del código de su país y quita el primer 0 de su número de teléfono).",
        "sms_explanation": "Haga clic en el botón de abajo para recibir un código SMS en su teléfono móvil con el fin de desactivar 2FA.",
        "sms_flash_error": "El número de teléfono no es válido o no se ha podido enviar el código.",
        "sms_flash_success": "Su ficha ha sido enviada a su número de teléfono.",
        "sms_send_btn": "Compruebe el número de teléfono",
        "sms_verify_btn": "Verifique el código",
        "sms_warning": "<b>Importante:</b> dependiendo del país en el que reciba los mensajes, la normativa local puede restringir el envío de mensajes SMS del Campus para evitar el spam. Por lo tanto, recomendamos habilitar 2FA usando Google Authenticator en su lugar.",
        "title": "la autenticación de 2 factores añade seguridad adicional a su cuenta. Además de su contraseña personal, también necesita un segundo método de seguridad para iniciar sesión."
      },
      "edit_password": {
        "at_least": "Por lo menos",
        "capital": "letra mayúscula",
        "characters": "carácteres",
        "description": "Introduzca la contraseña de usuario actual y dos veces la nueva contraseña para cambiar la contraseña actual.",
        "letter": "letra",
        "new_password": "Contraseña",
        "not_matching": "Las contraseñas coinciden",
        "number": "número",
        "old_password": "Contraseña antigua",
        "special": "carácter especial",
        "strength_title": "La(s) contraseña(s) debe(n) cumplir los siguientes requisitos:",
        "title": "Cambiar la contraseña"
      },
      "enable": "Activar",
      "flash_2fa_disabled": "Se ha desactivado la autenticación de dos factores (2FA).",
      "flash_2fa_enabled": "Se ha activado la autenticación de dos factores (2FA).",
      "flash_2fa_error": "El código de autenticación de dos factores era incorrecto. Por favor, inténtelo de nuevo.",
      "show_phone_number_verification": {
        "label": "Código:",
        "resend_link": "nuevo código SMS",
        "resend_title": "Campus UNLOQ acaba de enviarle un SMS con un código a su móvil, introdúzcalo aquí para validarlo. ¿No ha recibido el código en 2 minutos? Entonces solicite un",
        "title": "Verifique el código SMS"
      },
      "success_2fa": {
        "description": {
          "disabled": "Ha desactivado correctamente la autenticación en dos pasos para tu cuenta. Haga clic en el botón \"Finalizar\" para terminar o en \"2FA\" para volver al menú de verificación en dos pasos (2FA).",
          "enabled": "Ha activado correctamente la autenticación de doble factor para su cuenta. Haga clic en el botón de abajo para continuar."
        }
      },
      "update_password": {
        "flash_error": "Su contraseña actual no es válida.",
        "flash_success": "Su contraseña se ha actualizado correctamente"
      }
    },
    "actions": "Acciones",
    "admin": {
      "breadcrumbs": {
        "home": "Inicio"
      },
      "buttons": {
        "delete": "Borrar %{model_name}",
        "new": "Nuevo %{model_name}",
        "ok": "Aceptar",
        "save": "Guardar %{model_name}"
      },
      "datepicker": {
        "formats": {
          "date": "m/d/Y",
          "datetime": "m/d/Y h:i K",
          "time": "h:i K"
        }
      },
      "flash": {
        "create": {
          "failure": {
            "message": "Por favor corrige los siguientes errores.",
            "title": "¡Advertencia!"
          },
          "success": {
            "message": "El %{lowercase_model_name} fue creado exitosamente.",
            "title": "¡Exito!"
          }
        },
        "destroy": {
          "failure": {
            "message": "%{lowercase_model_name} no pudo ser borrado.",
            "title": "¡Advertencia!"
          },
          "success": {
            "message": "El %{lowercase_model_name} fue borrado exitosamente.",
            "title": "¡Exito!"
          }
        },
        "update": {
          "failure": {
            "message": "Por favor corrige los siguientes errores.",
            "title": "¡Advertencia!"
          },
          "success": {
            "message": "El %{lowercase_model_name} se actualizo exitosamente.",
            "title": "¡Exito!"
          }
        }
      },
      "form": {
        "select": {
          "prompt": "- Selecciona %{attribute_name} -"
        }
      },
      "format": {
        "blank": "Ninguno"
      },
      "search": {
        "placeholder": "Buscar",
        "results": "Resultados de la búsqueda"
      },
      "table": {
        "headers": {
          "id": "ID"
        }
      },
      "titles": {
        "edit": "Editando %{model_name}",
        "index": "Listado de %{pluralized_model_name}",
        "new": "Nuevo %{model_name}"
      }
    },
    "are_you_sure": "¿Está seguro/a?",
    "assignments": {
      "edit": {
        "add_question": "Añadir una pregunta",
        "title": "Editar el ejercicio"
      },
      "new": {
        "description": "Cree aquí un nuevo ejercicio, reflexión u otra actividad de aprendizaje. La creación de una tarea consta de dos pasos distintos:",
        "step1": "Asigne un nombre claro al ejercicio (*obligatorio), escriba el texto de introducción, la explicación o el objetivo del ejercicio y seleccione el botón \"Guardar\".",
        "step2": "Añada una o varias preguntas para que el cliente responda y seleccione el botón \"Finalizar\".",
        "title": "Añadir un ejercicio"
      },
      "questions": {
        "hint": "Esta es la pregunta que se muestra al cliente.",
        "prompt": "Añada una pregunta para que la responda el cliente.",
        "title": "Añadir / editar una pregunta",
        "type": "Tipo de pregunta"
      },
      "submission": "enviar",
      "title": "Tarea/ Ejercicio",
      "waiting_for": "A la espera de"
    },
    "auth_mailer": {
      "password_reset": {
        "message": "Hemos recibido una solicitud para restablecer tu contraseña de UNLOQ Campus. ¿Has sido tú? Genial, haz clic en el siguiente enlace y podrás establecer una nueva contraseña para tu cuenta. Este enlace funcionará durante un par de horas.",
        "no_email": "¿No solicitaste una nueva contraseña? No te preocupes, ignora este correo y nada cambiará. No dudes en ponerte en contacto con nosotros si tienes alguna pregunta.",
        "subject": "Restablecer contraseña"
      }
    },
    "avatars": {
      "new": {
        "btn_later": "Hágalo más tarde",
        "flash_success": "Su perfil está configurado y listo.",
        "title": "Personalice su cuenta mostrando una foto de perfil."
      }
    },
    "btn": {
      "back": "Volver",
      "cancel": "Cancelar",
      "change": "Cambiar",
      "finalize": "Finalizar",
      "finish": "Finalizar",
      "ok": "OK",
      "save": "Guardar",
      "select_file": "Seleccionar un archivo",
      "select_logo": "Seleccionar un logotipo",
      "send": "Enviar"
    },
    "by": "por",
    "client": {
      "bank_account_number": "Número de cuenta bancaria",
      "chamber_of_commerce": "Cámara de comercio",
      "client_details": "Detalles de inscripción en PDF",
      "client_details_error": "Debe ser un archivo PDF",
      "country": "País",
      "dialogs": "Diálogos",
      "dossier": "Registros de sesión de cliente",
      "edit": {
        "title": "Configuración del cliente"
      },
      "gender": "Género",
      "job_title": "Puesto",
      "location": "Ciudad",
      "miscellaneous": "Varios",
      "office_location": "Ubicación de la oficina",
      "phone_number": "Número de teléfono",
      "remarks": "Comentarios",
      "show": {
        "title": "Datos del cliente"
      },
      "time_zone": "Huso horario",
      "title": "Cliente",
      "vat_number": "Número de IVA",
      "zip_code": "Código postal"
    },
    "client_mailer": {
      "existing_user_invitation": {
        "and_program": "y el programa",
        "btn": "Aceptar invitación",
        "message": "Haga clic aquí para afiliarse",
        "subject": "te invitó a un programa en el Campus UNLOQ"
      },
      "new_user_invitation": {
        "and_program": "y el programa",
        "btn": "Aceptar invitación",
        "message": "Haga clic aquí para afiliarse",
        "subject": "te invitó a un programa en el Campus UNLOQ"
      },
      "rating_enabled": {
        "btn": "campus UNLOQ abierto",
        "footer": "Nota:<br/> - Se trata de una solicitud, no de una obligación.<br/> - Puede modificar una revisión retrospectivamente a voluntad.<br/> - Puede especificar quién está autorizado a ver la revisión.<br/>",
        "intro": "me gustaría hacerle una pregunta. ¿Sería tan amable de escribir una reseña sobre su experiencia con el coaching?",
        "message": "Hay una función especial para esto dentro de UNLOQ Campus que ahora está disponible. <br/>Haz clic aquí para abrir el diálogo dentro de UNLOQ Campus y luego indica cuántas estrellas te gustaría dar a tu entrenador además de escribir una reseña o recomendación.",
        "subject": "le gustaría que revisara"
      },
      "remind_user_invitation": {
        "and_program": "y el programa",
        "btn": "Aceptar invitación",
        "message": "Haga clic aquí para afiliarse",
        "reminder": "Recordatorio: ",
        "subject": "volver a invitarte a un programa en el Campus UNLOQ"
      }
    },
    "client_sessions": {
      "all_programs_ended": {
        "body": "Todos los programas en los que se encuentra este cliente han finalizado, no se pueden añadir más sesiones. Si tiene alguna duda al respecto, póngase en contacto con el responsable del programa.",
        "title": "Todos los programas han finalizado"
      },
      "available": "Disponible",
      "btn_new": "Añadir una sesión",
      "client": "Cliente",
      "client_archived": {
        "archived_at": "Este cliente se archivó en %{datetime}",
        "body": "Este cliente ha sido archivado, no se pueden añadir más sesiones. Si tiene alguna pregunta al respecto, póngase en contacto con su responsable de programa.",
        "title": "Cliente archivado"
      },
      "date_exceeded": "o el contenido del registro de sesiones no está disponible. Una vez transcurrido un mes, dispone de %{days} días para crear, editar o borrar el registro de sesiones. Este plazo ya ha expirado.",
      "date_not_in_future": "no puede estar/ser en el futuro",
      "duration": "Facturable",
      "duration_nonbillable": "No facturable",
      "file": "Adjunto",
      "file_explanation": "Nota: Sólo se permite 1 archivo por sesión. Si este registro de sesión ya contiene un archivo adjunto, se anulará al cargar uno nuevo.",
      "from_date": "De la fecha",
      "heading": "Registros de sesión de cliente de:",
      "in_minutes": "En minutos",
      "new_client_session": "Nuevo registro de sesión de cliente para:",
      "new_client_session_description": "Los datos que figuran a continuación no son visibles para el cliente. El nombre del programa, el tipo, la fecha y la duración están a disposición del responsable de la organización con fines administrativos. La información relacionada con el contenido (confidencial) es sólo para su vista.",
      "notes": "Notas de la sesión",
      "number_of": "Sesiones",
      "other": "Otros / varios",
      "remaining": "Restante",
      "session": "Sesión",
      "sessions": "sesión(es)",
      "title": "Título",
      "to_date": "Hasta la fecha",
      "tooltip_filter_first": "Filtre por programa o coach <br/> antes de poder exportar",
      "type": "Escriba",
      "type__360_assessments": "Evaluaciones 360",
      "type_administration": "Administración",
      "type_call": "Llamar/ llamado",
      "type_campus": "Campus UNLOQ",
      "type_cancellation_legit": "Anulación (legítima)",
      "type_cancellation_not_legit": "Cancelación (no legal)",
      "type_face2face": "Cara a cara",
      "type_group_coaching": "Coaching de grupo",
      "type_noshow": "Cancelación (no presentación)",
      "type_other": "Otro",
      "type_rescheduled": "Reprogramado/a",
      "type_sms": "SMS",
      "type_technical_difficulties": "Dificultades técnicas",
      "type_videocall": "Videollamada",
      "used": "Usado/a"
    },
    "client_signup": {
      "new": {
        "explanation": "Este formulario de inscripción está destinado específicamente a %{name}. Es necesario registrarse para recibir correos electrónicos con enlaces a nuestra evaluación 360 (es decir, el cuestionario). Además, podrá añadir encuestados."
      },
      "new_signup": {
        "btn": "Añadir encuestados",
        "message": "Haga clic aquí para añadir encuestados",
        "subject": "Se ha registrado correctamente para"
      }
    },
    "clients": {
      "archive": "Archivo",
      "minutes_per_client": "# Mín. por cliente",
      "reactivate": "Reactivar",
      "tab_active": "Activo/a",
      "tab_archive": "Archivo",
      "title": "Clientes",
      "toggle_active": {
        "flash_success": "El cliente fue exitosamente"
      },
      "tooltip_active_and_registered": "Mostrar diálogo (el cliente está totalmente registrado)",
      "tooltip_minutes": "Número de minutos facturables registrados para este cliente",
      "tooltip_not_active": "Mostrar diálogo (el/la cliente debe recibir un correo electrónico de invitación)",
      "tooltip_not_registered": "Mostrar diálogo (el/la cliente aún no está registrado)"
    },
    "coach_mailer": {
      "batched_new_clients": {
        "footer": "Si tiene alguna duda al respecto, póngase en contacto con el Director del Programa. Le deseamos un viaje exitoso e inspirador.",
        "message": "Se le han añadido nuevos clientes al programa: ",
        "subject": "Tiene nuevo(s) cliente(s) para el"
      },
      "client_rematched": {
        "footer": "Si tiene alguna duda al respecto, póngase en contacto con el Director del Programa. Le deseamos un viaje exitoso e inspirador.",
        "message": "Se le ha reenviado un cliente al programa: ",
        "subject": "Usted tiene un cliente que le es reenviado para el"
      },
      "existing_user_invitation": {
        "click": "Haga clic aquí para afiliarse",
        "link": "Ir al programa",
        "message": "te ha añadido al programa %{program} desde %{organization} en UNLOQ Campus",
        "subject": "le invitó a un programa"
      },
      "extra_minutes_for_client": {
        "footer": "Este tiempo extra ha sido añadido por: ",
        "message": "La cantidad total de tiempo de entrenamiento para <b>%{client}</b> en el programa <b>%{program}</b> se ha cambiado a:",
        "subject": "Campus UNLOQ: minutos adicionales para el cliente"
      },
      "new_client_matched": {
        "footer": "Si tiene alguna duda al respecto, póngase en contacto con el Director del Programa. Le deseamos un viaje exitoso e inspirador.",
        "message": "Se le ha añadido un nuevo cliente al programa: ",
        "subject": "Tiene un nuevo cliente para el"
      },
      "new_user_invitation": {
        "click": "Haga clic aquí para afiliarse",
        "link": "Aceptar invitación",
        "message": "%{organization} y el programa %{program} en el Campus UNLOQ:",
        "subject": "le invitó a un programa"
      },
      "rating_done": {
        "btn": "campus UNLOQ abierto",
        "message": "Haga clic aquí para ver las reseñas:",
        "subject": "Uno de sus clientes ha creado una reseña para usted."
      }
    },
    "coaches": {
      "find_coach": "Buscar entrenador",
      "match_failed": "Se ha producido un error y no se ha podido asignar el cliente a este entrenador",
      "match_success": "Cliente emparejado con éxito",
      "modal_title": "Emparejar al cliente con un entrenador",
      "title": "Coaches"
    },
    "comments": {
      "disabled": "Las notas están desactivadas para este programa o no tiene permisos.",
      "email_explanation": "Diez minutos después de que alguien haya puesto una nota, se enviará una notificación por correo electrónico. Ten en cuenta que estas notificaciones se recogen y envían una vez cada 10 minutos para evitar el spam\"",
      "explanation": "Notas: comunicación interna entre el entrenador y los L&D programa.",
      "new_messages_emails": "Nuevos correos de notas",
      "shared": {
        "coach_only": "Sólo entrenador",
        "private": "Sólo gestores de programas de I+D",
        "public": "Entrenadores y directores de programas de I+D",
        "title": "Compartir con"
      },
      "subject": "Asunto",
      "title": "Notas",
      "title_placeholder": "Escribe tus notas sobre este cliente..."
    },
    "cookies": {
      "cookiebar_link_text": "política de cookies",
      "cookiebar_link_url": "/pages/cookies",
      "cookiebar_text": "Utilizamos cookies para ofrecer la mejor experiencia posible. Lea nuestro %{link} para obtener más información sobre cómo y por qué utilizamos cookies."
    },
    "copy": "Copiar",
    "date": {
      "abbr_day_names": [
        "dom",
        "lun",
        "mar",
        "mié",
        "jue",
        "vie",
        "sáb"
      ],
      "abbr_month_names": [
        null,
        "ene",
        "feb",
        "mar",
        "abr",
        "may",
        "jun",
        "jul",
        "ago",
        "sep",
        "oct",
        "nov",
        "dic"
      ],
      "day_names": [
        "domingo",
        "lunes",
        "martes",
        "miércoles",
        "jueves",
        "viernes",
        "sábado"
      ],
      "formats": {
        "default": "%-d/%-m/%Y",
        "long": "%e %b %Y at %H:%M",
        "notime": "%e %b %Y",
        "short": "%-d de %b"
      },
      "month_names": [
        null,
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre"
      ],
      "order": [
        "day",
        "month",
        "year"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "alrededor de %{count} hora",
          "other": "alrededor de %{count} horas"
        },
        "about_x_months": {
          "one": "alrededor de %{count} mes",
          "other": "alrededor de %{count} meses"
        },
        "about_x_years": {
          "one": "alrededor de %{count} año",
          "other": "alrededor de %{count} años"
        },
        "almost_x_years": {
          "one": "casi %{count} año",
          "other": "casi %{count} años"
        },
        "half_a_minute": "medio minuto",
        "less_than_x_minutes": {
          "one": "menos de %{count} minuto",
          "other": "menos de %{count} minutos"
        },
        "less_than_x_seconds": {
          "one": "menos de %{count} segundo",
          "other": "menos de %{count} segundos"
        },
        "over_x_years": {
          "one": "más de %{count} año",
          "other": "más de %{count} años"
        },
        "x_days": {
          "one": "%{count} día",
          "other": "%{count} días"
        },
        "x_minutes": {
          "one": "%{count} minuto",
          "other": "%{count} minutos"
        },
        "x_months": {
          "one": "%{count} mes",
          "other": "%{count} meses"
        },
        "x_seconds": {
          "one": "%{count} segundo",
          "other": "%{count} segundos"
        },
        "x_years": {
          "one": "%{count} año",
          "other": "%{count} años"
        }
      },
      "prompts": {
        "day": "Día",
        "hour": "Hora",
        "minute": "Minuto",
        "month": "Mes",
        "second": "Segundo",
        "year": "Año"
      }
    },
    "deactivated": "Desactivado",
    "delete": "Borrar",
    "dialog_successfully_archived": "Diálogo archivado correctamente",
    "dialog_title": {
      "label": "Diálogo de grupo",
      "placeholder": "Escriba aquí el título del grupo..."
    },
    "dialogs": {
      "a_group_dialog": "un diálogo de grupo",
      "add_participant": "Añadir participante a este diálogo",
      "add_profile_picture": "Personalice su cuenta añadiendo una foto de perfil y otra información relevante. Ir a",
      "already_present": "Ya se ha creado un diálogo antes",
      "are_you_sure": {
        "reactivate": "¿Está seguro de que quiere reactivar a este participante?",
        "remove": "¿Está seguro de que quiere eliminar a este participante?"
      },
      "are_you_sure_activate": "¿Está seguro de que desea activar este diálogo y que los participantes reciban una invitación por correo electrónico?",
      "assignment": {
        "completed_description": "Ha completado todas las preguntas. Escriba un mensaje de diálogo de acompañamiento y envíe la tarea.",
        "continue": "Continuar",
        "hand_in": "Entregar",
        "hand_in_success": "Su tarea ha sido entregada correctamente.",
        "open": "Abrir",
        "view_answers": "Ver respuestas"
      },
      "autosaved": "Guardado como concepto",
      "btn": {
        "attachment": "Añadir contenido a la caja de herramientas",
        "new": "Nuevo cliente / nuevo diálogo"
      },
      "edited": "editado",
      "email_explanation": "Diez minutos después de que alguien haya colocado un nuevo mensaje de diálogo, se enviará una notificación por correo electrónico. Ten en cuenta que estas notificaciones se recogen y envían una vez cada 10 minutos para evitar el spam\"",
      "empty": "Ningún diálogo coincide con su consulta.",
      "explanation": "Notificaciones a los clientes: mensajes de diálogo.",
      "group_success": "El usuario se ha añadido correctamente al diálogo de grupo.",
      "invitation_pending": "Invitación pendiente",
      "invite_someone": "empezar la primera",
      "new": {
        "btn": {
          "create_account": "Crear una cuenta",
          "send": "Enviar invitación"
        },
        "description": "Puede elegir entre las distintas opciones que figuran a continuación:",
        "enter_email": "Introduzca la dirección de correo electrónico de la persona con la que desea iniciar un diálogo para verificar si ya tiene una cuenta en Campus UNLOQ.",
        "extra_information_account_only": "Después de crear la cuenta sin invitación, esta cuenta no estará disponible para el cliente. En una etapa posterior, puede (opcionalmente) activar el diálogo.",
        "extra_information_already_account": "Después de enviar esta invitación puede (opcionalmente) añadir un mensaje de bienvenida en la ventana de diálogo.",
        "extra_information_no_account": "Después de enviar esta invitación puede (opcionalmente) añadir un mensaje de bienvenida en la ventana de diálogo. Atención: el cliente debe aceptar la invitación (enlace en el correo electrónico) antes de poder participar.",
        "flash_success_create_account": "La cuenta se ha creado correctamente.",
        "flash_success_current": "Todos los participantes han sido invitados y usted está listo para enviar su primer mensaje.",
        "flash_success_invited": "Se ha creado el diálogo y se ha invitado a los participantes.",
        "no_account": "Parece que esta persona aún no tiene una cuenta. Le enviaremos una invitación para que cree una cuenta. ¿Desea incluir un nombre en la invitación?",
        "options": "<ul><li>A) Iniciar diálogo con un cliente existente</li><li>B) Iniciar diálogo con un nuevo cliente</li><li>C) Crear cuenta sin diálogo</li></ul>",
        "participant": {
          "already_account": "¡ya tiene una cuenta! Seleccione el botón \"Enviar invitación\" para añadir a esta persona a este diálogo de Grupo.",
          "description": "Puede elegir entre las dos opciones siguientes:",
          "enter_email": "Introduzca la dirección de correo electrónico de la persona que desea añadir al cuadro de diálogo Grupo para verificar si ya tiene una cuenta en Campus UNLOQ.",
          "extra_information_no_account": "El nuevo participante tiene que aceptar primero la invitación (a través de un enlace en el correo electrónico) antes de poder participar en este diálogo.",
          "no_account": "Esta persona aún no tiene una cuenta. Rellene algunos datos básicos para crear una cuenta. Seleccione el botón \"Enviar invitación\" para añadir a esta persona a este diálogo de grupo.",
          "options": "<ul><li>A) Añadir una cuenta existente a este Grupo</li><li>B) Añadir una nueva cuenta a este Grupo </li></ul>",
          "title": "¿A quién desea añadir a este diálogo de grupo?"
        },
        "title": "¿A quién quiere invitar?"
      },
      "new_messages_emails": "Nuevos mensajes de diálogo",
      "no_dialogs": "Aún no tiene diálogos.",
      "no_messages": "Ningún mensaje (aún)",
      "no_program_membership": "El coach no ha sido asignado al programa (aún).",
      "not_logged_in": "No se ha conectado (aún)",
      "others": "otro(s)",
      "remove_participant": "Eliminar participante",
      "table": {
        "participants": "Participantes"
      },
      "unread_message": {
        "one": "mensaje no leído",
        "other": "mensajes no leídos"
      },
      "welcome": "Bienvenido a tu cuenta de Campus UNLOQ.",
      "why_not": "Aquí puede",
      "with": "Diálogo con",
      "you": "Tú:",
      "your_responses": "Respuestas"
    },
    "download": "Descargar",
    "edit": "Editar",
    "email": {
      "delivery_failure": "Fallo en la entrega del correo electrónico"
    },
    "export": "Exportar",
    "false": "No",
    "flash_archived_success": "se ha archivado correctamente",
    "flash_created_success": "se ha creado correctamente",
    "flash_deleted_success": "se ha eliminado correctamente",
    "flash_enabled_success": "se ha activado correctamente",
    "flash_error": "Algo ha ido mal o, por favor, corrija los errores resaltados.",
    "flash_updated_success": "se ha actualizado correctamente",
    "footer_text": "Con más de 10 años de experiencia, Campus UNLOQ se ha convertido en un socio de confianza para organizaciones nacionales e internacionales que exigen altos niveles de confidencialidad y seguridad. UNLOQ Campus cumple plenamente la normativa GDPR y cuenta con la certificación ISO 27001 para las actividades de desarrollo, mantenimiento y alojamiento seguro.",
    "for": "para",
    "forms": {
      "add_program": "Añadir programa",
      "all_coaches": "Todos los coaches",
      "choose": "Elige...",
      "choose_program": "Todos los programas",
      "confirm_password": "Confirmación de contraseña",
      "consent": "Entiendo y acepto el registro de información personal tal y como se describe en el <a href='/en/pages/privacy_regulations' target='_blank'>Reglamento de privacidad</a>",
      "description": "Descripción",
      "email": "Correo electrónico",
      "first_name": "Nombre",
      "joined": "Creado el",
      "language": "Idioma",
      "last_name": "Apellido",
      "last_seen": "Última conexión",
      "name": "Nombre",
      "new_message": "Nuevo mensaje",
      "optional_attachment": "Adjunto",
      "organization_name": "Nombre de la organización",
      "organization_name_unloq": "Nombre de la organización (conocido como Campus UNLOQ)",
      "password": "Contraseña",
      "picture": "Fotografía",
      "select_file": {
        "one": "Seleccionar archivo",
        "other": "Seleccionar archivos"
      },
      "select_from_date": "Seleccionar desde-fecha",
      "select_to_date": "Seleccionar hasta la fecha",
      "terms": "He revisado y acepto los <a href='/en/pages/terms' target='_blank'>términos y condiciones</a>",
      "title": "Título",
      "type": "Escriba...",
      "type_here": "Escriba un mensaje de diálogo..."
    },
    "helpers": {
      "select": {
        "prompt": "Por favor seleccione"
      },
      "submit": {
        "create": "Crear %{model}",
        "submit": "Guardar %{model}",
        "update": "Actualizar %{model}"
      }
    },
    "how_do_i_enable_javascript": "¿Cómo habilito javascript?",
    "i18n": {
      "plural": {
        "keys": [
          "one",
          "other"
        ]
      },
      "transliterate": {
        "rule": {
          "Á": "A",
          "É": "E",
          "Í": "I",
          "Ñ": "N",
          "Ó": "O",
          "Ú": "U",
          "Ü": "U",
          "á": "a",
          "é": "e",
          "í": "i",
          "ñ": "n",
          "ó": "o",
          "ú": "u",
          "ü": "u"
        }
      }
    },
    "javascript_required": "Se requiere Javascript para utilizar esta aplicación.",
    "language": {
      "en": "Inglés",
      "es": "Español",
      "fr": "Francés",
      "nl": "Holandés"
    },
    "last_login": "Último acceso",
    "last_modified": "Última actividad",
    "learning_path": "Ruta de aprendizaje",
    "learning_path_mailer": {
      "progress": {
        "subject": "Su progreso en el curso del %{program}"
      }
    },
    "library": {
      "add": "Añadir",
      "are_you_sure": "¿Está seguro? Tenga en cuenta que este elemento también se eliminará de todos los diálogos en los que pueda utilizarse.",
      "assignment": "Tarea/ Ejercicio",
      "delete_folder_confirmation": "¿Está seguro de que desea eliminar esta carpeta, incluido su contenido?",
      "document": "Documento",
      "empty": "Aún no hay elementos en su caja de herramientas. Elija el botón '+' para añadir un documento, vídeo, ejercicio u otro contenido.",
      "empty_folder": "Esta carpeta aún no contiene ningún elemento (visible). Seleccione el botón \"+\" para añadir un documento, vídeo, ejercicio u otro contenido.",
      "filters": {
        "assignments": "Tareas/ Ejercicios",
        "documents": "Documentos",
        "embeds": "Vídeos",
        "folders": "Carpetas",
        "images": "Imágenes",
        "uploads": "Documentos",
        "videos": "Vídeos"
      },
      "folder": "Carpeta",
      "image": "Imagen",
      "questions": "Preguntas",
      "video": "Vídeo"
    },
    "license": {
      "description": {
        "business": "Contrate esta licencia Business a 83,25 € al mes (999,- € al año, sin IVA).<br/><br/> ✔️ Incluye 3 cuentas de Experto y 150 cuentas de Cliente.<br/><br/>\nComplete los datos solicitados a continuación y comience hoy mismo con su propia cuenta Business.",
        "education": "Contrate esta licencia educativa Todo en Uno de 60 días.<br/><br/> ✔️ Incluye 3 cuentas de Experto y 150 cuentas de Cliente.<br/> Los costes de esta licencia están incluidos en su programa de formación.<br/><br/>\nComplete los detalles solicitados a continuación y comience hoy mismo con su propia cuenta de Educación.",
        "enterprise": "Contrate esta licencia Enterprise a 832,50 € al mes (9.999,- € al año, sin IVA)<br/><br/> ✔️ Incluye cuentas de Experto ilimitadas y cuentas de Cliente ilimitadas.<br/><br/>\nComplete los datos solicitados a continuación y comience hoy mismo con su propia cuenta Enterprise",
        "transition": "Contrata esta licencia especial de Transición.<br/><br/> ✔️ Incluye 3 cuentas de Experto y 150 cuentas de Cliente.<br/> Puede utilizar esta licencia Campus UNLOQ de forma gratuita siempre y cuando tenga una licencia válida para Campus UNLOQ.<br/><br/>\r\nComplete los datos solicitados a continuación y comience hoy mismo con su propia cuenta UNLOQ Campus.",
        "trial": "Apúntese a esta prueba gratuita de 30 días de Todo en Uno.<br/><br/> ✔️ Sin compromiso.<br/> ✔️ No necesita tarjeta de crédito.<br/> ✔️ Incluye 3 cuentas de Experto y 150 cuentas de Cliente.<br/><br/>\r\nComplete los datos solicitados a continuación y comience hoy mismo con su propia cuenta gratuita."
      }
    },
    "license_expired": "Esta cuenta está bloqueada, no es posible realizar ajustes.",
    "login": {
      "btn": "Inicio de sesión",
      "password_forgotten": "¿Ha olvidado su contraseña?"
    },
    "manager": "Director",
    "menu": {
      "associate_profile": "Perfil de asociado",
      "dialogs": "Diálogos",
      "logout": "Cierre de sesión",
      "manage": "Gestionar",
      "my_profile": "Mi perfil",
      "notifications": "Ajustes de notificación",
      "pages": "Páginas",
      "pending_clients": "Clientes pendientes",
      "profile_settings": "Configuración del perfil",
      "questionnaires": "Cuestionarios",
      "translations": "Traducciones",
      "users": "Usuarios"
    },
    "new": "Nuevo",
    "none": "Ninguno",
    "not_applicable": "N/A",
    "not_authenticated": "Para realizar esta acción, debe iniciar sesión.",
    "not_authorized": "No está autorizado/a a realizar esta acción.",
    "notification_mailer": {
      "batched_comments": {
        "btn": "campus UNLOQ abierto",
        "message": "Hay nuevas notas sobre un cliente en Campus que podrían ser relevantes para usted.",
        "subject": "Campus UNLOQ: nueva(s) nota(s) sobre un cliente"
      },
      "batched_dialog_messages": {
        "link_description": "Para ver el mensaje completo y responder:",
        "link_text": "visite el Campus UNLOQ",
        "message": "Ha recibido un nuevo mensaje de",
        "subject": "Campus UNLOQ: nuevo(s) mensaje(s)"
      },
      "unsubscribe": "Haga clic aquí si desea darse de baja de estos correos electrónicos."
    },
    "notifications": {
      "description": "Mueva el botón a 'ON' para recibir notificaciones. Mueva el botón a 'OFF' si no quiere recibir notificaciones de Campus UNLOQ.",
      "title": "Aquí puede indicar si desea recibir correos electrónicos de  UNLOQ cuando haya nuevos mensajes disponibles para usted."
    },
    "number": {
      "currency": {
        "format": {
          "delimiter": ".",
          "format": "%n %u",
          "precision": 2,
          "separator": ",",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "€"
        }
      },
      "format": {
        "delimiter": ".",
        "precision": 3,
        "round_mode": "default",
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": "mil millones",
            "million": {
              "one": "millón",
              "other": "millones"
            },
            "quadrillion": "mil billones",
            "thousand": "mil",
            "trillion": {
              "one": "billón",
              "other": "billones"
            },
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "Byte",
              "other": "Bytes"
            },
            "eb": "EB",
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "pb": "PB",
            "tb": "TB"
          }
        }
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n %"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "number_short": "No.",
    "ordinalize_full": {
      "n_1": "primero",
      "n_10": "décimo",
      "n_100": "centésimo",
      "n_11": "undécimo",
      "n_12": "duodécimo",
      "n_13": "decimotercero",
      "n_14": "decimocuarto",
      "n_15": "decimoquinto",
      "n_16": "decimosexto",
      "n_17": "decimoséptimo",
      "n_18": "decimoctavo",
      "n_19": "decimonoveno",
      "n_2": "segundo",
      "n_20": "vigésimo",
      "n_3": "tercero",
      "n_30": "trigésimo",
      "n_4": "cuarto",
      "n_40": "cuadragésimo",
      "n_5": "quinto",
      "n_50": "quincuagésimo",
      "n_6": "sexto",
      "n_60": "sexagésimo",
      "n_7": "séptimo",
      "n_70": "septuagésimo",
      "n_8": "octavo",
      "n_80": "octogésimo",
      "n_9": "noveno",
      "n_90": "nonagésimo"
    },
    "organization": "Organización",
    "organization_manager_mailer": {
      "new_signup": {
        "message": "Un nuevo cliente se ha inscrito en el programa: %{program}. Por favor, asigne el coach a este cliente para comenzar su viaje de coaching.",
        "subject": "Se ha registrado un nuevo cliente."
      }
    },
    "organizations": {
      "choose_term": "Elija la terminología",
      "flexible_term_description": "Puede ajustar la terminología a continuación y, por tanto, elegir los términos que mejor se adapten a su organización, cargo y grupo destinatario. Seleccione los términos que desea utilizar y haga clic en Guardar. A continuación, estos términos se implementarán en toda la cuenta de su organización Campus UNLOQ  y también serán visibles para los clientes.",
      "flexible_term_title": "Terminología flexible",
      "flexible_term_warning": "Tenga en cuenta que los cambios terminológicos son visibles al cabo de 1 hora.",
      "license": "Licencia",
      "licensed_to": "Con licencia para",
      "logo_explanation": "Le recomendamos que cargue un logotipo PNG con fondo transparente.",
      "settings_for": "Ajustes para",
      "term_assignment": "Tarea/ Ejercicio",
      "term_client": "Cliente",
      "term_coach": "Coach",
      "term_for": "Término para: "
    },
    "other_organizations": "Otra(s) organización(es):",
    "passwords": {
      "edit": {
        "btn": "Restablecer contraseña",
        "description": "Introduzca su nueva contraseña y se la enviaremos enseguida.",
        "label_password": "Nueva contraseña",
        "title": "Restablecer contraseña"
      },
      "new": {
        "description": "Introduzca su dirección de correo electrónico y le enviaremos instrucciones para establecer una nueva contraseña. Si no has recibido ningún correo electrónico, comprueba tu carpeta de correo no deseado o inténtalo de nuevo.",
        "placeholder_email": "Su dirección de correo electrónico",
        "title": "No se preocupe, nos pasa a todos/as."
      }
    },
    "pdf_report_ready": "Informe PDF listo",
    "pending_clients": {
      "description": "A continuación puede ver un resumen de los clientes que necesitan ser asignados (o que ya están asignados). A la derecha se puede seleccionar el coach adecuado."
    },
    "people_analytics": {
      "360_degree": "360 grados",
      "aborted_signature": "Saludos cordiales,<br/><br/>Campus People Analytics | parte de UNLOQ<br/><a href='https://unloq.org/' target='_blank'>unloq.org</a>",
      "aborted_success": "Gracias por su colaboración. Este cuestionario no se tramitará más, ya que sólo se puede realizar si se aceptan los términos y condiciones.<br> <br> Ahora puede cerrar esta página. Si desea reanudar el cuestionario, utilice el botón de abajo.",
      "aborted_success_title": "Cuestionario cerrado",
      "add_respondent": "añadir un encuestado",
      "add_respondent_button": "Añadir encuestado",
      "admin": {
        "choose_program": "Elegir programa",
        "choose_questionnaire": "Elegir cuestionario",
        "filter_first": "Filtre primero para ver los resultados.",
        "measure_moments": "Medir momentos",
        "not_enough_results": "No hay suficientes resultados para mostrar (mínimo 3).",
        "question": "Pregunta",
        "title": "Resultados de People Analytics",
        "topic": "Tema(s)"
      },
      "analytical_thinking": "Pensamiento analítico",
      "analytical_thinking_description": "Promuevo la resistencia y la capacidad de respuesta ante las cuestiones y oportunidades estratégicas emergentes para el Departamento/División.",
      "answer": "Respuesta",
      "assessment": "Evaluación",
      "assessment_leadership_organizational": "Liderazgo organizativo",
      "assessment_leadership_self": "Personal / Autoliderazgo",
      "assessment_leadership_team": "Liderazgo de equipos",
      "assessment_report": "Informe de evaluación",
      "attention_objectives": "<b>Atención:</b> Si desea añadir más objetivos, utilice este botón antes de pasar a la página siguiente.",
      "autonomy": "Autonomía",
      "autonomy_description": "La libertad y la autoridad para actuar por uno mismo dentro del puesto de trabajo.",
      "average_objectives": "Nº medio de objetivos de coaching",
      "average_objectives_score": "Puntuación media de los objetivos de los clientes",
      "basicdata": "Datos básicos del cliente",
      "btn_abort": "Cerrar",
      "btn_add_goal": "Añadir objetivo",
      "btn_continue": "Continuar",
      "btn_copy_report_link": "Copiar enlace para informar",
      "btn_copy_report_link_hint": "Copie la URL del informe para compartirlo",
      "btn_download_report": "Descargar PDF",
      "btn_download_report_active": "Descargando...",
      "btn_download_report_hint": "Descargar el archivo PDF de su informe",
      "btn_generate_for": "Generar informe de coaching para",
      "btn_generate_report": "Generar informe",
      "btn_generate_report_hint": "Genera un nuevo informe",
      "btn_regenerate_report": "Volver a generar el informe",
      "btn_regenerate_report_hint": "Vuelve a generar el informe, no se puede deshacer",
      "btn_remove_goal": "Eliminar objetivo",
      "btn_resume": "Cuestionario de currículum",
      "btn_send_report_link_dialog": "Enviar informe por diálogo",
      "btn_send_report_link_dialog_hint": "Envía el enlace al informe a tu cliente en el diálogo",
      "btn_send_report_link_email": "Enviar informe por correo electrónico",
      "btn_send_report_link_email_active": "Enviando...",
      "btn_send_report_link_email_hint": "Envía un correo electrónico automático al cliente con el enlace al informe",
      "btn_submit": "Enviar",
      "can_add_peers_explanation": "Este cuestionario está configurado para que no se puedan añadir encuestados a partir de un determinado momento del calendario de evaluación",
      "choose_role": "Elegir papel",
      "client_option": {
        "anonymous": "Anónimo",
        "fullname": "Nombre completo del cliente"
      },
      "closed": "Cerrado",
      "closed_on": "Cerrado el",
      "coaching_objectives": "Objetivos del coaching",
      "coachprocedure": "Evaluación del proceso de coaching",
      "coachprocedure_description": "Este cuestionario sondea la opinión del cliente sobre el proceso de coaching. El cliente responde a las preguntas a medida que las va completando.",
      "coachprocedure_explanation": "Este cuestionario sondea la opinión del cliente sobre el coaching.",
      "coachrelation": "Relación de coaching",
      "coachrelation_explanation": "Un coach y un cliente colaboran para alcanzar los objetivos del cliente. Esta colaboración profesional se denomina \"alianza de coaching\". En la alianza de trabajo se distinguen tres aspectos: Vínculo afectivo, Orientación a la tarea y Orientación al objetivo.",
      "coachrelation_only_one": "Nota: las imágenes de esta sección sólo se visualizan 1 vez, por lo que sólo se visualiza 1 momento de encuentro en los siguientes gráficos.",
      "coachrelation_total": "Puntuación total",
      "coachrelation_total_description": "Puntuaciones totales de vínculo afectivo, orientación a la tarea y objetivos.",
      "communication": "Comunicación",
      "communication_description": "Tengo presencia y porte como defensor eficaz de la misión y la dirección estratégica del programa.",
      "competence": "Competencia",
      "competence_description": "Utilizar las propias capacidades y ser eficaz en las interacciones con los demás en el trabajo.",
      "confidence": "Confianza",
      "confidence_description": "Cuánta confianza tengo en alcanzar el objetivo de coaching",
      "confidential": "Confidencial",
      "confirm_objectives_modal": {
        "back": "Volver a los objetivos",
        "body": "Está a punto de terminar la sección Objetivos. Si está seguro de haber añadido todos los objetivos, haga clic en \"Continuar\". Si desea añadir más objetivos, haga clic en \"Volver a los objetivos\"",
        "continue": "Continuar",
        "title": "¿Ha añadido todos sus objetivos?"
      },
      "consent": "Debe aceptar las condiciones para continuar.",
      "copy_link_error": "No se puede copiar el enlace, el cliente no está activo para el usuario actual",
      "copy_link_success": "Enlace copiado correctamente en el portapapeles",
      "current": "Situación actual",
      "current_description": "El estado objetivo actual",
      "deactivate": "Desactivar",
      "description": "Descripción",
      "evaluation_report": "Informe de evaluación",
      "explanation": "Explicación",
      "final_remarks": "Observaciones finales",
      "finished_signature": "Saludos cordiales,<br/><br/>Campus People Analytics | parte de UNLOQ<br/><a href='https://unloq.org/' target='_blank'>unloq.org</a>",
      "finished_success": "Todas sus respuestas se almacenan correctamente (y de forma segura). Gracias por su colaboración.",
      "finished_success_title": "Muy bien Has terminado con éxito todas tus preguntas.",
      "five": "Cinco",
      "generate_report_error": "Ha habido un problema al visualizar este informe",
      "generate_report_prompt": "Empiece por generar un informe",
      "goal": "Objetivo del coaching",
      "goal_explanation": "Las escalas van de 0 a 10, donde 0 representa una puntuación muy baja y 10 la puntuación más alta posible. Cuanto más baja es la puntuación, mayor es el crecimiento posible hacia el objetivo. Cuanto mayor sea la puntuación, más se alcanzará el objetivo.",
      "goaloriented": "Objetivos",
      "goaloriented_description": "El grado de satisfacción de un cliente respecto a los objetivos que debe alcanzar.",
      "independent": "Independencia",
      "independent_description": "Hasta qué punto soy capaz de lograr este objetivo de forma independiente",
      "information": "Información",
      "integrity": "Integridad",
      "integrity_description": "Demuestro y acato los valores fundamentales de la Agencia en mis actividades y mi comportamiento.",
      "leadership_assessment_title": "Resultados de la evaluación del liderazgo",
      "leading_and_supervising": "Dirigir y supervisar",
      "leading_and_supervising_description": "Me aseguro de que no se abusa del poder y la autoridad y demuestro liderazgo y credibilidad en las interacciones con todos los socios y partes interesadas.",
      "legend": {
        "aborted": "Abortado",
        "completed": "Completado",
        "not_sent_yet": "No enviado",
        "partially_completed": "Comenzó",
        "reminder_sent": "Recordado",
        "sent": "Enviado"
      },
      "legend_description": {
        "aborted": "El participante / encuestado ha rechazado la política de privacidad.",
        "completed": "El participante / encuestado ha completado la tarea.",
        "not_sent_yet": "La asignación aún no se ha enviado al participante / encuestado.",
        "partially_completed": "El participante / encuestado ha comenzado la tarea pero aún no la ha completado en su totalidad.",
        "reminder_sent": "Se ha enviado al participante/encuestado un recordatorio automático para que cumplimente el cuestionario.",
        "sent": "La tarea se ha enviado al participante / encuestado."
      },
      "measure_moment": "Medir el momento",
      "menu": {
        "background": "Fondo",
        "clients_progress": "Progresos de los clientes",
        "example_report": "Ejemplo de informe"
      },
      "mindset": "Visión de las personas (mentalidad)",
      "mindset_description": "El grado en que una persona cree que las personas pueden cambiar y desarrollarse.",
      "mindset_explanation": "El grado en que una persona cree que las personas (incluidas ellas mismas) pueden cambiar su comportamiento. Cuanto mayor sea la puntuación, más cree la persona que la gente puede cambiar (mentalidad de crecimiento). Cuanto más baja es la puntuación, más convencida está la persona de que la gente no puede cambiar (mentalidad fija).",
      "mindset_scale": "Se expresa en una escala de un mínimo de 8 y un máximo de 48.",
      "motivation": "Motivación",
      "motivation_description": "Cuánto me motiva alcanzar el objetivo",
      "new": "Nuevo",
      "new_client": "Nuevo cliente",
      "new_from": "Nuevo de",
      "no_goals": "Debe especificar al menos un objetivo activo para continuar con este cuestionario.",
      "no_respondents_yet": "Aún no se ha añadido ningún encuestado. Adelante",
      "not_all_questions_answered": "No se ha respondido a todas las preguntas.",
      "objective": "Objetivo",
      "objective_closed": "Cerrado",
      "objective_no": "Objetivo no.",
      "objective_relevant": "Correspondiente",
      "pdf_confidential": "La información contenida en estos documentos es confidencial, privilegiada y sólo para información del destinatario y no puede ser utilizada, publicada o redistribuida sin el consentimiento del destinatario. Este documento ha sido generado por UNLOQ People Analytics, un instrumento de UNLOQ.",
      "pdf_share_answer": {
        "private": "Entrenador",
        "public": "Entrenador y encuestado(s)"
      },
      "peer": "Encuestado",
      "peers": "Encuestados",
      "planning": "Planificación",
      "planning_and_organizing": "Planificación y organización",
      "planning_and_organizing_description": "Convenzo a los demás con argumentos convincentes.",
      "planning_description": "Me resisto a toda presión indebida en la toma de decisiones y en el desempeño de mis funciones.",
      "preview": "Vista previa",
      "previous_peer_answer": "En la evaluación anterior de este objetivo, usted dio a %{client} una puntuación: %{number} (escala 0 - 10).",
      "private_objectives_modal": {
        "body": "Si comparte sus objetivos <i>únicamente</i> con su coach, sus encuestados no participarán en esta encuesta de 360 grados.",
        "title": "¿Sólo comparte objetivos con su entrenador?"
      },
      "professionalism": "Profesionalidad",
      "professionalism_description": "Tomo medidas rápidas y eficaces ante comportamientos poco profesionales o poco éticos.",
      "program": "Programa",
      "psychological_needs": "Necesidades psicológicas básicas en el trabajo",
      "psychological_needs_explanation": "Todo ser humano se esfuerza por satisfacer sus propias necesidades psicológicas básicas. Cuanto más se satisfagan estas necesidades básicas a través y en el trabajo, más motivada estará una persona para hacer mejor su trabajo. Se distinguen tres necesidades básicas, a saber: Autonomía, Competencia y Relación.",
      "question": "Pregunta",
      "questionnaire": "Cuestionario",
      "relatedness": "Relación",
      "relatedness_description": "El sentimiento de pertenencia y el contacto estrecho con los demás en el trabajo.",
      "relation": "Vínculo afectivo",
      "relation_description": "La medida en que existe una buena alianza de trabajo entre el coach y el cliente.",
      "reminded_at": "Recordatorio por correo electrónico enviado a",
      "report": {
        "assessment_intro": "Esta sección presenta los resultados de la evaluación del liderazgo.<br /><br />\nPor competencia, las puntuaciones se muestran en el gráfico. Aquí se da su puntuación (Auto) en la primera Medida de Evaluación (M1) y, si está disponible, también en la segunda Medida de Evaluación (M2). Debajo de la pantalla \"Yo\" se muestran las puntuaciones de los encuestados, así como el número de encuestados (N). En la tabla situada junto al gráfico, se muestran las puntuaciones de la primera medición y (si se dispone de ella) de la segunda medición, además de la diferencia entre estas mediciones (Δ). Esto mostrará si se ha logrado alguna mejora.",
        "assessment_intro_m1": "El segundo nivel de evaluación (M2) no es aplicable a este informe. (M2) sólo está disponible cuando se completa una segunda evaluación. Cuando (M2) esté disponible, la diferencia entre (M1) y (M2) medirá su nivel de crecimiento.",
        "assessment_intro_m2": "El segundo nivel de evaluación (M2) también es visible en el gráfico (2ª barra) y también en la tabla. La diferencia entre (M1) y (M2) medirá tu nivel de crecimiento.",
        "assessment_legend": "La puntuación se muestra en una escala Likert de 5 puntos, lo que significa que la puntuación va de 1) totalmente en desacuerdo a 5) totalmente de acuerdo.",
        "assessment_scale": "Escala",
        "assessment_scale_explanation": "1: totalmente en desacuerdo / 2: en desacuerdo / 3: ni de acuerdo ni en desacuerdo / 4: de acuerdo / 5: totalmente de acuerdo",
        "average": "Media",
        "average_overall": "Media general",
        "averages": "Promedios",
        "highest": "más alto",
        "lowest": "más bajo",
        "open_ended_intro": "Esta sección presenta las respuestas dadas a varias preguntas abiertas (P).<br /><br />\nEn primer lugar se muestran las respuestas dadas por el propio cliente, seguidas de las respuestas dadas por los encuestados.",
        "open_ended_title": "Respuestas a las preguntas abiertas",
        "overview_of": "Visión general de",
        "section": "Sección",
        "supervisory_assessment_intro": "Esta sección presenta los resultados de la Evaluación del Liderazgo Supervisor.<br /><br />\nLas puntuaciones se muestran en el gráfico para cada habilidad y/o comportamiento de liderazgo supervisor. Su puntuación (Auto) se presenta en este primer nivel de evaluación (M1). Debajo de la visualización (Auto), se muestran respectivamente las puntuaciones medias de los encuestados para las categorías Gerente, Informe Directo y/o Par.",
        "supervisory_top_results_intro": "Esta sección presenta las cinco habilidades y comportamientos de liderazgo supervisor mejor valorados y las cinco habilidades y comportamientos de liderazgo supervisor peor valorados (valorados por uno mismo).",
        "supervisory_top_results_top_rated": "calificaron de media las capacidades de supervisión",
        "table_results_intro": "Esta sección muestra las respuestas para cada una de las tres dimensiones de liderazgo de la retroalimentación recopilada de múltiples fuentes (Yo, Gerentes y Pares).",
        "table_results_intro_explanation": "<ul class=\"list-check ml-3\">\n  <li>La columna \"Promedio propio\" muestra el promedio general de las respuestas que usted mismo dio para cada dimensión.</li>\n  <li>La columna \"Promedio general\" muestra los promedios de las respuestas de los evaluadores.</li>\n  <li>Las columnas de \"encuestados\" muestran la respuesta de cada evaluador.</li>\n  <li>Las respuestas pueden ir de 1 - Totalmente en desacuerdo a 5 - Totalmente de acuerdo.</li>\n</ul>",
        "top_results_extra": "En primer lugar, se muestran las puntuaciones del primer punto temporal de medición (M1) y, si están disponibles, también las del segundo punto temporal de medición (M2).<br /><br />",
        "top_results_intro": "Esta sección presenta las cinco competencias mejor valoradas del cliente y también las cinco peor valoradas.<br /><br />",
        "top_results_top_rated": "competencias autoevaluadas"
      },
      "report_create": "Crear un informe",
      "report_epilogue": "Informe epílogo",
      "report_generated_at": "Este informe se generó en %{date}",
      "report_is_loading": "Carga del informe generado",
      "report_measure": "Medición",
      "report_opened_at": "Último informe abierto en",
      "report_opened_by_client_at": "El cliente abrió su informe en %{date}",
      "report_preface": "Prefacio del informe",
      "report_sent_at": "Último informe enviado a",
      "reset_last_submission": "Restablecer el último cuestionario enviado",
      "respect_for_diversity": "Respeto de la diversidad",
      "respect_for_diversity_description": "Creo un entorno que fomenta la comunicación abierta dentro y fuera de los límites de la organización y convierto las situaciones controvertidas o delicadas en oportunidades",
      "respondents_overview": "Resumen de los encuestados",
      "result_score": "Resultado",
      "role": {
        "board_member": "Miembro del Consejo",
        "colleague": "Pares",
        "customer": "Cliente",
        "family": "Familia",
        "friends": "Amigos / conocidos",
        "manager": "Director",
        "staff_member": "Informe directo",
        "supplier": "Proveedor"
      },
      "score": "Puntuación",
      "self": "Personal",
      "send_coach_questionnaire": "Envíe el cuestionario para entrenadores a %{next} ",
      "send_via_dialog": "Enviar cuestionario %{next} (Diálogo)",
      "send_via_email": "Enviar cuestionario %{next} (E-mail)",
      "send_via_link": "Enviar cuestionario %{next} (Copiar enlace)",
      "share_answer": {
        "private": "Compartir sólo con el entrenador",
        "public": "Compartir con el entrenador y los encuestados"
      },
      "shared_with": "Compartido con",
      "status_description": "A continuación encontrará los detalles del progreso del coaching, así como las respuestas del cliente.",
      "step_show_client_name": "Mostrar el nombre del cliente",
      "step_show_private_objectives": "Mostrar objetivos de coaching que sólo se comparten con el coach",
      "step_write_epilogue": "Escribir el epílogo",
      "step_write_preface": "Redactar el prefacio",
      "still_relevant": "¿Sigue siendo pertinente este objetivo?",
      "submit_success": "Se ha enviado un correo electrónico con un enlace al cuestionario de análisis de personas.",
      "submit_too_many": "El correo electrónico con el cuestionario ya ha sido enviado. Vuelva a intentarlo más tarde.",
      "supervisory_leadership_assessment_title": "Resultados de la evaluación del liderazgo de los supervisores",
      "supervisory_mindset_explanation": "Esta sección presenta los resultados sobre cómo cree que las personas pueden cambiar con el tiempo. Cuanto mayor sea la puntuación, más cree la persona que la gente puede cambiar (mentalidad de crecimiento). Cuanto más baja es la puntuación, más convencida está la persona de que la gente no puede cambiar (mentalidad fija).",
      "supervisory_top_highest_and_lowest_title": "Cinco calificaciones más altas y cinco más bajas de las capacidades de supervisión",
      "taskoriented": "Tarea",
      "taskoriented_description": "El grado de satisfacción del cliente con el enfoque y el método de coaching.",
      "teamwork": "Trabajo en equipo",
      "teamwork_description": "Me hago visible para el personal escuchándolo y comunicándome a todos los niveles dentro de la Agencia y con los homólogos externos.",
      "title": "Análisis de personas",
      "top_highest_and_lowest_title": "Los cinco valores de liderazgo mejor y peor valorados",
      "total_objectives": "Total de objetivos de coaching",
      "view_results": "Ver resultados"
    },
    "people_analytics_mailer": {
      "client_finished": {
        "btn": "Ir al Campus UNLOQ",
        "message": "ha rellenado el cuestionario para el programa <b>%{program}</b>.",
        "subject": "Cuestionario cumplimentado"
      },
      "peer_finished": {
        "btn": "Ir al Campus UNLOQ",
        "message": "El encuestado %{peer} ha rellenado el cuestionario para el programa %{program}.",
        "subject": "El encuestado ha rellenado el cuestionario"
      },
      "remind_client": {
        "message": "le ruega que rellene el cuestionario de autoevaluación del programa <b>%{program}</b>. <br><br> <b>Atención:</b> el cuestionario solo está disponible durante un tiempo limitado, y este enlace caducará pronto.",
        "subject": "Recordatorio: Cuestionario People Analytics"
      },
      "remind_coach": {
        "message": "solicita amablemente que cumplimente su cuestionario de autoevaluación del programa <b>%{program}</b> desde su rol de <b>coach</b>. <br><br> <b>Atención:</b> el cuestionario sólo está disponible por tiempo limitado, y este enlace caducará en breve.",
        "subject": "Recordatorio: Cuestionario para entrenadores de People Analytics"
      },
      "remind_peer": {
        "message": "le ruega que rellene el cuestionario de autoevaluación del programa <b>%{program}</b> desde la perspectiva de su papel como <b>%{peertype}</b>. <br><br> <b>Atención:</b> el cuestionario sólo está disponible durante un tiempo limitado, y este enlace caducará pronto.",
        "subject": "Recordatorio: Cuestionario para encuestados de People Analytics"
      },
      "submit_client": {
        "btn": "Ir al cuestionario",
        "explanation": "Al hacer clic en el enlace, accederá a la herramienta de UNLOQ Campus denominada People Analytics e iniciará el cuestionario, cuya cumplimentación le llevará aproximadamente entre 10 y 15 minutos. Después de completar cada página del cuestionario, debe hacer clic en el botón \"continuar\" que aparece en la parte inferior.<br/> <br/> <b>Por favor, tenga en cuenta que:</b><br/> Al rellenar el cuestionario, no puede hacer clic de nuevo una vez que haya hecho clic en \"continuar\".<br/> Es necesario responder a todas las preguntas de cada página, de lo contrario, el botón \"continuar\" no funciona.<br/><br/>",
        "message": "solicita amablemente que cumplimente su cuestionario de autoevaluación del programa <b>%{program}</b>.",
        "subject": "Cuestionario People Analytics"
      },
      "submit_client_report": {
        "btn": "Ir al informe",
        "message": "quiere compartir su informe para el programa <b>%{program}</b>.",
        "subject": "Informe People Analytics"
      },
      "submit_coach": {
        "btn": "Ir al cuestionario",
        "message": "solicita amablemente que cumplimente su cuestionario de autoevaluación del programa <b>%{program}</b> desde su rol de <b>coach</b>.",
        "subject": "Cuestionario para coaches de People Analytics"
      },
      "submit_peer": {
        "btn": "Ir al cuestionario",
        "explanation": "Al hacer clic en el enlace, accederá a la herramienta de UNLOQ Campus denominada People Analytics e iniciará el cuestionario, cuya cumplimentación le llevará aproximadamente entre 10 y 15 minutos. Después de completar cada página del cuestionario, debe hacer clic en el botón \"continuar\" que aparece en la parte inferior.<br/> <br/> <b>Por favor, tenga en cuenta que:</b><br/> Al rellenar el cuestionario, no puede hacer clic de nuevo una vez que haya hecho clic en \"continuar\".<br/> Es necesario responder a todas las preguntas de cada página, de lo contrario, el botón \"continuar\" no funciona.<br/><br/>",
        "message": "le ruega que cumplimente su cuestionario de autoevaluación del programa <b>%{program}</b> desde la perspectiva de su función como <b>%{peertype}</b>.",
        "subject": "Cuestionario para encuestados de People Analytics"
      }
    },
    "profile": {
      "change_password": "Cambiar contraseña",
      "information": "Información sobre el perfil",
      "people_analytics_emails": "Correos electrónicos de People Analytics",
      "people_analytics_emails_explanation": "Estos correos electrónicos se envían cuando, por ejemplo, un participante o encuestado ha enviado un cuestionario.",
      "two_factor_authentication": "Autenticación de dos factores"
    },
    "program": {
      "days_after_month_closure": "Registros de sesión cerrados (periodo en días que se pueden modificar los registros de sesión después del último día del mes)",
      "minutes_per_client": "Tiempo máximo disponible por cliente (en minutos)",
      "title": "Programa"
    },
    "program_breaks": {
      "create": "Añadir pausa de programa"
    },
    "program_questionnaire_explanation": "El cuestionario de People Analytics sólo puede configurarse una vez.",
    "program_sessions": {
      "activities": {
        "delivery": "Entrega",
        "design": "Diseño",
        "other": "Otro",
        "preparation": "Preparación",
        "projectmanagement": "Gestión de proyectos",
        "reporting": "Informes"
      },
      "activity": "Actividad",
      "activity_not_enabled": ": no habilitado. Elige otra categoría.",
      "activity_type": "Actividad",
      "btn_new": "Nuevo grupo y actividad de formación",
      "category_type": "Categoría",
      "charts_explanation": "Total de los minutos del programa",
      "choose_activity": "Todas las actividades",
      "description": "A continuación puede administrar sus horas relacionadas con cualquier grupo o actividad de entrenamiento para un programa específico. Esté alerta, estas horas no son horas de entrenamiento individual.",
      "hourly_rate": "Precio por hora",
      "minutes_exceeded": "máximo superado",
      "notes": "Notas",
      "sum_duration": "Facturable",
      "sum_duration_nonbillable": "No facturable",
      "title": "Actividades de grupo y formación"
    },
    "programs": "Programas",
    "progress": "Progreso",
    "progress_explanation": "Seleccione un programa para ver su progreso.<br/><br/> - Azul = número de minutos de programa utilizados<br/> - Verde 100% = se han utilizado todos los minutos de programa<br/> - Rojo &gt; 100% = se han utilizado más minutos de los acordados<br/> <br/> ¿Necesita más minutos? Pida al Responsable de Organización que los añada.<br/>",
    "question": {
      "input_type": {
        "checkboxes": "Opción múltiple, respuestas múltiples (casillas de verificación)",
        "radio_buttons": "Opción múltiple, 1 respuesta (botones de opción)",
        "rating": "Pregunta de valoración (1-10)",
        "sortable": "Pregunta clasificable",
        "text_area": "Respuesta abierta (varias líneas de texto)",
        "text_field": "Respuesta corta (1 línea de texto)"
      }
    },
    "questionnaire": "Cuestionario",
    "ra": {
      "action": {
        "save": "Guardar"
      },
      "page": {
        "empty": "No se ha encontrado %{name}."
      }
    },
    "ransack": {
      "all": "todos",
      "and": "y",
      "any": "cualquier",
      "asc": "ascendente",
      "attribute": "atributo",
      "combinator": "combinador",
      "condition": "condición",
      "desc": "descendente",
      "or": "o",
      "predicate": "predicado",
      "predicates": {
        "blank": "está en blanco",
        "cont": "contiene",
        "cont_all": "contiene todos",
        "cont_any": "contiene cualquier",
        "does_not_match": "no coincide con",
        "does_not_match_all": "no coincide con todos",
        "does_not_match_any": "no coincide con ninguno",
        "end": "termina en",
        "end_all": "termina en todos",
        "end_any": "termina en cualquier",
        "eq": "es igual a",
        "eq_all": "es igual a todos",
        "eq_any": "es igual a cualquier",
        "false": "es falso",
        "gt": "mayor que",
        "gt_all": "mayor que todos",
        "gt_any": "mayor que cualquier",
        "gteq": "mayor que o igual a",
        "gteq_all": "mayor que o igual a todos",
        "gteq_any": "mayor que o igual a cualquier",
        "in": "en",
        "in_all": "en todos",
        "in_any": "en cualquier",
        "lt": "menor que",
        "lt_all": "menor o igual a",
        "lt_any": "menor que cualquier",
        "lteq": "menor que o igual a",
        "lteq_all": "menor o igual a todos",
        "lteq_any": "menor o igual a cualquier",
        "matches": "coincide con",
        "matches_all": "coincide con todos",
        "matches_any": "coincide con cualquier",
        "not_cont": "no contiene",
        "not_cont_all": "no contiene todos",
        "not_cont_any": "no contiene ninguno",
        "not_end": "no termina en",
        "not_end_all": "no termina en todos",
        "not_end_any": "no termina en cualquier",
        "not_eq": "no es igual a",
        "not_eq_all": "no es igual a todos",
        "not_eq_any": "no es igual a cualquier",
        "not_in": "no en",
        "not_in_all": "no en todos",
        "not_in_any": "no en cualquier",
        "not_null": "no es nulo",
        "not_start": "no comienza con",
        "not_start_all": "no comienza con todos",
        "not_start_any": "no comienza con cualquier",
        "null": "es nulo",
        "present": "está presente",
        "start": "comienza con",
        "start_all": "comienza con todos",
        "start_any": "comienza con cualquier",
        "true": "es verdadero"
      },
      "search": "buscar",
      "sort": "ordenar",
      "value": "valor"
    },
    "rating": {
      "empty": "Aún no hay reseñas.",
      "empty_explanation_step1": "Habilite las reseñas por cliente en la página de resumen de Clientes:",
      "empty_explanation_step2": "Haga clic en el icono de la estrella situado detrás del nombre del cliente para activar la reseña de un cliente específico.",
      "empty_explanation_step3": "El cliente puede ahora escribir una reseña para usted como coach en la página de diálogo.",
      "placeholder": "Escribe una reseña...",
      "private": "Privado (compartir sólo con el/la coach)",
      "public": "Público (visible para todos)",
      "semi_private": "Semiprivado (compartir con las partes interesadas)",
      "shared_placeholder": "Elige el nivel de uso compartido...",
      "title": "Reseña"
    },
    "react": {
      "admin": {
        "add_to_associate_list": "Añadir a la lista de asociados",
        "address": "Dirección",
        "addresses": "Datos comerciales",
        "affiliation": "Afiliación",
        "affiliations": "Afiliaciones",
        "are_you_sure_delete": "¿Está seguro de que desea eliminar este %{referenceName}?",
        "associate_lists": {
          "add_coach_button": "Añadir entrenador",
          "add_coach_to_list_dialog_title": "Añadir entrenador a la lista",
          "add_to_list_failed": "El entrenador no pudo ser añadido a la lista",
          "add_to_list_success": "El entrenador se ha añadido a la lista",
          "coach_count": "Entrenadores",
          "confirm_delete": "¿Suprimir este usuario de la lista?",
          "create": "Crear lista de entrenadores",
          "edit": "Editar la lista de entrenadores",
          "intro": "Aquí puedes crear listas de entrenadores. Estas listas se pueden utilizar en CoachDesks, en el sitio web de UNLOQ y para exportar rápidamente todos los CV de un grupo de entrenadores.",
          "no_coaches_in_list": "Aún no hay entrenadores en esta lista.",
          "title": "Listas de entrenadores"
        },
        "avatars": "Foto de perfil",
        "bio": {
          "new": "El usuario ha actualizado su biografía"
        },
        "business_address": "Dirección profesional",
        "business_details": "Detalles de la empresa",
        "certification": "Certificación",
        "certifications": "Certificaciones",
        "client_imports": {
          "fields": {
            "excel_file": {
              "helper_text": "Sólo se admiten archivos Excel con extensión .xslx",
              "placeholder": "Envíe aquí su archivo de importación de Excel"
            }
          }
        },
        "coaching_preferences": "Preferencias de los entrenadores",
        "curriculum_vitae": "Curriculum vitae",
        "curriculum_vitae_intro": "El CV comercial difiere del CV por defecto porque no incluye actividades relacionadas con ONG, organizaciones sin ánimo de lucro o sin ánimo de lucro. Consejo: copie el CV por defecto y luego elimine todas las actividades que no sean comerciales.",
        "edit": "Editar",
        "education": "Educación",
        "end_date": "Fecha final",
        "files": {
          "preview_loading": "Carga previa"
        },
        "group_work": "Trabajo en grupo",
        "help": "Ayuda",
        "internal_only": "Sólo para uso interno, no visible públicamente",
        "job_information": "Información sobre el puesto",
        "language": "Idioma",
        "languages": "Idiomas",
        "name": "Nombre",
        "personal_information": "Información personal",
        "preview": "Vista previa",
        "profile_photo": "Foto de perfil",
        "prompt": "Aprobar o rechazar",
        "publication": "Publicación",
        "publications": "Publicaciones",
        "reject": "Rechazar",
        "rematch": "Revancha",
        "rematch_client": "Cliente de revancha",
        "remove": "Eliminar",
        "save": "Guardar",
        "short_bio": "Breve biografía",
        "unloq_only_contract_fields": {
          "body": "Los siguientes campos son gestionados por UNLOQ y <b>NO</b> son visibles y editables por los propios asociados. Estos campos se utilizan en el procedimiento de selección de asociados.",
          "title": "Campos gestionados por UNLOQ"
        },
        "untranslated": "Traducciones pendientes",
        "validation": {
          "date_of_birth": {
            "not_adult": "Debe facilitar una fecha de nacimiento de al menos 16 años"
          },
          "end_date_after_start_date": "La fecha de finalización debe ser posterior a la de inicio",
          "linkedin": {
            "invalid": "Debe ser una URL válida de LinkedIn"
          },
          "start_date_invalid": "La fecha de inicio no es válida",
          "start_date_required": "Se requiere fecha de inicio"
        },
        "work_experience": "Experiencia laboral"
      },
      "associate_lists": {
        "add_failed": "Fallo al añadir a la lista de asociados",
        "added": "Añadido a la lista de asociados"
      },
      "clients": {
        "rematch_failed": "El cliente no ha podido ser reenviado, por favor contacte con soporte",
        "rematch_success": "Cliente reemparejado con éxito"
      },
      "coaches": {
        "address": {
          "account_holder_name": "Nombre del titular de la cuenta",
          "address_line": "Dirección",
          "bank_account_number": "Número de cuenta bancaria",
          "bank_identification": "Identificación bancaria (BIC/SWIFT)",
          "bank_name": "Nombre del banco",
          "city": "Ciudad",
          "company": "Nombre de la empresa",
          "country": "País",
          "helper_texts": {
            "bank_account_number": "IBAN u otro número de cuenta bancaria",
            "bank_identification": "Obligatorio si el número de cuenta no es el IBAN",
            "chamber_of_commerce": "Cámara de Comercio"
          },
          "house_number": "Número de casa",
          "region": "Región",
          "zip_code": "Código postal"
        },
        "all": "Todos los entrenadores registrados",
        "associate_status": {
          "active": "Activo",
          "label": "Estado",
          "none": "Sin estado",
          "offboard": "Fuera de borda",
          "on_hold": "En espera"
        },
        "avatars": {
          "approved": "Tu nueva foto de perfil ha sido aprobada",
          "avatar": {
            "label": "Avatar",
            "no_avatar": "No hay avatar",
            "upload": "Subir nuevo avatar"
          },
          "not_approved_yet": "Tu nueva foto de perfil aún no ha sido aprobada. La revisaremos lo antes posible",
          "photo": {
            "label": "Foto de perfil",
            "no_photo": "No hay foto de perfil"
          },
          "preview": "Vista previa",
          "rejected": "Tu nueva foto de perfil ha sido rechazada, por favor sube otra",
          "upload": "Subir una nueva foto de perfil",
          "uploaded": "Se ha subido una nueva foto, la revisaremos lo antes posible"
        },
        "bio": {
          "approved": "Su nueva biografía ha sido aprobada",
          "not_approved_yet": "Tu nueva biografía aún no ha sido aprobada",
          "rejected": "Su nueva biografía ha sido rechazada, por favor escriba otra",
          "updated": "Su biografía ha sido actualizada y está pendiente de aprobación"
        },
        "both": "Ambos",
        "cards_view": "La vista de las tarjetas",
        "chamber_of_commerce": "Número de registro mercantil",
        "coach_type": "Tipo de autocar",
        "coach_types": {
          "a": "Entrenador principal y formador",
          "t": "Formador Senior y Facilitador"
        },
        "coaching_level": "Entrenamiento de nivel",
        "coaching_levels": {
          "executive": "Ejecutivo",
          "leadership_coaching": "Coaching de liderazgo",
          "top_executive": "Alto ejecutivo",
          "young_talent_professional": "Profesional de talento joven"
        },
        "commercial_cv": "CV comercial",
        "contract_remarks": "Observaciones",
        "contract_type": "Tipo de contrato",
        "contracts": {
          "core_team": "Miembro del equipo central",
          "fixed_hours": "Asociado con horario fijo",
          "project_basis": "Asociado por proyecto"
        },
        "core_team": "Equipo principal",
        "cv": {
          "affiliations": "Afiliaciones",
          "approach": "Acérquese a",
          "branch_experiences": "Experiencias de las sucursales",
          "certifications": "Certificaciones",
          "confirm_copy_cv": "¿Estás seguro de que quieres copiar toda la información de tu CV por defecto?<br /><br />Ten en cuenta que <b>SÓLO PUEDES HACER ESTO UNA VEZ</b>, ¡después el botón desaparecerá!",
          "contract_type": "Tipo de contrato",
          "copy_default_cv": "¿Copiar su CV por defecto?",
          "copy_from_default_button": "Copiar del CV por defecto",
          "education": "Educación",
          "examples": {
            "affiliation": {
              "acronym": "Ejemplo: EMCC",
              "name": "Ejemplo: EMCC Global",
              "number": "Ejemplo: 100"
            },
            "certification": {
              "end_date": "Ejemplo: Fecha hasta la que fue válida la certificación (vacío = indefinidamente)",
              "institution": "Ejemplo: Federación Internacional de Coaching",
              "name": "Ejemplo: Coach profesional certificado por la ICF",
              "start_year": "Ejemplo: Año de validez de la certificación",
              "topic": "Ejemplo: Curso de coaching"
            },
            "education": {
              "end_date": "Ejemplo: Fecha hasta la que la educación era válida (vacío = indefinidamente)",
              "field_of_study": "Ejemplo: Psicología clínica",
              "school": "Ejemplo: Harvard",
              "specialisation": "Ejemplo: Dinámica de grupo",
              "start_year": "Ejemplo: Año a partir del cual se imparte la enseñanza"
            },
            "publication": {
              "authors": "Ejemplo: Doe, J. / Doe. J. & Doedel, K.P.",
              "journal_title": "Ejemplo: Diario de la conciencia",
              "medium": "Ejemplo: Televisión",
              "page_range": "Ejemplo: 201-220",
              "publisher": "Ejemplo: Routledge",
              "publisher_city": "Ejemplo: Nueva York",
              "title": "Ejemplo: El autorreconocimiento en la vida cotidiana",
              "url": "Ejemplo: https://www.cbs.com/shows/the-late-show-with-stephen-colbert/",
              "various_title": "Ejemplo: Invitado en The Late Show with Stephen Colbert.",
              "volume_number": "Ejemplo: 5(2)",
              "year": "Ejemplo: (2022)"
            },
            "publications": {
              "type": "Elija el tipo de publicación"
            }
          },
          "fields": {
            "affiliation": {
              "acronym": "Acrónimo",
              "add": "Añadir otra afiliación",
              "name": "Nombre",
              "number": "Número de afiliado"
            },
            "approach": "Breve biografía",
            "approach_current": "Biografía actual",
            "approach_helper_text": "Escriba su biografía profesional en inglés",
            "certification": {
              "add": "Añadir otra certificación",
              "end_date": "Fecha final",
              "institution": "Organización emisora",
              "level": "Nivel",
              "name": "Nombre del certificado",
              "start_year": "Año de inicio",
              "topic": "Asunto / Tema"
            },
            "certification_dropzone": "Envíe aquí sus archivos de diplomas / certificaciones / testimonios",
            "certification_dropzone_helpertext": "Sólo se pueden cargar archivos PDF, PNG o JPG",
            "education": {
              "add": "Añadir otra educación",
              "degree": "Titulación",
              "end_date": "Fecha final",
              "field_of_study": "Ámbito de estudio",
              "school": "Escuela",
              "specialisation": "Especialización",
              "start_year": "Año de inicio"
            },
            "job_title": "Puesto actual",
            "job_title_helper_text": "Escriba su cargo en inglés",
            "occupations": "Ocupaciones",
            "publication": {
              "add": "Añadir otra publicación",
              "article": "Artículo",
              "authors": "Autor(es)",
              "book": "Libro",
              "choose": "Elija el tipo de publicación",
              "journal_title": "Nombre de la revista",
              "medium": "Medio",
              "page_range": "Páginas",
              "publisher": "Editorial",
              "publisher_city": "Sucursal del editor Ciudad",
              "title": "Título",
              "title_article": "Título del artículo",
              "title_book": "Título del libro",
              "url": "Enlace de Internet",
              "various": "Varios",
              "volume_number": "Número de volumen (Número de edición)",
              "year": "Año"
            },
            "publication_type": "Tipo de publicación",
            "specializations": "Especializaciones",
            "work_experience": {
              "add": "Añadir otra experiencia laboral",
              "branch_experience": "Experiencias de las sucursales",
              "company_name": "Nombre de la empresa",
              "description": "Descripción del trabajo",
              "end_date": "Fecha de finalización (vacío = indefinidamente)",
              "role": "Función / título",
              "start_date": "Fecha de inicio"
            }
          },
          "headers": {
            "certifications": "Formación / Certificaciones"
          },
          "help": {
            "avatars": {
              "help": "Consejos fotográficos"
            }
          },
          "intro": {
            "affiliations": "Enumere todas las asociaciones profesionales a las que está afiliado.",
            "certifications": "Enumere aquí todas sus Licencias y Certificados para métodos, enfoques, evaluaciones, pruebas y otros asuntos relacionados con actividades de aprendizaje, desarrollo y orientación. Debe tratarse de su educación (orientada profesionalmente), formación, cursos y talleres con un certificado / licencia / sello oficial.",
            "contract_type": "Esta sección es de uso interno y está gestionada por un responsable de programa. Aquí no puede realizar ajustes usted mismo.",
            "education": "Enumere aquí todos los estudios y cursos de formación que ha seguido (incluidos los que no ha completado). Debe tratarse de estudios, titulaciones o diplomas reconocidos oficialmente por el gobierno.",
            "publications": "Enumere todas las publicaciones en las que haya escrito o colaborado. Tenga en cuenta que, para una presentación uniforme, utilizamos las normas APA internacionales. Para cada campo proporcionamos un ejemplo, utilícelos para rellenar correctamente cada campo.",
            "work_experience": "Enumere aquí su experiencia laboral relevante, no sólo la relacionada con el coaching y la formación, sino toda su experiencia laboral."
          },
          "job_experience": "Empleo y experiencia laboral",
          "job_information": "Información sobre el puesto",
          "job_title": "Función",
          "level_coaching": "Nivel de entrenador",
          "level_ecoaching": "Nivel de e-coaching",
          "professions": "Ocupaciones",
          "publications": "Publicaciones",
          "specializations": "Especializaciones",
          "work_experiences": "Experiencias laborales"
        },
        "date_of_birth": "Fecha de nacimiento",
        "default_cv": "CV por defecto",
        "ecoaching_level": "Nivel ecoaching",
        "ecoaching_levels": {
          "level_i": "Nivel I",
          "level_ii": "Nivel II",
          "level_iii": "Nivel III",
          "none": "Ninguno"
        },
        "first_name": "Nombre",
        "group_work": {
          "add": "Añadir otra experiencia de trabajo en grupo",
          "select": "Seleccione el tipo de trabajo en grupo"
        },
        "group_work_location": {
          "label": "En línea / Fuera de línea"
        },
        "group_work_locations": {
          "both": "Ambos",
          "offline": "Fuera de línea",
          "online": "En línea"
        },
        "group_work_type": {
          "label": "Diseño / Entrega"
        },
        "group_work_types": {
          "both": "Ambos",
          "delivery": "Entrega",
          "design": "Diseño"
        },
        "id_number": "Número de identificación (pasaporte u otro documento de identidad)",
        "intro": {
          "addresses": "Indique a continuación los datos de su empresa. Tenga en cuenta que solo es obligatorio rellenar el campo \"BIC/SWIFT\" si no dispone de un número de cuenta bancaria IBAN.",
          "avatars": "Cargue su foto de perfil. Buscamos retratos impactantes en los que las personas sean el centro de atención. Un look & feel limpio sin perder la calidez del aspecto humano. Las fotos también se caracterizan por la profundidad de campo y el uso de luz natural.<br /><br />Ten en cuenta que buscamos fotos limpias, sin muchos colores brillantes ni objetos que distraigan en el fondo.<br /><br />Piensa en tu foto de LinkedIn, pero en una versión más relajada. Tu foto debe mostrar quién eres sin olvidar que este es un entorno de negocios. Pregúntate: ¿qué foto publicarías en tu LinkedIn frente a la que publicarías estando de vacaciones?<br /><br />Trata de evitar: Pantalones cortos, camisetas sin mangas o llamativas, sandalias<br /><br />La foto que subas aquí se mostrará en la página \"Nuestro equipo\" del sitio web de UNLOQ y (si procede) en las páginas web de los CoachDesks de los clientes.<br /><br /><b>Nota:</b> una vez que la subas, la foto tendrá que ser aprobada por UNLOQ antes de que sea visible en el sitio web de UNLOQ y en los CoachDesks y como avatar dentro del Campus. Tan pronto como se complete la aprobación, esta foto aparecerá en tu perfil.",
          "bio": "Por favor, escriba una breve biografía sobre usted. Esta biografía se añadirá a su CV y también será visible en la página <a href=\"https://unloq.org/our-team/\" target=\"_blank\">Nuestro equipo</a> del sitio web de UNLOQ y (si procede) en los CoachDesks.<br /><br />Se establecen los siguientes requisitos:<ul><li>Mín 400 caracteres</li><li>Máx 800 caracteres</li><li>Escrito en tercera persona</li><li>Escrito en inglés</li></ul><i>EJEMPLO: Marcel tiene 15 años de experiencia como coach en procesos de desarrollo y transición de liderazgo. Le interesan los temas relacionados con la diversidad, la equidad y la inclusión y su enfoque práctico se basa en la psicología oriental y occidental. Clientes y compañeros dicen que es abierto y flexible, que va al grano rápidamente y que tiene un gran sentido de lo que se necesita.</i><br /><br /><b>Nota:</b> una vez que actualices tu biografía, tendrá que ser aprobada por UNLOQ antes de que sea visible en el sitio web de UNLOQ y en CoachDesks. Tan pronto como se complete la aprobación, esta biografía aparecerá en nuestro perfil.",
          "bio_no_cv_yet": "Por favor, facilite primero información sobre su trabajo actual antes de redactar su breve biografía.",
          "group_work": "Indique aquí su experiencia y sus preferencias en distintos tipos de trabajo en grupo. Puede especificar para cada tipo de trabajo en grupo si prefiere realizarlo fuera de línea, en línea o ambos y si tiene experiencia en el diseño, la realización o ambos.",
          "job_information": "Proporcione información sobre su trabajo actual",
          "languages": "Por favor, añada todos los idiomas que habla junto con el nivel de competencia.",
          "personal_information": "En esta sección de tu perfil puedes introducir toda tu información personal básica. Ten en cuenta que parte de esta información será visible en la página <a href=\"https://unloq.org/our-team/\" target=\"_blank\">\"Nuestro equipo\"</a> del sitio web de UNLOQ y en los CoachDesks. Los campos marcados como \"sólo para uso interno\" no serán visibles públicamente."
        },
        "languages": {
          "add": "Agregar otro idioma",
          "select": "Seleccionar idioma",
          "select_level": "Seleccionar nivel de idioma"
        },
        "last_name": "Apellido",
        "linkedin": "LinkedIn",
        "list_view": "vista de lista",
        "multiple_answers_possible": "Múltiples respuestas posibles",
        "nationalities": "Nacionalidades",
        "native_background": "Origen / raíces nativas",
        "organization_name": "Nombre de la organización",
        "phone_number": "Número de teléfono",
        "place_of_birth": "Lugar de nacimiento",
        "profile": "Perfil",
        "residence_country": "País de residencia",
        "search_in": "Buscar en",
        "secondary_phone_number": "Número de teléfono secundario",
        "select_one_answer": "Seleccione una respuesta",
        "timezone": "Zona horaria",
        "training_level": "Facilitación del nivel",
        "training_levels": {
          "level_i": "Nivel I",
          "level_ii": "Nivel II",
          "level_iii": "Nivel III",
          "none": "Ninguno"
        },
        "unloq": "Asociados UNLOQ",
        "unloq_only": "¿Sólo asociados UNLOQ?"
      },
      "filters": {
        "branch_experiences": "Experiencias de las sucursales",
        "countries": "Países",
        "job_title": "Puesto",
        "languages": "Idiomas",
        "miscellaneous": "Varios",
        "name_or_email": "Nombre o correo electrónico",
        "organization": "Organización",
        "professions": "Ocupaciones",
        "programs": "Programas",
        "remarks": "Observaciones",
        "specializations": "Especializaciones",
        "timezones": "Husos horarios"
      },
      "select": {
        "associate_list": "Seleccionar lista de asociados",
        "coach": "Seleccionar entrenador",
        "program": "Seleccionar programa"
      },
      "tours": {
        "coach_edit": {
          "step_coach": {
            "text_1": "¡Bienvenido a la página de edición de perfil!",
            "text_2": "En esta pestaña debe rellenar sus datos principales.",
            "text_3": "La información que introduzca aquí se utilizará para emparejarlo con un cliente y generar su currículum vítae."
          },
          "step_commercial_cv": {
            "text_1": "La pestaña <b>%{commercial_cv}</b> es similar a la pestaña CV predeterminado, salvo que la información que se introduce aquí está destinada específicamente a los clientes comerciales."
          },
          "step_copy": {
            "text_1": "Puede utilizar el botón <b>%{button_name}</b> si aún no se ha creado ningún CV comercial.",
            "text_2": "El botón copia toda la información de su CV por defecto, tras lo cual puede cambiar o eliminar determinadas partes."
          },
          "step_default_cv": {
            "text_1": "En esta pestaña se compone su currículum vitae por defecto.",
            "text_2": "Por favor, rellena estos datos en la medida de lo posible, para que UNLOQ pueda adecuar mejor tus capacidades a las de nuestros clientes.",
            "text_3": "También utilizamos estos datos para generar automáticamente documentos de CV.",
            "text_4": "Los documentos de CV generados sólo se compartirán internamente o con nuestros clientes."
          },
          "step_help": {
            "text_1": "Este botón abre de nuevo este recorrido de ayuda. Así que, si desea algunas explicaciones más adelante, haga clic en este botón."
          }
        }
      }
    },
    "register": {
      "create": {
        "flash_email_invalid": "El inicio de sesión único ha fallado porque no se ha encontrado un correo electrónico válido. Por favor, intente el inicio de sesión normal en su lugar.",
        "flash_error": "El inicio de sesión único ha fallado, por favor inténtelo con el inicio de sesión normal.",
        "flash_success": "Su cuenta ha sido creada"
      },
      "new": {
        "btn": "Crear mi cuenta",
        "description": "Está a sólo un minuto de hacer un cambio positivo en tu vida.",
        "edit_name": "Editar nombre",
        "flash_error": "No se ha podido crear su cuenta. ",
        "flash_success": "Confirme su dirección de correo electrónico para continuar",
        "title": "Regístrese para obtener su cuenta Campus UNLOQ."
      }
    },
    "reply": "Respuesta",
    "results": "Resultados",
    "reviews": "Reseñas",
    "role": "Rol",
    "roles": {
      "admin": "Admin",
      "client": "Cliente",
      "coach": "Coach",
      "organization_manager": "Responsable de Organización"
    },
    "search": {
      "clear": "limpiar búsqueda",
      "placeholder": "Busca..."
    },
    "search_client": "Buscar cliente",
    "search_coach": "Buscar entrenador",
    "search_respondent": "Buscar encuestado",
    "select_client": "Seleccionar cliente",
    "select_coach": "Seleccionar entrenador",
    "select_language": "Seleccionar idioma",
    "select_language_level": "Seleccionar competencia",
    "select_program": "Seleccionar programa",
    "select_questionnaire": "Seleccionar cuestionario",
    "select_respondent": "Seleccionar encuestado",
    "send_pdf_reports": "Enviar informes en PDF",
    "send_pdf_reports_failed": "No se pueden enviar informes en PDF, no todos los participantes han rellenado los cuestionarios",
    "send_pdf_reports_success": "Informes PDF enviados correctamente",
    "sessions": {
      "create": {
        "captcha_invalid": "Captcha inválido: por favor, asegúrese de que es humano y no un robot.",
        "invalid": "Correo electrónico o contraseña no válidos. Por favor, inténtalo de nuevo."
      },
      "verify_2fa_code": {
        "btn": "Verifcar",
        "label": "Introduzca el código de autenticación de dos factores"
      }
    },
    "settings": "Ajustes",
    "shared_by": "Compartido por: ",
    "shared_mailer": {
      "dear": "Estimado",
      "note": "Nota: este mensaje confidencial se ha generado automáticamente.",
      "regards": "Saludos cordiales,",
      "sender": "El equipo del Campus UNLOQ"
    },
    "show_all": "mostrar todo",
    "sidemenu": {
      "clients": "Clientes",
      "dialogs": "Diálogos",
      "language": "Idioma",
      "library": "Caja de herramientas",
      "privacy": "Política de privacidad",
      "privacy_policy": "Política de privacidad",
      "privacy_regulations": "Normativa sobre privacidad",
      "support": "Ayuda",
      "terms": "Condiciones generales",
      "user_terms": "Condiciones de uso"
    },
    "signup": {
      "confirm_email": {
        "description": "Compruebe si ha recibido un correo electrónico de confirmación. Haga clic en el enlace del correo electrónico para confirmar su dirección de correo electrónico.<br><br>Si no ha recibido ningún correo electrónico, compruebe su carpeta de correo no deseado.",
        "flash_success": "Su cuenta está activada.",
        "title": "Confirme su dirección de correo electrónico"
      },
      "new": {
        "already_account": "¿Ya tiene una cuenta?",
        "btn": "Inscríbase ahora",
        "description": "Regístrese para utilizar Campus UNLOQ y conseguir conversaciones de coaching más atractivas, impactantes y seguras.",
        "email_already_exists": "La dirección de correo electrónico ya existe.",
        "organization_description": "Este formulario de inscripción está destinado específicamente a %{name}. Sólo puede registrarse utilizando una dirección de correo electrónico @%{domain}.",
        "organization_domain_error": "El correo electrónico no es válido: el dominio de la dirección de correo electrónico debe formar parte de la organización / institución.",
        "title": "Eleve el nivel de su coaching."
      }
    },
    "simple_form": {
      "error_notification": {
        "default_message": "Revise los problemas a continuación:"
      },
      "no": "No",
      "required": {
        "mark": "*",
        "text": "Requerido"
      },
      "yes": "Sí"
    },
    "spinner": {
      "title": "Por favor, espere..."
    },
    "state": "Estado",
    "status": "Estado",
    "support": {
      "array": {
        "last_word_connector": " y ",
        "two_words_connector": " y ",
        "words_connector": ", "
      },
      "check_manual": "Bienvenido a la página de soporte. En el manual encontrará mucha información adicional, haga clic aquí: ",
      "help_text": "Si su pregunta no encuentra respuesta allí, envíeme un correo electrónico a esta dirección ",
      "help_text2": "o llámeme en horario de oficina al",
      "help_text3": "Estaré encantada/o de ayudarle",
      "manual": "Manual",
      "title": "Ayuda"
    },
    "time": {
      "am": "am",
      "formats": {
        "default": "%A, %-d de %B de %Y %H:%M:%S %z",
        "dialogue": "%a %e %b %Y %H:%M %Z",
        "long": "%-d de %B de %Y %H:%M",
        "no_timezone": "%a, %d %b %Y %H:%M:%S",
        "notime": "%e %b %Y",
        "short": "%-d de %b %H:%M"
      },
      "pm": "pm"
    },
    "timesheets": {
      "clients_title": "Sesiones con clientes",
      "description": "A continuación puede crear resúmenes del número total de horas registradas por programa, cliente y periodo de tiempo. Sólo se pueden añadir o modificar horas a través de un registro de sesión de cliente.",
      "export": {
        "all_figures": "Todas las cifras",
        "confirm": "Está descargando información confidencial, ¿está seguro/a?",
        "details": "Detalles de la sesión",
        "key_figures": "Cifras clave"
      },
      "title": "Hoja de horas"
    },
    "title_date": "Fecha",
    "toastrs": {
      "copied": "¡Copiado!",
      "sent": "¡Enviado!"
    },
    "total": "Total",
    "total_clients": "Número total de clientes",
    "total_costs": "Costes totales",
    "trestle": {
      "confirmation": {
        "cancel": "Cancelar",
        "delete": "Borrar",
        "title": "¿Estás seguro?"
      },
      "dialog": {
        "error": "La solicitud no pudo ser completada."
      },
      "footer": "Patrocinado por Trestle",
      "helpers": {
        "page_entries_info": {
          "more_pages": {
            "display_entries": "Mostrando %{entry_name} <strong>%{first}&nbsp;-&nbsp;%{last}</strong> of <b>%{total}</b>"
          },
          "one_page": {
            "display_entries": {
              "one": "Mostrando <strong>1</strong> %{entry_name}",
              "other": "Mostrando <strong>total %{count}</strong> %{entry_name}",
              "zero": "No %{entry_name} encontrado"
            }
          }
        }
      },
      "onboarding": {
        "no_admins": "Para poder ingresar, por favor crea una cuenta de administrador en <code>app/admin</code>.",
        "no_form": "Por favor define una forma de bloque o crea un parcial <code>_form.html</code>.",
        "no_template": "Para personalizar esta plantilla, por favor crea <code>%{path}</code>.",
        "welcome": "Bienvenido a Trestle"
      },
      "title": "Trestle Administrador",
      "ui": {
        "toggle_navigation": "Cambiar (modo de) navegación",
        "toggle_sidebar": "Alternar la barra lateral"
      },
      "version": "Version"
    },
    "true": "Sí",
    "user_mailer": {
      "email_confirmation": {
        "btn": "Confirme su e-mail",
        "message": "Antes de que podamos activar su nueva cuenta, necesitamos confirmar su dirección de correo electrónico. Por favor, haga clic en el enlace de abajo para confirmar su correo electrónico y continuar en UNLOQ Campus.",
        "subject": "Campus UNLOQ: confirma tu dirección de correo electrónico",
        "thanks": "¡Gracias por unirte a UNLOQ Campus!"
      }
    },
    "users": {
      "active_explanation": "No marcado = inactivo = archivado en la página de resumen de clientes (para el coach).",
      "already_account": "¡ya tiene una cuenta! Le enviaremos una invitación para que se una a tu conversación.",
      "coach_for_client": "Coach para este cliente",
      "copy_to_clipboard_tooltip": "Copie el enlace de invitación y envíe este enlace al cliente con <br/>una aplicación de correo electrónico externa (no utilice este enlace usted mismo).",
      "edit_user": "Editar usuario",
      "empty": "No se han encontrado clientes.",
      "enable_rating": "De este modo, este cliente puede puntuarle<br/> y escribir un texto testimonial.",
      "organizations_label": "Responsable(s) de organización para:",
      "programs_label": "Programa(s): A la cuenta de usuario se le pueden asignar uno o más programas y seleccionar un rol por programa.",
      "remove_membership": "Eliminar la afiliación",
      "remove_program": "Quitar programa",
      "resend_confirm": "¿Está seguro de que desea (re)enviar un correo electrónico de registro o confirmación?",
      "resend_tooltip": "Reenvíe el correo electrónico de confirmación de registro <br/>a este usuario.",
      "roles_empty": "A la cuenta de usuario hay que asignarle uno o varios programas."
    },
    "views": {
      "pagination": {
        "first": "Primero",
        "last": "Última/o",
        "next": "Siguiente",
        "previous": "Anterior",
        "truncate": "..."
      }
    },
    "your_coach": "Su coach es:"
  },
  "fr": {
    "account_only": "Uniquement compte",
    "accounts": {
      "app_verify_label": "Saisissez le code à six chiffres du",
      "curriculum_vitae": {
        "approach_required": "L'approbation est requise pour la langue anglaise",
        "approach_too_long": "Votre bio est trop longue, le maximum est de 800 caractères",
        "approach_too_short": "Votre biographie est trop courte, le minimum est de 400 caractères",
        "copy_from_default_success": "Le CV par défaut a été copié avec succès dans le CV commercial",
        "job_title_and_approach_required": "L'intitulé du poste et l'approche sont requis pour la langue anglaise",
        "job_title_required": "Veuillez indiquer l'intitulé de votre poste actuel"
      },
      "disable": "Désactiver",
      "disable_title": "Vous pouvez désactiver cette méthode d'authentification à deux facteurs ou passer à la méthode SMS.",
      "edit": {
        "flash_success": "Votre profil a été mis à jour avec succès",
        "language_label": "La langue par défaut de ce compte pour les e-mails, et la langue préférée pour la présentation du site :"
      },
      "edit_2fa": {
        "authenticator_title": "Scannez ce code QR avec votre application d'authentification :",
        "choose_below": "Choisissez ci-dessous la méthode que vous souhaitez utiliser. Vous pouvez toujours changer de méthode par la suite.",
        "description": "Il existe deux façons d'utiliser l'authentification à deux facteurs :",
        "no_app_description": "Google Authenticator est une application 2FA gratuite. Téléchargez-la sur votre appareil portable en recherchant \"Google Authenticator\" sur le Play Store ou l'App Store.",
        "no_app_title": "N'avez-vous pas l'application ?",
        "option1": "via une application d'authentification",
        "option2": "par SMS",
        "phone_number_label": "Saisissez votre numéro de téléphone :",
        "sms_description": "Avec cette méthode d'authentification à 2 facteurs (2FA), vous recevrez automatiquement un nouveau code par SMS sur votre téléphone portable à chaque fois que vous vous connecterez. Saisissez votre numéro de téléphone portable ci-dessous pour activer cette méthode (remarque : ajoutez '+' suivi de l'indicatif de votre pays et supprimez le premier 0 de votre numéro de téléphone).",
        "sms_explanation": "Cliquez sur le bouton ci-dessous pour recevoir un code SMS sur votre téléphone portable afin de désactiver le 2FA.",
        "sms_flash_error": "Le numéro de téléphone n'est pas valable ou le code n'a pas pu être envoyé.",
        "sms_flash_success": "Votre jeton a été envoyé à votre numéro de téléphone.",
        "sms_send_btn": "Vérifiez le numéro de téléphone",
        "sms_verify_btn": "Vérifiez le code",
        "sms_warning": "<b>Important:</b> selon le pays dans lequel vous recevez des messages, les réglementations locales peuvent restreindre l'envoi de SMS Campus afin d'éviter les spams. Par conséquent, nous recommandons d'activer le 2FA à l'aide de Google Authenticator.",
        "title": "l'authentification à deux facteurs ajoute une sécurité supplémentaire à votre compte. Outre votre mot de passe personnel, vous avez besoin d'un deuxième moyen de sécurité pour vous connecter."
      },
      "edit_password": {
        "at_least": "Au moins",
        "capital": "lettre majuscule",
        "characters": "caractères",
        "description": "Saisissez le mot de passe d'utilisateur actuel et deux fois le nouveau mot de passe pour modifier le mot de passe actuel.",
        "letter": "lettre",
        "new_password": "Mot de passe",
        "not_matching": "Les mots de passe sont identiques",
        "number": "numéro",
        "old_password": "Ancien mot de passe",
        "special": "caractère spécial",
        "strength_title": "Le(s) mot(s) de passe doit(vent) répondre aux exigences suivantes :",
        "title": "Modifier le mot de passe"
      },
      "enable": "Activer",
      "flash_2fa_disabled": "L'authentification à deux facteurs a été désactivée.",
      "flash_2fa_enabled": "L'authentification à deux facteurs a été activée.",
      "flash_2fa_error": "Le code d'authentification à deux facteurs était incorrect. Veuillez réessayer.",
      "show_phone_number_verification": {
        "label": "Code :",
        "resend_link": "nouveau code SMS",
        "resend_title": "Campus UNLOQ vient d'envoyer un SMS avec un code sur votre téléphone portable, saisissez le code ici pour valider. En cas de ne pas avoir reçu de code dans les 2 minutes qui ont suivi l'envoi, demandez-en un",
        "title": "Vérifiez le code SMS"
      },
      "success_2fa": {
        "description": {
          "disabled": "Vous avez désactivé avec succès l'authentification à deux facteurs pour votre compte. Cliquez sur le bouton \"Terminer\" pour finaliser ou sur \"2FA\" pour revenir au menu de la vérification en deux étapes (2FA).",
          "enabled": "Vous avez activé avec succès l'authentification à deux facteurs pour votre compte. Cliquez sur le bouton ci-dessous pour continuer."
        }
      },
      "update_password": {
        "flash_error": "Votre mot de passe actuel n'est pas valable.",
        "flash_success": "Votre mot de passe a été mis à jour avec succès"
      }
    },
    "actions": "Actions",
    "admin": {
      "breadcrumbs": {
        "home": "Accueil"
      },
      "buttons": {
        "delete": "Supprimer",
        "edit": "Éditer %{model_name}",
        "new": "Nouveau",
        "ok": "Valider",
        "save": "Enregistrer",
        "show": "Voir %{model_name}"
      },
      "datepicker": {
        "formats": {
          "date": "d/m/Y",
          "datetime": "d/m/Y H:i",
          "time": "H:i"
        }
      },
      "flash": {
        "create": {
          "failure": {
            "message": "Veuillez corriger les erreurs ci-après.",
            "title": "Attention!"
          },
          "success": {
            "message": "%{model_name} créé(e).",
            "title": "Opération réussie"
          }
        },
        "destroy": {
          "failure": {
            "message": "Suppression impossible.",
            "title": "Attention!"
          },
          "success": {
            "message": "%{model_name} supprimé(e).",
            "title": "Opération réussie"
          }
        },
        "update": {
          "failure": {
            "message": "Veuillez corriger les erreurs ci-après.",
            "title": "Attention!"
          },
          "success": {
            "message": "%{model_name} mis(e) à jour.",
            "title": "Opération réussie"
          }
        }
      },
      "form": {
        "select": {
          "prompt": "- Choisir le/la %{attribute_name} -"
        }
      },
      "format": {
        "blank": "Aucun"
      },
      "search": {
        "placeholder": "Recherche",
        "results": "Résultats de la recherche"
      },
      "table": {
        "headers": {
          "id": "ID"
        }
      },
      "titles": {
        "edit": "%{model_name} (modification)",
        "index": "Liste des %{pluralized_model_name}",
        "new": "%{model_name} (ajout)"
      }
    },
    "are_you_sure": "Êtes-vous sûr ?",
    "assignments": {
      "edit": {
        "add_question": "Ajouter une question",
        "title": "Modifier l'exercice"
      },
      "new": {
        "description": "Créez ici un nouvel exercice, une réflexion ou une autre activité d'apprentissage. La création d'une tâche se compose de deux étapes distinctes :",
        "step1": "Donnez un nom clair à l'exercice (*obligatoire), écrivez le texte d'introduction, l'explication ou l'objectif de la tâche et cliquez sur le bouton \"Sauvegarder\".",
        "step2": "Ajoutez une ou plusieurs questions auxquelles le client doit répondre et cliquez sur le bouton \"Finaliser\".",
        "title": "Ajouter un exercice"
      },
      "questions": {
        "hint": "Celle-ci est la question posée au client.",
        "prompt": "Ajoutez une question à laquelle le client devra répondre.",
        "title": "Ajouter / modifier une question",
        "type": "Type de question"
      },
      "submission": "soumission",
      "title": "Exercice",
      "waiting_for": "En attendant"
    },
    "auth_mailer": {
      "password_reset": {
        "message": "Nous avons reçu une demande de réinitialisation de votre mot de passe pour UNLOQ Campus. C'était vous ? Très bien, cliquez sur le lien ci-dessous et vous pourrez définir un nouveau mot de passe pour votre compte. Ce lien fonctionnera pendant quelques heures.",
        "no_email": "Vous n'avez pas demandé un nouveau mot de passe ? Ne vous inquiétez pas, ignorez cet e-mail et rien ne changera. N'hésitez pas à nous contacter si vous avez des questions.",
        "subject": "Réinitialisation du mot de passe"
      }
    },
    "avatars": {
      "new": {
        "btn_later": "Faites celà plus tard",
        "flash_success": "Votre profil est créé et vous êtes prêt/e",
        "title": "Personnalisez votre compte en affichant une photo de profil."
      }
    },
    "btn": {
      "back": "Revenir en arrière",
      "cancel": "Annuler",
      "change": "Changer",
      "finalize": "Terminer",
      "finish": "Terminer",
      "ok": "OK",
      "save": "Sauver",
      "select_file": "Choisir un fichier",
      "select_logo": "Sélectionner le logo",
      "send": "Envoyer"
    },
    "by": "par",
    "client": {
      "bank_account_number": "Numéro de compte bancaire",
      "chamber_of_commerce": "Chambre de commerce",
      "client_details": "Détails de l'inscription au format PDF",
      "client_details_error": "Doit être un fichier PDF",
      "country": "Pays",
      "dialogs": "Dialogues",
      "dossier": "Journaux de session du client",
      "edit": {
        "title": "Paramètres du client"
      },
      "gender": "Genre",
      "job_title": "Titre du poste",
      "location": "Ville",
      "miscellaneous": "Divers",
      "office_location": "Localisation du bureau",
      "phone_number": "Numéro de téléphone",
      "remarks": "Remarques",
      "show": {
        "title": "Coordonnées du client"
      },
      "time_zone": "Fuseau horaire",
      "title": "Client",
      "vat_number": "Numéro de TVA",
      "zip_code": "Code postal"
    },
    "client_mailer": {
      "existing_user_invitation": {
        "and_program": "et le programme",
        "btn": "Accepter l'invitation",
        "message": "Cliquez ici pour vous inscrire",
        "subject": "vous a invité à un programme au Campus UNLOQ"
      },
      "new_user_invitation": {
        "and_program": "et le programme",
        "btn": "Accepter l'invitation",
        "message": "Cliquez ici pour vous inscrire",
        "subject": "vous a invité à un programme au Campus UNLOQ"
      },
      "rating_enabled": {
        "btn": "ouvrir le Campus UNLOQ",
        "footer": "Veuillez noter :<br/> - Il s'agit d'une demande, pas d'une obligation.<br/> - Vous pouvez modifier une révision rétrospectivement à volonté.<br/> - Vous pouvez spécifier qui est autorisé à voir la révision.<br/>",
        "intro": "aimerait vous poser une question. Auriez-vous l'amabilité d'écrire un commentaire sur la façon dont vous avez vécu le coaching ?",
        "message": "Il existe une fonction spéciale pour cela dans UNLOQ Campus qui est maintenant disponible. <br/>Cliquez ici pour ouvrir la boîte de dialogue dans UNLOQ Campus, puis indiquez le nombre d'étoiles que vous souhaitez attribuer à votre coach et rédigez un avis ou une recommandation.",
        "subject": "souhaite que vous examiniez"
      },
      "remind_user_invitation": {
        "and_program": "et le programme",
        "btn": "Accepter l'invitation",
        "message": "Cliquez ici pour vous inscrire",
        "reminder": "Rappel : ",
        "subject": "vous réinviter à un programme au Campus UNLOQ"
      }
    },
    "client_sessions": {
      "all_programs_ended": {
        "body": "Tous les programmes auxquels participe ce client sont terminés, il n'est plus possible d'ajouter des sessions. Si vous avez des questions à ce sujet, veuillez contacter votre responsable de programme.",
        "title": "Tous les programmes sont terminés"
      },
      "available": "Disponible",
      "btn_new": "Ajouter une session",
      "client": "Client",
      "client_archived": {
        "archived_at": "Ce client a été archivé le %{datetime}",
        "body": "Ce client a été archivé, aucune session ne peut plus être ajoutée. Si vous avez des questions à ce sujet, veuillez contacter votre responsable de programme.",
        "title": "Client archivé"
      },
      "date_exceeded": "ou le contenu du journal de session n'est pas disponible. Après la fin d'un mois, vous disposez de %{days} jours pour créer, modifier ou supprimer un journal de session. Cette période a maintenant expiré.",
      "date_not_in_future": "ne peut pas être dans le futur",
      "duration": "Facturable",
      "duration_nonbillable": "Non facturable",
      "file": "Pièce jointe",
      "file_explanation": "Remarque : un seul fichier par session est autorisé. Si ce journal de session contient déjà une pièce jointe, celle-ci sera remplacée lors du téléchargement d'une nouvelle pièce jointe.",
      "from_date": "De la date",
      "heading": "Journaux de session du client de :",
      "in_minutes": "En minutes",
      "new_client_session": "Nouvel enregistrement de session du client pour :",
      "new_client_session_description": "Les données ci-dessous ne sont pas visibles pour le client. Le nom, le type, la date et la durée du programme sont accessibles au responsable de l'organisation à des fins administratives. Les informations relatives au contenu (confidentiel) ne sont visibles que par vous.",
      "notes": "Notes de la session",
      "number_of": "Sessions",
      "other": "Autres / divers",
      "remaining": "Restant",
      "session": "Session",
      "sessions": "session(s)",
      "title": "Titre",
      "to_date": "À ce jour",
      "tooltip_filter_first": "Veuillez filtrer le programme ou le coach<br/> avant de pouvoir exporter",
      "type": "Écrivez",
      "type__360_assessments": "Évaluations à 360",
      "type_administration": "Administration",
      "type_call": "Appel",
      "type_campus": "Campus UNLOQ",
      "type_cancellation_legit": "Annulation (legitime)",
      "type_cancellation_not_legit": "Annulation (non légitime)",
      "type_face2face": "Face2Face",
      "type_group_coaching": "Coaching de groupe",
      "type_noshow": "Annulation (non-présentation)",
      "type_other": "Autre",
      "type_rescheduled": "Reprogrammé",
      "type_sms": "SMS",
      "type_technical_difficulties": "Difficultés techniques",
      "type_videocall": "Appel vidéo/ Visio",
      "used": "Utilisé"
    },
    "client_signup": {
      "new": {
        "explanation": "Ce formulaire d'inscription est spécifiquement destiné à %{name}. L'inscription est nécessaire pour recevoir des courriels contenant des liens vers notre évaluation à 360° (c.-à-d. le questionnaire). Vous pourrez également ajouter des répondants."
      },
      "new_signup": {
        "btn": "Ajouter des répondants",
        "message": "Cliquez ici pour ajouter des répondants",
        "subject": "Vous avez été inscrit avec succès à"
      }
    },
    "clients": {
      "archive": "Archives",
      "minutes_per_client": "# Min. par client",
      "reactivate": "Réactiver",
      "tab_active": "Actif/ve",
      "tab_archive": "Archives",
      "title": "Clients",
      "toggle_active": {
        "flash_success": "Le client a réussi à"
      },
      "tooltip_active_and_registered": "Afficher le dialogue (le client est entièrement enregistré)",
      "tooltip_minutes": "Nombre de minutes facturables enregistrées pour ce client",
      "tooltip_not_active": "Afficher le dialogue (le client doit recevoir un e-mail d'invitation)",
      "tooltip_not_registered": "Afficher le dialogue (le client n'est pas encore enregistré)"
    },
    "coach_mailer": {
      "batched_new_clients": {
        "footer": "Si vous avez des questions à ce sujet, veuillez contacter le responsable du programme. Nous vous souhaitons un voyage réussi et inspirant.",
        "message": "Un ou plusieurs nouveaux clients ont été ajoutés au programme pour vous : ",
        "subject": "Vous avez un ou plusieurs nouveaux clients pour le"
      },
      "client_rematched": {
        "footer": "Si vous avez des questions à ce sujet, veuillez contacter le responsable du programme. Nous vous souhaitons un voyage réussi et inspirant.",
        "message": "Un client a été réaffecté au programme pour vous : ",
        "subject": "Vous avez un client qui vous est réaffecté pour le.."
      },
      "existing_user_invitation": {
        "click": "Cliquez ici pour vous inscrire",
        "link": "Aller au programme",
        "message": "vous a ajouté au programme %{program} de %{organization} sur le Campus UNLOQ",
        "subject": "vous a invité à un programme"
      },
      "extra_minutes_for_client": {
        "footer": "Ce temps supplémentaire a été ajouté par : ",
        "message": "Le temps total de coaching pour <b>%{client}</b> dans le programme <b>%{program}</b> a été modifié en :",
        "subject": "UNLOQ Campus : minutes supplémentaires pour le client"
      },
      "new_client_matched": {
        "footer": "Si vous avez des questions à ce sujet, veuillez contacter le responsable du programme. Nous vous souhaitons un voyage réussi et inspirant.",
        "message": "Un nouveau client a été ajouté au programme pour vous : ",
        "subject": "Vous avez un nouveau client pour le"
      },
      "new_user_invitation": {
        "click": "Cliquez ici pour vous inscrire",
        "link": "Accepter l'invitation",
        "message": "%{organization} et le programme %{program} sur le campus d'UNLOQ :",
        "subject": "vous a invité à un programme"
      },
      "rating_done": {
        "btn": "ouvrir le Campus UNLOQ",
        "message": "Cliquez ici pour afficher le(s) commentaire(s) :",
        "subject": "L'un de vos clients a créé une critique pour vous."
      }
    },
    "coaches": {
      "find_coach": "Trouver un coach",
      "match_failed": "Une erreur s'est produite et le client n'a pas pu être mis en relation avec cet entraîneur",
      "match_success": "Le client a été jumelé avec succès",
      "modal_title": "Faire correspondre un client à un coach",
      "title": "Coachs"
    },
    "comments": {
      "disabled": "Les notes sont désactivées pour ce programme ou vous n'avez pas les autorisations nécessaires.",
      "email_explanation": "Dix minutes après qu'une note a été placée par quelqu'un, une notification par courrier électronique est envoyée. Veuillez noter que ces notifications sont collectées et envoyées une fois toutes les 10 minutes afin d'éviter les spams\"",
      "explanation": "Notes : communication interne entre l'accompagnateur et les responsables de L&D programme.",
      "new_messages_emails": "Nouveaux courriels de notes",
      "shared": {
        "coach_only": "Entraîneur uniquement",
        "private": "Uniquement les responsables de programmes de L&amp;D",
        "public": "Coachs et responsables de programmes de L&amp;D",
        "title": "Partager avec"
      },
      "subject": "Sujet",
      "title": "Notes",
      "title_placeholder": "Rédigez vos notes sur ce client..."
    },
    "cookies": {
      "cookiebar_link_text": "politique en matière de cookies",
      "cookiebar_link_url": "/pages/cookies",
      "cookiebar_text": "Nous utilisons des cookies pour vous offrir la meilleure expérience possible. Veuillez lire notre %{link} pour plus d'informations sur la manière dont nous utilisons les cookies et sur les raisons pour lesquelles nous les utilisons."
    },
    "copy": "Copier",
    "date": {
      "abbr_day_names": [
        "dim",
        "lun",
        "mar",
        "mer",
        "jeu",
        "ven",
        "sam"
      ],
      "abbr_month_names": [
        null,
        "jan.",
        "fév.",
        "mars",
        "avr.",
        "mai",
        "juin",
        "juil.",
        "août",
        "sept.",
        "oct.",
        "nov.",
        "déc."
      ],
      "day_names": [
        "dimanche",
        "lundi",
        "mardi",
        "mercredi",
        "jeudi",
        "vendredi",
        "samedi"
      ],
      "formats": {
        "default": "%d/%m/%Y",
        "long": "%e %b %Y at %H:%M",
        "notime": "%e %b %Y",
        "short": "%-d %b",
        "trestle_calendar": "%d-%m-%Y",
        "trestle_date": "%e %b %Y"
      },
      "month_names": [
        null,
        "janvier",
        "février",
        "mars",
        "avril",
        "mai",
        "juin",
        "juillet",
        "août",
        "septembre",
        "octobre",
        "novembre",
        "décembre"
      ],
      "order": [
        "day",
        "month",
        "year"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "environ une heure",
          "other": "environ %{count} heures"
        },
        "about_x_months": {
          "one": "environ un mois",
          "other": "environ %{count} mois"
        },
        "about_x_years": {
          "one": "environ un an",
          "other": "environ %{count} ans"
        },
        "almost_x_years": {
          "one": "presque un an",
          "other": "presque %{count} ans"
        },
        "half_a_minute": "une demi‑minute",
        "less_than_x_minutes": {
          "one": "moins d'une minute",
          "other": "moins de %{count} minutes",
          "zero": "moins d'une minute"
        },
        "less_than_x_seconds": {
          "one": "moins d'une seconde",
          "other": "moins de %{count} secondes",
          "zero": "moins d'une seconde"
        },
        "over_x_years": {
          "one": "plus d'un an",
          "other": "plus de %{count} ans"
        },
        "x_days": {
          "one": "%{count} jour",
          "other": "%{count} jours"
        },
        "x_minutes": {
          "one": "%{count} minute",
          "other": "%{count} minutes"
        },
        "x_months": {
          "one": "%{count} mois",
          "other": "%{count} mois"
        },
        "x_seconds": {
          "one": "%{count} seconde",
          "other": "%{count} secondes"
        },
        "x_years": {
          "one": "%{count} an",
          "other": "%{count} ans"
        }
      },
      "prompts": {
        "day": "Jour",
        "hour": "Heure",
        "minute": "Minute",
        "month": "Mois",
        "second": "Seconde",
        "year": "Année"
      }
    },
    "deactivated": "Désactivé",
    "delete": "Supprimer",
    "dialog_successfully_archived": "Dialogue archivé avec succès",
    "dialog_title": {
      "label": "Dialogue en groupe",
      "placeholder": "Écrivez le titre du groupe ici..."
    },
    "dialogs": {
      "a_group_dialog": "un dialogue en groupe",
      "add_participant": "Ajouter un participant à ce dialogue",
      "add_profile_picture": "Personnalisez votre compte en ajoutant une photo de profil et d'autres informations pertinentes. Allez à",
      "already_present": "Un dialogue a déjà été créé auparavant",
      "are_you_sure": {
        "reactivate": "Êtes-vous sûr de vouloir réactiver ce participant?",
        "remove": "Êtes-vous sûr de vouloir retirer ce participant?"
      },
      "are_you_sure_activate": "Êtes-vous sûr de vouloir activer ce dialogue et que les participants reçoivent une invitation par e-mail ?",
      "assignment": {
        "completed_description": "Vous avez répondu à toutes les questions. Rédigez un message d'accompagnement dans le dialogue et remettez votre exercice.",
        "continue": "Continuer",
        "hand_in": "Remettre",
        "hand_in_success": "Votre exercice a été remis avec succès.",
        "open": "Ouvrir",
        "view_answers": "Voir les réponses"
      },
      "autosaved": "Sauvegardé comme concept",
      "btn": {
        "attachment": "Ajouter le contenu de la boîte à outils",
        "new": "Nouveau client / nouveau dialogue"
      },
      "edited": "édité",
      "email_explanation": "Dix minutes après qu'un nouveau message de dialogue a été placé par quelqu'un, une notification par courrier électronique est envoyée. Veuillez noter que ces notifications sont collectées et envoyées une fois toutes les 10 minutes afin d'éviter les spams.\"",
      "empty": "Aucune boîte de dialogue ne correspond à votre requête.",
      "explanation": "Notifications du client : messages de dialogue.",
      "group_success": "Un utilisateur a été ajouté avec succès au dialogue en groupe.",
      "invitation_pending": "Invitation en attente",
      "invite_someone": "commencer le premier",
      "new": {
        "btn": {
          "create_account": "Créer un compte",
          "send": "Envoyer une invitation"
        },
        "description": "Vous pouvez choisir parmi les différentes options ci-dessous :",
        "enter_email": "Saisissez l'adresse e-mail de la personne avec laquelle vous souhaitez entamer un dialogue afin de vérifier si elle possède déjà un compte sur Campus UNLOQ.",
        "extra_information_account_only": "Après la création du compte sans invitation, ce compte ne sera pas disponible pour le client. A un stade ultérieur, vous pourrez (optionnellement) activer le dialogue.",
        "extra_information_already_account": "Après avoir envoyé cette invitation, vous pouvez (optionnellement) ajouter un message de bienvenue dans la fenêtre de dialogue.",
        "extra_information_no_account": "Après avoir envoyé cette invitation, vous pouvez (optionnellement) ajouter un message de bienvenue dans la fenêtre de dialogue. Attention : le client doit accepter l'invitation (lien dans l'e-mail) avant de pouvoir participer.",
        "flash_success_create_account": "Le compte a été créé avec succès.",
        "flash_success_current": "Tous les participants ont été invités et vous êtes prêt/e à envoyer votre premier message.",
        "flash_success_invited": "Le dialogue a été créé et les participants ont été invités.",
        "no_account": "Il semble que cette personne n'a pas encore de compte. Nous allons lui envoyer une invitation afin de pouvoir créer un compte. Souhaitez-vous inclure un nom dans l'invitation ?",
        "options": "<ul><li>A) Démarrer le dialogue avec un client existant</li><li>B) Démarrer le dialogue avec un nouveau client</li><li>C) Créer un compte sans dialogue</li></ul>",
        "participant": {
          "already_account": "a déjà un compte! Cliquez sur le bouton \"Envoyer l'invitation\" pour ajouter cette personne à ce dialogue en groupe",
          "description": "Vous pouvez choisir entre les deux options différentes ci-dessous :",
          "enter_email": "Entrez l'adresse e-mail de la personne que vous souhaitez ajouter à la boîte de dialogue en groupe pour vérifier si elle a déjà un compte avec Campus UNLOQ.",
          "extra_information_no_account": "Le nouveau participant doit d'abord accepter l'invitation (via le lien dans l'e-mail) avant de pouvoir participer à ce dialogue.",
          "no_account": "Cette personne n'a pas encore de compte. Remplissez les informations de base ci-dessous pour créer un compte. Cliquez sur le bouton \"Envoyer l'invitation\" pour ajouter cette personne à ce dialogue en groupe.",
          "options": "<ul><li>A) Ajouter un compte existant à ce dialogue en groupe</li><li>B) Ajouter un nouveau compte à ce dialogue en groupe </li></ul>",
          "title": "Qui voulez-vous ajouter à ce dialogue en groupe ?"
        },
        "title": "Qui voulez-vous inviter ?"
      },
      "new_messages_emails": "Nouveaux messages électroniques de dialogue",
      "no_dialogs": "Vous n'avez pas encore de dialogues.",
      "no_messages": "Aucun message (pour l'instant)",
      "no_program_membership": "Le coach n'a pas (encore) été assigné au programme.",
      "not_logged_in": "Ne s'est pas connecté (encore)",
      "others": "autre(s)",
      "remove_participant": "Retirer le participant",
      "table": {
        "participants": "Participants"
      },
      "unread_message": {
        "one": "message non lu",
        "other": "messages non lus"
      },
      "welcome": "Bienvenus sur votre compte sur Campus UNLOQ.",
      "why_not": "Ici, vous pouvez",
      "with": "Dialogue avec",
      "you": "Vous :",
      "your_responses": "Réponses"
    },
    "download": "Télécharger",
    "edit": "Modifier",
    "email": {
      "delivery_failure": "Échec de l'envoi du courrier électronique"
    },
    "export": "Exportation",
    "false": "Non",
    "flash_archived_success": "a été archivé avec succès",
    "flash_created_success": "a été créé avec succès",
    "flash_deleted_success": "a été supprimé avec succès",
    "flash_enabled_success": "a été activé avec succès",
    "flash_error": "Quelque chose n'a pas fonctionné ou veuillez corriger les erreurs mises en évidence.",
    "flash_updated_success": "a été mis à jour avec succès",
    "footer_text": "Avec plus de 10 ans d'expérience, UNLOQ Campus est devenu un partenaire de confiance pour les organisations nationales et internationales qui exigent des niveaux élevés de confidentialité et de sécurité. UNLOQ Campus est entièrement conforme au GDPR et certifié ISO 27001 pour les activités de développement, de maintenance et d'hébergement sécurisé.",
    "for": "pour",
    "forms": {
      "add_program": "Ajouter un programme",
      "all_coaches": "Tous les coachs",
      "choose": "Choisissez...",
      "choose_program": "Tous les programmes",
      "confirm_password": "Confirmation du mot de passe",
      "consent": "Je comprends et j'accepte l'enregistrement d'informations personnelles tel que décrit dans le <a href='/en/pages/privacy_regulations' target='_blank'>règlement sur la protection de la vie privée</a>",
      "description": "Description",
      "email": "E-mail",
      "first_name": "Prénom",
      "joined": "Créé le",
      "language": "Langue",
      "last_name": "Nom de famille",
      "last_seen": "Dernière connexion",
      "name": "Nom",
      "new_message": "Nouveau message",
      "optional_attachment": "Pièce jointe",
      "organization_name": "Nom de l'organisation",
      "organization_name_unloq": "Nom de l'organisation (tel que registré sur Campus UNLOQ)",
      "password": "Mot de passe",
      "picture": "Photo",
      "select_file": {
        "one": "Sélectionner un fichier",
        "other": "Sélectionner les fichiers"
      },
      "select_from_date": "Sélectionnez la date de début",
      "select_to_date": "Sélectionner la date de fin",
      "terms": "J'ai pris connaissance et accepte les <a href='/en/pages/terms' target='_blank'>termes et conditions </a> ",
      "title": "Titre",
      "type": "Écrivez...",
      "type_here": "Rédigez un message de dialogue..."
    },
    "helpers": {
      "select": {
        "prompt": "Veuillez sélectionner"
      },
      "submit": {
        "create": "Créer un(e) %{model}",
        "submit": "Enregistrer ce(tte) %{model}",
        "update": "Modifier ce(tte) %{model}"
      }
    },
    "how_do_i_enable_javascript": "Comment activer le javascript ?",
    "i18n": {
      "plural": {
        "keys": [
          "one",
          "other"
        ]
      },
      "transliterate": {
        "rule": {
          "À": "A",
          "Â": "A",
          "Æ": "Ae",
          "Ç": "C",
          "È": "E",
          "É": "E",
          "Ê": "E",
          "Ë": "E",
          "Î": "I",
          "Ï": "I",
          "Ô": "O",
          "Ù": "U",
          "Û": "U",
          "Ü": "U",
          "à": "a",
          "â": "a",
          "æ": "ae",
          "ç": "c",
          "è": "e",
          "é": "e",
          "ê": "e",
          "ë": "e",
          "î": "i",
          "ï": "i",
          "ô": "o",
          "ù": "u",
          "û": "u",
          "ü": "u",
          "ÿ": "y",
          "Œ": "Oe",
          "œ": "oe",
          "Ÿ": "Y"
        }
      }
    },
    "javascript_required": "Javascript est nécessaire pour utiliser cette application.",
    "language": {
      "en": "Anglais",
      "es": "Espagnol",
      "fr": "Français",
      "nl": "Néerlandais"
    },
    "last_login": "Dernière connexion",
    "last_modified": "Dernière activité",
    "learning_path": "Parcours d'apprentissage",
    "learning_path_mailer": {
      "progress": {
        "subject": "Votre progression dans le %{program}"
      }
    },
    "library": {
      "add": "Ajouter",
      "are_you_sure": "Êtes-vous sûr/e? Veuillez noter que cet élément sera également supprimé de toutes les boîtes de dialogue où il peut être utilisé.",
      "assignment": "Exercice",
      "delete_folder_confirmation": "Êtes-vous sûr de vouloir supprimer ce dossier et son contenu ?",
      "document": "Document",
      "empty": "Il n'y a pas encore d'éléments dans votre boîte à outils. Cliquez sur le bouton \"+\" pour ajouter un document, une vidéo, un exercice ou tout autre contenu.",
      "empty_folder": "Ce dossier ne contient pas encore d'éléments (visibles). Cliquez sur le bouton \"+\" pour ajouter un document, une vidéo, un exercice ou tout autre contenu.",
      "filters": {
        "assignments": "Exercices",
        "documents": "Documents",
        "embeds": "Vidéos",
        "folders": "Dossiers",
        "images": "Images",
        "uploads": "Documents",
        "videos": "Vidéos"
      },
      "folder": "Dossier",
      "image": "Image",
      "questions": "Questions",
      "video": "Vidéo"
    },
    "license": {
      "description": {
        "business": "Souscrivez cette licence Business à € 83,25 par mois (€ 999,- par an, hors TVA).<br/><br/> ✔️ Comprend 3 comptes Expert et 150 comptes Client.<br/><br/>\r\nComplétez les informations sollicitées ci-dessous et commencez dès aujourd'hui avec votre propre compte Business.",
        "education": "Souscrivez cette licence Éducative Tout-En-Un de 60 jours.<br/><br/> ✔️ Comprend 3 comptes Expert et 150 comptes Client <br/> Les coûts de cette licence sont compris dans votre programme de formation.<br/><br/>\r\nComplétez les informations sollicitées ci-dessous et commencez dès aujourd'hui avec votre propre compte Education.",
        "enterprise": "Souscrivez cette licence Entreprise à € 832,50 par mois (€ 9.999,- par an, hors TVA)<br/><br/> ✔️ Comprend un nombre illimité de comptes Expert et de comptes Client.<br/><br/>\r\nComplétez les informations sollicitées ci-dessous et commencez dès aujourd'hui avec votre propre compte Entreprise",
        "transition": "Souscrivez cette licence spéciale Transition.<br/><br/> ✔️ Comprend 3 comptes Expert et 150 comptes Client.<br/> Vous pouvez utiliser cette licence Campus UNLOQ gratuitement tant que vous disposez d'une licence valable pour Campus UNLOQ <br/><br/>\r\nComplétez les informations sollicitées ci-dessous et commencez dès aujourd'hui avec votre propre compte Campus UNLOQ.",
        "trial": "Inscrivez-vous à cet essai gratuit de 30 jours Tout-En-Un.<br/><br/> ✔️ Sans engagement de votre part.<br/> ✔️ Aucune carte de crédit requise.<br/> ✔️ Comprend 3 comptes Expert et 150 comptes Client.<br/><br/>\r\nComplétez les informations sollicitées ci-dessous et commencez dès aujourd'hui avec votre propre compte gratuit."
      }
    },
    "license_expired": "Ce compte est verrouillé, les réglages ne sont pas possibles.",
    "login": {
      "btn": "Se connecter",
      "password_forgotten": "Avez-vous oublié votre mot de passe ?"
    },
    "manager": "Gestionnaire",
    "menu": {
      "associate_profile": "Profil de l'associé",
      "dialogs": "Dialogues",
      "logout": "Se déconnecter",
      "manage": "Gérer",
      "my_profile": "Mon profil",
      "notifications": "Paramètres de notification",
      "pages": "Pages",
      "pending_clients": "Clients en attente",
      "profile_settings": "Paramètres du profil",
      "questionnaires": "Questionnaires",
      "translations": "Traductions",
      "users": "Utilisateurs"
    },
    "new": "Nouveau",
    "none": "Aucun",
    "not_applicable": "N/A",
    "not_authenticated": "Vous devez être connecté pour effectuer cette action.",
    "not_authorized": "Vous n'êtes pas autorisé/e à effectuer cette action.",
    "notification_mailer": {
      "batched_comments": {
        "btn": "ouvrir le campus UNLOQ",
        "message": "De nouvelles notes concernant un client de Campus pourraient vous intéresser.",
        "subject": "UNLOQ Campus : nouvelle(s) note(s) sur un client"
      },
      "batched_dialog_messages": {
        "link_description": "Pour voir l'intégralité du message et y répondre :",
        "link_text": "visitez le Campus UNLOQ",
        "message": "Vous avez reçu un nouveau message de",
        "subject": "UNLOQ Campus : nouveau(x) message(s)"
      },
      "unsubscribe": "Cliquez ici si vous souhaitez vous désabonner de ces e-mails."
    },
    "notifications": {
      "description": "Placez le bouton sur \"ON\" pour recevoir des notifications. Placez le bouton sur \"OFF\" si vous ne souhaitez pas recevoir de notifications du Campus UNLOQ.",
      "title": "Vous pouvez indiquer ici si vous souhaitez recevoir des emails de Campus UNLOQ lorsqu'il y a de nouveaux messages disponibles pour vous."
    },
    "number": {
      "currency": {
        "format": {
          "delimiter": " ",
          "format": "%n %u",
          "precision": 2,
          "separator": ",",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "€"
        }
      },
      "format": {
        "delimiter": " ",
        "precision": 3,
        "round_mode": "default",
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": "milliard",
            "million": "million",
            "quadrillion": "million de milliards",
            "thousand": "millier",
            "trillion": "billion",
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "octet",
              "other": "octets"
            },
            "eb": "Eo",
            "gb": "Go",
            "kb": "ko",
            "mb": "Mo",
            "pb": "Po",
            "tb": "To"
          }
        }
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "number_short": "No.",
    "ordinalize_full": {
      "n_1": "premier",
      "n_10": "dixième",
      "n_100": "centième",
      "n_11": "onzième",
      "n_12": "douzième",
      "n_13": "treizième",
      "n_14": "quatorzième",
      "n_15": "quinzième",
      "n_16": "seizième",
      "n_17": "dix-septième",
      "n_18": "dix-huitième",
      "n_19": "dix-neuvième",
      "n_1_feminine": "première",
      "n_2": "deuxième",
      "n_20": "vingtième",
      "n_21": "vingt-et-unième",
      "n_22": "vingt-deuxième",
      "n_23": "vingt-troisième",
      "n_24": "vingt-quatrième",
      "n_25": "vingt-cinquième",
      "n_26": "vingt-sixième",
      "n_27": "vingt-septième",
      "n_28": "vingt-huitième",
      "n_29": "vingt-neuvième",
      "n_3": "troisième",
      "n_30": "trentième",
      "n_31": "trente-et-unième",
      "n_32": "trente-deuxième",
      "n_33": "trente-troisième",
      "n_34": "trente-quatrième",
      "n_35": "trente-cinquième",
      "n_36": "trente-sixième",
      "n_37": "trente-septième",
      "n_38": "trente-huitième",
      "n_39": "trente-neuvième",
      "n_4": "quatrième",
      "n_40": "quarantième",
      "n_41": "quarante-et-unième",
      "n_42": "quarante-deuxième",
      "n_43": "quarante-troisième",
      "n_44": "quarante-quatrième",
      "n_45": "quarante-cinquième",
      "n_46": "quarante-sixième",
      "n_47": "quarante-septième",
      "n_48": "quarante-huitième",
      "n_49": "quarante-neuvième",
      "n_5": "cinquième",
      "n_50": "cinquantième",
      "n_51": "cinquante-et-unième",
      "n_52": "cinquante-deuxième",
      "n_53": "cinquante-troisième",
      "n_54": "cinquante-quatrième",
      "n_55": "cinquante-cinquième",
      "n_56": "cinquante-sixième",
      "n_57": "cinquante-septième",
      "n_58": "cinquante-huitième",
      "n_59": "cinquante-neuvième",
      "n_6": "sixième",
      "n_60": "soixantième",
      "n_61": "soixante-et-unième",
      "n_62": "soixante-deuxième",
      "n_63": "soixante-troisième",
      "n_64": "soixante-quatrième",
      "n_65": "soixante-cinquième",
      "n_66": "soixante-sixième",
      "n_67": "soixante-septième",
      "n_68": "soixante-huitième",
      "n_69": "soixante-neuvième",
      "n_7": "septième",
      "n_70": "soixante-dixième",
      "n_71": "soixante-et-onzième",
      "n_72": "soixante-douzième",
      "n_73": "soixante-treizième",
      "n_74": "soixante-quatorzième",
      "n_75": "soixante-quinzième",
      "n_76": "soixante-seizième",
      "n_77": "soixante-dix-septième",
      "n_78": "soixante-dix-huitième",
      "n_79": "soixante-dix-neuvième",
      "n_8": "huitième",
      "n_80": "quatre-vingtième",
      "n_81": "quatre-vingt-unième",
      "n_82": "quatre-vingt-deuxième",
      "n_83": "quatre-vingt-troisième",
      "n_84": "quatre-vingt-quatrième",
      "n_85": "quatre-vingt-cinquième",
      "n_86": "quatre-vingt-sixième",
      "n_87": "quatre-vingt-septième",
      "n_88": "quatre-vingt-huitième",
      "n_89": "quatre-vingt-neuvième",
      "n_9": "neuvième",
      "n_90": "quatre-vingt-dixième",
      "n_91": "quatre-vingt-onzième",
      "n_92": "quatre-vingt-douzième",
      "n_93": "quatre-vingt-treizième",
      "n_94": "quatre-vingt-quatorzième",
      "n_95": "quatre-vingt-quinzième",
      "n_96": "quatre-vingt-seizième",
      "n_97": "quatre-vingt-dix-septième",
      "n_98": "quatre-vingt-dix-huitième",
      "n_99": "quatre-vingt-dix-neuvième"
    },
    "organization": "Organisation",
    "organization_manager_mailer": {
      "new_signup": {
        "message": "Un nouveau client a été inscrit au programme : %{program}. Veuillez assigner le coach à ce client afin de commencer son parcours de coaching.",
        "subject": "Un nouveau client a été inscrit."
      }
    },
    "organizations": {
      "choose_term": "Choisir la terminologie",
      "flexible_term_description": "Vous pouvez adapter la terminologie ci-dessous et donc choisir les termes qui mieux conviennent à votre organisation, votre poste et votre groupe cible. Sélectionnez les termes que vous souhaitez utiliser et cliquez sur Enregistrer. Ces termes seront ensuite appliqués à l'ensemble du compte de votre organisation  sur Campus  UNLOQ et seront également visibles pour les clients.",
      "flexible_term_title": "Une terminologie flexible",
      "flexible_term_warning": "Veuillez noter que les changements terminologiques sont visibles après 1 heure.",
      "license": "Licence",
      "licensed_to": "Sous licence pour",
      "logo_explanation": "Nous vous recommandons de télécharger un logo au format PNG sur fond transparent.",
      "settings_for": "Paramètres pour",
      "term_assignment": "Exercice",
      "term_client": "Client",
      "term_coach": "Coach",
      "term_for": "Délai pour : "
    },
    "other_organizations": "Autre(s) organisation(s) :",
    "passwords": {
      "edit": {
        "btn": "Réinitialiser le mot de passe",
        "description": "Entrez votre nouveau mot de passe et nous vous enverrons tout de suite.",
        "label_password": "Nouveau mot de passe",
        "title": "Réinitialisation du mot de passe"
      },
      "new": {
        "description": "Saisissez votre adresse électronique et nous vous enverrons des instructions pour définir un nouveau mot de passe. Si vous n'avez pas reçu d'e-mail, veuillez vérifier votre dossier de spam ou réessayer.",
        "placeholder_email": "Votre adresse e-mail",
        "title": "Ne vous inquiétez pas, cela est courant."
      }
    },
    "pdf_report_ready": "Rapport PDF prêt à l'emploi",
    "pending_clients": {
      "description": "Vous trouverez ci-dessous une vue d'ensemble des clients qui doivent être associés à un coach (ou qui le sont déjà). Sur le côté droit, vous pouvez sélectionner un coach approprié."
    },
    "people_analytics": {
      "360_degree": "360 degrés",
      "aborted_signature": "Cordialement,<br/><br/>Campus People Analytics | partie de UNLOQ<br/><a href='https://unloq.org/' target='_blank'>unloq.org</a>",
      "aborted_success": "Nous vous remercions de votre coopération. Ce questionnaire ne sera pas traité plus avant car il ne peut être réalisé qu'en adhérant aux termes et conditions.<br> <br> Vous pouvez maintenant fermer cette page. Si vous souhaitez reprendre le questionnaire, veuillez utiliser le bouton ci-dessous.",
      "aborted_success_title": "Questionnaire fermé",
      "add_respondent": "ajouter un répondant",
      "add_respondent_button": "Ajouter un répondant",
      "admin": {
        "choose_program": "Choisir le programme",
        "choose_questionnaire": "Choisir le questionnaire",
        "filter_first": "Veuillez d'abord filtrer pour voir les résultats.",
        "measure_moments": "Mesurer les moments",
        "not_enough_results": "Il n'y a pas assez de résultats à afficher (minimum de 3).",
        "question": "Question",
        "title": "Résultats de l'analyse des personnes",
        "topic": "Sujet(s)"
      },
      "analytical_thinking": "Pensée analytique",
      "analytical_thinking_description": "Je favorise la résilience et la réactivité face aux questions et opportunités stratégiques émergentes pour le département/division.",
      "answer": "Réponse ",
      "assessment": "Évaluation",
      "assessment_leadership_organizational": "Leadership organisationnel",
      "assessment_leadership_self": "Leadership personnel",
      "assessment_leadership_team": "Leadership d'équipe",
      "assessment_report": "Rapport d'évaluation",
      "attention_objectives": "<b>Attention :</b> Si vous souhaitez ajouter d'autres objectifs, utilisez ce bouton avant de passer à la page suivante.",
      "autonomy": "Autonomie",
      "autonomy_description": "La liberté et l'autorité d'agir pour soi-même dans le cadre du travail.",
      "average_objectives": "Nombre moyen d'objectifs de coaching",
      "average_objectives_score": "Score moyen des objectifs du client",
      "basicdata": "Données de base sur le client",
      "btn_abort": "Fermer",
      "btn_add_goal": "Ajouter un objectif",
      "btn_continue": "Continuer",
      "btn_copy_report_link": "Copier le lien vers le rapport",
      "btn_copy_report_link_hint": "Copier l'URL du rapport à partager",
      "btn_download_report": "Télécharger le PDF",
      "btn_download_report_active": "Téléchargement...",
      "btn_download_report_hint": "Télécharger le fichier PDF de votre rapport",
      "btn_generate_for": "Générer un rapport de coaching pour",
      "btn_generate_report": "Générer un rapport",
      "btn_generate_report_hint": "Génère un nouveau rapport",
      "btn_regenerate_report": "Générer à nouveau un rapport",
      "btn_regenerate_report_hint": "Génère à nouveau le rapport, ne peut être annulé",
      "btn_remove_goal": "Supprimer l'objectif",
      "btn_resume": "Questionnaire du CV",
      "btn_send_report_link_dialog": "Envoi d'un rapport par dialogue",
      "btn_send_report_link_dialog_hint": "Envoi du lien vers le rapport à votre client dans le dialogue",
      "btn_send_report_link_email": "Envoyer le rapport par courrier électronique",
      "btn_send_report_link_email_active": "Envoi...",
      "btn_send_report_link_email_hint": "Envoi automatique d'un courrier électronique au client avec le lien vers le rapport",
      "btn_submit": "Soumettre",
      "can_add_peers_explanation": "Ce questionnaire est configuré de manière à ne pas pouvoir ajouter de répondants après un certain moment de l'évaluation",
      "choose_role": "Choisir un rôle",
      "client_option": {
        "anonymous": "Anonyme",
        "fullname": "Nom complet du client"
      },
      "closed": "Fermé",
      "closed_on": "Fermé le",
      "coaching_objectives": "Objectifs du coaching",
      "coachprocedure": "Évaluation du processus de coaching",
      "coachprocedure_description": "Ce questionnaire permet de connaître l'opinion du client sur le processus de coaching. Les questions sont renvoyées au fur et à mesure qu'elles sont remplies par le client.",
      "coachprocedure_explanation": "Ce questionnaire permet de connaître l'opinion du client sur le coaching.",
      "coachrelation": "Relation de coaching",
      "coachrelation_explanation": "Un coach et un client collaborent pour atteindre les objectifs du client. Cette collaboration professionnelle est appelée \"alliance de coaching\". On distingue trois aspects concernant l'alliance professionnelle : Le lien affectif, l'orientation vers la tâche et l'orientation vers l'objectif.",
      "coachrelation_only_one": "Les données de cette section ne sont pas modifiées plus d'une fois, ce qui signifie qu'un seul moment de rencontre est pris en compte dans les graphiques suivants.",
      "coachrelation_total": "Score total",
      "coachrelation_total_description": "Les scores totaux du lien affectif, de l'orientation vers la tâche et des objectifs.",
      "communication": "Communication",
      "communication_description": "J'ai la présence et l'influence d'un défenseur efficace de la mission et de la direction stratégique du programme.",
      "competence": "Compétence",
      "competence_description": "Utiliser ses propres capacités et être efficace dans les interactions avec les autres au travail.",
      "confidence": "Confiance",
      "confidence_description": "A quel point je suis confiant pour atteindre l'objectif du coaching",
      "confidential": "Confidentiel",
      "confirm_objectives_modal": {
        "back": "Retour aux objectifs",
        "body": "Vous êtes sur le point de terminer la section Objectifs. Si vous êtes sûr d'avoir ajouté tous vos objectifs, cliquez sur \"Continuer\". Si vous souhaitez ajouter d'autres objectifs, cliquez sur \"Retour aux objectifs\"",
        "continue": "Continuer",
        "title": "Avez-vous ajouté tous vos objectifs ?"
      },
      "consent": "Vous devez accepter les conditions pour continuer.",
      "copy_link_error": "Impossible de copier le lien, le client n'est pas actif pour l'utilisateur actuel",
      "copy_link_success": "Le lien a été copié avec succès dans votre presse-papiers",
      "current": "Situation actuelle",
      "current_description": "L'état actuel de l'objectif",
      "deactivate": "Désactiver",
      "description": "Description",
      "evaluation_report": "Rapport d'évaluation",
      "explanation": "Explication",
      "final_remarks": "Remarques finales",
      "finished_signature": "Cordialement,<br/><br/>Campus People Analytics | partie de UNLOQ<br/><a href='https://unloq.org/' target='_blank'>unloq.org</a>",
      "finished_success": "Toutes vos réponses sont stockées avec succès (et en toute sécurité). Nous vous remercions de votre collaboration.",
      "finished_success_title": "Bravo ! Vous avez réussi à répondre à toutes vos questions.",
      "five": "Cinq",
      "generate_report_error": "Il y a eu un problème d'affichage de ce rapport",
      "generate_report_prompt": "Commencez par générer un rapport",
      "goal": "Objectif du coaching",
      "goal_explanation": "Les échelles vont de 0 à 10, où 0 correspond à un score très bas et 10 au score le plus élevé possible. Plus le score est bas, plus la progression vers l'objectif est possible. Plus le score est élevé, plus l'objectif est atteint.",
      "goaloriented": "Objectifs",
      "goaloriented_description": "La mesure dans laquelle un client est satisfait des objectifs à atteindre.",
      "independent": "Indépendance",
      "independent_description": "Dans quelle mesure suis-je capable d'atteindre cet objectif de manière indépendante ?",
      "information": "Information",
      "integrity": "Intégrité",
      "integrity_description": "Je démontre et respecte les valeurs fondamentales de l'Agence dans mes activités et mon comportement.",
      "leadership_assessment_title": "Résultats de l'évaluation du Leadership",
      "leading_and_supervising": "Diriger et superviser",
      "leading_and_supervising_description": "Je veille à ce qu'il n'y ait pas d'abus de pouvoir et d'autorité et je fais preuve de leadership et de crédibilité dans les interactions avec tous les partenaires et parties prenantes.",
      "legend": {
        "aborted": "Avorté",
        "completed": "Complété",
        "not_sent_yet": "Non envoyé",
        "partially_completed": "Démarré",
        "reminder_sent": "Rappelé",
        "sent": "Envoyé"
      },
      "legend_description": {
        "aborted": "Le participant / répondant a refusé la politique de confidentialité.",
        "completed": "Le participant / répondant a terminé la mission.",
        "not_sent_yet": "L'affectation n'a pas encore été envoyée au participant/répondant.",
        "partially_completed": "Le participant / répondant a commencé la mission mais ne l'a pas encore complètement achevée.",
        "reminder_sent": "Un rappel automatique de remplir le questionnaire a été envoyé au participant / répondant.",
        "sent": "La mission a été envoyée au participant / répondant."
      },
      "measure_moment": "Mesurer le moment",
      "menu": {
        "background": "Contexte",
        "clients_progress": "Progression des clients",
        "example_report": "Exemple de rapport"
      },
      "mindset": "Points de vue sur les gens (Mindset)",
      "mindset_description": "La mesure dans laquelle une personne croit que les gens peuvent changer et se développer.",
      "mindset_explanation": "La mesure dans laquelle une personne croit que les gens (y compris elle-même) peuvent changer leur comportement. Plus le score est élevé, plus la personne est convaincue que les gens peuvent changer (attitude de croissance). Plus le score est bas, plus la personne est convaincue que les gens ne peuvent pas changer (fixed mindset).",
      "mindset_scale": "Elle est exprimée sur une échelle allant d'un minimum de 8 à un maximum de 48.",
      "motivation": "Motivation",
      "motivation_description": "A quel point je suis motivé pour atteindre l'objectif",
      "new": "Nouveau",
      "new_client": "Nouveau client",
      "new_from": "Nouveau de",
      "no_goals": "Vous devez indiquer au moins un objectif actif pour poursuivre ce questionnaire.",
      "no_respondents_yet": "Aucun répondant n'a encore été ajouté. Allez-y et",
      "not_all_questions_answered": "Toutes les questions n'ont pas reçu de réponse.",
      "objective": "Objectif",
      "objective_closed": "Fermé",
      "objective_no": "Objectif non.",
      "objective_relevant": "Pertinent",
      "pdf_confidential": "Les informations contenues dans ces documents sont confidentielles, privilégiées et uniquement destinées à l'information du destinataire prévu et ne peuvent être utilisées, publiées ou redistribuées sans le consentement du destinataire prévu. Ce document a été produit par UNLOQ People Analytics, un instrument d'UNLOQ.",
      "pdf_share_answer": {
        "private": "Entraîneur",
        "public": "Entraîneur et répondant(s)"
      },
      "peer": "Répondant",
      "peers": "Répondants",
      "planning": "Planification",
      "planning_and_organizing": "Planification et organisation",
      "planning_and_organizing_description": "Je convaincs et j'engage les autres en utilisant des arguments convaincants.",
      "planning_description": "Je résiste à toute pression indue dans la prise de décision et dans l'exercice de mes fonctions.",
      "preview": "Avant-première",
      "previous_peer_answer": "Dans l'évaluation précédente de cet objectif, vous avez donné à %{client} une note : %{number} (échelle de 0 à 10).",
      "private_objectives_modal": {
        "body": "Si vous partagez vos objectifs avec votre coach <i>uniquement</i>, vos répondants ne seront pas impliqués dans cette enquête à 360 degrés.",
        "title": "Vous ne partagez vos objectifs qu'avec votre coach ?"
      },
      "professionalism": "Professionnalisme",
      "professionalism_description": "Je prends des mesures rapides et efficaces pour faire face à un comportement non professionnel ou contraire à l'éthique.",
      "program": "Programme",
      "psychological_needs": "Les besoins psychologiques fondamentaux au travail",
      "psychological_needs_explanation": "Chaque être humain s'efforce de satisfaire ses propres besoins psychologiques fondamentaux. Plus ces besoins fondamentaux sont satisfaits par le biais du travail et sur le lieu de travail, plus une personne est motivée pour mieux faire son travail. On distingue trois besoins fondamentaux, à savoir : Autonomie, Compétence et Relation.",
      "question": "Question",
      "questionnaire": "Questionnaire",
      "relatedness": "Parenté",
      "relatedness_description": "Le sentiment d'appartenance et le contact étroit avec les autres au travail.",
      "relation": "Lien affectif",
      "relation_description": "La mesure dans laquelle il existe une bonne alliance de travail entre le coach et le client.",
      "reminded_at": "Un courriel de rappel a été envoyé à l'adresse suivante",
      "report": {
        "assessment_intro": "Cette section présente les résultats de l'évaluation du leadership.<br /><br />\nLes scores par compétence sont indiqués dans le graphique. Ici, votre score (Self) est donné lors de la première mesure d'évaluation (M1) et, si disponible, également lors de la deuxième mesure d'évaluation (M2). Sous l'affichage \"Self\", les scores des répondants sont indiqués, ainsi que le nombre de répondants (N). Dans le tableau situé à côté du graphique, les scores de la première mesure et (si disponible) de la deuxième mesure sont indiqués, ainsi que la différence entre ces mesures (Δ). Cela montre si une amélioration a été obtenue.",
        "assessment_intro_m1": "Le deuxième niveau d'évaluation (M2) ne s'applique pas à ce rapport. le niveau (M2) n'est disponible que lorsqu'une deuxième évaluation est effectuée. Lorsque (M2) est disponible, la différence entre (M1) et (M2) mesure votre niveau de croissance.",
        "assessment_intro_m2": "Le deuxième niveau d'évaluation (M2) est également visible dans le graphique (2ème barre) et dans le tableau. La différence entre (M1) et (M2) mesurera votre niveau de croissance.",
        "assessment_legend": "Le score est affiché sur une échelle de Likert à 5 points, ce qui signifie que le score va de 1) pas du tout d'accord à 5) tout à fait d'accord.",
        "assessment_scale": "Échelle",
        "assessment_scale_explanation": "1 : pas du tout d'accord / 2 : pas d'accord / 3 : ni d'accord ni pas d'accord / 4 : d'accord / 5 : tout à fait d'accord",
        "average": "Moyenne",
        "average_overall": "Moyenne générale",
        "averages": "Moyennes",
        "highest": "le plus élevé",
        "lowest": "le plus bas",
        "open_ended_intro": "Cette section présente les réponses données aux différentes questions ouvertes (Q).<br /><br />\nElle présente d'abord les réponses données par le client lui-même, puis les réponses données par les répondants.",
        "open_ended_title": "Réponses aux questions ouvertes",
        "overview_of": "Vue d'ensemble",
        "section": "Section",
        "supervisory_assessment_intro": "Cette section présente les résultats de l'évaluation des compétences de direction.<br /><br />\nLes scores sont indiqués dans le graphique pour chaque compétence et/ou comportement de leadership en supervision. Votre score (Self) est présenté à ce premier niveau d'évaluation (M1). En dessous de l'affichage (Self), les scores moyens des répondants pour les catégories Manager, Rapport direct et/ou Pair sont indiqués respectivement.",
        "supervisory_top_results_intro": "Cette section présente les cinq compétences et comportements en matière d'encadrement les mieux notés et les cinq compétences et comportements en matière d'encadrement les moins bien notés (évalués par Self).",
        "supervisory_top_results_top_rated": "ont évalué les compétences de supervision en moyenne",
        "table_results_intro": "Cette section présente les réponses pour chacune des trois dimensions du leadership dans le cadre du retour d'information recueilli auprès de sources multiples (soi-même, les managers et les pairs).",
        "table_results_intro_explanation": "<ul class=\"list-check ml-3\">\n  <li>La colonne \"Moyenne personnelle\" indique la moyenne générale des réponses que vous vous êtes données pour chaque dimension.</li>\n  <li>La colonne \"Moyenne générale\" indique les moyennes des réponses des évaluateurs.</li>\n  <li>Les colonnes \"répondant\" indiquent la réponse de chaque évaluateur.</li>\n  <li>Les réponses peuvent aller de 1 - Pas du tout d'accord à 5 - Tout à fait d'accord.</li>\n</ul>",
        "top_results_extra": "Tout d'abord, les scores du premier point de mesure (M1) sont affichés et, s'ils sont disponibles, également ceux du deuxième point de mesure (M2).<br /><br />",
        "top_results_intro": "Cette section présente les cinq compétences les mieux notées du client ainsi que les cinq compétences les moins bien notées.<br /><br />",
        "top_results_top_rated": "compétences évaluées"
      },
      "report_create": "Créer un rapport",
      "report_epilogue": "Epilogue du rapport",
      "report_generated_at": "Ce rapport a été généré à %{date}",
      "report_is_loading": "Chargement du rapport généré",
      "report_measure": "Mesure",
      "report_opened_at": "Le dernier rapport a été ouvert à",
      "report_opened_by_client_at": "Le client a ouvert son rapport à %{date}",
      "report_preface": "Préface du rapport",
      "report_sent_at": "Dernier rapport envoyé à",
      "reset_last_submission": "Réinitialiser le dernier questionnaire soumis",
      "respect_for_diversity": "Respect de la diversité",
      "respect_for_diversity_description": "Je crée un environnement qui favorise une communication ouverte à l'intérieur et au-delà des frontières de l'organisation et je transforme les situations controversées ou sensibles en opportunités",
      "respondents_overview": "Aperçu des répondants",
      "result_score": "Score du résultat",
      "role": {
        "board_member": "Membre du conseil d'administration",
        "colleague": "Pairs",
        "customer": "Client",
        "family": "Famille",
        "friends": "Amis / connaissances",
        "manager": "Directeur",
        "staff_member": "Rapport direct",
        "supplier": "Fournisseur"
      },
      "score": "Score",
      "self": "Autonome",
      "send_coach_questionnaire": "Envoyer le questionnaire au coach %{next} ",
      "send_via_dialog": "Envoyer le questionnaire %{next} (Dialog)",
      "send_via_email": "Envoyer le questionnaire %{next} (E-mail)",
      "send_via_link": "Envoyer le questionnaire %{next} (Copier le lien)",
      "share_answer": {
        "private": "Partager uniquement avec le coach",
        "public": "Partager avec le coach et le(s) répondant(s)"
      },
      "shared_with": "Partagé avec",
      "status_description": "Vous trouverez ci-dessous les détails du déroulement du coaching ainsi que les réponses du client.",
      "step_show_client_name": "Afficher le nom du client",
      "step_show_private_objectives": "Afficher les objectifs de coaching qui ne sont partagés qu'avec le coach",
      "step_write_epilogue": "Rédiger l'épilogue",
      "step_write_preface": "Rédiger la préface",
      "still_relevant": "Cet objectif est-il toujours pertinent ?",
      "submit_success": "Un e-mail contenant un lien vers le questionnaire d'analyse des personnes a été envoyé.",
      "submit_too_many": "L'e-mail contenant le questionnaire a déjà été envoyé. Veuillez réessayer plus tard.",
      "supervisory_leadership_assessment_title": "Résultats de l'évaluation de l'encadrement",
      "supervisory_mindset_explanation": "Cette section présente les résultats relatifs à la manière dont vous pensez que les gens peuvent changer au fil du temps. Plus le score est élevé, plus la personne est convaincue que les gens peuvent changer (état d'esprit de croissance). Plus le score est bas, plus la personne est convaincue que les gens ne peuvent pas changer (état d'esprit fixe).",
      "supervisory_top_highest_and_lowest_title": "Les cinq notes les plus élevées et les cinq notes les plus basses en matière de compétences de supervision",
      "taskoriented": "Tâche",
      "taskoriented_description": "La mesure dans laquelle un client est satisfait de l'approche et de la méthode de coaching.",
      "teamwork": "Travail d'équipe",
      "teamwork_description": "Je me fais connaître du personnel en l'écoutant et en communiquant à tous les niveaux au sein de l'Agence et avec les homologues extérieurs.",
      "title": "Analyse des personnes",
      "top_highest_and_lowest_title": "Les cinq valeurs de leadership les mieux et les moins bien notées",
      "total_objectives": "Total des objectifs de coaching",
      "view_results": "Voir les résultats"
    },
    "people_analytics_mailer": {
      "client_finished": {
        "btn": "Aller sur le campus UNLOQ",
        "message": "a rempli le questionnaire pour le programme <b>%{program}</b>.",
        "subject": "Le questionnaire a été rempli"
      },
      "peer_finished": {
        "btn": "Aller sur le campus UNLOQ",
        "message": "Le répondant %{peer} a rempli le questionnaire pour le programme %{program}.",
        "subject": "Le questionnaire a été rempli par le répondant"
      },
      "remind_client": {
        "message": "vous prie de bien vouloir remplir votre questionnaire d'auto-évaluation pour le programme <b>%{program}</b>. <br><br> <b>Attention:</b> le questionnaire n'est disponible que pour une durée limitée, et ce lien expirera bientôt.",
        "subject": "Rappel : Questionnaire People Analytics"
      },
      "remind_coach": {
        "message": "vous demande de bien vouloir remplir votre questionnaire d'auto-évaluation pour le programme <b>%{program}</b> à partir de votre rôle de <b>coach</b>. <br><br> <b>Attention:</b> le questionnaire n'est disponible que pour une durée limitée, et ce lien expirera bientôt.",
        "subject": "Rappel : Questionnaire pour le coach People Analytics"
      },
      "remind_peer": {
        "message": "vous prie de bien vouloir remplir votre questionnaire d'auto-évaluation pour le programme <b>%{program}</b> du point de vue de votre rôle en tant que <b>%{peertype}</b>. <br><br> <b>Attention:</b> le questionnaire n'est disponible que pour une durée limitée, et ce lien expirera bientôt.",
        "subject": "Rappel : Questionnaire à l'intention des personnes interrogées sur l'analyse de la population"
      },
      "submit_client": {
        "btn": "Aller au questionnaire",
        "explanation": "En cliquant sur le lien, vous accéderez à l'outil d'UNLOQ Campus appelé People Analytics et commencerez le questionnaire, qui prendra environ 10 à 15 minutes à remplir. Après avoir rempli chaque page du questionnaire, vous devez cliquer sur le bouton \"continuer\" en bas.<br/> <br/> <b>Veuillez noter que:</b><br/> Lorsque vous remplissez le questionnaire, vous ne pouvez pas revenir en arrière une fois que vous avez cliqué sur \"continuer\".<br/> Vous devez répondre à toutes les questions de chaque page, sinon le bouton \"continuer\" ne fonctionne pas.<br/><br/>",
        "message": "vous prie de bien vouloir remplir votre questionnaire d'auto-évaluation pour le programme <b>%{program}</b>.",
        "subject": "Questionnaire People Analytics"
      },
      "submit_client_report": {
        "btn": "Aller au rapport",
        "message": "souhaite partager votre rapport sur le programme <b>%{program}</b>.",
        "subject": "Rapport People Analytics"
      },
      "submit_coach": {
        "btn": "Aller au questionnaire",
        "message": "vous demande de bien vouloir remplir votre questionnaire d'auto-évaluation pour le programme <b>%{program}</b> à partir de votre rôle de <b>coach</b>.",
        "subject": "Questionnaire du coach People Analytics"
      },
      "submit_peer": {
        "btn": "Aller au questionnaire",
        "explanation": "En cliquant sur le lien, vous accéderez à l'outil d'UNLOQ Campus appelé People Analytics et commencerez le questionnaire, qui prendra environ 10 à 15 minutes à remplir. Après avoir rempli chaque page du questionnaire, vous devez cliquer sur le bouton \"continuer\" en bas.<br/> <br/> <b>Veuillez noter que:</b><br/> Lorsque vous remplissez le questionnaire, vous ne pouvez pas revenir en arrière une fois que vous avez cliqué sur \"continuer\".<br/> Vous devez répondre à toutes les questions de chaque page, sinon le bouton \"continuer\" ne fonctionne pas.<br/><br/>",
        "message": "vous prie de bien vouloir remplir votre questionnaire d'auto-évaluation pour le programme <b>%{program}</b> du point de vue de votre rôle en tant que <b>%{peertype}</b>.",
        "subject": "Questionnaire pour les répondants de People Analytics"
      }
    },
    "profile": {
      "change_password": "Modifier le mot de passe",
      "information": "Informations de profil",
      "people_analytics_emails": "E-mails d'analyse des personnes",
      "people_analytics_emails_explanation": "Ces courriels sont envoyés lorsque, par exemple, un participant ou un répondant a soumis un questionnaire.",
      "two_factor_authentication": "Authentification à deux facteurs"
    },
    "program": {
      "days_after_month_closure": "Journaux de session fermés (période en jours pendant laquelle les journaux de session peuvent être modifiés après le dernier jour du mois)",
      "minutes_per_client": "Temps maximum disponible par client (en minutes)",
      "title": "Programme"
    },
    "program_breaks": {
      "create": "Ajouter une pause dans le programme"
    },
    "program_questionnaire_explanation": "Le questionnaire People Analytics ne peut être configuré qu'une seule fois.",
    "program_sessions": {
      "activities": {
        "delivery": "Remise",
        "design": "Design",
        "other": "Autres",
        "preparation": "Préparation",
        "projectmanagement": "Gestion de projet",
        "reporting": "Rapport"
      },
      "activity": "Activité",
      "activity_not_enabled": ": non activé. Choisissez une autre catégorie.",
      "activity_type": "Activité",
      "btn_new": "Nouveau groupe et activité de formation",
      "category_type": "Catégorie",
      "charts_explanation": "Nombre total de minutes du programme",
      "choose_activity": "Toutes les activités",
      "description": "Vous pouvez administrer ci-dessous vos heures liées à toute activité de groupe ou de formation pour un programme spécifique. Attention, ces heures ne sont pas des heures de coaching individuel.",
      "hourly_rate": "Taux horaire",
      "minutes_exceeded": ": maximum dépassé",
      "notes": "Notes",
      "sum_duration": "Facturable",
      "sum_duration_nonbillable": "Non facturable",
      "title": "Activités de groupe et de formation"
    },
    "programs": "Programmes",
    "progress": "Progrès",
    "progress_explanation": "Sélectionnez un programme pour voir votre  progression.<br/><br/> - Bleu = nombre de minutes de programme utilisées<br/> - Vert 100% = toutes les minutes de programme ont été utilisées<br/> - Rouge &gt; 100% = plus de minutes ont été utilisées que convenu<br/> <br/> Besoin de minutes supplémentaires ? Demandez au responsable d'organisation de les ajouter.<br/>",
    "question": {
      "input_type": {
        "checkboxes": "Choix multiple, réponses multiples (cases à cocher)",
        "radio_buttons": "Choix multiple, 1 réponse (boutons radio)",
        "rating": "Question d'évaluation (1-10)",
        "sortable": "Question à trier",
        "text_area": "Réponse ouverte (plusieurs lignes de texte)",
        "text_field": "Réponse courte (1 ligne de texte)"
      }
    },
    "questionnaire": "Questionnaire",
    "ra": {
      "action": {
        "save": "Économiser"
      },
      "page": {
        "empty": "Aucun %{name} n'a été trouvé."
      }
    },
    "ransack": {
      "all": "tous",
      "and": "et",
      "any": "au moins un",
      "asc": "ascendant",
      "attribute": "attribut",
      "combinator": "combinateur",
      "condition": "condition",
      "desc": "descendant",
      "or": "ou",
      "predicate": "prédicat",
      "predicates": {
        "blank": "est blanc",
        "cont": "contient",
        "cont_all": "contient tous",
        "cont_any": "contient au moins un",
        "does_not_match": "ne correspond pas à",
        "does_not_match_all": "ne correspond à aucun",
        "does_not_match_any": "ne correspond pas à au moins un",
        "end": "finit par",
        "end_all": "finit par tous",
        "end_any": "finit par au moins un",
        "eq": "égal à",
        "eq_all": "égal à tous",
        "eq_any": "égal à au moins un",
        "false": "est faux",
        "gt": "supérieur à",
        "gt_all": "supérieur à tous",
        "gt_any": "supérieur à au moins un",
        "gteq": "supérieur ou égal à",
        "gteq_all": "supérieur ou égal à tous",
        "gteq_any": "supérieur ou égal à au moins un",
        "in": "inclus dans",
        "in_all": "inclus dans tous",
        "in_any": "inclus dans au moins un",
        "lt": "inférieur à",
        "lt_all": "inférieur à tous",
        "lt_any": "inférieur à au moins un",
        "lteq": "inférieur ou égal à",
        "lteq_all": "inférieur ou égal à tous",
        "lteq_any": "inférieur ou égal à au moins un",
        "matches": "correspond à",
        "matches_all": "correspond à tous",
        "matches_any": "correspond à au moins un",
        "not_cont": "ne contient pas",
        "not_cont_all": "ne contient pas tous",
        "not_cont_any": "ne contient pas au moins un",
        "not_end": "ne finit pas par",
        "not_end_all": "ne finit pas par tous",
        "not_end_any": "ne finit pas par au moins un",
        "not_eq": "différent de",
        "not_eq_all": "différent de tous",
        "not_eq_any": "différent d'au moins un",
        "not_in": "non inclus dans",
        "not_in_all": "non inclus dans tous",
        "not_in_any": "non inclus dans au moins un",
        "not_null": "n'est pas null",
        "not_start": "ne commence pas par",
        "not_start_all": "ne commence pas par tous",
        "not_start_any": "ne commence pas par au moins un",
        "null": "est null",
        "present": "est présent",
        "start": "commence par",
        "start_all": "commence par tous",
        "start_any": "commence par au moins un",
        "true": "est vrai"
      },
      "search": "recherche",
      "sort": "tri",
      "value": "valeur"
    },
    "rating": {
      "empty": "Il n'y a pas encore d´évaluations.",
      "empty_explanation_step1": "Activez les évaluations par client sur la page de vue d'ensemble des clients :",
      "empty_explanation_step2": "Cliquez sur l'icône en forme d'étoile située derrière le nom du client pour activer l'évaluation d' un client spécifique.",
      "empty_explanation_step3": "Le client peut maintenant rédiger un commentaire sur vous en tant que coach sur la page de dialogue.",
      "placeholder": "Rédiger un commentaire...",
      "private": "Privé (partager uniquement avec le coach)",
      "public": "Public (visible par tous)",
      "semi_private": "Semi-privé (partagé avec les parties prenantes concernées)",
      "shared_placeholder": "Choisissez le niveau de partage...",
      "title": "Commentaire"
    },
    "react": {
      "admin": {
        "add_to_associate_list": "Ajouter à la liste des associés",
        "address": "Adresse",
        "addresses": "Informations commerciales",
        "affiliation": "Affiliation",
        "affiliations": "Affiliations",
        "are_you_sure_delete": "Êtes-vous sûr de vouloir supprimer cette %{referenceName} ?",
        "associate_lists": {
          "add_coach_button": "Ajouter un entraîneur",
          "add_coach_to_list_dialog_title": "Ajouter l'entraîneur à la liste",
          "add_to_list_failed": "L'entraîneur n'a pas pu être ajouté à la liste",
          "add_to_list_success": "L'entraîneur a été ajouté à la liste",
          "coach_count": "Entraîneurs",
          "confirm_delete": "Supprimer cet utilisateur de la liste ?",
          "create": "Créer une liste d'entraîneurs",
          "edit": "Modifier la liste des entraîneurs",
          "intro": "Ici, vous pouvez créer des listes de coachs. Ces listes peuvent être utilisées dans les CoachDesks, le site web UNLOQ et pour exporter rapidement tous les CV d'un groupe d'entraîneurs.",
          "no_coaches_in_list": "Il n'y a pas encore d'entraîneur dans cette liste.",
          "title": "Listes d'entraîneurs"
        },
        "avatars": "Photo de profil",
        "bio": {
          "new": "L'utilisateur a mis à jour sa bio"
        },
        "business_address": "Adresse professionnelle",
        "business_details": "Détails de l'entreprise",
        "certification": "Certification",
        "certifications": "Certifications",
        "client_imports": {
          "fields": {
            "excel_file": {
              "helper_text": "Seuls les fichiers Excel portant l'extension .xslx sont pris en charge",
              "placeholder": "Déposez votre fichier d'importation Excel ici"
            }
          }
        },
        "coaching_preferences": "Préférences en matière de coaching",
        "curriculum_vitae": "Curriculum Vitae",
        "curriculum_vitae_intro": "Le CV commercial diffère du CV par défaut parce qu'il n'inclut pas les activités liées aux ONG, aux organisations à but non lucratif ou aux organisations à but non lucratif. Conseil : copiez le CV par défaut et supprimez toutes les activités qui ne sont pas commerciales.",
        "edit": "Editer",
        "education": "L'éducation",
        "end_date": "Date de fin",
        "files": {
          "preview_loading": "Chargement de l'aperçu"
        },
        "group_work": "Travail en groupe",
        "help": "Aide",
        "internal_only": "Usage interne uniquement, non visible par le public",
        "job_information": "Informations sur l'emploi",
        "language": "Langue",
        "languages": "Langues",
        "name": "Nom",
        "personal_information": "Informations personnelles",
        "preview": "Avant-première",
        "profile_photo": "Photo de profil",
        "prompt": "Approuver ou rejeter",
        "publication": "Publication",
        "publications": "Publications",
        "reject": "Rejeter",
        "rematch": "Rematch",
        "rematch_client": "Client du match retour",
        "remove": "Retirer",
        "save": "Économiser",
        "short_bio": "Brève biographie",
        "unloq_only_contract_fields": {
          "body": "Les champs ci-dessous sont gérés par UNLOQ et ne sont <b>PAS</b> visibles et modifiables par les associés eux-mêmes. Ces champs sont utilisés dans la procédure de sélection des associés.",
          "title": "Champs gérés par UNLOQ"
        },
        "untranslated": "Traductions manquantes",
        "validation": {
          "date_of_birth": {
            "not_adult": "Vous devez fournir une date de naissance d'au moins 16 ans"
          },
          "end_date_after_start_date": "La date de fin doit être postérieure à la date de début",
          "linkedin": {
            "invalid": "Doit être une URL LinkedIn valide"
          },
          "start_date_invalid": "La date de début n'est pas valide",
          "start_date_required": "La date de début est requise"
        },
        "work_experience": "Expérience professionnelle"
      },
      "associate_lists": {
        "add_failed": "Échec de l'ajout à la liste des associés",
        "added": "Ajouté à la liste des associés"
      },
      "clients": {
        "rematch_failed": "Le client n'a pas pu être remis en contact, veuillez contacter l'assistance",
        "rematch_success": "Le client a été réaffecté avec succès"
      },
      "coaches": {
        "address": {
          "account_holder_name": "Nom du titulaire du compte",
          "address_line": "Adresse",
          "bank_account_number": "Numéro de compte bancaire",
          "bank_identification": "Identification de la banque (BIC/SWIFT)",
          "bank_name": "Nom de la banque",
          "city": "Ville",
          "company": "Nom de l'entreprise",
          "country": "Pays",
          "helper_texts": {
            "bank_account_number": "IBAN ou autre numéro de compte bancaire",
            "bank_identification": "Obligatoire si le numéro de compte n'est pas IBAN",
            "chamber_of_commerce": "Chambre de commerce"
          },
          "house_number": "Numéro de maison",
          "region": "Région",
          "zip_code": "Code postal"
        },
        "all": "Tous les entraîneurs inscrits",
        "associate_status": {
          "active": "Actif",
          "label": "Statut",
          "none": "Pas de statut",
          "offboard": "Hors bord",
          "on_hold": "En attente"
        },
        "avatars": {
          "approved": "Votre nouvelle photo de profil a été approuvée",
          "avatar": {
            "label": "Avatar",
            "no_avatar": "Il n'y a pas d'avatar",
            "upload": "Télécharger un nouvel avatar"
          },
          "not_approved_yet": "Votre nouvelle photo de profil n'a pas encore été approuvée. Nous l'examinerons dès que possible",
          "photo": {
            "label": "Photo de profil",
            "no_photo": "Il n'y a pas de photo de profil"
          },
          "preview": "Avant-première",
          "rejected": "Votre nouvelle photo de profil a été rejetée, veuillez en télécharger une autre",
          "upload": "Télécharger une nouvelle photo de profil",
          "uploaded": "Une nouvelle photo a été téléchargée, nous l'examinerons dès que possible"
        },
        "bio": {
          "approved": "Votre nouvelle bio a été approuvée",
          "not_approved_yet": "Votre nouvelle bio n'a pas encore été approuvée",
          "rejected": "Votre nouvelle bio a été rejetée, veuillez en écrire une autre",
          "updated": "Votre biographie a été mise à jour et est en attente d'approbation"
        },
        "both": "Les deux",
        "cards_view": "La vue des cartes",
        "chamber_of_commerce": "Numéro de registre du commerce",
        "coach_type": "Type d'autocar",
        "coach_types": {
          "a": "Entraîneur et formateur principal",
          "t": "Formateur principal et animateur"
        },
        "coaching_level": "Niveau de coaching",
        "coaching_levels": {
          "executive": "Cadre",
          "leadership_coaching": "Coaching en matière de leadership",
          "top_executive": "Cadre supérieur",
          "young_talent_professional": "Jeunes talents professionnels"
        },
        "commercial_cv": "CV commercial",
        "contract_remarks": "Remarques",
        "contract_type": "Type de contrat",
        "contracts": {
          "core_team": "Membre de l'équipe centrale",
          "fixed_hours": "Associé avec des heures fixes",
          "project_basis": "Associé sur la base d'un projet"
        },
        "core_team": "Équipe de base",
        "cv": {
          "affiliations": "Affiliations",
          "approach": "Approche",
          "branch_experiences": "Expériences de la branche",
          "certifications": "Certifications",
          "confirm_copy_cv": "Êtes-vous sûr de vouloir copier toutes les informations de votre CV par défaut ? <br /><br />Veuillez noter <b>VOUS NE POUVEZ LE FAIRE QU'UNE SEULE FOIS</b>, après quoi le bouton disparaîtra !",
          "contract_type": "Type de contrat",
          "copy_default_cv": "Copier votre CV par défaut ?",
          "copy_from_default_button": "Copie du CV par défaut",
          "education": "L'éducation",
          "examples": {
            "affiliation": {
              "acronym": "Exemple : EMCC",
              "name": "Exemple : EMCC Global",
              "number": "Exemple : 100"
            },
            "certification": {
              "end_date": "Exemple : Date jusqu'à laquelle la certification était valide (vide = indéfiniment)",
              "institution": "Exemple : Fédération internationale des entraîneurs",
              "name": "Exemple : Coach professionnel certifié ICF",
              "start_year": "Exemple : Année de délivrance de la certification",
              "topic": "Exemple : Cours de coaching"
            },
            "education": {
              "end_date": "Exemple : Date jusqu'à laquelle l'éducation était valable (vide = indéfiniment)",
              "field_of_study": "Exemple : Psychologie clinique",
              "school": "Exemple : Harvard",
              "specialisation": "Exemple : Dynamique de groupe",
              "start_year": "Exemple : Année à partir de laquelle l'enseignement est dispensé"
            },
            "publication": {
              "authors": "Exemple : Doe, J. / Doe. J. & Doedel, K.P.",
              "journal_title": "Exemple : Journal de sensibilisation",
              "medium": "Exemple : La télévision",
              "page_range": "Exemple : 201-220",
              "publisher": "Exemple : Routledge",
              "publisher_city": "Exemple : New York",
              "title": "Exemple : Reconnaissance de soi dans la vie quotidienne",
              "url": "Exemple : https://www.cbs.com/shows/the-late-show-with-stephen-colbert/",
              "various_title": "Exemple : Invité au Late Show avec Stephen Colbert.",
              "volume_number": "Exemple : 5(2)",
              "year": "Exemple : (2022)"
            },
            "publications": {
              "type": "Choisir le type de publication"
            }
          },
          "fields": {
            "affiliation": {
              "acronym": "Acronyme",
              "add": "Ajouter une autre affiliation",
              "name": "Nom",
              "number": "Numéro d'affiliation"
            },
            "approach": "Brève biographie",
            "approach_current": "Biographie actuelle",
            "approach_helper_text": "Veuillez rédiger votre CV en anglais",
            "certification": {
              "add": "Ajouter une autre certification",
              "end_date": "Date de fin",
              "institution": "Organisme émetteur",
              "level": "Niveau",
              "name": "Nom du certificat",
              "start_year": "Année de démarrage",
              "topic": "Sujet / Thème"
            },
            "certification_dropzone": "Déposez ici vos fichiers de diplômes / certifications / témoignages",
            "certification_dropzone_helpertext": "Seuls les fichiers PDF, PNG ou JPG peuvent être téléchargés",
            "education": {
              "add": "Ajouter une autre éducation",
              "degree": "Diplôme",
              "end_date": "Date de fin",
              "field_of_study": "Domaine d'études",
              "school": "L'école",
              "specialisation": "Spécialisation",
              "start_year": "Année de démarrage"
            },
            "job_title": "Titre de l'emploi actuel",
            "job_title_helper_text": "Veuillez écrire le titre de votre poste en anglais",
            "occupations": "Professions",
            "publication": {
              "add": "Ajouter une autre publication",
              "article": "Article",
              "authors": "Auteur(s)",
              "book": "Livre",
              "choose": "Choisir le type de publication",
              "journal_title": "Nom du journal",
              "medium": "Moyen",
              "page_range": "Gamme de pages",
              "publisher": "Éditeur",
              "publisher_city": "Branche de l'éditeur Ville",
              "title": "Titre",
              "title_article": "Titre de l'article",
              "title_book": "Titre du livre",
              "url": "Lien internet",
              "various": "Divers",
              "volume_number": "Numéro de volume (numéro d'édition)",
              "year": "Année"
            },
            "publication_type": "Type de publication",
            "specializations": "Spécialisations",
            "work_experience": {
              "add": "Ajouter une autre expérience professionnelle",
              "branch_experience": "Expériences de la branche",
              "company_name": "Nom de l'entreprise",
              "description": "Description du travail",
              "end_date": "Date de fin (vide = indéfiniment)",
              "role": "Rôle / titre",
              "start_date": "Date de début"
            }
          },
          "headers": {
            "certifications": "Formations / Certifications"
          },
          "help": {
            "avatars": {
              "help": "Conseils photo"
            }
          },
          "intro": {
            "affiliations": "Indiquez toutes les associations professionnelles auxquelles vous êtes affilié.",
            "certifications": "Dressez ici la liste de vos licences et certificats pour les méthodes, approches, évaluations, tests et autres questions liées à l'apprentissage, au développement et aux activités d'orientation. Il doit s'agir de votre éducation, de votre formation, de vos cours et de vos ateliers (orientés professionnellement) avec un certificat / une licence / un sceau officiel.",
            "contract_type": "Cette section est à usage interne et est gérée par un gestionnaire de programme. Vous ne pouvez pas y apporter de modifications vous-même.",
            "education": "Dressez ici la liste de toutes les études et formations que vous avez suivies (y compris celles que vous n'avez pas achevées). Il doit s'agir de vos études/qualifications/diplômes officiellement reconnus par le gouvernement.",
            "publications": "Dressez la liste de toutes les publications auxquelles vous avez participé ou écrit. Veuillez noter que pour une présentation uniforme, nous utilisons les règles internationales de l'APA. Pour chaque champ, nous fournissons un exemple. Utilisez-le pour remplir correctement chaque champ.",
            "work_experience": "Indiquez ici votre expérience professionnelle pertinente, non seulement dans le domaine du coaching et de la formation, mais aussi dans tous les domaines."
          },
          "job_experience": "Emploi et expérience professionnelle",
          "job_information": "Informations sur l'emploi",
          "job_title": "Fonction",
          "level_coaching": "Niveau d'entraînement",
          "level_ecoaching": "Niveau d'e-coaching",
          "professions": "Professions",
          "publications": "Publications",
          "specializations": "Spécialisations",
          "work_experiences": "Expériences professionnelles"
        },
        "date_of_birth": "Date de naissance",
        "default_cv": "CV par défaut",
        "ecoaching_level": "Niveau d'éco-aching",
        "ecoaching_levels": {
          "level_i": "Niveau I",
          "level_ii": "Niveau II",
          "level_iii": "Niveau III",
          "none": "Aucun"
        },
        "first_name": "Prénom",
        "group_work": {
          "add": "Ajouter une autre expérience de travail en groupe",
          "select": "Sélectionner le type de travail en groupe"
        },
        "group_work_location": {
          "label": "En ligne / Hors ligne"
        },
        "group_work_locations": {
          "both": "Les deux",
          "offline": "Hors ligne",
          "online": "En ligne"
        },
        "group_work_type": {
          "label": "Conception / Livraison"
        },
        "group_work_types": {
          "both": "Les deux",
          "delivery": "Livraison",
          "design": "Conception"
        },
        "id_number": "Numéro d'identification (passeport ou autre pièce d'identité)",
        "intro": {
          "addresses": "Veuillez fournir les détails de votre entreprise ci-dessous. Veuillez noter que le champ \"BIC/SWIFT\" n'est obligatoire que si vous ne disposez pas d'un numéro de compte bancaire IBAN.",
          "avatars": "Veuillez télécharger votre photo de profil. Nous recherchons des portraits puissants où les personnes occupent une place centrale. Les photos doivent être nettes, sans perdre la chaleur de l'aspect humain. Les photos sont également caractérisées par la profondeur de champ et l'utilisation de la lumière naturelle.<br /><br />Veuillez garder à l'esprit que nous recherchons des photos nettes, sans couleurs vives ni objets distrayants en arrière-plan.<br /><br />Pensez à votre photo LinkedIn, mais en version plus décontractée. Votre photo doit montrer qui vous êtes sans oublier qu'il s'agit d'un environnement professionnel. Posez-vous la question suivante : quelle photo afficheriez-vous sur votre LinkedIn par rapport à celle que vous afficheriez pendant vos vacances ? <br /><br />Essayez d'éviter : Shorts, hauts sans manches ou voyants, sandales<br /><br />La photo que vous téléchargez ici sera affichée sur la page \"Notre équipe\" du site web d'UNLOQ et (le cas échéant) sur les pages du site Web des CoachDesks des clients.<br /><br /> <b>Note:</b> une fois que vous avez téléchargé, la photo devra être approuvée par UNLOQ avant d'être visible sur le site Web d'UNLOQ et sur les CoachDesks et comme avatar dans Campus. Dès que l'approbation sera terminée, cette photo apparaîtra dans votre profil.",
          "bio": "Veuillez rédiger une courte biographie de vous-même. Cette biographie sera ajoutée à votre CV et sera également visible sur la page <a href=\"https://unloq.org/our-team/\" target=\"_blank\">Notre équipe</a> du site web d'UNLOQ et (le cas échéant) sur les CoachDesks.<br /><br />Les exigences suivantes sont fixées:<ul><li>Min 400 caractères</li><li>Max 800 caractères</li><li>Rédigé à la troisième personne</li><li>Rédigé en anglais</li></ul><i>EXEMPLE : Marcel a 15 ans d'expérience de coaching dans le développement du leadership et les processus de transition. Il s'intéresse aux sujets liés à la diversité, à l'équité et à l'inclusion et son approche pratique est basée sur la psychologie orientale et occidentale. Ses clients et ses pairs disent qu'il est ouvert d'esprit et flexible, qu'il va rapidement au but et qu'il a un sens aigu de ce qui est nécessaire.</i><br /><br /><b>Note:</b> une fois que vous aurez mis à jour votre bio, elle devra être approuvée par UNLOQ avant d'être visible sur le site web d'UNLOQ et sur les CoachDesks. Dès que l'approbation sera terminée, cette biographie apparaîtra dans notre profil.",
          "bio_no_cv_yet": "Veuillez d'abord fournir des informations sur votre emploi actuel avant de rédiger votre courte biographie.",
          "group_work": "Veuillez indiquer ici votre expérience et vos préférences en ce qui concerne les différents types de travail de groupe. Vous pouvez préciser pour chaque type de travail de groupe si vous préférez le faire hors ligne, en ligne ou les deux et si vous avez de l'expérience dans la conception ou la réalisation ou les deux.",
          "job_information": "Veuillez fournir des informations sur votre emploi actuel",
          "languages": "Veuillez ajouter toutes les langues que vous parlez ainsi que leur niveau de compétence.",
          "personal_information": "Dans cette section de votre profil, vous pouvez entrer toutes vos informations personnelles de base. Veuillez noter que certaines de ces informations seront visibles sur la page <a href=\"https://unloq.org/our-team/\" target=\"_blank\">\"Notre équipe\"</a> du site web UNLOQ et sur les CoachDesks. Les champs marqués comme \"usage interne uniquement\" ne seront pas visibles par le public."
        },
        "languages": {
          "add": "Ajouter une autre langue",
          "select": "Sélection de la langue",
          "select_level": "Sélectionner le niveau de langue"
        },
        "last_name": "Nom de famille",
        "linkedin": "LinkedIn",
        "list_view": "vue en liste",
        "multiple_answers_possible": "Plusieurs réponses possibles",
        "nationalities": "Nationalités",
        "native_background": "Origine / racines",
        "organization_name": "Nom de l'organisation",
        "phone_number": "Numéro de téléphone",
        "place_of_birth": "Lieu de naissance",
        "profile": "Profil",
        "residence_country": "Pays de résidence",
        "search_in": "Rechercher dans",
        "secondary_phone_number": "Numéro de téléphone secondaire",
        "select_one_answer": "Sélectionnez une réponse",
        "timezone": "Fuseau horaire",
        "training_level": "Facilitation des niveaux",
        "training_levels": {
          "level_i": "Niveau I",
          "level_ii": "Niveau II",
          "level_iii": "Niveau III",
          "none": "Aucun"
        },
        "unloq": "Associés d'UNLOQ",
        "unloq_only": "Uniquement les associés de l'UNLOQ ?"
      },
      "filters": {
        "branch_experiences": "Expériences de la branche",
        "countries": "Pays",
        "job_title": "Titre du poste",
        "languages": "Langues",
        "miscellaneous": "Divers",
        "name_or_email": "Nom ou courriel",
        "organization": "Organisation",
        "professions": "Professions",
        "programs": "Les programmes",
        "remarks": "Remarques",
        "specializations": "Spécialisations",
        "timezones": "Fuseaux horaires"
      },
      "select": {
        "associate_list": "Sélectionner la liste des associés",
        "coach": "Sélectionner l'entraîneur",
        "program": "Sélectionner le programme"
      },
      "tours": {
        "coach_edit": {
          "step_coach": {
            "text_1": "Bienvenue sur la page de modification du profil !",
            "text_2": "Dans cet onglet, vos principales informations doivent être renseignées.",
            "text_3": "Les informations que vous saisissez ici seront utilisées pour vous mettre en relation avec un client et pour créer votre curriculum vitae."
          },
          "step_commercial_cv": {
            "text_1": "L'onglet <b>%{commercial_cv}</b> est similaire à l'onglet CV par défaut, sauf que les informations que vous saisissez ici sont destinées spécifiquement aux clients commerciaux."
          },
          "step_copy": {
            "text_1": "Vous pouvez utiliser le bouton <b>%{button_name}</b> si aucun CV commercial n'a encore été créé.",
            "text_2": "Le bouton copie toutes les informations de votre CV par défaut, après quoi vous pouvez modifier ou supprimer certaines parties."
          },
          "step_default_cv": {
            "text_1": "Dans cet onglet, votre curriculum vitae par défaut est composé.",
            "text_2": "Veuillez remplir ces données autant que possible, afin qu'UNLOQ puisse mieux adapter vos compétences à nos clients.",
            "text_3": "Nous utilisons également ces données pour générer automatiquement des CV.",
            "text_4": "Les CV générés ne seront partagés qu'en interne ou avec nos clients."
          },
          "step_help": {
            "text_1": "Ce bouton permet d'ouvrir à nouveau ce tour d'aide. Si vous souhaitez obtenir des explications plus tard, cliquez sur ce bouton."
          }
        }
      }
    },
    "recaptcha": {
      "errors": {
        "recaptcha_unreachable": "Oops, nous n'avons pas pu valider votre réponse reCAPTCHA. Veuillez essayer à nouveau.",
        "verification_failed": "La vérification reCAPTCHA a échoué, veuillez essayer à nouveau."
      }
    },
    "register": {
      "create": {
        "flash_email_invalid": "La connexion par signature unique (Single sign-on) a échoué car aucun e-mail valable n'a été trouvé. Veuillez essayer une connexion normale à la place.",
        "flash_error": "La connexion par signature unique a échoué, veuillez essayer la connexion normale à la place.",
        "flash_success": "Votre compte a été créé"
      },
      "new": {
        "btn": "Créer mon compte",
        "description": "Vous n'êtes qu'à une minute de faire un changement positif dans votre vie.",
        "edit_name": "Modifier le nom",
        "flash_error": "Votre compte n'a pas pu être créé. ",
        "flash_success": "Veuillez confirmer votre adresse e-mail pour continuer",
        "title": "Créez votre compte Campus UNLOQ."
      }
    },
    "reply": "Répondre",
    "results": "Résultats",
    "reviews": "Commentaires",
    "role": "Rôle",
    "roles": {
      "admin": "Admin",
      "client": "Client",
      "coach": "Coach",
      "organization_manager": "Responsable d'organisation"
    },
    "search": {
      "clear": "effacer la recherche",
      "placeholder": "Recherche..."
    },
    "search_client": "Rechercher un client",
    "search_coach": "Recherche d'un entraîneur",
    "search_respondent": "Recherche d'un répondant",
    "select_client": "Sélectionner un client",
    "select_coach": "Sélectionner l'entraîneur",
    "select_language": "Sélection de la langue",
    "select_language_level": "Sélectionner la compétence",
    "select_program": "Sélectionner un programme",
    "select_questionnaire": "Sélectionner le questionnaire",
    "select_respondent": "Sélectionner le répondant",
    "send_pdf_reports": "Envoyer des rapports PDF",
    "send_pdf_reports_failed": "Impossible d'envoyer les rapports PDF, tous les participants n'ont pas rempli les questionnaires",
    "send_pdf_reports_success": "Les rapports PDF ont été envoyés avec succès",
    "sessions": {
      "create": {
        "captcha_invalid": "Captcha non valable : veuillez assurer que vous êtes un être humain et pas un robot.",
        "invalid": "E-mail ou mot de passe non valable. Veuillez réessayer."
      },
      "verify_2fa_code": {
        "btn": "Vérifier",
        "label": "Entrez le code d'authentification à deux facteurs"
      }
    },
    "settings": "Paramètres",
    "shared_by": "Partagé par : ",
    "shared_mailer": {
      "dear": "Cher",
      "note": "Note : ce message confidentiel a été généré automatiquement.",
      "regards": "Cordialement,",
      "sender": "L'équipe du Campus UNLOQ"
    },
    "show_all": "tout montrer",
    "sidemenu": {
      "clients": "Clients",
      "dialogs": "Dialogues",
      "language": "Langue",
      "library": "Boîte à outils",
      "privacy": "Politique de confidentialité",
      "privacy_policy": "Politique de confidentialité",
      "privacy_regulations": "Normes de confidentialité",
      "support": "Soutien",
      "terms": "Termes et conditions",
      "user_terms": "Conditions d'utilisation"
    },
    "signup": {
      "confirm_email": {
        "description": "Veuillez vérifier si vous avez reçu un e-mail de confirmation dans  votre boîte de réception. Cliquez sur le lien dans l'e-mail pour confirmer votre adresse e-mail.<br><br>Si vous n'avez pas reçu d'e-mail, vérifiez votre dossier spam.",
        "flash_success": "Votre compte est activé.",
        "title": "Confirmez votre adresse e-mail"
      },
      "new": {
        "already_account": "Avez-vous déjà un compte ?",
        "btn": "Inscrivez-vous maintenant",
        "description": "Inscrivez-vous sur Campus UNLOQ pour des conversations de coaching plus engageantes, plus percutantes et plus sûres.",
        "email_already_exists": "L'adresse électronique existe déjà.",
        "organization_description": "Ce formulaire d'inscription est spécialement conçu pour %{name}. Vous ne pouvez vous inscrire qu'en utilisant une adresse électronique @%{domain}.",
        "organization_domain_error": "L'adresse électronique n'est pas valable : le domaine de l'adresse électronique doit faire partie de l'organisation/institution.",
        "title": "Élevez le niveau de votre coaching"
      }
    },
    "simple_form": {
      "error_notification": {
        "default_message": "Veuillez examiner les problèmes ci-dessous :"
      },
      "no": "Non",
      "required": {
        "mark": "*",
        "text": "Requis"
      },
      "yes": "Oui"
    },
    "spinner": {
      "title": "Veuillez patienter..."
    },
    "state": "État",
    "status": "Statut",
    "support": {
      "array": {
        "last_word_connector": " et ",
        "two_words_connector": " et ",
        "words_connector": ", "
      },
      "check_manual": "Bienvenus sur la page d'assistance. Dans le manuel, vous trouverez de nombreuses informations supplémentaires, cliquez ici : ",
      "help_text": "Si votre question n'y trouve pas de réponse, veuillez m'envoyer un e-mail à l'adresse suivante ",
      "help_text2": "ou appelez-moi pendant les heures de bureau au",
      "help_text3": "Je serai heureux/se de pouvoir vous aider !",
      "manual": "Manuel",
      "title": "Soutien"
    },
    "time": {
      "am": "am",
      "formats": {
        "default": "%d %B %Y %Hh %Mmin %Ss",
        "dialogue": "%a %e %b %Y %H:%M %Z",
        "long": "%A %d %B %Y %Hh%M",
        "no_timezone": "%a, %d %b %Y %H:%M:%S",
        "notime": "%e %b %Y",
        "short": "%d %b %Hh%M",
        "trestle_date": "%e %b %Y",
        "trestle_time": "%Hh%M",
        "trestle_time_with_seconds": "%Hh %M' %S\""
      },
      "pm": "pm"
    },
    "timesheets": {
      "clients_title": "Séances avec des clients",
      "description": "Vous pouvez créer ci-dessous des aperçus du nombre total d'heures enregistrées par programme, par client et par période. L'ajout ou la modification d'heures ne peut se faire que via un journal de session du client.",
      "export": {
        "all_figures": "Tous les chiffres",
        "confirm": "Vous téléchargez des informations confidentielles, êtes-vous sûr ?",
        "details": "Détails de la session",
        "key_figures": "Chiffres clés"
      },
      "title": "Feuille de temps"
    },
    "title_date": "Date",
    "toastrs": {
      "copied": "Copié !",
      "sent": "Envoyé !"
    },
    "total": "Total",
    "total_clients": "Nombre total de clients",
    "total_costs": "Coûts totaux",
    "trestle": {
      "confirmation": {
        "cancel": "Non",
        "delete": "Oui",
        "title": "Voulez-vous supprimer ?"
      },
      "dialog": {
        "error": "La requête n'a pas pu être traitée."
      },
      "footer": "Fourni par Trestle",
      "helpers": {
        "page_entries_info": {
          "more_pages": {
            "display_entries": "%{entry_name} <strong>%{first}&nbsp;-&nbsp;%{last}</strong> sur <b>%{total}</b>"
          },
          "one_page": {
            "display_entries": {
              "one": "<strong>1</strong> %{entry_name}",
              "other": "<strong>%{count}</strong> %{entry_name}",
              "zero": "Aucun(e) '%{entry_name}'"
            }
          }
        }
      },
      "onboarding": {
        "no_admins": "Pour commencer, créez le répertoire <code>app/admin</code>.",
        "no_form": "Veuillez déclarer un block 'form' ou créer un modèle partiel <code>_form.html</code>.",
        "no_template": "Pour personnaliser ce modèle, veuillez créer <code>%{path}</code>.",
        "welcome": "Bienvenue sur Trestle"
      },
      "title": "Trestle Admin",
      "ui": {
        "toggle_navigation": "Basculer navigation",
        "toggle_sidebar": "Basculer vue latérale"
      },
      "version": "Version"
    },
    "true": "Oui",
    "user_mailer": {
      "email_confirmation": {
        "btn": "Confirmez votre e-mail",
        "message": "Avant de pouvoir activer votre nouveau compte, nous devons confirmer votre adresse e-mail. Veuillez cliquer sur le lien ci-dessous pour confirmer votre e-mail et continuer sur UNLOQ Campus.",
        "subject": "UNLOQ Campus : confirmez votre adresse e-mail",
        "thanks": "Merci de rejoindre UNLOQ Campus !"
      }
    },
    "users": {
      "active_explanation": "Non coché = inactif = archivé sur la page d'aperçu des clients (pour le coach).",
      "already_account": "a déjà un compte ! Nous leur enverrons une invitation pour rejoindre votre conversation.",
      "coach_for_client": "Coach pour ce client",
      "copy_to_clipboard_tooltip": "Copiez le lien d'invitation et envoyez ce lien au client avec <br/>une application de messagerie externe (n'utilisez pas ce lien vous-même).",
      "edit_user": "Modifier l'utilisateur",
      "empty": "Aucun client n'a été trouvé.",
      "enable_rating": "Cela permet à ce client de vous évaluer sur<br/> et de rédiger un texte de témoignage.",
      "organizations_label": "Responsable(s) de l'organisation pour :",
      "programs_label": "Programme(s) : un ou plusieurs programmes  peuvent être attribués au compte de l'utilisateur et il est possible de sélectionner un rôle par programme.",
      "remove_membership": "Supprimer l'adhésion",
      "remove_program": "Supprimer le programme",
      "resend_confirm": "Êtes-vous sûr de vouloir (r)envoyer un e-mail d'inscription ou de confirmation ?",
      "resend_tooltip": "Renvoyez l'e-mail d'inscription ou de <br/>confirmation à cet utilisateur.",
      "roles_empty": "Un ou plusieurs programmes doivent être attribués au compte d'utilisateur."
    },
    "views": {
      "pagination": {
        "first": "En premier",
        "last": "En dernier",
        "next": "Suivant",
        "previous": "Précédent",
        "truncate": "..."
      }
    },
    "your_coach": "Votre coach est :"
  },
  "nl": {
    "account_only": "Zonder dialoog",
    "accounts": {
      "app_verify_label": "Vul hier je 6-cijferige code in vanuit de",
      "curriculum_vitae": {
        "approach_required": "Benaderingen zijn vereist voor de Engelse taal",
        "approach_too_long": "Je bio is te lang, het maximum is 800 tekens",
        "approach_too_short": "Je bio is te kort, het minimum is 400 tekens",
        "copy_from_default_success": "Standaard CV is succesvol gekopieerd naar commercieel CV",
        "job_title_and_approach_required": "Functietitel en Aanpak zijn vereist voor de Engelse taal",
        "job_title_required": "Geef uw huidige functietitel"
      },
      "disable": "Uitschakelen",
      "disable_title": "Hier kun je deze 2-staps verificatiemethode uitschakelen of overstappen naar de SMS-methode.",
      "edit": {
        "flash_success": "Je profiel is successvol gewijzigd",
        "language_label": "Taalinstelling voor e-mails voor dit account en voorkeurstaal voor websiteweergave:"
      },
      "edit_2fa": {
        "authenticator_title": "Scan deze QR-code met je authenticator app:",
        "choose_below": "Kies hieronder de methode die je wilt gebruiken. Je kunt deze later nog wijzigen.",
        "description": "Er zijn twee manieren om gebruik te maken van 2-stapsverificatie:",
        "no_app_description": "Google Authenticator is een gratis 2-stapsverificatie app. Download deze op je smartphone door te zoeken naar 'Google Authenticator' in de Play Store of App Store.",
        "no_app_title": "Heb je de app nog niet?",
        "option1": "via een identificatie app",
        "option2": "via een SMS-bericht",
        "phone_number_label": "Telefoonnummer:",
        "sms_description": "Door middel van deze 2-stapsverificatie ontvang je een SMS code op je mobiele telefoon telkens wanneer je inlogt. Vul hieronder je mobiele nummer in om deze methode te activeren (PS: typ '+' en daarna de landcode, vervolgens de 0 weglaten van je telefoonnummer).",
        "sms_explanation": "Klik op onderstaande knop om een SMS code op uw mobiele telefoon te ontvangen waarmee u 2FA kunt uitschakelen.",
        "sms_flash_error": "Telefoonnummer is ongeldig of code kon niet worden verzonden.",
        "sms_flash_success": "De code is verzonden per SMS naar je mobiele telefoon.",
        "sms_send_btn": "Controleer nummer",
        "sms_verify_btn": "Vraag code op",
        "sms_warning": "<b>Belangrijk:</b> afhankelijk van het land waar je berichten ontvangt, kunnen lokale regels het verzenden van Campus SMS-berichten beperken om spam te voorkomen. Daarom raden we aan om 2FA in te schakelen met Google Authenticator.",
        "title": "2-Stapsverificatie voegt extra beveiliging toe aan je account. Naast je persoonlijke wachtwoord gebruik je dan een tweede beveiligingsmiddel tijdens het inloggen."
      },
      "edit_password": {
        "at_least": "Minstens",
        "capital": "hoofdletter",
        "characters": "karakters",
        "description": "Voer het huidige wachtwoord en tweemaal het nieuwe wachtwoord in om het huidige wachtwoord te wijzigen.",
        "letter": "kleine letter",
        "new_password": "Nieuw wachtwoord",
        "not_matching": "Wachtwoorden komen overeen",
        "number": "cijfer",
        "old_password": "Huidig wachtwoord",
        "special": "speciaal karakter",
        "strength_title": "Wachtwoord(en) moeten aan de volgende criteria voldoen:",
        "title": "Wachtwoord wijzigen"
      },
      "enable": "Inschakelen",
      "flash_2fa_disabled": "2-stapsverificatie is uitgeschakeld.",
      "flash_2fa_enabled": "2-stapsverificatie is ingeschakeld.",
      "flash_2fa_error": "2-staps verificatiecode was niet juist. Probeer het opnieuw.",
      "show_phone_number_verification": {
        "label": "SMS code:",
        "resend_link": "nieuwe SMS code aan.",
        "resend_title": "UNLOQ Campus heeft zojuist een SMS-bericht met een code naar je mobiele telefoon verzonden, vul deze code hier in om te valideren. Binnen 2 minuten geen code ontvangen? Vraag dan een",
        "title": "Verifiëer SMS code"
      },
      "success_2fa": {
        "description": {
          "disabled": "Je hebt 2-stapsverificatie succesvol uitgeschakeld voor je account. Klik op 'Afsluiten' om af te ronden of op '2FA' om naar het 2-stapsverificatie (2FA) menu terug te keren.",
          "enabled": "Je hebt 2-stapsverificatie succesvol ingeschakeld voor je account. Klik op de knop hieronder om af te sluiten."
        }
      },
      "update_password": {
        "flash_error": "Je wachtwoord is ongeldig.",
        "flash_success": "Je wachtwoord is succesvol gewijzigd."
      }
    },
    "actions": "Acties",
    "admin": {
      "breadcrumbs": {
        "home": "Home"
      },
      "buttons": {
        "delete": "%{model_name} verwijderen",
        "save": "%{model_name} opslaan"
      },
      "flash": {
        "create": {
          "failure": {
            "message": "Kon niet toevoegen om de volgende redenen.",
            "title": "Waarschuwing!"
          },
          "success": {
            "message": "De %{model_name} succesvol toegevoegd.",
            "title": "Gelukt!"
          }
        },
        "destroy": {
          "failure": {
            "message": "Kon %{model_name} niet verwijderen.",
            "title": "Waarschuwing!"
          },
          "success": {
            "message": "De %{model_name} is succesvol verwijderd.",
            "title": "Gelukt!"
          }
        },
        "update": {
          "failure": {
            "message": "Kon niet aanpassen om de volgende redenen.",
            "title": "Waarschuwing!"
          },
          "success": {
            "message": "De %{model_name} is succesvol gewijzigd.",
            "title": "Gelukt!"
          }
        }
      },
      "form": {
        "select": {
          "prompt": "- Selecteer %{attribute_name} -"
        }
      },
      "format": {
        "blank": "Geen"
      },
      "table": {
        "headers": {
          "id": "ID"
        }
      },
      "titles": {
        "edit": "%{model_name} aanpassen",
        "index": "Lijst van %{pluralized_model_name}"
      }
    },
    "are_you_sure": "Weet je het zeker?",
    "assignments": {
      "edit": {
        "add_question": "Vraag toevoegen",
        "title": "Wijzigen oefening"
      },
      "new": {
        "description": "Creëer hier een oefening, opdracht, reflectie of andere leeractiviteit. Dit gaat in twee stappen:",
        "step1": "Geef de opdracht een duidelijke naam (verplicht), noteer de introductietekst, uitleg of doelstelling van de opdracht. Kies 'Opslaan'.",
        "step2": "Formuleer een of meerdere vragen die de cliënt dient te beantwoorden en kies 'Afronden'.",
        "title": "Creëer oefening"
      },
      "questions": {
        "hint": "Dit is de vraag zoals deze wordt gesteld richting de cliënt.",
        "prompt": "Voeg een vraag toe die de cliënt kan dient te beantwoorden.",
        "title": "Vraag toevoegen / wijzigen",
        "type": "Vraagtype"
      },
      "submission": "invullen",
      "title": "Oefening",
      "waiting_for": "Wachten op"
    },
    "auth_mailer": {
      "password_reset": {
        "message": "We hebben een verzoek ontvangen om je wachtwoord voor UNLOQ Campus te resetten. Was jij dat? Geweldig, klik op de link hieronder en je kunt een nieuw wachtwoord instellen voor je account. Deze link werkt een paar uur.",
        "no_email": "Heb je geen nieuw wachtwoord aangevraagd? Maak je geen zorgen, negeer deze e-mail en er verandert niets. Aarzel niet om contact met onze servicedesk (support@unloq.org) op te nemen als je aanvullende vragen hebt.",
        "subject": "Wachtwoord resetten"
      }
    },
    "avatars": {
      "new": {
        "btn_later": "Doe dit later",
        "flash_success": "Je profiel is ingesteld.",
        "title": "Maak je account persoonlijker door een profielfoto weer te geven."
      }
    },
    "btn": {
      "back": "Terug",
      "cancel": "Annuleren",
      "change": "Wijzigen",
      "finalize": "Afronden",
      "finish": "Afsluiten",
      "ok": "OK",
      "save": "Opslaan",
      "select_file": "Selecteer bestand",
      "select_logo": "Selecteer logo",
      "send": "Versturen"
    },
    "by": "door",
    "client": {
      "bank_account_number": "Bankrekeningnummer",
      "chamber_of_commerce": "KvK nummer",
      "client_details": "PDF inschrijfgegevens",
      "client_details_error": "Moet een PDF-bestand zijn",
      "country": "Land",
      "dialogs": "Dialogen",
      "dossier": "Cliënt sessielogs",
      "edit": {
        "title": "Cliënt-instellingen"
      },
      "gender": "Geslacht",
      "job_title": "Functietitel",
      "location": "Plaats",
      "miscellaneous": "Diversen",
      "office_location": "Kantoorlocatie",
      "phone_number": "Telefoonnummer",
      "remarks": "Opmerkingen",
      "show": {
        "title": "Cliënt-details"
      },
      "time_zone": "Tijdzone",
      "title": "Cliënt",
      "vat_number": "BTW (identificatie)nummer",
      "zip_code": "Postcode"
    },
    "client_mailer": {
      "existing_user_invitation": {
        "and_program": "voor het programma",
        "btn": "Accepteer uitnodiging",
        "message": "Klik hier om deel te nemen",
        "subject": "heeft je uitgenodigd voor een programma"
      },
      "new_user_invitation": {
        "and_program": "voor het programma",
        "btn": "Accepteer uitnodiging",
        "message": "Klik hier om deel te nemen aan",
        "subject": "heeft je uitgenodigd voor een programma"
      },
      "rating_enabled": {
        "btn": "Klik hier voor UNLOQ Campus",
        "footer": "Ter informatie:<br/> - Dit is een verzoek, geen verplichting.<br/> - Je kunt altijd je review wijzigen.<br/> - Je kunt specificeren wie je review mag inzien.<br/>",
        "intro": "heeft een verzoek aan je. Zou je misschien een review willen schrijven over hoe je de coaching heb ervaren?",
        "message": "Er is een speciale functie beschikbaar voor reviews binnen UNLOQ Campus. <br/>Klik hier om de dialoog met deze coach in UNLOQ Campus te openen en klik vervolgens in de rechterkolom op het aantal sterren dat je wilt geven en schrijf vervolgens een review.",
        "subject": "heeft een reviewverzoek voor je"
      },
      "remind_user_invitation": {
        "and_program": "voor het programma",
        "btn": "Accepteer uitnodiging",
        "message": "Klik hier om deel te nemen",
        "reminder": "Herinnering: ",
        "subject": "heeft je opnieuw uitgenodigd voor een programma"
      }
    },
    "client_sessions": {
      "all_programs_ended": {
        "body": "Alle programma's waar deze cliënt in zit zijn afgelopen, er kunnen geen sessies meer worden toegevoegd. Als je hier vragen over hebt, neem dan contact op met je programmamanager.",
        "title": "Alle programma's zijn afgelopen"
      },
      "available": "Beschikbaar",
      "btn_new": "Voeg sessie toe",
      "client": "Cliënt",
      "client_archived": {
        "archived_at": "Deze clients zijn gearchiveerd op %{datetime}",
        "body": "Deze client is gearchiveerd, er kunnen geen sessies meer worden toegevoegd. Als je hier vragen over hebt, neem dan contact op met je programmamanager.",
        "title": "Klant gearchiveerd"
      },
      "date_exceeded": "of inhoud van de sessie is niet meer beschikbaar. Na het aflopen van de maand hebben coaches nog %{days} dagen om sessielogs aan te maken, te wijzigen of the verwijderen. Na deze periode kunnen de sessielogs over de afgeronde maand niet meer aangepast worden.",
      "date_not_in_future": "in de toekomst is niet toegestaan.",
      "duration": "Declarabel",
      "duration_nonbillable": "Niet declarabel",
      "file": "Bijlage",
      "file_explanation": "Let op: Er is slechts 1 bestand per sessie toegestaan. Als dit sessielogboek al een bijlage bevat, wordt deze overschreven bij het uploaden van een nieuwe.",
      "from_date": "Begindatum",
      "heading": "Cliënt sessielogs van:",
      "in_minutes": "In minuten",
      "new_client_session": "Nieuwe sessie voor:",
      "new_client_session_description": "De gegevens hieronder zijn niet zichtbaar voor de cliënt. Programma, type, datum en duur zijn zichtbaar voor de organisatiemanager voor administratieve doeleinden. Contentgerelateerde (vertrouwelijke) informatie is alleen voor jou zichtbaar.",
      "notes": "Sessie-notities",
      "number_of": "Sessies",
      "other": "Overig / extra notities",
      "remaining": "Resterend",
      "session": "Sessie",
      "sessions": "sessie(s)",
      "title": "Titel",
      "to_date": "Einddatum",
      "tooltip_filter_first": "Filter eerst op programma of coach <br/> voordat je kunt exporteren",
      "type": "Type",
      "type__360_assessments": "360 beoordelingen",
      "type_administration": "Administratie",
      "type_call": "Telefonisch",
      "type_campus": "UNLOQ Campus",
      "type_cancellation_legit": "Geannuleerd (tijdig)",
      "type_cancellation_not_legit": "Geannuleerd (te laat)",
      "type_face2face": "Face2Face",
      "type_group_coaching": "Groepscoaching",
      "type_noshow": "Geannuleerd (no-show)",
      "type_other": "Anders",
      "type_rescheduled": "Opnieuw ingepland",
      "type_sms": "SMS",
      "type_technical_difficulties": "Technische problemen",
      "type_videocall": "Beeldbellen",
      "used": "Gebruikt"
    },
    "client_signup": {
      "new": {
        "explanation": "Dit aanmeldingsformulier is specifiek bedoeld voor %{name}. Aanmelden is nodig om e-mails te ontvangen met links naar onze 360 assessment (d.w.z. vragenlijst). Ook kunt u respondenten toevoegen."
      },
      "new_signup": {
        "btn": "Respondenten toevoegen",
        "message": "Klik hier om respondenten toe te voegen",
        "subject": "U bent succesvol geregistreerd voor"
      }
    },
    "clients": {
      "archive": "Archiveer",
      "minutes_per_client": "# Min. per cliënt",
      "reactivate": "Re-activeer",
      "tab_active": "Actief",
      "tab_archive": "Archief",
      "title": "Cliënten",
      "toggle_active": {
        "flash_success": "De cliënt is successvol"
      },
      "tooltip_active_and_registered": "Toon dialoog (cliënt is geregistreerd)",
      "tooltip_minutes": "Aantal declarabele minuten die geregistreerd zijn voor deze cliënt",
      "tooltip_not_active": "Toon dialoog (cliënt moet nog een uitnodigingsmail ontvangen)",
      "tooltip_not_registered": "Toon dialoog (cliënt is nog niet geregistreerd)"
    },
    "coach_mailer": {
      "batched_new_clients": {
        "footer": "Bij vragen kun je contact opnemen met de programmamanager. We wensen je veel succes!",
        "message": "Nieuwe cliënt(en) zijn toegevoegd voor je voor het programma:",
        "subject": "Je hebt nieuwe cliënt(en) voor het"
      },
      "client_rematched": {
        "footer": "Bij vragen kun je contact opnemen met de programmamanager. We wensen je veel succes!",
        "message": "Een cliënt is opnieuw toegevoegd voor je voor het programma:",
        "subject": "Je hebt een cliënt die opnieuw aan jou is gekoppeld voor het"
      },
      "existing_user_invitation": {
        "click": "Klik hier om deel te nemen",
        "link": "Ga naar het programma",
        "message": "heeft je toegevoegd aan het programma %{program} van %{organization} in UNLOQ Campus",
        "subject": "heeft je uitgenodigd voor een programma"
      },
      "extra_minutes_for_client": {
        "footer": "Deze extra coachingminuten zijn toegevoegd door: ",
        "message": "De totale hoeveelheid begeleidingstijd voor <b>%{client}</b> in programma <b>%{program}</b> is veranderd in:",
        "subject": "UNLOQ Campus: extra minuten toegevoegd voor cliënt"
      },
      "new_client_matched": {
        "footer": "Bij vragen kun je contact opnemen met de programmamanager. We wensen je veel succes!",
        "message": "Een nieuwe cliënt is toegevoegd voor je voor het programma:",
        "subject": "Je hebt een nieuwe cliënt voor het"
      },
      "new_user_invitation": {
        "click": "Klik hier om deel te nemen aan",
        "link": "Accepteer uitnodiging",
        "message": "%{organization} en het programma %{program} in UNLOQ Campus:",
        "subject": "heeft je uitgenodigd voor een programma"
      },
      "rating_done": {
        "btn": "Klik hier voor UNLOQ Campus",
        "message": "Klik hier om de reviews te bekijken:",
        "subject": "Eén van je cliënten heeft een review ingezonden voor je."
      }
    },
    "coaches": {
      "find_coach": "Zoek coach",
      "match_failed": "Er is een fout opgetreden en de klant kon niet worden gekoppeld aan deze coach",
      "match_success": "Klant succesvol gekoppeld",
      "modal_title": "Klant aan coach koppelen",
      "title": "Coaches"
    },
    "comments": {
      "disabled": "Notities zijn uitgeschakeld voor dit programma of u hebt geen rechten.",
      "email_explanation": "Tien minuten nadat iemand een notitie heeft geplaatst, wordt er een e-mailnotificatie verzonden. Houd er rekening mee dat deze meldingen eens per 10 minuten worden verzameld en verzonden om spam te voorkomen.\"",
      "explanation": "Opmerkingen: interne communicatie tussen coach en L&D programmamanagers.",
      "new_messages_emails": "Nieuwe e-mails met notities",
      "shared": {
        "coach_only": "Alleen coach",
        "private": "Alleen voor L&D programmamanagers",
        "public": "Coaches én L&D programmamanagers",
        "title": "Deel met"
      },
      "subject": "Onderwerp",
      "title": "Notities",
      "title_placeholder": "Schrijf je notities over deze cliënt..."
    },
    "cookies": {
      "cookiebar_link_text": "cookiebeleid",
      "cookiebar_link_url": "/nl/pages/cookies",
      "cookiebar_text": "Wij gebruiken cookies om u de best mogelijke ervaring te bieden. Lees ons %{link} voor meer informatie over hoe en waarom we cookies gebruiken."
    },
    "copy": "Kopiëren",
    "date": {
      "abbr_day_names": [
        "zo",
        "ma",
        "di",
        "wo",
        "do",
        "vr",
        "za"
      ],
      "abbr_month_names": [
        null,
        "jan",
        "feb",
        "mrt",
        "apr",
        "mei",
        "jun",
        "jul",
        "aug",
        "sep",
        "okt",
        "nov",
        "dec"
      ],
      "day_names": [
        "zondag",
        "maandag",
        "dinsdag",
        "woensdag",
        "donderdag",
        "vrijdag",
        "zaterdag"
      ],
      "formats": {
        "default": "%d-%m-%Y",
        "long": "%e %b %Y at %H:%M",
        "notime": "%e %b %Y",
        "short": "%e %b",
        "trestle_calendar": "%-d-%-m-%Y"
      },
      "month_names": [
        null,
        "januari",
        "februari",
        "maart",
        "april",
        "mei",
        "juni",
        "juli",
        "augustus",
        "september",
        "oktober",
        "november",
        "december"
      ],
      "order": [
        "day",
        "month",
        "year"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "ongeveer een uur",
          "other": "ongeveer %{count} uur"
        },
        "about_x_months": {
          "one": "ongeveer een maand",
          "other": "ongeveer %{count} maanden"
        },
        "about_x_years": {
          "one": "ongeveer een jaar",
          "other": "ongeveer %{count} jaar"
        },
        "almost_x_years": {
          "one": "bijna een jaar",
          "other": "bijna %{count} jaar"
        },
        "half_a_minute": "een halve minuut",
        "less_than_x_minutes": {
          "one": "minder dan een minuut",
          "other": "minder dan %{count} minuten"
        },
        "less_than_x_seconds": {
          "one": "minder dan een seconde",
          "other": "minder dan %{count} seconden"
        },
        "over_x_years": {
          "one": "meer dan een jaar",
          "other": "meer dan %{count} jaar"
        },
        "x_days": {
          "one": "%{count} dag",
          "other": "%{count} dagen"
        },
        "x_minutes": {
          "one": "%{count} minuut",
          "other": "%{count} minuten"
        },
        "x_months": {
          "one": "%{count} maand",
          "other": "%{count} maanden"
        },
        "x_seconds": {
          "one": "%{count} seconde",
          "other": "%{count} seconden"
        },
        "x_years": {
          "one": "%{count} jaar",
          "other": "%{count} jaar"
        }
      },
      "prompts": {
        "day": "dag",
        "hour": "uur",
        "minute": "minuut",
        "month": "maand",
        "second": "seconde",
        "year": "jaar"
      }
    },
    "deactivated": "Gedeactiveerd",
    "delete": "Verwijderen",
    "dialog_successfully_archived": "Dialoog succesvol gearchiveerd",
    "dialog_title": {
      "label": "Groepsdialoog",
      "placeholder": "Typ hier groepstitel ..."
    },
    "dialogs": {
      "a_group_dialog": "een groepsdialoog",
      "add_participant": "Voeg deelnemer toe aan deze dialoog",
      "add_profile_picture": "Geef je account een professionele uitstraling door een profielfoto en ander (bedrijfs)gegevens toe te voegen. Ga naar",
      "already_present": "Er is al eerder een dialoog gemaakt",
      "are_you_sure": {
        "reactivate": "Weet u zeker dat u deze deelnemer opnieuw wilt activeren?",
        "remove": "Weet je zeker dat je deze deelnemer wilt verwijderen?"
      },
      "are_you_sure_activate": "Weet je zeker dat je deze dialoog wilt activeren en de deelnemers een uitnodiging per e-mail ontvangen?",
      "assignment": {
        "completed_description": "Je hebt alle vragen beantwoord. Om de opdracht in te kunnen dienen is nog een begeleidend Dialoog-bericht nodig, deze kun je hieronder schrijven.",
        "continue": "Ga door",
        "hand_in": "Inleveren",
        "hand_in_success": "Je oefening is ingeleverd.",
        "open": "Open",
        "view_answers": "Toon antwoorden"
      },
      "autosaved": "Als concept opgeslagen.",
      "btn": {
        "attachment": "Toolbox content",
        "new": "Nieuwe cliënt / nieuwe dialoog"
      },
      "edited": "gewijzigd",
      "email_explanation": "Tien minuten nadat iemand een nieuw dialoogbericht heeft geplaatst, wordt er een e-mailbericht verstuurd. Houd er rekening mee dat deze meldingen om de 10 minuten worden verzameld en verzonden om spam te voorkomen.\"",
      "empty": "Geen dialoog gevonden.",
      "explanation": "Nieuwe dialoogberichten.",
      "group_success": "Gebruiker is succesvol aan de dialog toegevoegd.",
      "invitation_pending": "Uitnodiging verstuurd",
      "invite_someone": "de eerste starten",
      "new": {
        "btn": {
          "create_account": "Account creëren",
          "send": "Uitnodiging versturen"
        },
        "description": "Je kunt hieronder uit drie verschillende mogelijkheden kiezen:",
        "enter_email": "Noteer hieronder het e-mail van de cliënt om te verifiëren of deze reeds bekend is binnen UNLOQ Campus.",
        "extra_information_account_only": "Na het creëren van enkel een account zonder uitnodiging voor het starten van een dialoog is het account niet beschikbaar voor de cliënt. Op een later moment is het alsnog mogelijk om een dialoog te starten.",
        "extra_information_already_account": "Na het versturen van de uitnodiging kun je indien gewenst direct een welkomstbericht schrijven in het nieuwe dialoog. Let op: de cliënt dient de uitnodiging eerst te accepteren (via link in e-mail) voordat deze kan deelnemen aan de dialoog.",
        "extra_information_no_account": "Na het versturen van de uitnodiging kun je indien gewenst direct een welkomstbericht schrijven in het nieuwe dialoog. Let op: de cliënt dient de uitnodiging eerst te accepteren (via link in e-mail) voordat deze kan deelnemen aan de dialoog.",
        "flash_success_create_account": "Het account is aangemaakt.",
        "flash_success_current": "Alle deelnemers zijn uitgenodigd. Je bent klaar om je eerste bericht te versturen.",
        "flash_success_invited": "De dialoog is aangemaakt en de deelnemers zijn uitgenodigd.",
        "no_account": "Deze persoon heeft nog geen account. Noteer hieronder enkele basisgegevens om een cliëntaccount te creëren. Selecteer vervolgens het programma waaraan het account moet worden toegevoegd. Kies tenslotte voor de knop 'Uitnodiging versturen' indien je deze cliënt direct wilt uitnodigen voor een dialoog of kies voor de knop 'Account creëren' indien je alleen een account wilt aanmaken zonder een dialoog.",
        "options": "<ul><li>A) Start dialoog met bestaande cliënt</li><li>B) Start dialoog met nieuwe cliënt</li><li>C) Creëren cliëntaccount zonder dialoog</li></ul>",
        "participant": {
          "already_account": "heeft al een account! Kies vervolgens voor de knop 'Uitnodiging versturen' om deze persoon aan de groepsdialoog toe te voegen.",
          "description": "Je kunt hieronder uit de mogelijkheden kiezen:",
          "enter_email": "Noteer hieronder het e-mailadres van de cliënt om te verifiëren of deze reeds bekend is binnen UNLOQ Campus.",
          "extra_information_no_account": "De persoon dient de uitnodiging eerst te accepteren (via link in e-mail) voordat deze kan deelnemen aan de groepsdialoog.",
          "no_account": "Deze persoon heeft nog geen account. Noteer hieronder enkele basisgegevens om een account te creëren. Kies vervolgens voor de knop 'Uitnodiging versturen' om de persoon aan de groepsdialoog toe te voegen.",
          "options": "<ul><li>A) Voeg een bestaand account toe aan deze groepsdialoog</li><li>B) Voeg een nieuw account toe aan deze groepsdialoog</li></ul>",
          "title": "Wie wil je toevoegen aan deze groepsdialoog?"
        },
        "title": "Wat wil je doen?"
      },
      "new_messages_emails": "Nieuw dialoogbericht e-mails",
      "no_dialogs": "Je hebt nog geen dialogen.",
      "no_messages": "(Nog) geen berichten",
      "no_program_membership": "Coach neemt (nog) niet deel aan het programma.",
      "not_logged_in": "Heeft (nog) niet ingelogd",
      "others": "ander(en)",
      "remove_participant": "Deelnemer verwijderen",
      "table": {
        "participants": "Deelnemers"
      },
      "unread_message": {
        "one": "ongelezen bericht",
        "other": "ongelezen berichten"
      },
      "welcome": "Welkom in je UNLOQ Campus account.",
      "why_not": "Hier kun je",
      "with": "Dialoog met",
      "you": "Jij:",
      "your_responses": "Antwoorden"
    },
    "download": "Download",
    "edit": "Wijzigen",
    "email": {
      "delivery_failure": "Fout bij aflevering e-mail"
    },
    "export": "Export",
    "false": "Nee",
    "flash_archived_success": "is succesvol gedeactiveerd.",
    "flash_created_success": "is succesvol aangemaakt.",
    "flash_deleted_success": "is succesvol verwijderd.",
    "flash_enabled_success": "is succesvol ingeschakeld",
    "flash_error": "Er is een fout opgetreden: corrigeer de foutmeldingen indien mogelijk.",
    "flash_updated_success": "is succesvol gewijzigd.",
    "footer_text": "Met meer dan 10 jaar ervaring is UNLOQ Campus een vertrouwde partner geworden voor nationale en internationale organisaties die hoge eisen stellen aan vertrouwelijkheid en beveiliging. UNLOQ Campus is volledig GDPR compliant en ISO 27001 gecertificeerd voor ontwikkeling, onderhoud en secure hosting activiteiten.",
    "for": "voor",
    "forms": {
      "add_program": "Voeg programma toe",
      "all_coaches": "Alle coaches",
      "choose": "Kies...",
      "choose_program": "Alle programma's",
      "confirm_password": "Bevestig wachtwoord",
      "consent": "Ik begrijp en ga akkoord met het verwerken van persoonlijke gegevens zoals beschreven in het <a href='/en/pages/privacy_regulations' target='_blank'>Privacybeleid</a>",
      "description": "Beschrijving",
      "email": "E-mail",
      "first_name": "Voornaam",
      "joined": "Gecreëerd op",
      "language": "Taal",
      "last_name": "Achternaam",
      "last_seen": "Laatst ingelogd op",
      "name": "Naam",
      "new_message": "Nieuw bericht",
      "optional_attachment": "Bijlage",
      "organization_name": "Naam organisatie",
      "organization_name_unloq": "Naam organisatie (zoals bekend UNLOQ Campus)",
      "password": "Wachtwoord:",
      "picture": "Profielfoto",
      "select_file": {
        "one": "Selecteer bestand",
        "other": "Bestanden selecteren"
      },
      "select_from_date": "Selecteer begindatum",
      "select_to_date": "Selecteer einddatum",
      "terms": "Ik heb de <a href='/nl/pages/terms' target='_blank'>algemene voorwaarden</a> gelezen en ga akkoord",
      "title": "Titel",
      "type": "...",
      "type_here": "Typ een dialoogbericht..."
    },
    "helpers": {
      "select": {
        "prompt": "Maak een keuze"
      },
      "submit": {
        "create": "%{model} toevoegen",
        "submit": "%{model} opslaan",
        "update": "%{model} bijwerken"
      }
    },
    "how_do_i_enable_javascript": "Hoe schakel ik javascript in?",
    "i18n": {
      "plural": {
        "keys": [
          "one",
          "other"
        ]
      }
    },
    "javascript_required": "Javascript is vereist om deze toepassing te gebruiken.",
    "language": {
      "en": "Engels",
      "es": "Spaans",
      "fr": "Frans",
      "nl": "Nederlands"
    },
    "last_login": "Laatst ingelogd",
    "last_modified": "Laatste activiteit",
    "learning_path": "Leertraject",
    "learning_path_mailer": {
      "progress": {
        "subject": "Je cursusvoortgang voor het programma %{program}"
      }
    },
    "library": {
      "add": "Bijvoegen",
      "are_you_sure": "Weet je het zeker? Dit toolbox-item zal ook worden verwijderd uit alle dialogen waar deze mogelijk gebruikt wordt.",
      "assignment": "Oefening",
      "delete_folder_confirmation": "Weet je zeker dat je deze map wilt verwijderen inclusief de inhoud?",
      "document": "Document",
      "empty": "Er zijn nog geen items in jouw toolbox. Kies de ‘+’-knop om een document, video, oefening of andere content toe te voegen.",
      "empty_folder": "Deze map bevat nog geen (zichtbare) items. Kies de '+' knop om een document, video, oefening of andere inhoud toe te voegen.",
      "filters": {
        "assignments": "Oefeningen",
        "documents": "Documenten",
        "embeds": "Video's",
        "folders": "Mappen",
        "images": "Afbeeldingen",
        "uploads": "Documenten",
        "videos": "Video's"
      },
      "folder": "Map",
      "image": "Afbeelding",
      "questions": "Vragen",
      "video": "Video"
    },
    "license": {
      "description": {
        "business": "Meld je aan voor deze Business licentie: € 83,25 per maand (€ 999,- per jaar, excl. BTW).<br/><br/> ✔️ Inclusief 3 Expert accounts en 150 Client accounts.<br/><br/>\nVul hieronder de gevraagde gegevens in en start vandaag nog met je eigen Business account.<br/>",
        "education": "Meld je aan voor deze 60-daagse All-In-One Education licentie.<br/><br/> ✔️ Inclusief 3 Expert accounts en 150 Client accounts.<br/> De kosten voor deze licentie zijn inbegrepen in je opleidingsprogramma.<br/><br/>\nVul de gevraagde gegevens hieronder in en start vandaag nog met je eigen Education account.",
        "enterprise": "Meld je aan deze Enterprise licentie aan à € 832,50 per maand (€ 9.999,- per jaar, excl. BTW)<br/><br/> ✔️ Inclusief onbeperkt aantal Expert accounts en onbeperkt aantal Client accounts.<br/><br/>\nVul hieronder de gevraagde gegevens in en start vandaag nog met je eigen Enterprise account.",
        "transition": "Meld je aan voor deze speciale Transition licentie.<br/><br/> ✔️ Inclusief 3 Expert accounts en 150 Client accounts.<br/> Je kunt deze UNLOQ Campus licentie gratis gebruiken zolang je een geldige licentie voor UNLOQ Campus hebt.<br/><br/>\nVul hieronder de gevraagde gegevens in en start vandaag nog met je eigen UNLOQ Campus account.<br/>",
        "trial": "Meld je aan voor deze gratis 30-dagen All-In-One proefversie.<br/><br/>\n✔️ Geen verplichtingen.<br/> ✔️ Geen creditcard nodig.<br/> ✔️ Inclusief 3 Expert accounts en 150 Client accounts.<br/><br/>\nVul de gevraagde gegevens hieronder in en start vandaag nog met je eigen gratis account."
      }
    },
    "license_expired": "Dit account is vergrendeld, aanpassingen niet mogelijk.",
    "login": {
      "btn": "Inloggen",
      "password_forgotten": "Wachtwoord vergeten?"
    },
    "manager": "Manager",
    "menu": {
      "associate_profile": "Profiel medewerker",
      "dialogs": "Dialogen",
      "logout": "Uitloggen",
      "manage": "Beheer",
      "my_profile": "Mijn Profiel",
      "notifications": "Notificaties",
      "pages": "Pagina's",
      "pending_clients": "Wachtlijst",
      "profile_settings": "Profielinstellingen",
      "questionnaires": "Vragenlijsten",
      "translations": "Vertalingen",
      "users": "Gebruikers"
    },
    "new": "Nieuw(e)",
    "none": "Geen",
    "not_applicable": "n.v.t.",
    "not_authenticated": "U moet ingelogd zijn om deze actie uit te voeren.",
    "not_authorized": "Je hebt geen permissies voor deze actie.",
    "notification_mailer": {
      "batched_comments": {
        "btn": "uNLOQ Campus openen",
        "message": "Er zijn nieuwe notities over een cliënt in Campus die misschien relevant zijn voor jou.",
        "subject": "UNLOQ Campus: nieuwe notitie(s) over een cliënt"
      },
      "batched_dialog_messages": {
        "link_description": "Om de hele dialoog te kunnen bekijken:",
        "link_text": "Klik hier voor UNLOQ Campus",
        "message": "Je hebt een nieuw dialoogbericht ontvangen van",
        "subject": "UNLOQ Campus: nieuw bericht(en)"
      },
      "unsubscribe": "Klik hier als je je wilt afmelden voor deze e-mails."
    },
    "notifications": {
      "description": "Verplaats de knop naar 'On' om notificaties te ontvangen. Verplaats de knop naar 'Off' als je geen notificaties van UNLOQ Campus wilt ontvangen.",
      "title": "Hier kun je aangeven of je e-mails (notificaties) van UNLOQ Campus wilt ontvangen wanneer er nieuwe berichten voor je zijn."
    },
    "number": {
      "currency": {
        "format": {
          "delimiter": ".",
          "format": "%u %n",
          "precision": 2,
          "separator": ",",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "€"
        }
      },
      "format": {
        "delimiter": ".",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": "miljard",
            "million": "miljoen",
            "quadrillion": "biljard",
            "thousand": "duizend",
            "trillion": "biljoen",
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "byte",
              "other": "bytes"
            },
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "tb": "TB"
          }
        }
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "number_short": "Nr.",
    "ordinalize_full": {
      "n_1": "eerste",
      "n_10": "tiende",
      "n_100": "honderdste",
      "n_1000": "duizendste",
      "n_1000000": "miljoenste",
      "n_11": "elfde",
      "n_12": "twaalfde",
      "n_13": "dertiende",
      "n_14": "veertiende",
      "n_15": "vijftiende",
      "n_16": "zestiende",
      "n_17": "zeventiende",
      "n_18": "achttiende",
      "n_19": "negentiende",
      "n_2": "tweede",
      "n_20": "twintigste",
      "n_21": "eenentwintigste",
      "n_22": "tweeëntwintigste",
      "n_3": "derde",
      "n_30": "dertigste",
      "n_4": "vierde",
      "n_40": "veertigste",
      "n_5": "vijfde",
      "n_50": "vijftigste",
      "n_6": "zesde",
      "n_60": "zestigste",
      "n_7": "zevende",
      "n_70": "zeventigste",
      "n_8": "achtste",
      "n_80": "tachtigste",
      "n_9": "negende",
      "n_90": "negentigste"
    },
    "organization": "Organisatie",
    "organization_manager_mailer": {
      "new_signup": {
        "message": "Een nieuwe cliënt heeft zich aangemeld voor het programma: %{program}. Zodra je een coach hebt toegewezen voor deze cliënt, kan de coaching beginnen.",
        "subject": "Een nieuwe cliënt heeft zich aangemeld."
      }
    },
    "organizations": {
      "choose_term": "Kies term",
      "flexible_term_description": "Je kunt de onderstaande terminologie aanpassen en daardoor de termen kiezen die goed bij jouw organisatie, functie en doelgroep aansluit. Selecteer de termen die je wilt gebruiken en klik op opslaan. Deze termen zullen vervolgens overal binnen het account van jouw organisatie UNLOQ Campus worden doorgevoerd en ook zichtbaar zijn voor cliënten.",
      "flexible_term_title": "Flexibele naamgeving",
      "flexible_term_warning": "Let op: gewijzigde termen worden na 1 uur zichtbaar.",
      "license": "Licentie",
      "licensed_to": "Geldig tot",
      "logo_explanation": "We raden aan om een PNG-logo met transparante achtergrond te uploaden.",
      "settings_for": "Profielinstellingen van",
      "term_assignment": "Oefening",
      "term_client": "Cliënt",
      "term_coach": "Coach",
      "term_for": "Term voor: "
    },
    "other_organizations": "Andere organisatie(s):",
    "passwords": {
      "edit": {
        "btn": "Wachtwoord opslaan",
        "description": "Vul hieronder je nieuwe wachtwoord in.",
        "label_password": "Nieuwe wachtwoord",
        "title": "Wachtwoord opnieuw instellen"
      },
      "new": {
        "description": "Vul je e-mailadres in en we sturen de instructies voor het opnieuw instellen van je wachtwoord. Als je geen e-mail ontvangt, controleer je spam-folder of probeer opnieuw.",
        "placeholder_email": "Je e-mailadres",
        "title": "Geen probleem, het overkomt ons allemaal wel eens."
      }
    },
    "pdf_report_ready": "PDF-rapport klaar",
    "pending_clients": {
      "description": "Hieronder vindt u een overzicht van de cliënten die gematcht moeten worden (of al gematcht zijn). Aan de rechterkant kan een geschikte coach worden geselecteerd."
    },
    "people_analytics": {
      "360_degree": "360 graden",
      "aborted_signature": "Vriendelijke groet,<br/><br/>Campus People Analytics | onderdeel van UNLOQ<br/><a href='https://unloq.org/' target='_blank'>unloq.org</a>",
      "aborted_success": "Bedankt voor uw medewerking. Deze vragenlijst houdt hier op omdat de voorwaarden geaccepteerd moeten worden om verder te kunnen.<br> <br> U kunt nu deze pagina sluiten. Als u de vragenlijst toch wilt invullen gebruik dan de knop hieronder.",
      "aborted_success_title": "Vragenlijst gesloten",
      "add_respondent": "respondent toevoegen",
      "add_respondent_button": "Respondent toevoegen",
      "admin": {
        "choose_program": "Kies programma",
        "choose_questionnaire": "Kies vragenlijstset",
        "filter_first": "Je moet eerst filteren om resultaten te kunnen zien.",
        "measure_moments": "Meetmomenten",
        "not_enough_results": "Er zijn niet genoeg resultaten om te laten zien (minimaal 3).",
        "question": "Vraag",
        "title": "People Analytics resultaten",
        "topic": "Onderwerp(en)"
      },
      "analytical_thinking": "Analytical thinking",
      "analytical_thinking_description": "I promote resilience and responsiveness to emerging strategic issues and opportunities for the Department/Division.",
      "answer": "Antwoord",
      "assessment": "Beoordeling",
      "assessment_leadership_organizational": "Organisatorisch leiderschap",
      "assessment_leadership_self": "Persoonlijk / Zelf leiderschap",
      "assessment_leadership_team": "Teamleiderschap",
      "assessment_report": "Beoordelingsverslag",
      "attention_objectives": "<b>Let op:</b> Als je meer doelen wilt toevoegen, gebruik dan deze button voordat je verder gaat naar de volgende pagina.",
      "autonomy": "Autonomie",
      "autonomy_description": "De vrijheid en bevoegdheid om zelf te kunnen handelen tijdens je werk.",
      "average_objectives": "Gemiddeld aantal coaching doelstellingen",
      "average_objectives_score": "Gemiddelde score cliëntdoelstellingen",
      "basicdata": "Basisgegevens cliënt",
      "btn_abort": "Afsluiten",
      "btn_add_goal": "Doelstelling toevoegen",
      "btn_continue": "Volgende",
      "btn_copy_report_link": "Link naar rapport kopiëren",
      "btn_copy_report_link_hint": "Kopieer de URL naar het rapport zodat je het kunt delen",
      "btn_download_report": "Downloaden PDF",
      "btn_download_report_active": "Downloaden...",
      "btn_download_report_hint": "Download het PDF-bestand van je rapport",
      "btn_generate_for": "Genereer coachingrapport voor",
      "btn_generate_report": "Genereer Rapport",
      "btn_generate_report_hint": "Genereert nieuw rapport",
      "btn_regenerate_report": "Rapport opnieuw genereren",
      "btn_regenerate_report_hint": "Genereert het rapport opnieuw, kan niet ongedaan worden gemaakt",
      "btn_remove_goal": "Doelstelling verwijderen",
      "btn_resume": "Vragenlijst hervatten",
      "btn_send_report_link_dialog": "Rapport verzenden via dialoog",
      "btn_send_report_link_dialog_hint": "Stuurt de link naar het rapport naar je klant via de dialoog",
      "btn_send_report_link_email": "Rapport per e-mail verzenden",
      "btn_send_report_link_email_active": "Versturen...",
      "btn_send_report_link_email_hint": "Stuurt automatisch een e-mail naar de klant met de link naar het rapport",
      "btn_submit": "Verstuur",
      "can_add_peers_explanation": "Deze vragenlijst is geconfigureerd om geen respondenten toe te kunnen voegen na een bepaald moment in de tijdlijn van de beoordeling",
      "choose_role": "Kies rol",
      "client_option": {
        "anonymous": "Anoniem",
        "fullname": "Volledige naam van cliënt weergeven"
      },
      "closed": "Niet langer van toepassing",
      "closed_on": "Niet langer van toepassing vanaf",
      "coaching_objectives": "Doelstellingen",
      "coachprocedure": "Evaluation of the coaching process",
      "coachprocedure_description": "This questionnaire surveys the client's opinion regarding the coaching process. The questions are fed back as completed by the client.",
      "coachprocedure_explanation": "This questionnaire surveys the client's opinion regarding the coaching.",
      "coachrelation": "Coaching relatie",
      "coachrelation_explanation": "Een coach en een cliënt werken samen om de doelen van de cliënt te bereiken. Deze professionele samenwerking wordt de 'coaching alliantie' genoemd. Met betrekking tot de werkalliantie worden drie aspecten onderscheiden: Affectieve binding, Taakgerichtheid en Doelgerichtheid.",
      "coachrelation_only_one": "Let op: de vragen uit deze sectie zijn slechts 1 keer afgenomen waardoor slechts 1 meetmoment zichtbaar is in de onderstaande grafieken.",
      "coachrelation_total": "Totaalscore",
      "coachrelation_total_description": "Totaalscores van relatie, taakgerichtheid en doelstellingen.",
      "communication": "Communication",
      "communication_description": "I have presence and bearing as an effective advocate of the mission and strategic direction of the programme.",
      "competence": "Competentie",
      "competence_description": "De eigen capaciteiten benutten en effectief zijn in interacties met anderen op het werk.",
      "confidence": "Vertrouwen",
      "confidence_description": "De mate van vertrouwen in het behalen van mijn doel.",
      "confidential": "Vertrouwelijk",
      "confirm_objectives_modal": {
        "back": "Terug naar doelstellingen",
        "body": "Je staat op het punt om de Doelstellingen sectie af te ronden. Als je zeker weet dat je al je doelstellingen hebt toegevoegd, klik dan op \"Volgende\". Als je nog meer doelstellingen wilt invoeren, klik dan op \"Terug naar doelstellingen\".",
        "continue": "Volgende",
        "title": "Heb je al je doelstellingen toegevoegd?"
      },
      "consent": "Je moet akkoord gaan met de voorwaarden om verder te gaan.",
      "copy_link_error": "Kan link niet kopiëren, cliënt niet actief voor huidige gebruiker",
      "copy_link_success": "Link naar je klembord gekopiëerd",
      "current": "Doelbepaling huidige status",
      "current_description": "De huidige status ten opzichte van mijn doel.",
      "deactivate": "Deactiveren",
      "description": "Omschrijving",
      "evaluation_report": "Evaluatieverslag",
      "explanation": "Toelichting",
      "final_remarks": "Toelichting",
      "finished_signature": "Vriendelijke groet,<br/><br/>Campus People Analytics | onderdeel van UNLOQ<br/><a href='https://unloq.org/' target='_blank'>unloq.org</a>",
      "finished_success": "Al je antwoorden worden succesvol (en veilig) opgeslagen. Bedankt voor je medewerking.",
      "finished_success_title": "Succesvol afgerond!",
      "five": "Vijf",
      "generate_report_error": "Er was een probleem met het weergeven van dit rapport",
      "generate_report_prompt": "Begin met het genereren van een rapport",
      "goal": "Doelstelling",
      "goal_explanation": "Schalen lopen van 0 tot 10 waarbij 0 staat voor een zeer lage score en een 10 voor hoogst mogelijk score. Hoe lager de score, des te meer groei er richting het doel mogelijk is. Hoe hoger de score, des te meer het doel bereikt is.",
      "goaloriented": "Doelstellingen",
      "goaloriented_description": "De mate waarin een cliënt tevreden is over de te bereiken doelstellingen.",
      "independent": "Zelfstandigheid",
      "independent_description": "De mate waarin ik mijn doel zelfstandig kan behalen.",
      "information": "Informatie",
      "integrity": "Integrity",
      "integrity_description": "I demonstrate and abide by the Agency's core values in activities and behaviour.",
      "leadership_assessment_title": "Leiderschapsassessment Resultaten",
      "leading_and_supervising": "Leading and supervising",
      "leading_and_supervising_description": "I ensure that power and authority are not abused and demonstrate leadership and credibility in interactions with all partners and stakeholders.",
      "legend": {
        "aborted": "Afgewezen",
        "completed": "Afgerond",
        "not_sent_yet": "Niet verzonden",
        "partially_completed": "Gestart",
        "reminder_sent": "Herinnerd aan",
        "sent": "Verzonden"
      },
      "legend_description": {
        "aborted": "De deelnemer/respondent heeft het privacybeleid geweigerd.",
        "completed": "De deelnemer / respondent heeft de opdracht voltooid.",
        "not_sent_yet": "De opdracht is nog niet verstuurd naar de deelnemer/respondent.",
        "partially_completed": "De deelnemer/respondent is aan de opdracht begonnen maar heeft deze nog niet volledig afgerond.",
        "reminder_sent": "Er is een automatische herinnering om de vragenlijst in te vullen naar de deelnemer/respondent gestuurd.",
        "sent": "De opdracht is verstuurd naar de deelnemer/respondent."
      },
      "measure_moment": "Meetmoment",
      "menu": {
        "background": "Achtergrond",
        "clients_progress": "Cliëntenvoortgang",
        "example_report": "Voorbeeldrapport"
      },
      "mindset": "Kijk op mensen (Mindset)",
      "mindset_description": "De mate waarin iemand gelooft dat mensen kunnen veranderen en zich kunnen ontwikkelen.",
      "mindset_explanation": "De mate waarin iemand gelooft dat mensen (inclusief zichzelf) hun gedrag kunnen veranderen. Hoe hoger de score, hoe meer de persoon gelooft dat mensen kunnen veranderen (groeimindset). Hoe lager de score, hoe meer de persoon ervan overtuigd is dat mensen niet kunnen veranderen (fixed mindset).",
      "mindset_scale": "Dit wordt uitgedrukt op een schaal van minimaal 8 en maximaal 48.",
      "motivation": "Motivatie",
      "motivation_description": "De mate waarin ik gemotiveerd ben om mijn doel te behalen.",
      "new": "Nieuw",
      "new_client": "Nieuwe cliënt",
      "new_from": "Nieuw vanaf",
      "no_goals": "U moet minstens één doelstelling formuleren om verder te gaan met deze vragenlijst.",
      "no_respondents_yet": "Nog geen respondenten toegevoegd. Direct een",
      "not_all_questions_answered": "Niet alle vragen zijn beantwoord.",
      "objective": "Doelstelling",
      "objective_closed": "Gesloten",
      "objective_no": "Doelstelling nr.",
      "objective_relevant": "Relevant",
      "pdf_confidential": "De informatie in deze documenten is persoonlijk, vertrouwelijk en alleen bestemd voor de ontvanger en mag niet worden gebruikt, gepubliceerd of gedistribueerd zonder toestemming van de bedoelde ontvanger. Dit document is gegenereerd door UNLOQ People Analytics, een instrument van UNLOQ.",
      "pdf_share_answer": {
        "private": "Coach",
        "public": "Coach en respondent(en)"
      },
      "peer": "Respondent",
      "peers": "Respondenten",
      "planning": "Planning",
      "planning_and_organizing": "Planning and organizing",
      "planning_and_organizing_description": "I convince and engage others by using compelling arguments.",
      "planning_description": "I resist all undue pressures in decision making and in the performance of duties.",
      "preview": "Voorbeeld",
      "previous_peer_answer": "In de meest recente evaluatie van bovenstaande doelstelling gaf u %{client} op een schaal van 0 tot 10 het cijfer: %{number}.",
      "private_objectives_modal": {
        "body": "Indien u de doelstellingen <i>alleen</i> deelt met uw coach worden uw respondenten niet betrokken bij deze 360-graden vragenlijst.",
        "title": "Doelstellingen alleen delen met uw coach?"
      },
      "professionalism": "Professionalism",
      "professionalism_description": "I take prompt and effective action to deal with unprofessional or unethical behaviour.",
      "program": "Programma",
      "psychological_needs": "Psychologische basisbehoeftes",
      "psychological_needs_explanation": "Ieder mens streeft ernaar zijn eigen psychologische basisbehoeften te vervullen. Hoe meer deze basisbehoeften door en op het werk worden vervuld, hoe meer iemand gemotiveerd is om zijn werk beter te doen. Er worden drie basisbehoeften onderscheiden, namelijk: Autonomie, Competentie en Verwantschap.",
      "question": "Vraag",
      "questionnaire": "Vragenlijst",
      "relatedness": "Verbondenheid",
      "relatedness_description": "Het gevoel erbij te horen en nauw contact met anderen op het werk.",
      "relation": "Relatie",
      "relation_description": "De mate waarin sprake is van een goede band tussen de coach en cliënt.",
      "reminded_at": "Herinneringse-mail verstuurd naar",
      "report": {
        "assessment_intro": "Dit hoofdstuk bevat de resultaten van de beoordeling van het leiderschap.<br /><br />\nDe scores worden weergegeven in de grafiek voor elk leiderschapsgedrag. Uw score (Self) wordt weergegeven op dit eerste beoordelingsniveau (MI). Onder de weergave (Self) staan de scores van de respondenten en het aantal respondenten.<br /><br />",
        "assessment_intro_m1": "Het tweede beoordelingsniveau (M2) is niet van toepassing op dit rapport. (M2) wordt alleen beschikbaar wanneer een tweede beoordeling is voltooid. Wanneer (M2) beschikbaar is, zal het verschil tussen (M1) en (M2) uw groeiniveau meten.",
        "assessment_intro_m2": "Het tweede beoordelingsniveau (M2) is ook zichtbaar in de grafiek (2e staaf) en ook in de tabel. Het verschil tussen (M1) en (M2) meet je groeiniveau.",
        "assessment_legend": "De score wordt weergegeven op een 5-punts Likertschaal, wat betekent dat de score loopt van 1) Sterk oneens tot 5) Sterk eens.",
        "assessment_scale": "Scale",
        "assessment_scale_explanation": "1: sterk oneens / 2: oneens / 3: noch eens noch oneens / 4: eens / 5: sterk eens",
        "average": "Gemiddelde",
        "average_overall": "Algemeen gemiddelde",
        "averages": "Gemiddeldes",
        "highest": "hoogste",
        "lowest": "laagste",
        "open_ended_intro": "In dit deel worden de antwoorden op verschillende open vragen (Q) gepresenteerd.<br /><br />\nEerst worden de antwoorden van de cliënt zelf weergegeven, gevolgd door de antwoorden van de respondenten.",
        "open_ended_title": "Antwoorden op open vragen",
        "overview_of": "Overzicht van",
        "section": "Sectie",
        "supervisory_assessment_intro": "Dit gedeelte bevat de resultaten van de beoordeling van het toezichthoudend leiderschap.<br /><br />\nDe scores worden in de grafiek weergegeven voor elke vaardigheid en/of gedrag op het gebied van toezichthoudend leiderschap. Uw score (Self) wordt weergegeven op dit eerste beoordelingsniveau (M1). Onder de weergave (Self) staan de gemiddelde scores van de respondenten voor respectievelijk de categorieën Manager, Direct Report en/of Peer.",
        "supervisory_top_results_intro": "In dit deel worden de vijf hoogst gewaardeerde vaardigheden en gedragingen van toezichthoudend leiderschap gepresenteerd en de vijf laagst gewaardeerde vaardigheden en gedragingen van toezichthoudend leiderschap (beoordeeld door Self).",
        "supervisory_top_results_top_rated": "beoordeelden toezichthoudende vaardigheden gemiddeld",
        "table_results_intro": "Dit deel toont de antwoorden voor elk van de drie leiderschapsdimensies van de feedback die uit verschillende bronnen (zelf, managers en respondenten) is verzameld.",
        "table_results_intro_explanation": "<ul class=\"list-check ml-3\">\n  <li>De kolom \"Gemiddeld zelf\" toont de algemene gemiddelde antwoorden die u uzelf gaf voor elke dimensie.</li>\n  <li>De kolommen \"respondent\" tonen het antwoord van elke beoordelaar.</li>\n  <li>De kolom \"Algemeen gemiddelde\" toont het gemiddelde van de antwoorden van de respondenten.</li>\n  <li>De antwoordkeuzes lopen van 1-Sterk oneens tot 5-Sterk eens.</li>\n</ul>",
        "top_results_extra": "Eerst worden de scores van het eerste meetmoment (M1) weergegeven en, indien beschikbaar, ook die van het tweede meetmoment (M2).<br /><br />",
        "top_results_intro": "Dit deel bevat de vijf hoogst gewaardeerde leiderschapsgedragingen van de cliënt en ook de vijf laagst gewaardeerde leiderschapsgedragingen.<br /><br />",
        "top_results_top_rated": "gewaardeerde leiderschapsgedragingen"
      },
      "report_create": "Maak een rapport",
      "report_epilogue": "Afsluitende tekst in rapport",
      "report_generated_at": "Dit rapport is gegenereerd op %{date}",
      "report_is_loading": "Gegenereerd rapport laden",
      "report_measure": "Meting",
      "report_opened_at": "Laatste rapport geopend op",
      "report_opened_by_client_at": "Klant opende zijn rapport op %{date}",
      "report_preface": "Inleiding in rapport",
      "report_sent_at": "Laatste rapport verzonden op",
      "reset_last_submission": "Laatste ingediende vragenlijst resetten",
      "respect_for_diversity": "Respect for diversity",
      "respect_for_diversity_description": "I create an environment that fosters open communication within and across organizational boundaries and convert controversial or sensitive situations into opportunities",
      "respondents_overview": "Respondenten overzicht",
      "result_score": "Resultaat score",
      "role": {
        "board_member": "Directielid",
        "colleague": "Collega / peer",
        "customer": "Klant",
        "family": "Familie",
        "friends": "Vriend / kennis",
        "manager": "Leidinggevende",
        "staff_member": "Medewerker",
        "supplier": "Leverancier"
      },
      "score": "Score",
      "self": "Zelf",
      "send_coach_questionnaire": "Stuur %{next} coach vragenlijst",
      "send_via_dialog": "Stuur %{next} vragenlijst (Dialoog)",
      "send_via_email": "Stuur %{next} vragenlijst (E-mail)",
      "send_via_link": "Stuur %{next} vragenlijst (Copy link)",
      "share_answer": {
        "private": "Deel alleen met coach",
        "public": "Deel met coach en respondent(en)"
      },
      "shared_with": "Gedeeld met",
      "status_description": "Hieronder vind je de details van de voortgang van de coaching en de respondenten van de cliënt.",
      "step_show_client_name": "Toon cliëntnaam",
      "step_show_private_objectives": "Toon doelstellingen die alleen met de coach zijn gedeeld",
      "step_write_epilogue": "Schrijf afsluiting",
      "step_write_preface": "Schrijf inleiding",
      "still_relevant": "Is deze doelstelling nog steeds relevant?",
      "submit_success": "Een e-mail met een link naar de vragenlijst voor People Analytics is verzonden.",
      "submit_too_many": "De e-mail met de vragenlijst is al verstuurd. Probeer het later nog eens.",
      "supervisory_leadership_assessment_title": "Resultaten beoordeling toezichthoudend leiderschap",
      "supervisory_mindset_explanation": "Dit onderdeel geeft de resultaten weer van hoe u denkt dat mensen in de loop der tijd kunnen veranderen. Hoe hoger de score, hoe meer iemand gelooft dat mensen kunnen veranderen (groeimindset). Hoe lager de score, hoe meer iemand ervan overtuigd is dat mensen niet kunnen veranderen (fixed mindset).",
      "supervisory_top_highest_and_lowest_title": "Vijf hoogste en vijf laagste beoordelingen van toezichthoudende vaardigheden",
      "taskoriented": "Taakgerichtheid",
      "taskoriented_description": "De mate waarin een cliënt tevreden is over de coachingsaanpak en methode.",
      "teamwork": "Teamwork",
      "teamwork_description": "I make myself visible to staff by listening to them and by communicating at all levels within the Agency and with external counterparts.",
      "title": "People Analytics",
      "top_highest_and_lowest_title": "Top vijf hoogst en laagst gewaardeerde leiderschapswaarden",
      "total_objectives": "Totaal coaching doelstellingen",
      "view_results": "Toon resultaten"
    },
    "people_analytics_mailer": {
      "client_finished": {
        "btn": "Ga naar de UNLOQ Campus",
        "message": "heeft de vragenlijst ingevuld voor het programma <b>%{program}</b>.",
        "subject": "Vragenlijst is ingevuld"
      },
      "peer_finished": {
        "btn": "Ga naar de UNLOQ Campus",
        "message": "Respondent %{peer} heeft de vragenlijst ingevuld voor het programma <b>%{program}</b>.",
        "subject": "Vragenlijst is ingevuld"
      },
      "remind_client": {
        "message": "verzoekt u vriendelijk uw zelfevaluatievragenlijst in te vullen voor het programma <b>%{program}</b>. <br><br> <b>Let op:</b> de vragenlijst is slechts een beperkte tijd beschikbaar en deze link zal binnenkort verlopen.",
        "subject": "Herinnering: People Analytics vragenlijst"
      },
      "remind_coach": {
        "message": "verzoekt u vriendelijk om uw zelfevaluatievragenlijst voor het programma <b>%{program}</b> in te vullen vanuit uw rol als <b>coach</b>. <br><br> <b>Let op:</b> de vragenlijst is slechts een beperkte tijd beschikbaar en deze link zal binnenkort verlopen.",
        "subject": "Herinnering: People Analytics coach vragenlijst"
      },
      "remind_peer": {
        "message": "wij verzoeken u vriendelijk uw zelfevaluatievragenlijst voor het <b>%{program}</b> programma in te vullen vanuit het perspectief van uw rol als <b>%{peertype}</b>. <br><br> <b>Let op:</b> de vragenlijst is slechts een beperkte tijd beschikbaar en deze link zal binnenkort verlopen.",
        "subject": "Herinnering: People Analytics vragenlijst voor respondenten"
      },
      "submit_client": {
        "btn": "Ga naar de vragenlijst",
        "explanation": "Door op de link te klikken, ga je naar de tool van UNLOQ Campus genaamd People Analytics en start je de vragenlijst, die ongeveer 10-15 minuten in beslag neemt om in te vullen. Na het invullen van elke pagina van de vragenlijst moet u onderaan op de knop 'doorgaan' klikken.<br/> <br/> <b>Bedenk wel dat:</b><br/>Bij het invullen van de vragenlijst kunt u niet meer terugklikken nadat u op 'doorgaan' hebt geklikt.<br/> Alle vragen op elke pagina moeten worden beantwoord, anders werkt de knop 'doorgaan' niet.<br/><br/>",
        "message": "verzoekt u vriendelijk uw zelfevaluatievragenlijst in te vullen voor het programma <b>%{program}</b>.",
        "subject": "People Analytics vragenlijst"
      },
      "submit_client_report": {
        "btn": "Ga naar het rapport",
        "message": "wil jouw rapport delen voor het programma <b>%{program}</b>.",
        "subject": "People Analytics Rapport"
      },
      "submit_coach": {
        "btn": "Ga naar de vragenlijst",
        "message": "verzoekt u vriendelijk om uw zelfevaluatievragenlijst voor het programma <b>%{program}</b> in te vullen vanuit uw rol als <b>coach</b>.",
        "subject": "People Analytics vragenlijst"
      },
      "submit_peer": {
        "btn": "Ga naar de vragenlijst",
        "explanation": "Door op de link te klikken, ga je naar de tool van UNLOQ Campus genaamd People Analytics en start je de vragenlijst, die ongeveer 10-15 minuten in beslag neemt om in te vullen. Na het invullen van elke pagina van de vragenlijst moet u onderaan op de knop 'doorgaan' klikken.<br/> <br/> <b>Bedenk wel dat:</b><br/>Bij het invullen van de vragenlijst kunt u niet meer terugklikken nadat u op 'doorgaan' hebt geklikt.<br/> Alle vragen op elke pagina moeten worden beantwoord, anders werkt de knop 'doorgaan' niet.<br/><br/>",
        "message": "verzoekt u vriendelijk uw zelfbeoordelingsvragenlijst voor het <b>%{program}</b> programma in te vullen vanuit het perspectief van uw rol als <b>%{peertype}</b>.",
        "subject": "People Analytics respondentenvragenlijst"
      }
    },
    "profile": {
      "change_password": "Wachtwoord wijzigen",
      "information": "Mijn Profiel",
      "people_analytics_emails": "People Analytics e-mails",
      "people_analytics_emails_explanation": "Deze e-mails worden bijvoorbeeld verstuurd wanneer een deelnemer of respondent een vragenlijst heeft ingevuld.",
      "two_factor_authentication": "2-stapsverificatie"
    },
    "program": {
      "days_after_month_closure": "Sessielogs gesloten (periode in dagen na de laatste dag van de maand waarop het nog mogelijk is om sessielogs te wijzigen)",
      "minutes_per_client": "Maximale tijd beschikbaar per cliënt (in minuten)",
      "title": "Programma"
    },
    "program_breaks": {
      "create": "Programma-einde toevoegen"
    },
    "program_questionnaire_explanation": "De People Analytics-vragenlijst kan maar één keer geconfigureerd worden.",
    "program_sessions": {
      "activities": {
        "delivery": "Delivery",
        "design": "Ontwerp",
        "other": "Overig",
        "preparation": "Voorbereiding",
        "projectmanagement": "Projectmanagement",
        "reporting": "Rapportering"
      },
      "activity": "Activiteit",
      "activity_not_enabled": ": niet toegestaan. Kies een andere categorie.",
      "activity_type": "Activiteit",
      "btn_new": "Nieuwe groep- / trainingsactiviteit",
      "category_type": "Categorie",
      "charts_explanation": "Totale minuten van programma",
      "choose_activity": "Alle activiteiten",
      "description": "Hieronder kun je uren van een groep of training beheren. Let op: deze uren zijn niet bedoeld voor invididuele coachingsessies.",
      "hourly_rate": "Uurtarief",
      "minutes_exceeded": ": maximale aantal overschreden",
      "notes": "Notities",
      "sum_duration": "# minuten",
      "sum_duration_nonbillable": "Niet declarabele minuten",
      "title": "Groep & Trainingsactiviteiten"
    },
    "programs": "Programma's",
    "progress": "Voortgang",
    "progress_explanation": "Selecteer een programma om de voortgang te zien.<br/><br/> - Blauw = aantal minuten die gebruikt zijn<br/> - Groen 100% = alle programma minuten zijn benut<br/> - Rood > 100% = er zijn meer minuten benut dan overeengekomen<br/> <br/> Extra minuten nodig? Vraag de Organisatie Manager om deze toe te voegen.<br/>",
    "question": {
      "input_type": {
        "checkboxes": "Meerkeuze, meerdere antwoordmogelijkheden (checkboxes)",
        "radio_buttons": "Meerkeuze, 1 antwoordmogelijkheid (radiobuttons)",
        "rating": "Beoordelingsvraag (1-10)",
        "sortable": "Rangschikkingsvraag",
        "text_area": "Open antwoord (meerdere tekstregel)",
        "text_field": "Kort antwoord (1 tekstregel)"
      }
    },
    "questionnaire": "Vragenlijst",
    "ra": {
      "action": {
        "save": "Opslaan"
      },
      "page": {
        "empty": "Geen %{name} gevonden."
      }
    },
    "ransack": {
      "all": "alle",
      "and": "en",
      "any": "enig",
      "asc": "oplopend",
      "attribute": "attribuut",
      "combinator": "combinator",
      "condition": "conditie",
      "desc": "aflopend",
      "or": "of",
      "predicate": "eigenschap",
      "predicates": {
        "blank": "is afwezig",
        "cont": "bevat",
        "cont_all": "bevat alle",
        "cont_any": "bevat enig",
        "does_not_match": "evenaart niet",
        "does_not_match_all": "evenaart niet voor alle",
        "does_not_match_any": "evenaart niet voor enig",
        "end": "eindigt met",
        "end_all": "eindigt met alle",
        "end_any": "eindigt met enig",
        "eq": "gelijk",
        "eq_all": "gelijk alle",
        "eq_any": "gelijk enig",
        "false": "is niet waar",
        "gt": "groter dan",
        "gt_all": "groter dan alle",
        "gt_any": "groter dan enig",
        "gteq": "groter dan of gelijk aan",
        "gteq_all": "groter dan of gelijk aan alle",
        "gteq_any": "groter dan of gelijk aan enig",
        "in": "in",
        "in_all": "in alle",
        "in_any": "in enig",
        "lt": "kleiner dan",
        "lt_all": "kleiner dan alle",
        "lt_any": "kleiner dan enig",
        "lteq": "kleiner dan of gelijk aan",
        "lteq_all": "kleiner dan of gelijk aan alle",
        "lteq_any": "kleiner dan of gelijk aan enig",
        "matches": "evenaart",
        "matches_all": "evenaart alle",
        "matches_any": "evenaart enig",
        "not_cont": "bevat niet",
        "not_cont_all": "bevat niet alle",
        "not_cont_any": "bevat niet enig",
        "not_end": "eindigt niet met",
        "not_end_all": "eindigt niet met alle",
        "not_end_any": "eindigt niet met enig",
        "not_eq": "niet gelijk aan",
        "not_eq_all": "niet gelijk aan alle",
        "not_eq_any": "niet gelijk aan enig",
        "not_in": "niet in",
        "not_in_all": "niet in alle",
        "not_in_any": "niet in enig",
        "not_null": "is niet null",
        "not_start": "start niet met",
        "not_start_all": "start niet met alle",
        "not_start_any": "start niet met enig",
        "null": "is null",
        "present": "is aanwezig",
        "start": "start met",
        "start_all": "start met alle",
        "start_any": "start met enig",
        "true": "is waar"
      },
      "search": "zoeken",
      "sort": "sorteren",
      "value": "waarde"
    },
    "rating": {
      "empty": "Er zijn nog geen reviews.",
      "empty_explanation_step1": "Start met het verzamelen van reviews per cliënt via de Cliëntenpagina:",
      "empty_explanation_step2": "Klik vervolgens op het ster-icoon achter de cliëntnaam om reviews in te schakelen voor een specifieke cliënt.",
      "empty_explanation_step3": "De cliënt kan nu een review schrijven voor jou als coach op de dialoogpagina.",
      "placeholder": "Schrijf review...",
      "private": "Private (deel alleen met coach)",
      "public": "Publiek (zichtbaar voor iedereen)",
      "semi_private": "Semi-privé (deel met relevante belanghebbenden)",
      "shared_placeholder": "Kies niveau van datadeling...",
      "title": "Review"
    },
    "react": {
      "admin": {
        "add_to_associate_list": "Toevoegen aan de lijst met partners",
        "address": "Adres",
        "addresses": "Bedrijfsgegevens",
        "affiliation": "Aansluiting",
        "affiliations": "Affiliaties",
        "are_you_sure_delete": "Weet je zeker dat je deze %{referenceName} wilt verwijderen?",
        "associate_lists": {
          "add_coach_button": "Coach toevoegen",
          "add_coach_to_list_dialog_title": "Coach toevoegen aan lijst",
          "add_to_list_failed": "Coach kon niet worden toegevoegd aan de lijst",
          "add_to_list_success": "Coach werd toegevoegd aan de lijst",
          "coach_count": "Coaches",
          "confirm_delete": "Deze gebruiker uit de lijst verwijderen?",
          "create": "Coachlijst maken",
          "edit": "Coachlijst bewerken",
          "intro": "Hier kun je lijsten van coaches aanmaken. Deze lijsten kunnen gebruikt worden in CoachDesks, de UNLOQ website en om snel alle CV's van een groep coaches te exporteren.",
          "no_coaches_in_list": "Er staan nog geen coaches in deze lijst.",
          "title": "Coach lijsten"
        },
        "avatars": "Profielfoto",
        "bio": {
          "new": "Gebruiker heeft zijn bio bijgewerkt"
        },
        "business_address": "Adres",
        "business_details": "Bedrijfsgegevens",
        "certification": "Certificering",
        "certifications": "Certificeringen",
        "client_imports": {
          "fields": {
            "excel_file": {
              "helper_text": "Alleen Excel-bestanden met de extensie .xslx worden ondersteund",
              "placeholder": "Drop je Excel-importbestand hier"
            }
          }
        },
        "coaching_preferences": "Voorkeuren voor coaching",
        "curriculum_vitae": "Curriculum Vitae",
        "curriculum_vitae_intro": "Het commerciële CV verschilt van het standaard CV omdat het geen NGO, non-profit of non-profit gerelateerde activiteiten bevat. Advies: kopieer het standaard CV en verwijder vervolgens alle activiteiten die niet commercieel zijn.",
        "edit": "Bewerk",
        "education": "Onderwijs",
        "end_date": "Einddatum",
        "files": {
          "preview_loading": "Voorbeeld laden"
        },
        "group_work": "Groepswerk",
        "help": "Help",
        "internal_only": "Alleen voor intern gebruik, niet publiekelijk zichtbaar",
        "job_information": "Functie-informatie",
        "language": "Taal",
        "languages": "Talen",
        "name": "Naam",
        "personal_information": "Persoonlijke informatie",
        "preview": "Voorbeeld",
        "profile_photo": "Profielfoto",
        "prompt": "Goedkeuren of afwijzen",
        "publication": "Publicatie",
        "publications": "Publicaties",
        "reject": "Weiger",
        "rematch": "Rematch",
        "rematch_client": "Rematch-client",
        "remove": "Verwijder",
        "save": "Opslaan",
        "short_bio": "Korte biografie",
        "unloq_only_contract_fields": {
          "body": "De onderstaande velden worden beheerd door UNLOQ en zijn <b>NIET</b> zichtbaar en bewerkbaar door de medewerkers zelf. Deze velden worden gebruikt in de selectieprocedure van de medewerker.",
          "title": "Velden beheerd door UNLOQ"
        },
        "untranslated": "Ontbrekende vertalingen",
        "validation": {
          "date_of_birth": {
            "not_adult": "Je moet een geboortedatum opgeven die minstens 16 jaar oud is"
          },
          "end_date_after_start_date": "Einddatum moet later zijn dan begindatum",
          "linkedin": {
            "invalid": "Moet een geldige LinkedIn URL zijn"
          },
          "start_date_invalid": "Startdatum is niet geldig",
          "start_date_required": "Startdatum is vereist"
        },
        "work_experience": "Werkervaring"
      },
      "associate_lists": {
        "add_failed": "Kan niet worden toegevoegd aan de lijst met medewerkers",
        "added": "Toegevoegd aan de lijst met partners"
      },
      "clients": {
        "rematch_failed": "Klant kon niet opnieuw worden gekoppeld, neem contact op met ondersteuning",
        "rematch_success": "Klant succesvol opnieuw gekoppeld"
      },
      "coaches": {
        "address": {
          "account_holder_name": "Naam rekeninghouder",
          "address_line": "Adres",
          "bank_account_number": "Bankrekeningnummer",
          "bank_identification": "Bank identificatie (BIC/SWIFT)",
          "bank_name": "Naam bank",
          "city": "Stad",
          "company": "Bedrijfsnaam",
          "country": "Land",
          "helper_texts": {
            "bank_account_number": "IBAN of ander bankrekeningnummer",
            "bank_identification": "Vereist als het rekeningnummer geen IBAN is",
            "chamber_of_commerce": "Kamer van Koophandel"
          },
          "house_number": "Huisnummer",
          "region": "Regio",
          "zip_code": "Postcode"
        },
        "all": "Alle geregistreerde coaches",
        "associate_status": {
          "active": "Actief",
          "label": "Status",
          "none": "Geen status",
          "offboard": "Offboard",
          "on_hold": "In de wacht"
        },
        "avatars": {
          "approved": "Je nieuwe profielfoto is goedgekeurd",
          "avatar": {
            "label": "Avatar",
            "no_avatar": "Er is geen avatar",
            "upload": "Nieuwe avatar uploaden"
          },
          "not_approved_yet": "Je nieuwe profielfoto is nog niet goedgekeurd. We zullen het zo snel mogelijk beoordelen",
          "photo": {
            "label": "Profielfoto",
            "no_photo": "Er is geen profielfoto"
          },
          "preview": "Voorbeeld",
          "rejected": "Je nieuwe profielfoto is geweigerd, upload een andere a.u.b",
          "upload": "Upload nieuwe profielfoto",
          "uploaded": "Er is een nieuwe foto geüpload, we zullen deze zo snel mogelijk bekijken"
        },
        "bio": {
          "approved": "Je nieuwe bio is goedgekeurd",
          "not_approved_yet": "Je nieuwe bio is nog niet goedgekeurd",
          "rejected": "Je nieuwe bio is afgewezen, schrijf een nieuwe",
          "updated": "Je bio is bijgewerkt en wacht op goedkeuring"
        },
        "both": "Beide",
        "cards_view": "De kaarten bekijken",
        "chamber_of_commerce": "Bedrijfsregisternummer",
        "coach_type": "Type bus",
        "coach_types": {
          "a": "Senior Coach & Trainer",
          "t": "Senior trainer en facilitator"
        },
        "coaching_level": "Niveau coaching",
        "coaching_levels": {
          "executive": "Uitvoerend",
          "leadership_coaching": "Leiderschapscoaching",
          "top_executive": "Topman",
          "young_talent_professional": "Jong talent professional"
        },
        "commercial_cv": "Commercieel CV",
        "contract_remarks": "Opmerkingen",
        "contract_type": "Soort contract",
        "contracts": {
          "core_team": "Lid kernteam",
          "fixed_hours": "Medewerker met vaste uren",
          "project_basis": "Medewerker op projectbasis"
        },
        "core_team": "Kernteam",
        "cv": {
          "affiliations": "Affiliaties",
          "approach": "Benadering",
          "branch_experiences": "Branche-ervaringen",
          "certifications": "Certificeringen",
          "confirm_copy_cv": "Weet u zeker dat u alle informatie uit uw standaard-cv wilt kopiëren? <br /><br />Let op <b>U KUNT DIT ALLEEN EEN KEER DOEN</b>, daarna verdwijnt de knop!",
          "contract_type": "Soort contract",
          "copy_default_cv": "Uw standaard-cv kopiëren?",
          "copy_from_default_button": "Kopiëren van standaard CV",
          "education": "Onderwijs",
          "examples": {
            "affiliation": {
              "acronym": "Voorbeeld: EMCC",
              "name": "Voorbeeld: EMCC Wereldwijd",
              "number": "Voorbeeld: 100"
            },
            "certification": {
              "end_date": "Voorbeeld: Datum tot certificering geldig was (leeg = onbeperkt)",
              "institution": "Voorbeeld: Internationale Federatie voor Coaching",
              "name": "Voorbeeld: ICF Professioneel Gecertificeerd Coach",
              "start_year": "Voorbeeld: Jaar waarvan de certificering geldig is",
              "topic": "Voorbeeld: Coaching cursus"
            },
            "education": {
              "end_date": "Voorbeeld: Datum tot onderwijs geldig was (leeg = onbeperkt)",
              "field_of_study": "Voorbeeld: Klinische psychologie",
              "school": "Voorbeeld: Harvard",
              "specialisation": "Voorbeeld: Groepsdynamica",
              "start_year": "Voorbeeld: Jaar vanaf wanneer de opleiding geldt"
            },
            "publication": {
              "authors": "Voorbeeld: Doe, J. / Doe. J. & Doedel, K.P.",
              "journal_title": "Voorbeeld: Tijdschrift voor Bewustzijn",
              "medium": "Voorbeeld: Televisie",
              "page_range": "Voorbeeld: 201-220",
              "publisher": "Voorbeeld: Routledge",
              "publisher_city": "Voorbeeld: New York",
              "title": "Voorbeeld: Zelfherkenning in het dagelijks leven",
              "url": "Voorbeeld: https://www.cbs.com/shows/the-late-show-with-stephen-colbert/",
              "various_title": "Voorbeeld: Te gast bij The Late Show met Stephen Colbert.",
              "volume_number": "Voorbeeld: 5(2)",
              "year": "Voorbeeld: (2022)"
            },
            "publications": {
              "type": "Kies het type publicatie"
            }
          },
          "fields": {
            "affiliation": {
              "acronym": "Acroniem",
              "add": "Een andere affiliatie toevoegen",
              "name": "Naam",
              "number": "Aangesloten nummer"
            },
            "approach": "Korte biografie",
            "approach_current": "Huidige bio",
            "approach_helper_text": "Schrijf je sollicitatie in het Engels",
            "certification": {
              "add": "Nog een certificering toevoegen",
              "end_date": "Einddatum",
              "institution": "Uitgevende organisatie",
              "level": "Niveau",
              "name": "Naam certificaat",
              "start_year": "Startjaar",
              "topic": "Onderwerp / Thema"
            },
            "certification_dropzone": "Drop uw diploma / certificering / getuigenis bestanden hier",
            "certification_dropzone_helpertext": "Alleen PDF-, PNG- of JPG-bestanden kunnen worden geüpload",
            "education": {
              "add": "Nog een opleiding toevoegen",
              "degree": "Graad",
              "end_date": "Einddatum",
              "field_of_study": "Vakgebied",
              "school": "School",
              "specialisation": "Specialisatie",
              "start_year": "Startjaar"
            },
            "job_title": "Huidige functie",
            "job_title_helper_text": "Schrijf je functietitel in het Engels",
            "occupations": "Beroepen",
            "publication": {
              "add": "Nog een publicatie toevoegen",
              "article": "Artikel",
              "authors": "Auteur(s)",
              "book": "Boek",
              "choose": "Kies type publicatie",
              "journal_title": "Naam tijdschrift",
              "medium": "Medium",
              "page_range": "Pagina bereik",
              "publisher": "Uitgever",
              "publisher_city": "Uitgeverskantoor Stad",
              "title": "Titel",
              "title_article": "Artikel titel",
              "title_book": "Titel boek",
              "url": "Internetverbinding",
              "various": "Diverse",
              "volume_number": "Volumenummer (editienummer)",
              "year": "Jaar"
            },
            "publication_type": "Type publicatie",
            "specializations": "Specialisaties",
            "work_experience": {
              "add": "Nog een werkervaring toevoegen",
              "branch_experience": "Branche-ervaringen",
              "company_name": "Bedrijfsnaam",
              "description": "Werkbeschrijving",
              "end_date": "Einddatum (leeg = onbepaald)",
              "role": "Rol / titel",
              "start_date": "Startdatum"
            }
          },
          "headers": {
            "certifications": "Opleidingen/certificeringen"
          },
          "help": {
            "avatars": {
              "help": "Tips voor foto's"
            }
          },
          "intro": {
            "affiliations": "Vermeld alle beroepsverenigingen waarbij je bent aangesloten.",
            "certifications": "Vermeld hier al je licenties & certificaten voor methoden, aanpakken, assessments, tests en andere zaken die te maken hebben met leren, ontwikkelen en begeleidingsactiviteiten. Dit moeten je (beroepsgerichte) opleidingen, trainingen, cursussen en workshops zijn met een officieel certificaat / licentie / keurmerk.",
            "contract_type": "Dit gedeelte is voor intern gebruik en wordt onderhouden door een Programmamanager. Je kunt hier zelf geen aanpassingen maken.",
            "education": "Vermeld hier al je opleidingen en trainingen die je hebt gevolgd (ook die je niet hebt afgerond). Dit moeten je officieel door de overheid erkende opleidingen/kwalificaties/diploma's zijn.",
            "publications": "Vermeld alle publicaties die je hebt geschreven of waaraan je hebt bijgedragen. Let op, voor een uniforme weergave gebruiken we de internationale APA-regels. Voor elk veld geven we een voorbeeld, gebruik deze om elk veld correct in te vullen.",
            "work_experience": "Vermeld hier je relevante werkervaring, niet alleen met betrekking tot coaching & training, maar al je werkervaring."
          },
          "job_experience": "Baan & werkervaring",
          "job_information": "Functie-informatie",
          "job_title": "Functie",
          "level_coaching": "Coachingsniveau",
          "level_ecoaching": "E-coaching niveau",
          "professions": "Beroepen",
          "publications": "Publicaties",
          "specializations": "Specialisaties",
          "work_experiences": "Werkervaringen"
        },
        "date_of_birth": "Geboortedatum",
        "default_cv": "Standaard CV",
        "ecoaching_level": "Niveau ecoaching",
        "ecoaching_levels": {
          "level_i": "Niveau I",
          "level_ii": "Niveau II",
          "level_iii": "Niveau III",
          "none": "Geen"
        },
        "first_name": "Voornaam",
        "group_work": {
          "add": "Nog een groepswerkervaring toevoegen",
          "select": "Selecteer type groepswerk"
        },
        "group_work_location": {
          "label": "Online / offline"
        },
        "group_work_locations": {
          "both": "Beide",
          "offline": "Offline",
          "online": "Online"
        },
        "group_work_type": {
          "label": "Ontwerp / Levering"
        },
        "group_work_types": {
          "both": "Beide",
          "delivery": "Levering",
          "design": "Ontwerp"
        },
        "id_number": "ID-nummer (paspoort of ander ID)",
        "intro": {
          "addresses": "Geef hieronder uw bedrijfsgegevens op. Let op: alleen als je <b>geen</b> IBAN-bankrekeningnummer hebt, is het verplicht om ook het veld 'BIC/SWIFT' in te vullen.",
          "avatars": "Upload je profielfoto. We zijn op zoek naar krachtige portretten waarin de mens centraal staat. Een strakke look & feel zonder de warmte van het menselijke aspect te verliezen. De foto's worden ook gekenmerkt door scherptediepte en het gebruik van natuurlijk licht.<br /><br />Bedenk dat we op zoek zijn naar strakke foto's met weinig felle kleuren of afleidende objecten op de achtergrond.<br /><br />Denk aan je LinkedIn-foto, maar dan een meer ontspannen versie. Je foto moet laten zien wie je bent zonder te vergeten dat dit een zakelijke omgeving is. Vraag uzelf af: welke foto zou u op uw LinkedIn plaatsen vs. welke foto zou u plaatsen als u op vakantie bent? <br /><br />Probeer het volgende te vermijden: Korte broeken, mouwloze of opzichtige topjes, sandalen<br /><br />De foto die je hier uploadt, wordt weergegeven op de pagina \"Ons team\" op de website van UNLOQ en (indien van toepassing) op de websitepagina's van CoachDesks.<br /><br /><b>Opmerking:</b> zodra je de foto hebt geüpload, moet deze door UNLOQ worden goedgekeurd voordat deze zichtbaar wordt op de website van UNLOQ en CoachDesks en als avatar binnen Campus. Zodra de foto is goedgekeurd, verschijnt deze in je profiel.",
          "bio": "Schrijf een korte bio over jezelf. Deze bio wordt toegevoegd aan je CV en zal ook zichtbaar zijn op de <a href=\"https://unloq.org/our-team/\" target=\"_blank\">Ons Team</a> pagina op de UNLOQ website en (indien van toepassing) op de CoachDesks.<br /><br />De volgende eisen worden gesteld:<ul><li>Min 400 tekens</li><li>Max 800 tekens</li><li>Geschreven in derde persoon</li><li>Geschreven in het Engels</li></ul><i>Voorbeeld: Marcel heeft 15 jaar coachingservaring in leiderschapsontwikkeling en transitieprocessen. Hij is geïnteresseerd in onderwerpen die te maken hebben met diversiteit, gelijkheid en inclusie en zijn praktische benadering is gebaseerd op Oosterse en Westerse psychologie. Klanten en collega's zeggen dat hij ruimdenkend en flexibel is, snel ter zake komt en een scherp gevoel heeft voor wat nodig is.</i><br /><br /><b>Noot:</b> zodra je je bio hebt bijgewerkt, moet deze worden goedgekeurd door UNLOQ voordat deze zichtbaar wordt op de website en CoachDesks van UNLOQ. Zodra de bio is goedgekeurd, verschijnt deze in ons profiel.",
          "bio_no_cv_yet": "Geef eerst informatie over je huidige baan voordat je je korte bio schrijft.",
          "group_work": "Vermeld hier je ervaring en voorkeur voor verschillende soorten groepswerk. Je kunt voor elk type groepswerk aangeven of je de voorkeur geeft aan offline, online of beide en of je ervaring hebt in het ontwerpen of geven of beide.",
          "job_information": "Geef informatie over uw huidige baan",
          "languages": "Voeg alle talen die je spreekt toe, samen met het taalvaardigheidsniveau.",
          "personal_information": "In dit deel van je profiel kun je al je persoonlijke basisgegevens invullen. Houd er rekening mee dat sommige van deze gegevens zichtbaar zullen zijn op de <a href=\"https://unloq.org/our-team/\" target=\"_blank\">\"Ons Team\"</a> pagina op de UNLOQ website en op de CoachDesks. De velden die gemarkeerd zijn als \"alleen voor intern gebruik\" zijn niet zichtbaar voor het publiek."
        },
        "languages": {
          "add": "Voeg nog een taal toe",
          "select": "Selecteer taal",
          "select_level": "Selecteer taalniveau"
        },
        "last_name": "Achternaam",
        "linkedin": "LinkedIn",
        "list_view": "lijstweergave",
        "multiple_answers_possible": "Meerdere antwoorden mogelijk",
        "nationalities": "Nationaliteiten",
        "native_background": "Inheemse achtergrond / wortels",
        "organization_name": "Naam organisatie",
        "phone_number": "Telefoonnummer",
        "place_of_birth": "Geboorteplaats",
        "profile": "Profiel",
        "residence_country": "Land van verblijf",
        "search_in": "Zoeken in",
        "secondary_phone_number": "Alternatief telefoonnummer",
        "select_one_answer": "Selecteer een antwoord",
        "timezone": "Tijdzone",
        "training_level": "Niveau facilitering",
        "training_levels": {
          "level_i": "Niveau I",
          "level_ii": "Niveau II",
          "level_iii": "Niveau III",
          "none": "Geen"
        },
        "unloq": "UNLOQ associates",
        "unloq_only": "Alleen UNLOQ associates?"
      },
      "filters": {
        "branch_experiences": "Branche-ervaringen",
        "countries": "Landen",
        "job_title": "Functie",
        "languages": "Talen",
        "miscellaneous": "Diverse",
        "name_or_email": "Naam of e-mail",
        "organization": "Organisatie",
        "professions": "Beroepen",
        "programs": "Programma's",
        "remarks": "Opmerkingen",
        "specializations": "Specialisaties",
        "timezones": "Tijdzones"
      },
      "select": {
        "associate_list": "Selecteer geassocieerde lijst",
        "coach": "Selecteer coach",
        "program": "Selecteer programma"
      },
      "tours": {
        "coach_edit": {
          "step_coach": {
            "text_1": "Welkom op de pagina voor het bewerken van profielen!",
            "text_2": "In dit tabblad moeten je belangrijkste gegevens worden ingevuld.",
            "text_3": "De informatie die je hier invult, wordt gebruikt om je aan een klant te koppelen en om je curriculum vitae te genereren."
          },
          "step_commercial_cv": {
            "text_1": "Het tabblad <b>%{commercial_cv}</b> is vergelijkbaar met het tabblad Default CV, behalve dat de informatie die u hier invoert specifiek bedoeld is voor commerciële klanten."
          },
          "step_copy": {
            "text_1": "Je kunt de <b>%{button_name}</b> knop gebruiken als er nog geen commerciële CV is gemaakt.",
            "text_2": "De knop kopieert alle informatie van je standaard CV waarna je bepaalde onderdelen kunt wijzigen of verwijderen."
          },
          "step_default_cv": {
            "text_1": "In dit tabblad wordt je standaard curriculum vitae samengesteld.",
            "text_2": "Vul deze gegevens zo volledig mogelijk in, zodat UNLOQ jouw capaciteiten beter kan afstemmen op onze klanten.",
            "text_3": "We gebruiken deze gegevens ook om automatisch CV-documenten te genereren.",
            "text_4": "De gegenereerde CV-documenten worden alleen intern of met onze klanten gedeeld."
          },
          "step_help": {
            "text_1": "Deze knop opent deze Help-tour opnieuw. Dus als je later uitleg wilt, klik dan op deze knop."
          }
        }
      }
    },
    "recaptcha": {
      "errors": {
        "recaptcha_unreachable": "Oeps, we hebben uw reCAPTCHA-antwoord niet kunnen valideren. Probeer het opnieuw.",
        "verification_failed": "reCAPTCHA-verificatie mislukt, probeer het opnieuw."
      }
    },
    "register": {
      "create": {
        "flash_email_invalid": "Single SignOn inloggen is niet gelukt omdat geen geldig e-mailadres is gevonden. Probeer daarom het normale loginformulier.",
        "flash_error": "Single SignOn inloggen is niet gelukt, probeer daarom het normale loginformulier.",
        "flash_success": "Je account is aangemaakt."
      },
      "new": {
        "btn": "Account aanmaken",
        "description": "Je bent slechts enkele ogenblikken verwijderd van een positieve verandering in je leven.",
        "edit_name": "Wijzig naam",
        "flash_error": "Je account kon niet worden aangemaakt. ",
        "flash_success": "Er is een bevestigingsmail gestuurd.",
        "title": "Registreer voor een UNLOQ Campus account."
      }
    },
    "reply": "Antwoord",
    "results": "Resultaten",
    "reviews": "Beoordelingen",
    "role": "Rol",
    "roles": {
      "admin": "Admin",
      "client": "Cliënt",
      "coach": "Coach",
      "organization_manager": "Organisatiemanager"
    },
    "search": {
      "clear": "reset",
      "placeholder": "Zoek..."
    },
    "search_client": "Klant zoeken",
    "search_coach": "Zoek coach",
    "search_respondent": "Respondent zoeken",
    "select_client": "Selecteer klant",
    "select_coach": "Selecteer coach",
    "select_language": "Selecteer taal",
    "select_language_level": "Selecteer bekwaamheid",
    "select_program": "Selecteer programma",
    "select_questionnaire": "Selecteer vragenlijst",
    "select_respondent": "Selecteer respondent",
    "send_pdf_reports": "PDF-rapporten verzenden",
    "send_pdf_reports_failed": "Kan geen PDF-rapporten versturen, niet alle deelnemers hebben de vragenlijsten ingevuld",
    "send_pdf_reports_success": "PDF-rapporten succesvol verzonden",
    "sessions": {
      "create": {
        "captcha_invalid": "Captcha ongeldig: graag aanvinken.",
        "invalid": "Ongeldige gebruikersnaam of wachtwoord. Probeer opnieuw."
      },
      "verify_2fa_code": {
        "btn": "Verifiëren",
        "label": "Vul je 2-staps verificatiecode in"
      }
    },
    "settings": "Instellingen",
    "shared_by": "Gedeeld door: ",
    "shared_mailer": {
      "dear": "Beste",
      "note": "PS: dit vertrouwelijke bericht is automatisch gegenereerd.",
      "regards": "Vriendelijke groet,",
      "sender": "Het UNLOQ Campus team"
    },
    "show_all": "toon alles",
    "sidemenu": {
      "clients": "Cliënten",
      "dialogs": "Dialogen",
      "language": "Taalvoorkeur",
      "library": "Toolbox",
      "privacy": "Privacybeleid",
      "privacy_policy": "Privacybeleid",
      "privacy_regulations": "Privacyreglement",
      "support": "Support",
      "terms": "Algemene Voorwaarden",
      "user_terms": "Gebruikersvoorwaarden"
    },
    "signup": {
      "confirm_email": {
        "description": "Controleer je e-mail inbox voor een bevestigingse-mail. Klik de link in de e-mail om te bevestigen.<br><br>Als je geen e-mail hebt ontvangen: controleer je spam-folder.",
        "flash_success": "Je account is geactiveerd.",
        "title": "Bevestig je e-mailadres"
      },
      "new": {
        "already_account": "Heb je al een account?",
        "btn": "Registreer",
        "description": "Registreer om UNLOQ Campus te gebruiken voor effectieve en veilige coachingconversaties.",
        "email_already_exists": "E-mailadres bestaat al.",
        "organization_description": "Dit registratieformulier is specificiek bedoeld voor %{name}. Je kunt alleen registreren met een @%{domain} e-mailadres.",
        "organization_domain_error": "E-mail is ongeldig: het domein moet overeenkomen met de organisatie / instelling.",
        "title": "Level up your coaching."
      }
    },
    "simple_form": {
      "error_notification": {
        "default_message": "Bekijk onderstaande problemen:"
      },
      "no": "Nee",
      "required": {
        "mark": "*",
        "text": "Verplicht"
      },
      "yes": "Ja"
    },
    "spinner": {
      "title": "Even geduld..."
    },
    "state": "Status",
    "status": "Status",
    "support": {
      "array": {
        "last_word_connector": " en ",
        "two_words_connector": " en ",
        "words_connector": ", "
      },
      "check_manual": "Welkom op de support pagina. In de handleiding vind je veel extra achterground info, klik hier: ",
      "help_text": "Is je vraag nog niet beantwoord? Stuur dan alsjeblieft een e-mail naar het mail adres ",
      "help_text2": "of bel ons binnen kantoortijden",
      "help_text3": "Ik help je graag!",
      "manual": "Handleiding",
      "title": "Support"
    },
    "time": {
      "am": "'s ochtends",
      "formats": {
        "default": "%a %d %b %Y %H:%M:%S %Z",
        "dialogue": "%a %e %b %Y %H:%M %Z",
        "long": "%d %B %Y %H:%M",
        "no_timezone": "%a, %d %b %Y %H:%M:%S",
        "notime": "%e %b %Y",
        "short": "%d %b %H:%M",
        "trestle_time": "%R %p",
        "trestle_time_with_seconds": "%T %p"
      },
      "pm": "'s middags"
    },
    "timesheets": {
      "clients_title": "Cliëntensessies",
      "description": "Hieronder kun je overzichten creëren van het totaal aantal geregistreerd uren per programma, client en tijdsperiode. Uren invoeren of aanpassen kan alleen via een cliënt sessielog.",
      "export": {
        "all_figures": "Alle gegevens",
        "confirm": "Je wilt vertrouwelijke informatie downloaden, weet je het zeker?",
        "details": "Sessiedetails",
        "key_figures": "Basisgegevens"
      },
      "title": "Urenoverzicht"
    },
    "title_date": "Datum",
    "toastrs": {
      "copied": "Gekopieerd!",
      "sent": "Verzonden!"
    },
    "total": "Totaal",
    "total_clients": "Totaal aantal cliënten",
    "total_costs": "Totale kosten",
    "trestle": {
      "confirmation": {
        "cancel": "Annuleren",
        "delete": "Verwijderen",
        "title": "Weer je het zeker?"
      },
      "footer": "Powered by Trestle",
      "helpers": {
        "page_entries_info": {
          "more_pages": {
            "display_entries": "%{entry_name} <strong>%{first}&nbsp;-&nbsp;%{last}</strong> van de <b>%{total}</b> worden weergeven"
          },
          "one_page": {
            "display_entries": {
              "one": "<strong>1</strong> %{entry_name} wordt weergeven",
              "other": "<strong>Alle %{count}</strong> %{entry_name} worden weergeven",
              "zero": "Geen %{entry_name} gevonden"
            }
          }
        }
      },
      "onboarding": {
        "no_admins": "Voeg een admin toe in <code>app/admin</code> om te beginnen.",
        "no_form": "Definieer een form block of maak een <code>_form.html</code> partial.",
        "no_template": "Maak <code>%{path}</code> aan om dit template aan te passen.",
        "welcome": "Welkom bij Trestle"
      },
      "title": "Trestle Admin",
      "ui": {
        "toggle_navigation": "Navigation tonen/verbergen",
        "toggle_sidebar": "Sidebar tonen/verbergen"
      },
      "version": "Versie"
    },
    "true": "Ja",
    "user_mailer": {
      "email_confirmation": {
        "btn": "Bevestig je e-mailadres",
        "message": "Voordat we je account kunnen activeren, moet je je e-mailadres bevestigen. Klik op onderstaande link om je e-mailadres te bevestigen en om door te kunnen gaan op UNLOQ Campus.",
        "subject": "UNLOQ Campus: bevestig je e-mailadres",
        "thanks": "Bedankt dat je je hebt aangemeld bij UNLOQ Campus!"
      }
    },
    "users": {
      "active_explanation": "Niet aangevinkt = inactief = gearchiveerd op de Cliëntenpagina voor de coach.",
      "already_account": "heeft al een account! Selecteer hieronder het programma waarbinnen je met deze cliënt een nieuwe dialoog wilt starten. Kies vervolgens voor de knop 'Uitnodiging versturen'.",
      "coach_for_client": "Coach voor deze cliënt",
      "copy_to_clipboard_tooltip": "Kopiëer deze uitnodigingslink en verstuur deze middels een <br/>extern e-mailprogramma naar deze gebuiker (niet bedoeld voor eigen gebruik).",
      "edit_user": "Gebruikersaccount wijzigen",
      "empty": "Geen cliënt(en) gevonden.",
      "enable_rating": "Dit geeft cliënten de mogelijkheid je te beoordelen<br/> inclusief een begeleidende tekst.",
      "organizations_label": "Organisatiemanager van:",
      "programs_label": "Programma('s): voeg deze gebruiker toe aan 1 of meerdere programma's en selecteer een rol per programma.",
      "remove_membership": "Lidmaatschap verwijderen",
      "remove_program": "Programma verwijderen",
      "resend_confirm": "Weet je zeker dat je de registratiemail of bevestigingsmail opnieuw wilt versturen naar deze gebruiker?",
      "resend_tooltip": "Opnieuw verzenden van registratiemail <br/>of bevestigingsmail naar deze gebruiker.",
      "roles_empty": "Gebruikersaccount moet aan 1 of meerdere programma's toegewezen worden."
    },
    "views": {
      "pagination": {
        "first": "Eerste",
        "last": "Laatste",
        "next": "Volgende",
        "previous": "Vorige",
        "truncate": "..."
      }
    },
    "your_coach": "Jouw coach is:"
  }
}