import { Email } from "@mui/icons-material"
import { useState } from "react"
import {
  Button,
  Confirm,
  useDataProvider,
  useListContext,
  useNotify,
} from "react-admin"
import { useMutation } from "react-query"

export const BulkSurveySendReportAction = () => {
  const [open, setOpen] = useState(false)
  const { selectedIds } = useListContext()
  const notify = useNotify()
  const dataProvider = useDataProvider()

  const handleDialogClose = () => setOpen(false)
  const handleClick = () => setOpen(true)

  const surveySendReportMany = async () => {
    return await dataProvider.surveySendReportMany(selectedIds)
  }

  const { mutate: sendReports, isLoading } = useMutation(surveySendReportMany, {
    onSuccess: (data) => {
      if (data.status == 200) {
        notify(`send_pdf_reports_success`, { type: "success" })
      } else {
        notify(`send_pdf_reports_failed`, { type: "error" })
      }
    },
  })

  const handleConfirm = () => {
    sendReports()
    setOpen(false)
  }

  return (
    <>
      <Button
        label="send_pdf_reports"
        onClick={handleClick}
        disabled={isLoading}
      >
        <Email />
      </Button>
      <Confirm
        isOpen={open}
        title={`Send PDF Reports`}
        content={`Are you sure you want to send the PDF reports?`}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  )
}
