import {
  AutocompleteInput,
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  ReferenceField,
  ReferenceInput,
  TextField,
} from "react-admin"

import { List } from "@react-admin/ra-rbac"

import { JsonApiArrayField } from "../../components/fields/JsonApiArrayField"
import { UserField } from "../../components/fields/UserField"

const filters = [
  <ReferenceInput
    label="User"
    key="user"
    source="user_id"
    reference="permissions"
    sort={{ field: "updated_at", order: "DESC" }}
    alwaysOn
  >
    <AutocompleteInput sx={{ width: "400px" }} />
  </ReferenceInput>,
]

export const PermissionList = () => {
  return (
    <List filters={filters} perPage={25} hasCreate>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <ReferenceField source="user_id" reference="users" label="User">
          <UserField />
        </ReferenceField>
        <TextField source="resource" />
        <JsonApiArrayField source="actions" label="Actions" />
        <DateField source="updated_at" />
        <DateField source="created_at" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  )
}
