import { Typography } from "@mui/material"
import { useNotify, useRedirect, useTranslate } from "ra-core"
import { Edit } from "ra-ui-materialui"
import { useParams } from "react-router"
import { ContractForm } from "../../components/forms/ContractForm"
import { IRobinChatBubble } from "../../components/forms/IRobinChatBubble"

export const ContractType = ({ user = null }) => {
  const { coachId } = useParams()
  const redirect = useRedirect()
  const notify = useNotify()
  const translate = useTranslate()

  const onSuccess = () => {
    notify(`accounts.edit.flash_success`, { type: "success" })
    redirect(false)
  }

  return (
    <Edit
      resource="coaches"
      id={user ? user.id : coachId}
      redirect={false}
      mutationMode="optimistic"
      mutationOptions={{ onSuccess }}
      actions={null}
      sx={coachId && { "& .MuiPaper-root": { overflow: "visible" } }}
    >
      <IRobinChatBubble>
        <Typography variant="h6" mb={1} fontWeight="bold">
          {translate("react.coaches.contract_type")}
        </Typography>

        <Typography my={1}>
          {translate("react.coaches.cv.intro.contract_type")}
        </Typography>
      </IRobinChatBubble>

      <ContractForm />
    </Edit>
  )
}
