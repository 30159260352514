import { useState } from "react"
import { ReferenceOneField } from "react-admin"
import { Button, TextInput } from "ra-ui-materialui"
import { useInput, useRecordContext } from "ra-core"
import { Alert, Box, Typography } from "@mui/material"

import { dataProvider } from "../../dataProvider"
import CheckIcon from "@mui/icons-material/Check"

export function ApproachDiff() {
  const record = useRecordContext()
  const { field } = useInput({ source: "new_value" })
  const [statusText, setStatusText] = useState<string>("")

  const [count, setCount] = useState(field.value.length)
  const [isEditable, setIsEditable] = useState(false)

  const toggleEditable = () => {
    setIsEditable(!isEditable)
  }

  const mutate = async () => {
    await dataProvider
      .update("curriculum-vitaes", {
        id: record.changeable_id,
        data: { approach: { en: field.value } },
        previousData: record,
      })
      .then(function (response) {
        setStatusText("Bio updated")
        setIsEditable(!isEditable)
        return response
      })
  }

  const readOnlyElement = (
    <>
      <TextInput
        readOnly
        source="approach.en"
        label="react.coaches.cv.fields.approach_current"
        fullWidth
        multiline
        sx={{
          marginTop: "2rem",
          "& .MuiFilledInput-root.Mui-disabled": {
            backgroundColor: "white",
          },
        }}
      />
    </>
  )

  const inputElement = (
    <>
      <TextInput
        source="new_value"
        label="New bio"
        fullWidth
        rows={15}
        multiline
        readOnly={!isEditable}
        onChange={(event) => setCount(event.target.value.length)}
        sx={{ marginTop: "2rem" }}
      />
      <Box
        component="span"
        sx={{
          width: "100%",
          position: "relative",
          marginTop: "-50px",
          height: "50px",
          paddingRight: "0.5rem",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <span>{`${count} / ${800}`}</span>
      </Box>
      <Box>
        <Button onClick={toggleEditable} label="Edit" disabled={isEditable} />
        &nbsp;
        <Button onClick={mutate} label="Approve" />
        {statusText && (
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
            {statusText}
          </Alert>
        )}
      </Box>
    </>
  )

  return (
    <div style={{ width: "100%" }}>
      <Typography mb={3} fontWeight="bold">
        User has updated their approach
      </Typography>
      <ReferenceOneField
        reference="curriculum-vitaes"
        target="id"
        source="changeable_id"
      >
        {readOnlyElement}
      </ReferenceOneField>
      <hr />
      {inputElement}
    </div>
  )
}
