import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  ReferenceManyCount,
  SelectInput,
  TextField,
  TextInput,
  useDataProvider,
  useRefresh,
} from "react-admin"

import MenuIcon from "@mui/icons-material/Menu"
import { useEffect } from "react"
import Sortable from "sortablejs"

import { IconOnlyCloneButton } from "../../components/buttons/IconOnlyCloneButton"
import { IconOnlyDeleteButton } from "../../components/buttons/IconOnlyDeleteButton"
import { IconOnlyEditButton } from "../../components/buttons/IconOnlyEditButton"
import { Box } from "@mui/system"

const filters = [
  <BooleanInput
    key="include_in_report"
    source="include_in_report"
    label="Included in report?"
    sx={{
      height: "50px",
      display: "flex",
      justifyContent: "center",
      width: "100%",
    }}
  />,
  <ReferenceInput
    label="Module"
    key="learning_module"
    source="learning_module_id"
    reference="learning-modules"
    sort={{ field: "position", order: "ASC" }}
  >
    <AutocompleteInput sx={{ width: "400px" }} />
  </ReferenceInput>,
  <ReferenceInput
    label="Path"
    key="learning_path"
    source="learning_path_id"
    reference="learning-paths"
    sort={{ field: "name", order: "ASC" }}
  >
    <AutocompleteInput sx={{ width: "400px" }} />
  </ReferenceInput>,
  <TextInput key="search_name" source="name" sx={{ width: "400px" }} />,
  <TextInput
    key="search_presentation"
    source="presentation"
    label="Presentation"
    sx={{ width: "400px" }}
  />,
  <SelectInput
    key="source"
    source="source"
    label="Source"
    choices={[
      { id: "campus", name: "UNLOQ Campus" },
      { id: "teams", name: "Teams Attendance file" },
      { id: "welearn", name: "WeLearn" },
    ]}
    sx={{ width: "400px" }}
  />,
  <SelectInput
    key="target_group"
    source="target_group"
    label="Target group"
    choices={[{ id: "coaches", name: "Coaches" }]}
    sx={{ width: "400px" }}
  />,
]

export const LearningStepList = () => {
  const dataProvider = useDataProvider()
  const refresh = useRefresh()

  useEffect(() => {
    const updatePosition = (stepId, position) => {
      dataProvider.getOne("learning-steps", { id: stepId }).then(({ data }) => {
        const diff = { position: position }
        dataProvider
          .update("learning-steps", {
            id: stepId,
            data: diff,
            previousData: data,
          })
          .then(() => {
            refresh()
          })
      })
    }

    // https://github.com/SortableJS/Sortable
    const retries = 3
    const millisecords = 1500
    let attempts = 0

    const retrySortable = () => {
      const el = document.querySelector("#sortable-list tbody")
      if (!el) {
        if (++attempts >= retries) {
          console.log(`cannot initialise sortable after ${retries} retries.`)
        } else {
          setTimeout(() => retrySortable(), millisecords)
        }
      } else {
        new Sortable(el, {
          handle: ".handle",
          draggable: "tr",
          animation: 150, // ms, animation speed moving items when sorting, `0` — without animation
          easing: "cubic-bezier(1, 0, 0, 1)", // Easing for animation. Defaults to null. See https://easings.net/ for examples.
          onEnd: () => {
            const list = document.querySelectorAll(
              "#sortable-list tbody td.column-id span"
            )

            let position = 0
            ;[].forEach.call(list, function (span: Element) {
              updatePosition(span.innerHTML, position)
              position++
            })
          },
        })
      }
    }
    retrySortable()
  })

  return (
    <List
      filters={filters}
      sort={{ field: "name", order: "ASC" }}
      perPage={25}
      hasCreate
    >
      <section id="sortable-list">
        <Datagrid rowClick="edit" bulkActionButtons={false}>
          <MenuIcon sx={{ cursor: "move" }} className="handle" />
          <NumberField source="position" label="Pos" textAlign="center" />
          <NumberField source="id" textAlign="center" />
          <TextField source="presentation" />
          <TextField source="name" />
          <ReferenceField
            label="Alternative for"
            source="alternative_step_for_id"
            reference="learning-steps"
            link="show"
          />
          <BooleanField
            source="include_in_report"
            label="Report"
            textAlign="center"
          />
          <TextField source="target_group" label="Target" />
          <NumberField source="start_days" label="Start" />
          <TextField source="source" />
          <ReferenceField
            label="Path"
            source="learning_module_id"
            reference="learning-modules"
            link={false}
          >
            <ReferenceField
              source="learning_path_id"
              reference="learning-paths"
              link="show"
            />
          </ReferenceField>
          <ReferenceField
            label="Module"
            source="learning_module_id"
            reference="learning-modules"
            link="show"
          />
          <ReferenceManyCount
            label="Users"
            reference="learning-step-progresses"
            target="learning_step_id"
            textAlign="right"
            link
          />
          <NumberField source="score" />
          <DateField source="created_at" />
          <Box display="flex" gap={1}>
            <IconOnlyCloneButton />
            <IconOnlyEditButton />
            <IconOnlyDeleteButton />
          </Box>
        </Datagrid>
      </section>
    </List>
  )
}
