/* eslint-disable react/prop-types */
import { RichTextInput } from "ra-input-rich-text"
import { useEffect, useState } from "react"
import { useDataProvider } from "react-admin"

import { emailTemplateVariables } from "../tiptap/EmailTemplateVariables"

import Document from "@tiptap/extension-document"
import Mention from "@tiptap/extension-mention"
import Placeholder from "@tiptap/extension-placeholder"
import Text from "@tiptap/extension-text"

import { EmailTemplateSubjectNode } from "../tiptap/nodes/EmailTemplateSubjectNode"

const CustomDocument = Document.extend({
  content: "emailSubject",
})

export const TextWithEmailVariablesInput = ({
  emailTemplateModelId,
  source,
  ...rest
}) => {
  const dataProvider = useDataProvider()
  const [emailVariables, setEmailVariables] = useState([])
  const [tipTapEditorOptions, setTipTapEditorOptions] = useState({})

  useEffect(() => {
    async function fetchData() {
      const response = await dataProvider.getOne("email-template-models", {
        id: emailTemplateModelId,
      })
      if (response.data.email_variables) {
        const emailVariableChoices = response.data.email_variables.map(
          (emailVariable) => emailVariable.id
        )
        setEmailVariables(emailVariableChoices)
        setTipTapEditorOptions({
          extensions: [
            CustomDocument,
            Text,
            EmailTemplateSubjectNode,
            Mention.configure({
              HTMLAttributes: {
                class: "mention",
              },
              suggestion: emailTemplateVariables(emailVariableChoices),
            }),
            Placeholder.configure({
              includeChildren: true,
              showOnlyCurrent: false,
              placeholder: () => {
                return "Type here. Type @ to open a list of email variables."
              },
            }),
          ],
        })
      }
    }

    fetchData()
  }, [emailTemplateModelId, dataProvider])

  return (
    <>
      {emailVariables.length > 0 && (
        <RichTextInput
          toolbar={<></>}
          editorOptions={tipTapEditorOptions}
          source={source}
          fullWidth
          {...rest}
        />
      )}
    </>
  )
}
