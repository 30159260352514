import { Grid, Typography } from "@mui/material"
import { canAccess, IfCanAccess } from "@react-admin/ra-rbac"
import { TextInput } from "ra-ui-materialui"
import {
  SelectInput,
  SimpleForm,
  usePermissions,
  useTranslate,
} from "react-admin"

import { Box } from "@mui/system"
import { FormToolbar } from "./FormToolbar"
import { IRobinChatBubble } from "./IRobinChatBubble"

export const ContractForm = () => {
  return (
    <SimpleForm toolbar={<FormToolbar />}>
      <ContractInputs />
    </SimpleForm>
  )
}

const ContractInputs = () => {
  const translate = useTranslate()
  const { permissions } = usePermissions()

  const contractTypes = [
    { id: "core_team", name: translate("react.coaches.contracts.core_team") },
    {
      id: "fixed_hours",
      name: translate("react.coaches.contracts.fixed_hours"),
    },
    {
      id: "project_basis",
      name: translate("react.coaches.contracts.project_basis"),
    },
  ]

  const levelsCoaching = [
    {
      id: "executive",
      name: translate("react.coaches.coaching_levels.executive"),
    },
    {
      id: "leadership_coaching",
      name: translate("react.coaches.coaching_levels.leadership_coaching"),
    },
    {
      id: "top_executive",
      name: translate("react.coaches.coaching_levels.top_executive"),
    },
    {
      id: "young_talent_professional",
      name: translate(
        "react.coaches.coaching_levels.young_talent_professional"
      ),
    },
  ]

  const levelsEcoaching = [
    {
      id: "level_i",
      name: translate("react.coaches.ecoaching_levels.level_i"),
    },
    {
      id: "level_ii",
      name: translate("react.coaches.ecoaching_levels.level_ii"),
    },
    {
      id: "level_iii",
      name: translate("react.coaches.ecoaching_levels.level_iii"),
    },
  ]

  const levelsTraining = [
    { id: "level_i", name: translate("react.coaches.training_levels.level_i") },
    {
      id: "level_ii",
      name: translate("react.coaches.training_levels.level_ii"),
    },
    {
      id: "level_iii",
      name: translate("react.coaches.training_levels.level_iii"),
    },
  ]

  const coachTypes = [
    { id: "a", name: translate("react.coaches.coach_types.a") },
    {
      id: "t",
      name: translate("react.coaches.coach_types.t"),
    },
  ]

  const associateStatuses = [
    { id: "active", name: translate("react.coaches.associate_status.active") },
    {
      id: "on_hold",
      name: translate("react.coaches.associate_status.on_hold"),
    },
    {
      id: "offboard",
      name: translate("react.coaches.associate_status.offboard"),
    },
  ]

  const enabledContractType = canAccess({
    permissions,
    action: "contract_type",
    resource: "coaches",
  })

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SelectInput
            emptyText={translate("none")}
            source="associate_status"
            label="react.coaches.associate_status.label"
            choices={associateStatuses}
            fullWidth
            disabled={!enabledContractType}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            emptyText={translate("none")}
            source="contract_type"
            label="react.coaches.contract_type"
            choices={contractTypes}
            fullWidth
            disabled={!enabledContractType}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            emptyText={translate("none")}
            source="level_ecoaching"
            label="react.coaches.ecoaching_level"
            choices={levelsEcoaching}
            fullWidth
            disabled={!enabledContractType}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            emptyText={translate("none")}
            source="level_training"
            label="react.coaches.training_level"
            choices={levelsTraining}
            fullWidth
            disabled={!enabledContractType}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            emptyText={translate("none")}
            source="coach_type"
            label="react.coaches.coach_type"
            choices={coachTypes}
            fullWidth
            disabled={!enabledContractType}
          />
        </Grid>
      </Grid>

      <IfCanAccess action="contract_remarks" resource="coach">
        <Box
          mt={2}
          pt={4}
          sx={{
            borderTop: "1px solid #e5e5e5",
            width: "100%",
          }}
        >
          <IRobinChatBubble>
            <Typography variant="h6" mb={1} fontWeight="bold">
              {translate("react.admin.unloq_only_contract_fields.title")}
            </Typography>

            <Typography my={1}>
              <Box
                component="span"
                dangerouslySetInnerHTML={{
                  __html: translate(
                    "react.admin.unloq_only_contract_fields.body"
                  ),
                }}
              />
            </Typography>
          </IRobinChatBubble>

          <Grid container spacing={2} mt={2}>
            <Grid item xs={12}>
              <SelectInput
                emptyText={translate("none")}
                source="level_coaching"
                label="react.coaches.coaching_level"
                choices={levelsCoaching}
                fullWidth
                disabled={!enabledContractType}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                source="contract_remarks"
                multiline
                fullWidth
                rows={4}
              />
            </Grid>
          </Grid>
        </Box>
      </IfCanAccess>
    </>
  )
}
