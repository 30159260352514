import { FunctionField } from "react-admin"

const values = {
  executive: "Executive",
  leadership_coaching: "Leadership coaching",
  top_executive: "Top executive",
  young_talent_professional: "Young talent professional",
}

export const LevelCoachingField = () => {
  return (
    <FunctionField
      label="react.coaches.cv.level_coaching"
      render={(record) => values[record.level_coaching]}
    />
  )
}
