import {
  CustomRoutes,
  EditGuesser,
  ListGuesser,
  localStorageStore,
  ShowGuesser,
} from "react-admin"

import { Admin } from "@react-admin/ra-enterprise"
import { Resource } from "@react-admin/ra-rbac"

import { QueryClient } from "react-query"
import { Route } from "react-router"

import AccountBoxIcon from "@mui/icons-material/AccountBox"
import AllInboxIcon from "@mui/icons-material/AllInbox"
import CallSplitIcon from "@mui/icons-material/CallSplit"
import CorporateFareIcon from "@mui/icons-material/CorporateFare"
import DraftsIcon from "@mui/icons-material/Drafts"
import EmailIcon from "@mui/icons-material/Email"
import FlagIcon from "@mui/icons-material/Flag"
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered"
import GroupsIcon from "@mui/icons-material/Groups"
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon"
import MessageIcon from "@mui/icons-material/Message"
import NotificationsPausedIcon from "@mui/icons-material/NotificationsPaused"
import PeopleIcon from "@mui/icons-material/People"
import PersonIcon from "@mui/icons-material/Person"
import PlumbingIcon from "@mui/icons-material/Plumbing"
import PollIcon from "@mui/icons-material/Poll"
import PublicIcon from "@mui/icons-material/Public"
import QuizIcon from "@mui/icons-material/Quiz"
import SchoolIcon from "@mui/icons-material/School"
import ImportExportIcon from "@mui/icons-material/ImportExport"
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt"
import {
  default as ShieldIcon,
  default as WorkspacePremiumIcon,
} from "@mui/icons-material/Shield"
import StairsIcon from "@mui/icons-material/Stairs"
import TranslateIcon from "@mui/icons-material/Translate"
import ViewModuleIcon from "@mui/icons-material/ViewModule"
import WorkIcon from "@mui/icons-material/Work"

import { InlineLayout } from "./InlineLayout"
import { UnloqLayout } from "./UnloqLayout"

import { lightTheme } from "./lightTheme"

import { authProvider } from "./authProvider"
import { dataProvider } from "./dataProvider"
import { getStoredLocale, i18nProvider } from "./i18nProvider"

import {
  BranchExperience,
  CertificationLevel,
  Country,
  LanguageLevel,
  Profession,
  Region,
  Specialization,
  Timezone,
} from "api/models"

import { UnloqErrorBoundary } from "./components/error_boundaries/UnloqErrorBoundary"

import { AssociateListCreate } from "./resources/associate-lists/AssociateListCreate"
import { AssociateListEdit } from "./resources/associate-lists/AssociateListEdit"
import { AssociateListShow } from "./resources/associate-lists/AssociateListShow"
import { AssociateListList } from "./resources/associate-lists/AssociateListList"
import { AuditLogList } from "./resources/audit-logs/AuditLogList"
import { ChangeRequestEdit } from "./resources/change-requests/ChangeRequestEdit"
import { ChangeRequestList } from "./resources/change-requests/ChangeRequestList"
import { ClientList } from "./resources/clients/ClientList"
import { BusinessDetailsEdit } from "./resources/coaches/BusinessDetailsEdit"
import { CoachList } from "./resources/coaches/CoachList"
import { CoachMatch } from "./resources/coaches/CoachMatch"
import { CommercialCv } from "./resources/coaches/CommercialCv"
import { ContractType } from "./resources/coaches/ContractType"
import { DefaultCv } from "./resources/coaches/DefaultCv"
import { GroupWorkEdit } from "./resources/coaches/GroupWorkEdit"
import { LanguagesEdit } from "./resources/coaches/LanguagesEdit"
import { PersonalInformationEdit } from "./resources/coaches/PersonalInformationEdit"
import { PhotosEdit } from "./resources/coaches/PhotosEdit"
import { CountryList } from "./resources/countries/CountryList"
import { DialogList } from "./resources/dialogs/DialogList"
import { EmailBatchCreate } from "./resources/email-batches/EmailBatchCreate"
import { EmailBatchEdit } from "./resources/email-batches/EmailBatchEdit"
import { EmailBatchList } from "./resources/email-batches/EmailBatchList"
import { EmailTemplateCreate } from "./resources/email-templates/EmailTemplateCreate"
import { EmailTemplateEdit } from "./resources/email-templates/EmailTemplateEdit"
import { EmailTemplateList } from "./resources/email-templates/EmailTemplateList"
import { EmailList } from "./resources/emails/EmailList"
import { LearningModuleCreate } from "./resources/learning-modules/LearningModuleCreate"
import { LearningModuleEdit } from "./resources/learning-modules/LearningModuleEdit"
import { LearningModuleList } from "./resources/learning-modules/LearningModuleList"
import { LearningPathCreate } from "./resources/learning-paths/LearningPathCreate"
import { LearningPathEdit } from "./resources/learning-paths/LearningPathEdit"
import { LearningPathList } from "./resources/learning-paths/LearningPathList"
import { LearningPathProgressList } from "./resources/learning-paths/LearningPathProgressList"
import { LearningPathReportingList } from "./resources/learning-paths/LearningPathReportingList"
import { LearningPathShow } from "./resources/learning-paths/LearningPathShow"
import { LearningStepProgressEdit } from "./resources/learning-step-progresses/LearningStepProgressEdit"
import { LearningStepProgressList } from "./resources/learning-step-progresses/LearningStepProgressList"
import { LearningStepCreate } from "./resources/learning-steps/LearningStepCreate"
import { LearningStepEdit } from "./resources/learning-steps/LearningStepEdit"
import { LearningStepList } from "./resources/learning-steps/LearningStepList"
import { OrganizationList } from "./resources/organizations/OrganizationList"
import { PermissionCreate } from "./resources/permissions/PermissionCreate"
import { PermissionEdit } from "./resources/permissions/PermissionEdit"
import { PermissionList } from "./resources/permissions/PermissionList"
import { ProgramBreakCreate } from "./resources/program-breaks/ProgramBreakCreate"
import { ProgramBreakList } from "./resources/program-breaks/ProgramBreakList"
import { ProgramMembershipList } from "./resources/program-memberships/ProgramMembershipList"
import { ProgramEdit } from "./resources/programs/ProgramEdit"
import { ProgramList } from "./resources/programs/ProgramList"
import { ProgramShow } from "./resources/programs/ProgramShow"
import { QuestionnaireList } from "./resources/questionnaires/QuestionnaireList"
import { ProfileTraitCreate } from "./resources/reusable_resource/ProfileTraitCreate"
import { ProfileTraitEdit } from "./resources/reusable_resource/ProfileTraitEdit"
import { ProfileTraitList } from "./resources/reusable_resource/ProfileTraitList"
import { SurveyList } from "./resources/surveys/SurveyList"
import { UserList } from "./resources/users/UserList"
import { ClientImportList } from "./resources/client-imports/ClientImportList"
import { ClientImportCreate } from "./resources/client-imports/ClientImportCreate"

const STORE_VERSION = "6"

const App = ({ inline = false }) => {
  // this disables the (annoying) auto refresh
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  })

  return (
    <UnloqErrorBoundary>
      <Admin
        queryClient={queryClient}
        requireAuth
        authProvider={authProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        lightTheme={lightTheme}
        title="UNLOQ Admin"
        store={localStorageStore(STORE_VERSION)}
        layout={inline ? InlineLayout : UnloqLayout}
        disableTelemetry
      >
        <Resource
          icon={GroupsIcon}
          name="programs"
          list={ProgramList}
          show={ProgramShow}
          edit={ProgramEdit}
          recordRepresentation={(record) => record.name}
        />
        <Resource
          icon={NotificationsPausedIcon}
          name="program-breaks"
          list={ProgramBreakList}
          show={ShowGuesser}
          edit={EditGuesser}
          create={ProgramBreakCreate}
          recordRepresentation={(record) => record.name}
          options={{ label: "Breaks" }}
        />
        <Resource
          icon={ShieldIcon}
          name="audit-logs"
          options={{ label: "Audit logs" }}
          list={AuditLogList}
          show={ShowGuesser}
          recordRepresentation={(record) => record.name}
        />

        <Resource
          icon={MessageIcon}
          name="dialogs"
          list={DialogList}
          show={ShowGuesser}
          recordRepresentation={(record) => record.name}
        />
        <Resource
          name="dialog-participations"
          recordRepresentation={(record) => record.user_id}
        />

        <Resource
          icon={InsertEmoticonIcon}
          name="clients"
          list={ClientList}
          show={ShowGuesser}
          edit={EditGuesser}
          recordRepresentation={(record) => record.name}
        />
        <Resource
          icon={ImportExportIcon}
          name="client-imports"
          list={ClientImportList}
          create={ClientImportCreate}
        />
        <Resource
          icon={SentimentSatisfiedAltIcon}
          name="coaches"
          list={CoachList}
          show={ShowGuesser}
          recordRepresentation={(record) => record.name}
        >
          <Route path=":clientId/match" element={<CoachMatch />} />
          <Route path=":coachId/default-cv/:section" element={<DefaultCv />} />
          <Route
            path=":coachId/commercial-cv/:section"
            element={<CommercialCv />}
          />
          <Route
            path=":coachId/commercial-cv/:section"
            element={<DefaultCv />}
          />
          <Route path=":coachId/contract-type" element={<ContractType />} />
        </Resource>

        <Resource
          icon={SentimentSatisfiedAltIcon}
          name="change-requests"
          list={ChangeRequestList}
          show={ShowGuesser}
          edit={ChangeRequestEdit}
        />

        <Resource
          icon={AccountBoxIcon}
          name="profiles"
          recordRepresentation={(record) => record.name}
        >
          <Route
            path=":profileId/personal-information"
            element={<PersonalInformationEdit />}
          />
          <Route path=":profileId/photos" element={<PhotosEdit />} />
          <Route path=":profileId/languages" element={<LanguagesEdit />} />
          <Route
            path=":profileId/business-details"
            element={<BusinessDetailsEdit />}
          />
          <Route path=":profileId/group-work" element={<GroupWorkEdit />} />
        </Resource>

        <Resource
          icon={PersonIcon}
          name="users"
          options={{ label: "Users" }}
          list={UserList}
          show={ShowGuesser}
          recordRepresentation={(record) => record.name}
        />

        <Resource
          icon={CorporateFareIcon}
          name="organizations"
          list={OrganizationList}
          show={ShowGuesser}
          edit={EditGuesser}
          recordRepresentation={(record) => record.name}
        />

        <Resource
          icon={QuizIcon}
          name="questionnaires"
          list={QuestionnaireList}
          show={ShowGuesser}
          edit={EditGuesser}
          recordRepresentation={(record) => record.name}
        />
        <Resource
          icon={PollIcon}
          name="surveys"
          list={SurveyList}
          show={ShowGuesser}
          edit={EditGuesser}
        />
        <Resource
          icon={PollIcon}
          name="assignment-submissions"
          options={{ label: "Submissions" }}
          list={ListGuesser}
          show={ShowGuesser}
          edit={EditGuesser}
        />
        <Resource
          icon={PollIcon}
          name="peers"
          options={{ label: "Respondents" }}
          list={ListGuesser}
          show={ShowGuesser}
          edit={EditGuesser}
        />

        <Resource
          icon={PeopleIcon}
          name="program-memberships"
          options={{ label: "Users" }}
          list={ProgramMembershipList}
          show={ShowGuesser}
          recordRepresentation={(record) => record.name}
        />
        <Resource
          icon={PeopleIcon}
          name="permissions"
          options={{ label: "Permissions" }}
          list={PermissionList}
          edit={PermissionEdit}
          create={PermissionCreate}
          recordRepresentation={(record) => record.id}
        />

        <Resource
          icon={DraftsIcon}
          name="email-templates"
          options={{ label: "Templates" }}
          list={EmailTemplateList}
          show={ShowGuesser}
          edit={EmailTemplateEdit}
          create={EmailTemplateCreate}
          recordRepresentation={(record) => record.name}
        />
        <Resource
          name="email-template-models"
          options={{ label: "Template Models" }}
          list={ListGuesser}
          show={ShowGuesser}
          recordRepresentation={(record) => record.display_name}
        />
        <Resource
          icon={EmailIcon}
          name="emails"
          options={{ label: "E-mails" }}
          list={EmailList}
          show={ShowGuesser}
          edit={EditGuesser}
          recordRepresentation={(record) => record.subject}
        />
        <Resource
          icon={AllInboxIcon}
          name="email-batches"
          options={{ label: "Batches" }}
          list={EmailBatchList}
          edit={EmailBatchEdit}
          create={EmailBatchCreate}
        />
        <Resource
          icon={DraftsIcon}
          name="email-templates"
          options={{ label: "Templates" }}
          list={EmailTemplateList}
          show={ShowGuesser}
          edit={EmailTemplateEdit}
          create={EmailTemplateCreate}
          recordRepresentation={(record) => record.name}
        />
        <Resource
          name="email-template-models"
          options={{ label: "Template Models" }}
          list={ListGuesser}
          show={ShowGuesser}
          recordRepresentation={(record) => record.display_name}
        />
        <Resource
          icon={EmailIcon}
          name="emails"
          options={{ label: "E-mails" }}
          list={EmailList}
          show={ShowGuesser}
          edit={EditGuesser}
          recordRepresentation={(record) => record.subject}
        />

        <Resource
          icon={FlagIcon}
          name="countries"
          list={CountryList}
          edit={EditGuesser}
          recordRepresentation={(record: Country) => record.common_name}
        />
        <Resource
          icon={PublicIcon}
          name="regions"
          list={ProfileTraitList({
            model: "region",
            plural: "regions",
          })}
          edit={ProfileTraitEdit}
          create={ProfileTraitCreate}
          recordRepresentation={(record: Region) =>
            record.name[getStoredLocale()]
          }
        />
        <Resource
          icon={WorkspacePremiumIcon}
          name="certification-levels"
          list={ProfileTraitList({
            model: "certification_level",
            plural: "certification-levels",
          })}
          edit={ProfileTraitEdit}
          create={ProfileTraitCreate}
          recordRepresentation={(record: CertificationLevel) => record.name.en}
          options={{ label: "Certification levels" }}
        />
        <Resource
          icon={GroupsIcon}
          name="group-works"
          list={ProfileTraitList({
            model: "group_work",
            plural: "group-works",
          })}
          edit={ProfileTraitEdit}
          create={ProfileTraitCreate}
          recordRepresentation={(record) => record.name.en}
          options={{ label: "Group work types" }}
        />

        <Resource
          icon={TranslateIcon}
          name="language-levels"
          list={ProfileTraitList({
            model: "language_level",
            plural: "language-levels",
          })}
          edit={ProfileTraitEdit}
          create={ProfileTraitCreate}
          recordRepresentation={(record: LanguageLevel) => record.name.en}
          options={{ label: "Language levels" }}
        />
        <Resource
          icon={WorkIcon}
          name="specializations"
          list={ProfileTraitList({
            model: "specialization",
            plural: "specializations",
          })}
          show={ShowGuesser}
          edit={ProfileTraitEdit}
          create={ProfileTraitCreate}
          recordRepresentation={(record: Specialization) =>
            record.name[getStoredLocale()]
          }
        />
        <Resource
          icon={PlumbingIcon}
          name="professions"
          list={ProfileTraitList({
            model: "profession",
            plural: "professions",
          })}
          show={ShowGuesser}
          edit={ProfileTraitEdit}
          create={ProfileTraitCreate}
          recordRepresentation={(record: Profession) =>
            record.name[getStoredLocale()]
          }
          options={{ label: "Occupations" }}
        />
        <Resource
          icon={CallSplitIcon}
          name="branch-experiences"
          list={ProfileTraitList({
            model: "branch_experience",
            plural: "branch-experiences",
          })}
          show={ShowGuesser}
          edit={ProfileTraitEdit}
          create={ProfileTraitCreate}
          recordRepresentation={(record: BranchExperience) =>
            record.name[getStoredLocale()]
          }
          options={{ label: "Branch experiences" }}
        />
        <Resource
          icon={PublicIcon}
          name="timezones"
          show={ShowGuesser}
          recordRepresentation={(record: Timezone) => record.full_name}
        />
        <Resource
          icon={ViewModuleIcon}
          name="learning-modules"
          options={{ label: "Modules" }}
          list={LearningModuleList}
          show={ShowGuesser}
          edit={LearningModuleEdit}
          create={LearningModuleCreate}
          recordRepresentation={(record) => record.name}
        />
        <Resource
          icon={StairsIcon}
          name="learning-step-progresses"
          list={LearningStepProgressList}
          show={ShowGuesser}
          edit={LearningStepProgressEdit}
          create={LearningStepProgressEdit}
        />
        <Resource icon={StairsIcon} name="course-progresses" />
        <Resource
          icon={SchoolIcon}
          name="learning-paths"
          options={{ label: "Paths" }}
          list={LearningPathList}
          show={LearningPathShow}
          edit={LearningPathEdit}
          create={LearningPathCreate}
          recordRepresentation={(record) => record.name}
        />
        <Resource
          icon={StairsIcon}
          name="learning-steps"
          options={{ label: "Steps" }}
          list={LearningStepList}
          show={ShowGuesser}
          edit={LearningStepEdit}
          create={LearningStepCreate}
          recordRepresentation={(record) => record.name}
        />
        <Resource
          icon={StairsIcon}
          name="learning-step-progresses"
          list={LearningStepProgressList}
          show={ShowGuesser}
          edit={LearningStepProgressEdit}
          create={LearningStepProgressEdit}
        />
        <Resource
          icon={FormatListNumberedIcon}
          name="associate-lists"
          list={AssociateListList}
          show={AssociateListShow}
          edit={AssociateListEdit}
          create={AssociateListCreate}
          options={{ label: "Associate lists" }}
        />
        <Resource
          name="associate-list-users"
          list={ListGuesser}
          show={ShowGuesser}
          edit={EditGuesser}
        />
        <Resource icon={StairsIcon} name="course-progresses" />
        <CustomRoutes>
          <Route
            path="/learning-path-progress"
            element={<LearningPathProgressList />}
          />
          <Route
            path="/learning-path-reporting"
            element={<LearningPathReportingList />}
          />
        </CustomRoutes>
      </Admin>
    </UnloqErrorBoundary>
  )
}

export default App
