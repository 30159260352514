import { SelectInput } from "ra-ui-materialui"

export const YearInput = ({
  source,
  fromYear = 1960,
  helperText = "",
  label = "",
  validate = null,
}) => {
  const year = new Date().getFullYear()
  const years = Array.from(
    new Array(year - fromYear + 1),
    (_val, index) => year - index
  )

  const options = years.map((year) => {
    return { id: year, name: year }
  })

  return (
    <SelectInput
      source={source}
      label={label}
      choices={options}
      helperText={helperText}
      translateChoice={false}
      validate={validate}
      fullWidth
    />
  )
}
