import { Edit, SimpleForm } from "react-admin"
import { LearningModuleFormFields } from "./LearningModuleFormFields"

export const LearningModuleEdit = () => (
  <Edit>
    <SimpleForm>
      <LearningModuleFormFields />
    </SimpleForm>
  </Edit>
)
