import { Box } from "@mui/system"
import { FormDataConsumer, minValue, required } from "ra-core"
import { DateInput, SimpleForm, TextInput } from "ra-ui-materialui"

export const ProgramBreakForm = () => {
  return (
    <SimpleForm>
      <Box sx={{ maxWidth: "400px" }}>
        <TextInput source="name" validate={[required()]} fullWidth />
        <DateInput source="start_date" validate={[required()]} fullWidth />
        <FormDataConsumer>
          {({ formData }) => (
            <DateInput
              source="end_date"
              validate={[required(), minValue(formData.start_date)]}
              fullWidth
            />
          )}
        </FormDataConsumer>
      </Box>
    </SimpleForm>
  )
}
