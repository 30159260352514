import { Delete } from "@mui/icons-material"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material"
import { useTranslate } from "ra-core"
import {
  SimpleFormIteratorItemContext,
  useSimpleFormIterator,
} from "ra-ui-materialui"
import { useState } from "react"

export const UnloqRemoveButton = ({ referenceName }) => {
  const translate = useTranslate()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const { remove } = useSimpleFormIterator()

  const title = `${translate(`delete`)} ${translate(
    referenceName
  ).toLowerCase()}`

  const openConfirmDialog = () => {
    setConfirmOpen(true)
  }

  const deleteRow = (index) => {
    remove(index)
  }

  return (
    <>
      <Tooltip title={title}>
        <IconButton sx={{ margin: "0 0.5rem" }} onClick={openConfirmDialog}>
          <Delete color="error" />
        </IconButton>
      </Tooltip>
      <SimpleFormIteratorItemContext.Consumer>
        {({ index }) => {
          return (
            <ConfirmDialog
              title={`${title}?`}
              open={confirmOpen}
              setOpen={setConfirmOpen}
              index={index}
              onConfirm={deleteRow}
            >
              {translate("react.admin.are_you_sure_delete", {
                referenceName: translate(referenceName).toLowerCase(),
              })}
            </ConfirmDialog>
          )
        }}
      </SimpleFormIteratorItemContext.Consumer>
    </>
  )
}

const ConfirmDialog = (props) => {
  const translate = useTranslate()
  const { title, children, open, setOpen, onConfirm, index } = props

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setOpen(false)}
          color="error"
        >
          {translate("btn.cancel")}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false)
            onConfirm(index)
          }}
          color="success"
        >
          {translate("btn.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
