import { SimpleForm } from "ra-ui-materialui"
import { Edit, useNotify, useRedirect } from "react-admin"
import { useParams } from "react-router"

import { ProfilePhotoFields } from "../../components/fields/ProfilePhotoFields"
import { FormToolbar } from "../../components/forms/FormToolbar"
import { ProfileTabs } from "./ProfileTabs"

export const PhotosEdit = ({ user = null }) => {
  const { profileId } = useParams()
  const redirect = useRedirect()
  const notify = useNotify()

  const onSuccess = () => {
    notify(`accounts.edit.flash_success`, { type: "success" })
    redirect(false)
  }

  return (
    <Edit
      resource="profiles"
      id={user ? user.profile_id : profileId}
      redirect={false}
      mutationMode="pessimistic"
      mutationOptions={{ onSuccess }}
      actions={false}
      sx={profileId && { "& .MuiPaper-root": { overflow: "visible" } }}
    >
      {profileId && <ProfileTabs profileId={profileId} />}
      <SimpleForm toolbar={<FormToolbar section="photos" />} px={1}>
        <ProfilePhotoFields />
      </SimpleForm>
    </Edit>
  )
}
