import Image from "mui-image"
import { PdfPreview } from "./PdfPreview"

export const FilePreviewDialog = ({ url }) => {
  const extRegex = /(?:\.([^.]+))?$/
  const extension = extRegex.exec(url)[1]

  if (extension == "pdf") {
    return <PdfPreview url={url} />
  }

  return (
    <Image
      animationDuration={1000}
      src={url}
      style={{
        animation:
          "250ms cubic-bezier(0.7, 0, 0.6, 1) 0s 1 normal none running materialize",
      }}
    />
  )
}
