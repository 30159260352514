/* eslint-disable react/prop-types */
import { Avatar } from "@mui/material"
import {
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  EmailField,
  InfiniteList,
  ReferenceField,
  SelectField,
  TextInput,
  useRecordContext,
} from "react-admin"

const filters = [
  <TextInput
    key="search"
    label="Email"
    source="email"
    alwaysOn
    sx={{ width: "100%" }}
  />,
]

export const CoachList = (props) => {
  const record = useRecordContext()

  return (
    <InfiniteList
      resource="coaches"
      disableSyncWithLocation
      filters={filters}
      {...props}
    >
      <Datagrid>
        {record && <Avatar src={record.avatar_url} alt={record.name} />}
        <ReferenceField
          label="Name"
          source="profile_id"
          reference="profiles"
          link="show"
        />
        <EmailField source="email" />
        <BooleanField source="active" />
        <SelectField source="language" choices={languages} />
        <DateField label="Last login" source="last_login_at" />
        <DateField label="Created" source="created_at" />
        <EditButton />
      </Datagrid>
    </InfiniteList>
  )
}

const languages = [
  { id: "nl", name: "Dutch" },
  { id: "en", name: "English" },
  { id: "fr", name: "French" },
  { id: "es", name: "Spanish" },
]
