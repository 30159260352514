import {
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  List,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  ShowButton,
  useGetIdentity,
  WithListContext,
} from "react-admin"
import { UserFieldBox } from "../../components/fields/UserField"

export const DialogList = () => {
  const identity = useGetIdentity()

  if (!identity) {
    return null
  }

  const filters = [
    <ReferenceInput
      key="programs"
      source="program_id"
      reference="programs"
      alwaysOn
    >
      <AutocompleteInput sx={{ width: "400px" }} />
    </ReferenceInput>,
    <BooleanInput
      key="active"
      source="active"
      label="Show archived"
      alwaysOn
      sx={{
        height: "50px",
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    />,
  ]

  return (
    <List filters={filters}>
      <Datagrid rowClick="show" bulkActionButtons={false} size="medium">
        <ReferenceManyField
          label="Participants"
          reference="dialog-participations"
          target="dialog_id"
        >
          <WithListContext
            render={({ isLoading, data }) =>
              !isLoading && (
                <>
                  {data.map(
                    (participant) =>
                      participant.user_id != identity.data.id && (
                        <UserFieldBox
                          key={participant.id}
                          email={participant.email}
                          firstName={participant.first_name}
                          lastName={participant.last_name}
                          name={participant.name}
                          avatarUrl={participant.avatar_url}
                        />
                      ),
                  )}
                </>
              )
            }
          />
        </ReferenceManyField>
        <ReferenceField
          label="Program"
          source="program_id"
          reference="programs"
          link={false}
        />
        <ReferenceField
          label="Organization"
          source="organization_id"
          reference="organizations"
          link={false}
        />
        <ShowButton />
      </Datagrid>
    </List>
  )
}
