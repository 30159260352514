import { SimpleForm } from "ra-ui-materialui"
import { OrganizationWithProgramSelect } from "../../components/inputs/OrganizationWithProgramSelect"
import { ClientImportFileUploadField } from "./ClientImportFileUploadField"

export const ClientImportForm = () => {
  return (
    <SimpleForm>
      <ClientImportFileUploadField />
      <OrganizationWithProgramSelect />
    </SimpleForm>
  )
}
