import { Button as MuiButton, Dialog, DialogTitle } from "@mui/material"
import {
  FormDataConsumer,
  useDataProvider,
  useNotify,
  useRefresh,
  useTranslate,
} from "ra-core"
import { Button, SimpleForm } from "ra-ui-materialui"
import { useState } from "react"
import { useMutation } from "react-query"

import { AssociateListUser } from "api/models"
import { CoachSelect } from "../inputs/CoachSelect"

export const AddCoachToListButton = ({ listId, coachesIds }) => {
  const translate = useTranslate()
  const [confirmOpen, setConfirmOpen] = useState(false)

  const openConfirmDialog = () => {
    setConfirmOpen(true)
  }

  return (
    <>
      <Button
        label="react.admin.associate_lists.add_coach_button"
        onClick={openConfirmDialog}
      />
      <ConfirmDialog
        title={translate(
          "react.admin.associate_lists.add_coach_to_list_dialog_title"
        )}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        listId={listId}
        coachesIds={coachesIds}
      />
    </>
  )
}

const ConfirmDialog = (props) => {
  const { title, open, setOpen, listId, coachesIds } = props

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="add-coach-to-list-dialog"
    >
      <DialogTitle id="add-coach-to-list-dialog">{title}</DialogTitle>
      <SimpleForm toolbar={null}>
        <CoachSelect source="coach_id" perPage={10} coachIds={coachesIds} />
        <FormDataConsumer>
          {({ formData }) => {
            if (formData.coach_id != null) {
              return (
                <ConfirmButton
                  listId={listId}
                  coachId={formData.coach_id}
                  setOpen={setOpen}
                />
              )
            }
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Dialog>
  )
}

const ConfirmButton = ({ listId, coachId, setOpen }) => {
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const translate = useTranslate()
  const refresh = useRefresh()

  const addCoach = async (): Promise<AssociateListUser> => {
    return await dataProvider
      .create("associate-list-users", {
        data: {
          associate_list_id: listId,
          user_id: coachId,
        },
      })
      .then((result) => result.data)
  }

  const { mutate: addCoachToList, isLoading } = useMutation(addCoach, {
    onSuccess: (data) => {
      if (data && data.id) {
        notify(`react.admin.associate_lists.add_to_list_success`, {
          type: "success",
        })
        setOpen(false)
        refresh()
      } else {
        notify(`react.admin.associate_lists.add_to_list_failed`, {
          type: "error",
        })
      }
    },
  })

  const handleClick = () => {
    addCoachToList()
  }

  return (
    <MuiButton
      variant="contained"
      onClick={handleClick}
      disabled={isLoading}
      color="success"
    >
      {translate("btn.save")}
    </MuiButton>
  )
}
