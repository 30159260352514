/* eslint-disable */

import React, { useEffect } from "react"
import TextField from "@mui/material/TextField"
import MuiAutocomplete from "@mui/material/Autocomplete"

const Autocomplete = ({ submit, initial, label, name, options }) => {
  const [value, setValue] = React.useState<string | null>(null)
  const [selected, setSelected] = React.useState<string>(initial)

  useEffect(() => {
    if (options.length > 0) {
      const initialOption = options.find((item) => item.id == initial)
      if (initialOption) {
        setValue(initialOption)
      }
    }
  }, [options])

  useEffect(() => {
    if (selected != initial) {
      document.getElementById(submit).click()
    }
  }, [selected])

  const updateSelected = (newValue: any) => {
    setValue(newValue)
    if (newValue != null) {
      setSelected(newValue.id)
    } else {
      setSelected("")
    }
  }

  return (
    <>
      <TextField name={name} value={selected} sx={{ display: "none" }} />
      <MuiAutocomplete
        disablePortal
        options={options}
        value={value}
        groupBy={(option) => option.group}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.label}
          </li>
        )}
        onChange={(event: any, newValue: any | null) => {
          updateSelected(newValue)
        }}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </>
  )
}

Autocomplete.displayName = "Autocomplete"
export default Autocomplete
