import {
  AutocompleteArrayInput,
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  List,
  ReferenceField,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin"
import { UserField } from "../../components/fields/UserField"

import { states } from "../../constants/learningStepProgressStates"

const filters = [
  <AutocompleteArrayInput
    key="states"
    source="states"
    choices={states.map((state) => {
      return { id: state, name: state }
    })}
    sx={{ width: "400px" }}
  />,
  <TextInput
    source="email"
    name="email"
    key="email"
    label="E-mail"
    sx={{ width: "400px" }}
  />,
  <BooleanInput
    key="include_in_report"
    source="include_in_report"
    label="Included in report?"
    sx={{
      height: "50px",
      display: "flex",
      justifyContent: "center",
      width: "100%",
    }}
  />,
  <SelectInput
    key="source"
    source="source"
    label="Source"
    choices={[
      { id: "campus", name: "UNLOQ Campus" },
      { id: "teams", name: "Teams Attendance file" },
      { id: "welearn", name: "WeLearn" },
    ]}
    sx={{ width: "400px" }}
  />,
]

export const LearningStepProgressList = () => (
  <List title="Step Progress" filters={filters} perPage={25}>
    <Datagrid rowClick="edit" bulkActionButtons={false} size="medium">
      {/* <NumberField source="id" textAlign="center" /> */}
      <ReferenceField
        source="program_membership_id"
        reference="program-memberships"
        label="Participant"
      >
        <ReferenceField source="user_id" reference="users">
          <UserField />
        </ReferenceField>
      </ReferenceField>
      <ReferenceField source="learning_step_id" reference="learning-steps">
        <TextField source="presentation" />
      </ReferenceField>
      <ReferenceField
        source="learning_step_id"
        reference="learning-steps"
        label="Source"
        sortBy="learning_step.source"
        link={false}
      >
        <TextField source="source" />
      </ReferenceField>
      <ReferenceField
        source="learning_step_id"
        reference="learning-steps"
        label="Include in report?"
        sortBy="learning_step.include_in_report"
        link={false}
        textAlign="center"
      >
        <BooleanField source="include_in_report" textAlign="center" />
      </ReferenceField>
      <ReferenceField
        source="learning_step_id"
        reference="learning-steps"
        label="Module"
        link={false}
      >
        <ReferenceField
          source="learning_module_id"
          reference="learning-modules"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
      </ReferenceField>
      <ReferenceField
        source="learning_step_id"
        reference="learning-steps"
        label="Score"
        sortBy="learning_step.score"
        link={false}
      >
        <TextField source="score" />
      </ReferenceField>
      <TextField source="status" />
      <DateField source="modified_at" />
      <DateField source="created_at" />
      <DateField source="updated_at" showTime />
    </Datagrid>
  </List>
)
