import {
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  SavedQueriesList,
} from "react-admin"
import { Card, CardContent } from "@mui/material"

import MailIcon from "@mui/icons-material/MailOutline"
import AttributionIcon from "@mui/icons-material/Attribution"

export const ClientListFilterSidebar = () => (
  <Card sx={{ order: -1, mr: 2, width: 175 }}>
    <CardContent>
      <FilterLiveSearch sx={{ marginTop: "0px" }} />
      <SavedQueriesList />
      <FilterList label="Status" icon={<MailIcon />}>
        <FilterListItem label="Active" value={{ active: true }} />
        <FilterListItem label="Inactive" value={{ active: false }} />
      </FilterList>
      <FilterList label="Role" icon={<AttributionIcon />}>
        <FilterListItem label="Client" value={{ role: "client" }} />
        <FilterListItem label="Coach" value={{ role: "coach" }} />
      </FilterList>
    </CardContent>
  </Card>
)
