import { Grid, Typography } from "@mui/material"
import { ReferenceManyInput } from "@react-admin/ra-relationships"
import {
  FormDataConsumer,
  required,
  useLocaleState,
  useTranslate,
} from "ra-core"
import {
  AutocompleteInput,
  ReferenceInput,
  SimpleFormIterator,
  TextInput,
} from "ra-ui-materialui"
import { ValidateBeforeAddButton } from "../../components/buttons/ValidateBeforeAddButton"
import { EndDateInput } from "../../components/fields/EndDateInput"
import { YearInput } from "../../components/inputs/YearInput"
import { UnloqRemoveButton } from "../../components/buttons/UnloqRemoveButton"
import { CertificationFileUploadField } from "./CertificationFileUploadField"
import { IRobinChatBubble } from "../../components/forms/IRobinChatBubble"
import { Box } from "@mui/system"

export const CertificationFields = () => {
  const translate = useTranslate()

  return (
    <Box mt={1} width="100%">
      <IRobinChatBubble>
        <Typography variant="h6" mb={1} fontWeight="bold">
          {translate("react.coaches.cv.certifications")}
        </Typography>

        <Typography mb={1}>
          {translate("react.coaches.cv.intro.certifications")}
        </Typography>
      </IRobinChatBubble>

      <ReferenceManyInput
        label={false}
        reference="certifications"
        target="curriculum_vitae_id"
        filter={{ certification_type: "certification" }}
        defaultValue={[
          {
            "@@ra-many/curriculum-vitaes/certifications/curriculum_vitae_id.0.certifications.0.topic":
              "",
          },
        ]}
        sx={{ marginTop: "2rem" }}
      >
        <SimpleFormIterator
          inline
          fullWidth
          disableClear
          sx={{ ["& .RaSimpleFormIterator-line"]: { pb: 3, mb: 3 } }}
          addButton={
            <ValidateBeforeAddButton
              label="react.coaches.cv.fields.certification.add"
              sx={{ color: "#000" }}
            />
          }
          removeButton={
            <UnloqRemoveButton referenceName="react.admin.certification" />
          }
        >
          <CertificationGrid />
        </SimpleFormIterator>
      </ReferenceManyInput>
    </Box>
  )
}

const CertificationGrid = (props) => {
  const [locale] = useLocaleState()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <TextInput
          source={`${props.source}.certification_type`}
          defaultValue="certification"
          style={{ display: "none" }}
        />

        <TextInput
          source={`${props.source}.topic`}
          label="react.coaches.cv.fields.certification.topic"
          helperText="react.coaches.cv.examples.certification.topic"
          validate={required()}
          fullWidth
        />
        <TextInput
          source={`${props.source}.name`}
          label="react.coaches.cv.fields.certification.name"
          helperText="react.coaches.cv.examples.certification.name"
          validate={required()}
          fullWidth
        />
        <TextInput
          label="react.coaches.cv.fields.certification.institution"
          helperText="react.coaches.cv.examples.certification.institution"
          source={`${props.source}.institution`}
          validate={required()}
          fullWidth
        />

        <ReferenceInput
          source={`${props.source}.certification_level_id`}
          reference="certification-levels"
          filter={{ certification_type: "certification" }}
          sort={{ field: `name.${locale}`, order: "ASC" }}
          perPage={300}
          perPageChoices={300}
        >
          <AutocompleteInput
            label="react.coaches.cv.fields.certification.level"
            helperText="react.coaches.select_one_answer"
            size="medium"
            margin="none"
            optionText={`name.${locale}`}
            validate={required()}
          />
        </ReferenceInput>

        <FormDataConsumer>
          {({ getSource }) => {
            return (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <YearInput
                    source={`${props.source}.start_year`}
                    label="react.coaches.cv.fields.certification.start_year"
                    helperText="react.coaches.cv.examples.certification.start_year"
                    validate={required()}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <EndDateInput
                    source={`${props.source}.end_date`}
                    startSource={getSource(`${props.source}.start_year`)}
                    label="react.coaches.cv.fields.certification.end_date"
                    helperText="react.coaches.cv.examples.certification.end_date"
                    slotProps={{
                      field: {
                        id: "end_date",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            )
          }}
        </FormDataConsumer>
      </Grid>

      <Grid item xs={12} md={4} sx={{ mt: 1 }}>
        <CertificationFileUploadField source={props.source} />
      </Grid>
    </Grid>
  )
}
