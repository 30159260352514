/* eslint-disable react/prop-types */
import { RichTextField } from "react-admin"
import styled from "@emotion/styled"

const StyledRichTextFieldContainer = styled.div`
  .mention {
    border: 1px solid #000;
    border-radius: 0.4rem;
    padding: 0.1rem 0.3rem;
    box-decoration-break: clone;
  }

  p {
    margin: 15px 0;
  }
`

export const EmailBodyField = ({ source, ...rest }) => {
  return (
    <StyledRichTextFieldContainer>
      <RichTextField toolbar={null} source={source} fullWidth {...rest} />
    </StyledRichTextFieldContainer>
  )
}
