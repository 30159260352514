import {
  AutocompleteInput,
  BooleanInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from "ra-ui-materialui"
import { FormDataConsumer, required } from "react-admin"

export const LearningStepFormFields = () => {
  const moduleOptionRenderer = (choice) => {
    return `${choice.learning_path_name} - ${choice.name}`
  }

  const alternativeStepOptionRenderer = (choice) =>
    `${choice.name} (${choice.source})`

  return (
    <>
      <TextInput source="presentation" fullWidth />
      <TextInput source="name" validate={required()} fullWidth />
      <SelectInput
        source="source"
        choices={[
          { id: "campus", name: "UNLOQ Campus" },
          { id: "teams", name: "Teams Attendance file" },
          { id: "welearn", name: "WeLearn" },
        ]}
        validate={required()}
        fullWidth
      />
      <TextField source="target_group" label="Target group" />
      <NumberInput source="score" validate={required()} fullWidth />
      <NumberInput source="start_days" fullWidth />
      <ReferenceInput
        source="learning_module_id"
        reference="learning-modules"
        sort={{ field: "learning_path_id", order: "ASC" }}
        sx={{ width: "100%", pt: 0 }}
      >
        <AutocompleteInput
          sx={{ width: "100%" }}
          validate={required()}
          optionText={moduleOptionRenderer}
        />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData }) =>
          formData.learning_module_id && (
            <ReferenceInput
              source="alternative_step_for_id"
              reference="learning-steps"
              filter={{ learning_module_id: formData.learning_module_id }}
              sort={{ field: "name", order: "ASC" }}
              sx={{ width: "100%", pt: 0 }}
            >
              <AutocompleteInput
                sx={{ width: "100%" }}
                optionText={alternativeStepOptionRenderer}
              />
            </ReferenceInput>
          )
        }
      </FormDataConsumer>
      <BooleanInput source="include_in_report" label="Include in report?" />
    </>
  )
}
