import { Typography } from "@mui/material"
import { ReferenceManyInput } from "@react-admin/ra-relationships"
import { required, useTranslate } from "ra-core"
import {
  AutocompleteInput,
  ReferenceInput,
  SimpleFormIterator,
} from "ra-ui-materialui"
import { UnloqRemoveButton } from "../buttons/UnloqRemoveButton"
import { ValidateBeforeAddButton } from "../buttons/ValidateBeforeAddButton"
import { IRobinChatBubble } from "../forms/IRobinChatBubble"
import { Box } from "@mui/system"

export const LanguageFields = () => {
  const translate = useTranslate()

  return (
    <Box mt={2} width="100%">
      <IRobinChatBubble>
        <Typography variant="h6" mb={1} fontWeight="bold">
          {translate("react.admin.languages")}
        </Typography>

        <Typography my={1}>
          {translate("react.coaches.intro.languages")}
        </Typography>
      </IRobinChatBubble>

      <ReferenceManyInput
        label={false}
        reference="profile-languages"
        target="profile_id"
        sort={{ field: "id", order: "ASC" }}
        defaultValue={[
          {
            "@@ra-many/profiles/profile-languages/profile_id.0.profile-languages.0.language_id":
              "",
            "@@ra-many/profiles/profile-languages/profile_id.0.profile-languages.0.language_level_id":
              "",
          },
        ]}
        sx={{ marginTop: "2rem" }}
      >
        <SimpleFormIterator
          inline
          fullWidth
          disableClear
          disableReordering
          sx={{
            "& .RaSimpleFormIterator-line": { border: "none" },
          }}
          addButton={
            <ValidateBeforeAddButton
              label="react.coaches.languages.add"
              sx={{ marginTop: "0.5rem", color: "#000" }}
            />
          }
          removeButton={
            <UnloqRemoveButton referenceName="react.admin.language" />
          }
        >
          <ReferenceInput
            source="language_id"
            reference="languages"
            perPage={1000}
            sort={{ field: "common_name", order: "ASC" }}
          >
            <AutocompleteInput
              size="medium"
              margin="none"
              optionText="common_name"
              label="react.coaches.languages.select"
              validate={required()}
              sx={{
                flexGrow: 1,
                "& .MuiAutocomplete-clearIndicator": {
                  display: "none",
                },
              }}
            />
          </ReferenceInput>
          <ReferenceInput
            source="language_level_id"
            reference="language-levels"
            sort={{ field: "position", order: "ASC" }}
          >
            <AutocompleteInput
              size="medium"
              margin="none"
              optionText="name.en"
              label="react.coaches.languages.select_level"
              validate={required()}
              sx={{
                flexGrow: 1,
                "& .MuiAutocomplete-clearIndicator": {
                  display: "none",
                },
              }}
            />
          </ReferenceInput>
        </SimpleFormIterator>
      </ReferenceManyInput>
    </Box>
  )
}
