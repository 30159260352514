import { useRef, useState } from "react"
import { useQueryClient } from "react-query"
import { ReferenceField, WithRecord } from "react-admin"
import { Button, ImageField } from "ra-ui-materialui"
import { useRecordContext, useTranslate } from "ra-core"
import { Alert, Box, Grid, Typography } from "@mui/material"
import Divider from "@mui/material/Divider"
import { PinturaEditor } from "@pqina/react-pintura"
import CheckIcon from "@mui/icons-material/Check"

import { dataProvider } from "../../dataProvider"
import { PinturaEditorWrapper } from "../../components/fields/PinturaEditorWrapper"

export function AvatarDiff() {
  const translate = useTranslate()
  const queryClient = useQueryClient()
  const [result, setResult] = useState("")
  const [statusText, setStatusText] = useState<string>("")

  const editorRef = useRef<PinturaEditor>(null)

  const record = useRecordContext()

  const mutate = async (url: string, type: string) => {
    const blob = await fetch(url).then((r) => r.blob())

    const myFile = new File([blob], "image.jpeg", {
      type: blob.type,
    })

    let diff: object

    if (type == "avatar") {
      diff = { avatar_picture: { rawFile: myFile } }
    } else if (type == "photo") {
      diff = { photo_picture: { rawFile: myFile } }
    }

    await dataProvider
      .update("profiles", {
        id: record.changeable_id,
        data: diff,
        previousData: record,
      })
      .then(function (response) {
        if (type == "avatar") {
          setStatusText("Avatar updated")
        } else if (type == "photo") {
          setStatusText("Profile photo updated")
        }
        queryClient.invalidateQueries("profiles")
        return response
      })
  }

  return (
    <div style={{ width: "100%" }}>
      <Typography mb={3} fontWeight="bold">
        User has uploaded a new profile photo
      </Typography>

      <div style={{ height: "600px" }}>
        <WithRecord
          render={function (changeRequest) {
            return (
              <PinturaEditorWrapper
                ref={editorRef}
                cropSelectPresetFilter={"landscape"}
                src={changeRequest.avatar.src}
                onProcess={({ dest }) => setResult(URL.createObjectURL(dest))}
                labelButtonExport="Finish editing. Save changes below."
              />
            )
          }}
        />
      </div>

      {!!result.length && (
        <Box>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <img
                src={result}
                style={{ objectFit: "contain", width: "100%", height: "100%" }}
                alt=""
              />
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Button
                    label="Use as profile photo"
                    onClick={() => mutate(result, "photo")}
                  />
                </Grid>
                <Grid item>
                  <Button
                    label="Use as avatar"
                    onClick={() => mutate(result, "avatar")}
                  />
                </Grid>
                {statusText && (
                  <Grid item>
                    <Alert
                      icon={<CheckIcon fontSize="inherit" />}
                      severity="success"
                    >
                      {statusText}
                    </Alert>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}

      <Divider sx={{ my: 3 }} />

      <ReferenceField reference="profiles" source="changeable_id" link={false}>
        <WithRecord
          render={(profile) => {
            return (
              <Grid
                container
                justifyContent="center"
                alignItems="flex-start"
                spacing={2}
                sx={{ textAlign: "center" }}
              >
                <Grid item xs={12} md={6} alignItems="center">
                  {profile.photo_picture != null &&
                  profile.photo_picture.src ? (
                      <>
                      Current profile photo
                        <ImageField
                          source="photo_picture.src"
                          sx={{
                            "& .RaImageField-image": {
                              maxHeight: 300,
                              maxWidth: 300,
                              width: 300,
                              height: 300,
                              objectFit: "contain",
                              margin: "0 auto",
                            },
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="subtitle1"
                          sx={{ marginRight: "1rem" }}
                        >
                          {translate("react.coaches.avatars.photo.no_photo")}
                        </Typography>
                      </>
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                  {profile.avatar_picture_original != null &&
                  profile.avatar_picture_original.src ? (
                      <>
                      Current avatar
                        <ImageField
                          source="avatar_picture_original.src"
                          sx={{
                            "& .RaImageField-image": {
                              maxHeight: 300,
                              maxWidth: 300,
                              width: 300,
                              height: 300,
                              objectFit: "contain",
                              margin: "0 auto",
                            },
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="subtitle1"
                          sx={{ marginRight: "1rem" }}
                        >
                          {translate("react.coaches.avatars.avatar.no_avatar")}
                        </Typography>
                      </>
                    )}
                </Grid>
              </Grid>
            )
          }}
        />
      </ReferenceField>
    </div>
  )
}
