import { useEffect, useState } from "react"
import { AutocompleteInput, ReferenceInput } from "ra-ui-materialui"
import { required, useRecordContext } from "ra-core"

import { dataProvider } from "../../dataProvider"

export const AssociateListSelect = () => {
  const [associateListChoices, setAssociateListChoices] = useState([])

  const OptionRenderer = () => {
    const record = useRecordContext()

    return `${record.name}`
  }

  const inputText = (choice) => `${choice.name}`

  const fetchAssociateListChoices = async () => {
    const { data } = await dataProvider.getList("associate-lists", {
      filter: {},
      pagination: { page: 1, perPage: 2 },
      sort: { field: "id", order: "ASC" },
    })

    return data.map((record) => ({
      id: record.id,
      label: record.name,
    }))
  }

  useEffect(() => {
    if (associateListChoices.length > 0) return

    fetchAssociateListChoices().then((choices) => {
      setAssociateListChoices(choices)
    })
  })

  return (
    <ReferenceInput
      source="associate_list_id"
      reference="associate-lists"
      perPage={100}
      sort={{ field: "name", order: "ASC" }}
    >
      <AutocompleteInput
        size="medium"
        margin="none"
        optionText={<OptionRenderer />}
        inputText={inputText}
        label="react.select.associate_list"
        validate={required()}
        fullWidth
        sx={{ minWidth: "548px" }}
      />
    </ReferenceInput>
  )
}
