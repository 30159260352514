import { FilterPayload, required, useRecordContext } from "ra-core"
import { AutocompleteInput, ReferenceInput } from "ra-ui-materialui"

import { UserFieldBox } from "../fields/UserField"
import { User } from "api/models"

export const CoachSelect = ({
  source,
  filters = null,
  perPage = 100,
  coachIds = [],
}) => {
  const OptionRenderer = () => {
    const record = useRecordContext()

    return (
      <UserFieldBox
        email={record.email}
        firstName={record.first_name}
        lastName={record.last_name}
        name={record.name}
        avatarUrl={record.avatar_url}
      />
    )
  }

  const inputText = (choice: User) => `${choice.name} <${choice.email}>`

  const filterToQuery = (searchText: string) => ({ email: `%${searchText}%` })

  let filter: FilterPayload
  if (coachIds) {
    filter = { ids_not_in: coachIds }
  } else {
    filter = filters
  }

  return (
    <ReferenceInput
      source={source}
      reference="coaches"
      perPage={perPage}
      filter={filter}
    >
      <AutocompleteInput
        size="medium"
        margin="none"
        optionText={<OptionRenderer />}
        inputText={inputText}
        label="react.select.coach"
        validate={required()}
        filterToQuery={filterToQuery}
        fullWidth
        sx={{ minWidth: "548px" }}
      />
    </ReferenceInput>
  )
}
