import { Grid, Typography } from "@mui/material"
import { useContext } from "react"
import { ReferenceField, WithRecord } from "react-admin"

import { Timezone } from "api/models"
import { TimezoneContext } from "../../../context/timezone_context" // TODO: Move into "react_admin" folder
import { TimezoneProvider } from "../../providers/TimezoneProvider"

export const TimezoneGrid = ({ filters }) => {
  const FilterMatchesTimezone = (filters, profileTimezone) => {
    const contextTimezones = useContext(TimezoneContext)

    if (filters == undefined || filters.length == 0) return false

    let match = false

    for (const filter of filters) {
      if (match) break

      const filterTimezoneOffset = contextTimezones.find(
        (contextTimezone: Timezone) => contextTimezone.id === filter
      )

      match = filterTimezoneOffset?.offset == profileTimezone.offset
    }

    return match
  }

  return (
    <>
      {filters.filterValues.timezones?.length > 0 && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          spacing={0}
        >
          <Grid item>
            <ReferenceField
              source="profile_id"
              reference="profiles"
              link={false}
            >
              <ReferenceField
                source="timezone_id"
                reference="timezones"
                link={false}
              >
                <TimezoneProvider>
                  <WithRecord
                    render={(profileTimezone) => {
                      return (
                        <Typography
                          sx={{
                            textAlign: "center",
                            backgroundColor: FilterMatchesTimezone(
                              filters.filterValues.timezones,
                              profileTimezone
                            )
                              ? "yellow"
                              : null,
                          }}
                        >
                          &nbsp;({profileTimezone.name}&nbsp;
                          {profileTimezone.offset})
                        </Typography>
                      )
                    }}
                  />
                </TimezoneProvider>
              </ReferenceField>
            </ReferenceField>
          </Grid>
        </Grid>
      )}
    </>
  )
}
