import { Box } from "@mui/system"
import { useTranslate } from "ra-core"
import { FileField, FileInput } from "ra-ui-materialui"

export const ClientImportFileUploadField = () => {
  const translate = useTranslate()

  return (
    <Box
      mb={2}
      sx={{
        width: "100%",
      }}
    >
      <FileInput
        source="excel_file"
        label={false}
        fullWidth
        helperText="react.admin.client_imports.fields.excel_file.helper_text"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
        placeholder={
          <Box
            sx={{
              fontSize: "1rem",
              color: "grey",
              fontWeight: "400",
            }}
          >
            {translate(
              "react.admin.client_imports.fields.excel_file.placeholder"
            )}
          </Box>
        }
      >
        <FileField source="src" title="title" />
      </FileInput>
    </Box>
  )
}
