import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Grid,
  Typography,
} from "@mui/material"

import AlternateEmailIcon from "@mui/icons-material/AlternateEmail"
import LinkedInIcon from "@mui/icons-material/LinkedIn"

import { useEffect, useState } from "react"

import {
  DateField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from "react-admin"
import { Link } from "react-router-dom"

export const AssociateListShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="cv_purpose" />
        <DateField source="created_at" />
        <DateField source="updated_at" />
      </SimpleShowLayout>
      <Preview />
    </Show>
  )
}

const Preview = () => {
  const record = useRecordContext()

  if (!record) return null

  return <PreviewBox listId={record.id} />
}

const PreviewBox = ({ listId }) => {
  const [users, setUsers] = useState([])

  useEffect(() => {
    const apiUrl =
      document.location.port == "5173"
        ? `http://${document.location.hostname}:3000/en/api/lists/${listId}.json`
        : `/en/api/lists/${listId}.json`

    const request = new Request(apiUrl, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })

    fetch(request, { credentials: "include" }).then((response) => {
      response.json().then((data) => {
        setUsers(data.profiles)
      })
    })
  }, [listId])

  if (users.length == 0) return null

  return (
    <Box padding={2}>
      <Typography variant="h3" mb={3}>
        List Preview
      </Typography>
      <Grid container spacing={2}>
        {users.map((user) => {
          return (
            <Grid item xs={12} md={6} lg={4} xl={3} key={user.id}>
              <Card>
                {user.avatar_url && (
                  <CardMedia sx={{ height: 300 }} image={user.avatar_url} />
                )}
                {!user.avatar_url && <Box sx={{ height: "250px" }} />}
                <CardContent>
                  <Typography fontWeight="bold" variant="h6">
                    {user.name}
                  </Typography>

                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    {user.job_title}
                  </Typography>

                  {user.level && (
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      {user.level}
                    </Typography>
                  )}

                  <Divider sx={{ marginY: "0.5rem" }} />

                  <Box display="flex" alignItems="center" gap={1}>
                    <AlternateEmailIcon fontSize="small" />
                    <Typography sx={{ fontSize: 15 }} color="text.secondary">
                      {user.display_email}
                    </Typography>
                  </Box>

                  {user.linkedin_url && (
                    <Box display="flex" alignItems="center" gap={1}>
                      <LinkedInIcon fontSize="small" />
                      <Typography
                        sx={{ fontSize: 15, a: { color: "text.secondary" } }}
                      >
                        <Link to={user.linkedin_url} target="_blank">
                          {user.linked_in_name}
                        </Link>
                      </Typography>
                    </Box>
                  )}

                  {user.approach && (
                    <>
                      <Divider sx={{ marginY: "0.5rem" }} />
                      <Typography variant="body2" color="text.secondary">
                        {user.approach}
                      </Typography>
                    </>
                  )}

                  {user.type && (
                    <>
                      <Divider sx={{ marginY: "0.5rem" }} />
                      <Box>
                        Type: {user.type} <Divider sx={{ marginY: "0.5rem" }} />
                      </Box>
                    </>
                  )}

                  {user.languages.length > 0 && (
                    <>
                      <Divider sx={{ marginY: "0.5rem" }} />
                      <Typography sx={{ fontSize: 14, marginBottom: "0.3rem" }}>
                        Languages
                      </Typography>
                      {user.languages.map((language) => {
                        return <Chip key={language} label={language} />
                      })}
                    </>
                  )}

                  {user.experience_levels.length > 0 && (
                    <>
                      <Divider sx={{ marginY: "0.5rem" }} />
                      <Typography sx={{ fontSize: 14, marginBottom: "0.3rem" }}>
                        Experience Levels
                      </Typography>
                      {user.experience_levels.map((experience_level) => {
                        return (
                          <Chip
                            key={experience_level}
                            label={experience_level}
                          />
                        )
                      })}
                    </>
                  )}

                  {user.working_area?.length > 0 && (
                    <>
                      <Divider sx={{ marginY: "0.5rem" }} />
                      <Typography sx={{ fontSize: 14, marginBottom: "0.3rem" }}>
                        Working areas
                      </Typography>
                      {user.working_area.map((working_area) => {
                        return <Chip key={working_area} label={working_area} />
                      })}
                    </>
                  )}

                  {user.topics.length > 0 && (
                    <>
                      <Divider sx={{ marginY: "0.5rem" }} />
                      <Typography sx={{ fontSize: 14, marginBottom: "0.3rem" }}>
                        Topics
                      </Typography>
                      {user.topics.map((topic) => {
                        return <Chip key={topic} label={topic} />
                      })}
                    </>
                  )}

                  {user.profession.length > 0 && (
                    <>
                      <Divider sx={{ marginY: "0.5rem" }} />
                      <Typography sx={{ fontSize: 14, marginBottom: "0.3rem" }}>
                        Professions
                      </Typography>
                      {user.profession.map((profession) => {
                        return <Chip key={profession} label={profession} />
                      })}
                    </>
                  )}

                  {user.certifications.length > 0 && (
                    <>
                      <Divider sx={{ marginY: "0.5rem" }} />
                      <Typography sx={{ fontSize: 14, marginBottom: "0.3rem" }}>
                        Certifications
                      </Typography>
                      {user.certifications.map((certification) => {
                        return (
                          <Chip key={certification} label={certification} />
                        )
                      })}
                    </>
                  )}

                  {user.working_area.length > 0 && (
                    <>
                      <Divider sx={{ marginY: "0.5rem" }} />
                      <Typography sx={{ fontSize: 14, marginBottom: "0.3rem" }}>
                        Working area&apos;s
                      </Typography>
                      {user.working_area.map((working_area) => {
                        return <Chip key={working_area} label={working_area} />
                      })}
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}
