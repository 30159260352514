import { Box } from "@mui/material"
import { AppLocationContext } from "@react-admin/ra-navigation"
import { TourProvider } from "@react-admin/ra-tour"
import { profileEditTour } from "./tours/profileEditTour"

import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

import nl from "date-fns/locale/nl"

export const UnloqAppBar = () => <Box sx={{ mb: 0 }} />
export const UnloqMenu = () => <></>

export const InlineLayout = (props) => (
  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nl}>
    <AppLocationContext>
      <TourProvider
        tours={{
          "profiles-edit": profileEditTour,
        }}
      >
        <Box
          sx={{
            height: "100%",
          }}
        >
          <Box>{props.children}</Box>
        </Box>
      </TourProvider>
    </AppLocationContext>
  </LocalizationProvider>
)
