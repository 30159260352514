import { FilterButton, InfiniteList, TopToolbar, useGetOne } from "react-admin"

import { CoachCardsList } from "./CoachCardsList"
import { CoachListFilterTopBar } from "../../components/filters/CoachListFilterTopBar"

import Box from "@mui/material/Box"
import { LanguageLevelProvider } from "../../providers/LanguageLevelProvider"
import { LanguageProvider } from "../../providers/LanguageProvider"

import { useParams } from "react-router"
import { CoachSidebar } from "./CoachSidebar"

const CoachMatchProfile = ({ membership }) => {
  const {
    data: user,
    isLoading,
    error,
  } = useGetOne("users", { id: membership.user_id })
  if (isLoading) {
    return null
  }
  if (error) {
    return <p>ERROR</p>
  }

  return <CoachMatchUser membership={membership} user={user} />
}

const CoachMatchUser = ({ membership, user }) => {
  const {
    data: profile,
    isLoading,
    error,
  } = useGetOne("profiles", { id: user.profile_id })
  if (isLoading) {
    return null
  }
  if (error) {
    return <p>ERROR</p>
  }

  const CoachListActions = () => (
    <TopToolbar>
      <FilterButton
        key="coach_filter_button"
        filters={CoachListFilterTopBar()}
      />
    </TopToolbar>
  )

  const defaultFilters = {
    purposes: "both",
    residence_country_id: [profile.residence_country_id.toString()],
    languages: profile.language_ids.map((languageId) => languageId.toString()),
    program: membership.program_id.toString(),
  }

  return (
    <>
      <InfiniteList
        filterDefaultValues={defaultFilters}
        resource="coaches"
        actions={<CoachListActions />}
        aside={<CoachSidebar user={user} />}
        storeKey={false}
        sx={{
          "& .RaList-content": {
            backgroundColor: "inherit",
            boxShadow: "none",
          },
        }}
      >
        <Box>
          <LanguageLevelProvider>
            <LanguageProvider>
              <CoachCardsList />
            </LanguageProvider>
          </LanguageLevelProvider>
        </Box>
      </InfiniteList>
    </>
  )
}

export const CoachMatch = () => {
  const { clientId } = useParams()

  const {
    data: membership,
    isLoading,
    error,
  } = useGetOne("program-memberships", { id: clientId })
  if (isLoading) {
    return null
  }
  if (error) {
    return <p>ERROR</p>
  }

  return <CoachMatchProfile membership={membership} />
}
