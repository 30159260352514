import {
  Datagrid,
  DeleteWithConfirmButton,
  FileField,
  FunctionField,
  ReferenceField,
  TextField,
  useRecordContext,
  WrapperField,
} from "react-admin"

import { Box } from "@mui/system"
import {
  CreateInDialogButton,
  EditInDialogButton,
  ShowInDialogButton,
} from "@react-admin/ra-form-layout"
import { List } from "@react-admin/ra-rbac"

import ImportExportIcon from "@mui/icons-material/ImportExport"

import { ProgramField } from "../../components/fields/ProgramField"
import { ClientImportForm } from "./ClientImportForm"
import { ClientImportTable } from "./ClientImportTable"

export const ClientImportList = (props) => {
  return (
    <List
      resource="client-imports"
      disableSyncWithLocation
      actions={<CreateImportButton />}
      hasCreate={true}
      {...props}
    >
      <Datagrid rowClick={false} bulkActionButtons={false}>
        <ReferenceField source="organization_id" reference="organizations">
          <ProgramField />
        </ReferenceField>
        <ReferenceField source="program_id" reference="programs">
          <ProgramField />
        </ReferenceField>
        <WrapperField label="Excel file">
          <ExcelFileField />
        </WrapperField>
        <WrapperField label="Clients">
          <FunctionField render={(record) => record.import_lines?.length} />
        </WrapperField>
        <TextField source="status" />
        <TextField source="error" />
        <Box display="flex" justifyContent="end" gap={1}>
          <ImportButton />
          <EditImportButton />
          <DeleteImportButton />
        </Box>
      </Datagrid>
    </List>
  )
}

const CreateImportButton = () => {
  return (
    <Box mb={2}>
      <CreateInDialogButton
        sx={{ "& .MuiDialog-paper": { minWidth: "50%", maxWidth: "80%" } }}
      >
        <ClientImportForm />
      </CreateInDialogButton>
    </Box>
  )
}

const DeleteImportButton = () => {
  const record = useRecordContext()
  if (!record) return null
  if (record.status == "imported") return null

  return <DeleteWithConfirmButton redirect={false} />
}

const EditImportButton = () => {
  const record = useRecordContext()
  if (!record) return null
  if (record.status == "imported") return null

  return (
    <EditInDialogButton
      sx={{ "& .MuiDialog-paper": { minWidth: "50%", maxWidth: "80%" } }}
    >
      <ClientImportForm />
    </EditInDialogButton>
  )
}

const ImportButton = () => {
  const record = useRecordContext()
  if (!record) return null
  if (record.import_lines.length == 0) return null
  if (record.status == "imported") return null

  return (
    <ShowInDialogButton
      label="Import"
      icon={<ImportExportIcon />}
      sx={{ "& .MuiDialog-paper": { minWidth: "50%", maxWidth: "80%" } }}
    >
      <ClientImportTable record={record} />
    </ShowInDialogButton>
  )
}

const ExcelFileField = () => {
  const record = useRecordContext()
  if (!record) return null

  return <FileField source="excel_file" title={record.excel_file?.title} />
}
