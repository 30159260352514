/* eslint-disable react/prop-types */
import { Card, Typography } from "@mui/material"

import {
  BooleanField,
  DateField,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TabbedShowLayout,
  TextField,
  useGetRecordId,
  useRecordContext,
} from "react-admin"
import { SimpleClientList } from "../../components/lists/SimpleClientList"
import { CoachList } from "../../components/lists/SimpleCoachList"

import { ProgramMembershipList } from "../../components/lists/SimpleProgramMembershipList"
import { ProgramBreakList } from "../program-breaks/ProgramBreakList"

export const ProgramShow = (props) => {
  const recordId = useGetRecordId()

  return (
    <Show aside={<Aside />} {...props}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Clients">
          <SimpleClientList programId={recordId} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Coaches">
          <CoachList
            filter={{ program_id: recordId }}
            filterDefaultValues={{ program_id: recordId }}
          />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Matches">
          <ProgramMembershipList
            filter={{ program_id: recordId, role: "client" }}
            filterDefaultValues={{ program_id: recordId, role: "client" }}
          />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Breaks">
          <SimpleShowLayout>
            <ProgramBreakList
              filter={{ program_id: recordId }}
              filterDefaultValues={{ program_id: recordId }}
            />
          </SimpleShowLayout>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  )
}

const Aside = () => {
  const record = useRecordContext()
  return (
    <Card style={{ width: 250, marginLeft: "1em", padding: "1em" }}>
      {record && (
        <>
          <SimpleShowLayout>
            <Typography variant="h6">{record.name}</Typography>
            <TextField source="description" />
            <ReferenceField
              source="organization_id"
              reference="organizations"
              link="show"
            />
            <ReferenceField
              source="questionnaire_id"
              reference="questionnaires"
              link="show"
            />
            <ReferenceField
              source="learning_path_id"
              reference="learning-paths"
              link="show"
            />
            <NumberField source="minutes_per_client" />
            <NumberField source="reminder_days" />
            <BooleanField source="enrollment_open" />
            <DateField source="start_date" />
            <DateField source="end_date" />
          </SimpleShowLayout>
        </>
      )}
    </Card>
  )
}
