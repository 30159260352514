import { Avatar, Box, Typography } from "@mui/material"
import { useRecordContext } from "react-admin"

export const UserField = () => {
  const record = useRecordContext()

  if (record) {
    return (
      <UserFieldBox
        email={record.email}
        firstName={record.first_name}
        lastName={record.last_name}
        name={record.name}
        avatarUrl={record.avatar_url}
      />
    )
  }
}

export const UserFieldBox = ({
  email,
  firstName,
  lastName,
  name,
  avatarUrl,
}) => {
  const record = useRecordContext()

  if (record) {
    return (
      <Box display="flex" sx={{ gap: 2, alignItems: "center" }}>
        <Avatar src={avatarUrl} alt={name}>
          {firstName && firstName[0]}
          {lastName && lastName[0]}
        </Avatar>
        <Box>
          <Typography color="black">{name}</Typography>
          <Box
            sx={{ fontSize: "14px", fontStyle: "italic", color: "grey.600" }}
          >
            {email}
          </Box>
        </Box>
      </Box>
    )
  }
}
