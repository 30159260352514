import { useSelector } from "react-redux"
import { CircularProgress } from "@mui/material"
import { InfoOutlined, RefreshSharp, TrendingUp } from "@mui/icons-material"
import { I18n } from "i18n-js"

import translations from "../locales.json"
import { RootState } from "lib/store"
import { peopleAnalyticsReportChannel } from "lib/event-bus-channels"

const i18n = new I18n(translations)

interface Props {
  regenerate?: boolean
}

const defaultProps = {
  regenerate: false,
}

const GenerateReportButton = (props: Props) => {
  props = { ...defaultProps, ...props }

  const isLoading = useSelector(
    (state: RootState) => state.programMemberships.isLoading
  )

  const renderButtonIcon = () => {
    if (props.regenerate) {
      return <RefreshSharp fontSize="small" sx={{ marginRight: 1 }} />
    } else {
      return <TrendingUp fontSize="small" sx={{ marginRight: 1 }} />
    }
  }

  const renderButtonText = () => {
    if (props.regenerate) {
      return i18n.t("people_analytics.btn_regenerate_report")
    } else {
      return i18n.t("people_analytics.btn_generate_report")
    }
  }

  const renderButtonHelpText = () => {
    if (props.regenerate) {
      return i18n.t("people_analytics.btn_regenerate_report_hint")
    } else {
      return i18n.t("people_analytics.btn_generate_report_hint")
    }
  }

  return (
    <span className="flex items-center justify-center">
      <button
        type="submit"
        className="btn btn--primary"
        value="submit"
        disabled={isLoading}
        onClick={(e) => {
          e.preventDefault()
          peopleAnalyticsReportChannel.emit("onGenerateReportButtonClick", e)
        }}
      >
        {renderButtonIcon()}

        {renderButtonText()}
      </button>
      <div className="tooltip flex relative cursor-pointer ml-2">
        <InfoOutlined fontSize="small" sx={{ marginRight: 1 }} />
        <span className="tooltiptext tooltiptext-left">
          {" "}
          {renderButtonHelpText()}{" "}
        </span>
      </div>
      {isLoading && (
        <CircularProgress
          color="inherit"
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </span>
  )
}

GenerateReportButton.displayName = "GenerateReportButton"

export default GenerateReportButton
