import { Divider } from "@mui/material"
import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import { useTranslate } from "ra-core"
import { useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router"

export const CvTabs = ({ coachId, cvType }) => {
  const location = useLocation()
  const translate = useTranslate()
  const [value, setValue] = useState(0)

  const pages = useMemo(
    () => [
      {
        tab: 0,
        path: "job-information",
        title: translate("react.admin.job_information"),
      },
      {
        tab: 1,
        path: "short-bio",
        title: translate("react.admin.short_bio"),
      },
      {
        tab: 2,
        path: "work-experience",
        title: translate("react.admin.work_experience"),
      },
      {
        tab: 3,
        path: "education",
        title: translate("react.admin.education"),
      },
      {
        tab: 4,
        path: "certifications",
        title: translate("react.admin.certifications"),
      },
      {
        tab: 5,
        path: "affiliations",
        title: translate("react.admin.affiliations"),
      },
      {
        tab: 6,
        path: "publications",
        title: translate("react.admin.publications"),
      },
    ],
    [translate]
  )

  useEffect(() => {
    pages.forEach((page) => {
      if (
        location.pathname == `/coaches/${coachId}/${cvType}-cv/${page.path}`
      ) {
        setValue(page.tab)
      }
    })
  }, [location.pathname, coachId, cvType, pages])

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        aria-label="nav tabs example"
        role="navigation"
        TabIndicatorProps={{
          style: { transition: "none" },
        }}
      >
        {pages.map((page) => (
          <Tab
            onClick={(
              event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
            ) => {
              event.preventDefault()
              if (event.target instanceof HTMLAnchorElement) {
                document.location.hash = event.target.getAttribute("href")
              }
            }}
            key={page.tab}
            label={page.title}
            href={`#/coaches/${coachId}/${cvType}-cv/${page.path}`}
          />
        ))}
      </Tabs>
      <Divider />
    </Box>
  )
}
