import { Create, SimpleForm } from "react-admin"
import { LearningModuleFormFields } from "./LearningModuleFormFields"

export const LearningModuleCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <LearningModuleFormFields />
    </SimpleForm>
  </Create>
)
