import { FunctionField } from "react-admin"

const values = {
  core_team: "Core Team",
  fixed_hours: "Fixed hours",
  project_basis: "Project based",
}

export const ContractTypeField = () => {
  return (
    <FunctionField
      label="react.coaches.cv.contract_type"
      render={(record) => values[record.contract_type]}
    />
  )
}
