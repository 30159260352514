import * as toastr from "toastr"
import { ContentCopy, InfoOutlined } from "@mui/icons-material"
import { useSelector } from "react-redux"
import { I18n } from "i18n-js"

import translations from "../locales.json"
import { RootState } from "lib/store"
import { Box } from "@mui/material"

const i18n = new I18n(translations)

const CopyReportLinkButton = () => {
  const filePath = useSelector(
    (state: RootState) => state.programMemberships.filePath
  )
  const isLoading = useSelector(
    (state: RootState) => state.programMemberships.isLoading
  )

  return (
    <Box
      sx={{ m: 0, position: "relative" }}
      className="flex items-center justify-center"
    >
      {!isLoading && filePath && (
        <>
          <button
            className="btn btn--primary"
            onClick={(e) => {
              e.preventDefault()
              toastr.success(i18n.t("toastrs.copied"))

              navigator.clipboard
                .writeText(filePath)
                .catch(() => toastr.error("An unknown error occurred."))
            }}
          >
            <ContentCopy fontSize="small" sx={{ marginRight: 1 }} />
            {i18n.t("people_analytics.btn_copy_report_link")}
          </button>
          <div className="tooltip flex relative cursor-pointer ml-3">
            <InfoOutlined fontSize="small" sx={{ marginRight: 1 }} />
            <span className="tooltiptext tooltiptext-top">
              {" "}
              {i18n.t("people_analytics.btn_copy_report_link_hint")}{" "}
            </span>
          </div>
        </>
      )}
    </Box>
  )
}

CopyReportLinkButton.displayName = "CopyReportLinkButton"

export default CopyReportLinkButton
