import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import { ReferenceManyToManyInput } from "@react-admin/ra-relationships"
import { canAccess } from "@react-admin/ra-rbac"
import { required, useLocaleState, usePermissions, useTranslate } from "ra-core"
import { AutocompleteArrayInput, TextInput } from "ra-ui-materialui"
import { IRobinChatBubble } from "../../components/forms/IRobinChatBubble"

export const JobInformationFields = () => {
  const [locale] = useLocaleState()
  const translate = useTranslate()
  const { permissions } = usePermissions()

  let canManageAssociates = false
  if (
    canAccess({
      permissions,
      action: "manage_associates",
      resource: "coaches",
    })
  ) {
    canManageAssociates = true
  }

  const validateEnglishJobTitle = (value, allValues) => {
    if (
      allValues.job_title["en"] === null ||
      allValues.job_title["en"].length == 0
    ) {
      return "accounts.curriculum_vitae.job_title_required"
    }
    return undefined
  }
  const validateJobTitle = [required(), validateEnglishJobTitle]

  return (
    <Box mt={1} width="100%">
      <IRobinChatBubble>
        <Typography variant="h6" mb={1} fontWeight="bold">
          {translate("react.admin.job_information")}
        </Typography>

        <Typography mb={1}>
          {translate("react.coaches.intro.job_information")}
        </Typography>
      </IRobinChatBubble>

      <TextInput
        source="job_title.en"
        label="react.coaches.cv.fields.job_title"
        helperText="react.coaches.cv.fields.job_title_helper_text"
        fullWidth
        sx={{ marginTop: "2rem" }}
        validate={validateJobTitle}
      />

      <ReferenceManyToManyInput
        reference="professions"
        through="cv-professions"
        using="curriculum_vitae_id,profession_id"
        sortChoices={{ field: `name.${locale}`, order: "ASC" }}
        perPage={300}
        perPageChoices={300}
      >
        <AutocompleteArrayInput
          label="react.coaches.cv.fields.occupations"
          helperText="react.coaches.multiple_answers_possible"
          optionText={`name.${locale}`}
          validate={canManageAssociates ? null : required()}
          sx={{ width: "100%" }}
        />
      </ReferenceManyToManyInput>

      <ReferenceManyToManyInput
        reference="specializations"
        through="cv-specializations"
        using="curriculum_vitae_id,specialization_id"
        sortChoices={{ field: `name.${locale}`, order: "ASC" }}
        perPage={300}
        perPageChoices={300}
      >
        <AutocompleteArrayInput
          label="react.coaches.cv.fields.specializations"
          helperText="react.coaches.multiple_answers_possible"
          optionText={`name.${locale}`}
          validate={canManageAssociates ? null : required()}
          sx={{ width: "100%" }}
        />
      </ReferenceManyToManyInput>
    </Box>
  )
}
