import {
  CreateBase,
  EditBase,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
} from "ra-core"
import { Edit, SimpleForm } from "ra-ui-materialui"
import { useParams } from "react-router"
import { CvFields } from "../../components/fields/CvFields"
import { FormToolbar } from "../../components/forms/FormToolbar"

export const DefaultCv = () => {
  const { coachId } = useParams()

  return (
    <Edit
      resource="coaches"
      id={coachId}
      redirect={false}
      mutationMode="optimistic"
      actions={null}
      sx={{ "& .MuiPaper-root": { overflow: "visible" } }}
    >
      <DefaultCvBase />
    </Edit>
  )
}

const DefaultCvBase = () => {
  const record = useRecordContext()
  const { section } = useParams()
  const redirect = useRedirect()
  const notify = useNotify()
  const refresh = useRefresh()

  const onSuccess = () => {
    redirect(false)
    refresh()
    notify(`accounts.edit.flash_success`, { type: "success" })
  }

  if (!record) return null

  if (!record.curriculum_vitae_id) {
    return (
      <CreateBase
        resource="curriculum-vitaes"
        redirect={false}
        mutationOptions={{ onSuccess }}
      >
        <SimpleForm toolbar={<FormToolbar section={section} />}>
          <CvFields
            coachId={record.id}
            record={record}
            section={section}
            cvType="default"
          />
        </SimpleForm>
      </CreateBase>
    )
  } else {
    return (
      <EditBase
        resource="curriculum-vitaes"
        id={record.curriculum_vitae_id}
        redirect={false}
        mutationMode="optimistic"
        mutationOptions={{ onSuccess }}
      >
        <SimpleForm toolbar={<FormToolbar section={section} />}>
          <CvFields
            coachId={record.id}
            record={record}
            section={section}
            cvType="default"
          />
        </SimpleForm>
      </EditBase>
    )
  }
}
