import { Box, Checkbox, Grid } from "@mui/material"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Divider from "@mui/material/Divider"
import { ShowInDialogButton } from "@react-admin/ra-form-layout"
import {
  useListContext,
  useListFilterContext,
  useRecordContext,
} from "react-admin"

import { User } from "api/models"
import { JobTitleField } from "../../components//fields/JobTitleField"
import { BranchExperiencesField } from "../../components/fields/BranchExperiencesField"
import { CoachCardField } from "../../components/fields/CoachCardField"
import { ContractTypeField } from "../../components/fields/ContractTypeField"
import { LanguagesField } from "../../components/fields/LanguagesField"
import { LevelCoachingField } from "../../components/fields/LevelCoachingField"
import { LevelEcoachingField } from "../../components/fields/LevelEcoachingField"
import { ProfessionsField } from "../../components/fields/ProfessionsField"
import { ProfileChip } from "../../components/fields/ProfileChipField"
import { ResidenceCountryField } from "../../components/fields/ResidenceCountryField"
import { SpecializationsField } from "../../components/fields/SpecializationsField"
import { TabbedProfileEditor } from "./TabbedProfileEditor"
import { TimezoneGrid } from "./TimezoneGrid"

export const CoachCard = ({ showSelectableCheckbox = false }) => {
  const user: User = useRecordContext()
  const filters = useListFilterContext()
  const { onToggleItem, selectedIds } = useListContext()

  if (user) {
    return (
      <Card
        sx={{
          alignContent: "center",
          alignItems: "center",
          alignSelf: "center",
          height: "100%",
          overflow: "none",
        }}
      >
        <CardContent sx={{ height: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {showSelectableCheckbox && (
                <Checkbox
                  onClick={() => {
                    onToggleItem(user.id)
                  }}
                  checked={selectedIds.includes(user.id)}
                />
              )}
              <ProfileChip filters={filters} />

              {filters.filterValues.length > 0 && <Divider sx={{ my: 2 }} />}

              {filters.filterValues.job_title != undefined && (
                <JobTitleField filters={filters} />
              )}

              {filters.filterValues.timezones != undefined && (
                <TimezoneGrid filters={filters} />
              )}

              {filters.filterValues.residence_country_id != undefined && (
                <CoachCardField label="react.coaches.residence_country">
                  <ResidenceCountryField filters={filters} />
                </CoachCardField>
              )}

              {filters.filterValues.languages?.length > 0 && (
                <CoachCardField label="react.filters.languages">
                  <LanguagesField filters={filters} />
                </CoachCardField>
              )}

              {filters.filterValues.professions?.length > 0 && (
                <CoachCardField label="react.coaches.cv.professions">
                  <ProfessionsField filters={filters} />
                </CoachCardField>
              )}

              {filters.filterValues.specializations?.length > 0 && (
                <CoachCardField label="react.coaches.cv.specializations">
                  <SpecializationsField filters={filters} />
                </CoachCardField>
              )}

              {filters.filterValues.branch_experiences?.length > 0 && (
                <CoachCardField label="react.coaches.cv.branch_experiences">
                  <BranchExperiencesField filters={filters} />
                </CoachCardField>
              )}

              {filters.filterValues.contract_type?.length > 0 && (
                <CoachCardField label="react.coaches.cv.contract_type">
                  <ContractTypeField />
                </CoachCardField>
              )}

              {filters.filterValues.level_ecoaching?.length > 0 && (
                <CoachCardField label="react.coaches.cv.level_ecoaching">
                  <LevelEcoachingField />
                </CoachCardField>
              )}

              {filters.filterValues.level_coaching?.length > 0 && (
                <CoachCardField label="react.coaches.cv.level_coaching">
                  <LevelCoachingField />
                </CoachCardField>
              )}

              <Divider sx={{ my: 2 }} />

              <ViewButton />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }
}

const ViewButton = () => {
  const record = useRecordContext()

  if (!record) return null

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }} mt={3}>
      <ShowInDialogButton
        sx={{
          "& .MuiDialog-paper": {
            padding: "1rem",
            maxWidth: "75%",
            minWidth: "75%",
          },
        }}
        label="View"
      >
        <TabbedProfileEditor record={record} />
      </ShowInDialogButton>
    </Box>
  )
}
