import {
  AutocompleteArrayInput,
  BooleanField,
  BulkExportButton,
  Datagrid,
  InfiniteList,
  NumberField,
  ReferenceField,
  TextInput,
  WrapperField,
} from "react-admin"

import { BulkEmailBatchAction } from "../../components/bulk_actions/BulkEmailBatchAction"
import { UserField } from "../../components/fields/UserField"
import { ClientListFilterSidebar } from "../../components/filters/ClientListFilterSidebar"

import { countries } from "countries-list"
import { ProgramSelect } from "../../components/inputs/ProgramSelect"

const countryChoices = []
Object.keys(countries).forEach(function (key) {
  countryChoices.push({ id: key, name: countries[key].name })
})

const filters = [
  <ProgramSelect key="program" />,
  <AutocompleteArrayInput
    source="countries"
    key="countries"
    label="Countries"
    choices={countryChoices}
    sx={{ width: "500px" }}
  />,
  <TextInput
    source="client_name"
    name="client_name"
    key="client_name"
    label="User name"
    sx={{ width: "400px" }}
  />,
  <TextInput
    source="organization_name"
    name="organization_name"
    key="organization_name"
    label="Organization name"
    sx={{ width: "400px" }}
  />,
]

export const ProgramMembershipList = (props) => (
  <InfiniteList
    resource="program-memberships"
    filters={filters}
    filter={{ role: "client" }}
    aside={<ClientListFilterSidebar />}
    {...props}
  >
    <Datagrid
      rowClick="toggleSelection"
      size="medium"
      bulkActionButtons={
        <>
          <BulkEmailBatchAction
            resourceType="Program::Membership"
            resourceName={"program-memberships"}
          />
          <BulkExportButton />
        </>
      }
    >
      <ReferenceField source="user_id" reference="users" link={false}>
        <UserField />
      </ReferenceField>
      <ReferenceField
        source="main_coach_id"
        reference="coaches"
        label="Coach"
        link={false}
      >
        <UserField />
      </ReferenceField>
      <ReferenceField source="program_id" reference="programs" link={false} />
      <BooleanField source="active" />
      <WrapperField label="Extra">
        <NumberField source="extra_minutes_for_client" />
        &nbsp;min
      </WrapperField>
    </Datagrid>
  </InfiniteList>
)
