/* eslint-disable react/prop-types */
import { Avatar, Card, CardContent } from "@mui/material"
import {
  BooleanField,
  BulkExportButton,
  Datagrid,
  DateField,
  EmailField,
  InfiniteList,
  ReferenceField,
  SelectField,
  useRecordContext,
} from "react-admin"
import { BulkEmailBatchAction } from "../../components/bulk_actions/BulkEmailBatchAction"
import { ClientListFilterSidebar } from "../../components/filters/ClientListFilterSidebar"

const languages = [
  { id: "nl", name: "Dutch" },
  { id: "en", name: "English" },
  { id: "fr", name: "French" },
  { id: "es", name: "Spanish" },
]

const filters = []

export const ClientList = (props) => {
  const record = useRecordContext()

  return (
    <Card>
      <CardContent>
        <InfiniteList
          resource="clients"
          filters={filters}
          aside={<ClientListFilterSidebar />}
          {...props}
        >
          <Datagrid
            title="Clients"
            bulkActionButtons={
              <>
                <BulkEmailBatchAction
                  resourceType="User"
                  resourceName={"clients"}
                />
                <BulkExportButton />
              </>
            }
          >
            {record && <Avatar src={record.avatar_url} alt={record.name} />}
            <ReferenceField
              label="Name"
              source="profile_id"
              reference="profiles"
              link="show"
            />
            <EmailField source="email" />
            <BooleanField source="active" />
            <SelectField source="language" choices={languages} />
            <DateField label="Last login" source="last_login_at" />
            <DateField label="Created" source="created_at" />
          </Datagrid>
        </InfiniteList>
      </CardContent>
    </Card>
  )
}
