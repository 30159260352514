import { Grid, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { DateInput } from "@react-admin/ra-form-layout"
import { ReferenceManyToManyInput } from "@react-admin/ra-relationships"
import { FormDataConsumer, required } from "ra-core"
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  ReferenceInput,
  TextInput,
} from "ra-ui-materialui"
import { maxValue, regex, useTranslate } from "react-admin"

import { IRobinChatBubble } from "../forms/IRobinChatBubble"
import { PhoneNumberInput } from "../inputs/PhoneNumberInput"

const NameFields = () => {
  const translate = useTranslate()

  return (
    <Box mt={2}>
      <IRobinChatBubble>
        <Typography variant="h6" mb={1} fontWeight="bold">
          {translate("react.admin.personal_information")}
        </Typography>
        <Box
          component="span"
          dangerouslySetInnerHTML={{
            __html: translate("react.coaches.intro.personal_information"),
          }}
          sx={{ a: { color: "#000", textUnderlineOffset: "3px" } }}
        />
      </IRobinChatBubble>

      <Box mt={3} sx={{ display: "flex", gap: 2 }}>
        <TextInput
          source="first_name"
          fullWidth
          label="react.coaches.first_name"
          validate={required()}
        />
        <TextInput
          source="last_name"
          fullWidth
          label="react.coaches.last_name"
          validate={required()}
        />
      </Box>
    </Box>
  )
}

const TimezoneField = () => {
  return (
    <ReferenceInput
      source="timezone_id"
      reference="timezones"
      perPage={200}
      sort={{ field: "name", order: "ASC" }}
    >
      <AutocompleteInput
        fullWidth
        size="small"
        optionText={function (record) {
          return `${record.name} [${record.offset}]`
        }}
        label="react.coaches.timezone"
        validate={required()}
      />
    </ReferenceInput>
  )
}

const PersonOriginFields = () => {
  const translate = useTranslate()

  const validateUrl = regex(
    /^https:\/\/[a-z]{2,3}\.linkedin\.com\/.*$/,
    translate("react.admin.validation.linkedin.invalid")
  )

  const today = new Date()
  const validateDateOfBirth = [
    maxValue(
      `${today.getFullYear() - 16}-01-01`,
      "react.admin.validation.date_of_birth.not_adult"
    ),
    required(),
  ]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <ReferenceInput
          source="residence_country_id"
          reference="countries"
          sort={{ field: "common_name", order: "ASC" }}
          perPage={300}
        >
          <AutocompleteInput
            fullWidth
            label="react.coaches.residence_country"
            validate={required()}
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          source="place_of_birth"
          label="react.coaches.place_of_birth"
          fullWidth
          validate={required()}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          source="native_background"
          label="react.coaches.native_background"
          fullWidth
          validate={required()}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ReferenceManyToManyInput
          reference="nationalities"
          through="profile-nationalities"
          using="profile_id,country_id"
          sortChoices={{ field: "nationality", order: "ASC" }}
          perPage={300}
          perPageChoices={300}
        >
          <AutocompleteArrayInput
            label="react.coaches.nationalities"
            optionText={"nationality"}
            helperText="react.coaches.multiple_answers_possible"
            sx={{ width: "100%" }}
            validate={required()}
          />
        </ReferenceManyToManyInput>
      </Grid>
      <Grid item xs={12} md={6}>
        <TimezoneField />
      </Grid>
      <Grid item xs={12} md={6}>
        <DateInput
          desktopModeMediaQuery="@media(min-width: 90px)"
          source="date_of_birth"
          label="react.coaches.date_of_birth"
          helperText="react.admin.internal_only"
          validate={validateDateOfBirth}
          fullWidth
          slotProps={{
            actionBar: {
              actions: ["today", "clear"],
            },
            field: {
              clearable: true,
              id: "date_of_birth",
            },
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          source="id_number"
          fullWidth
          label="react.coaches.id_number"
          helperText="react.admin.internal_only"
          validate={required()}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          source="linkedin"
          fullWidth
          validate={[validateUrl, required()]}
          label="react.coaches.linkedin"
        />
      </Grid>
      <PhoneInputFields />
    </Grid>
  )
}

const PhoneInputFields = () => {
  const translate = useTranslate()

  return (
    <FormDataConsumer>
      {({ formData }) => {
        return (
          <>
            <Grid item xs={12} md={6}>
              <PhoneNumberInput
                residenceCountryId={formData.residence_country_id}
                source="phone_number"
                label={translate("react.coaches.phone_number")}
                helperText={translate("react.admin.internal_only")}
                sx={{ marginBottom: "1rem" }}
                fullWidth
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <PhoneNumberInput
                residenceCountryId={formData.residence_country_id}
                source="secondary_phone_number"
                label={translate("react.coaches.secondary_phone_number")}
                helperText={translate("react.admin.internal_only")}
                sx={{ marginBottom: "1rem" }}
                fullWidth
              />
            </Grid>
          </>
        )
      }}
    </FormDataConsumer>
  )
}

export const ProfileFields = () => {
  return (
    <Box width="100%">
      <NameFields />
      <PersonOriginFields />
    </Box>
  )
}
