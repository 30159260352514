import { Button, InputAdornment } from "@mui/material"
import { useTranslatableContext } from "ra-core"
import { TextInput } from "ra-ui-materialui"
import { useController, useFormContext } from "react-hook-form"

export const DeeplTranslatableTextInput = ({
  sourceField,
  source,
  ...rest
}) => {
  const { selectedLocale } = useTranslatableContext()
  const { field } = useController({ name: `${sourceField}.en` })
  const { setValue } = useFormContext()

  const handleClick = () => {
    const apiUrl =
      document.location.port == "5173"
        ? `http://${document.location.hostname}:3000/api/v1/deepl/translate`
        : "/api/v1/deepl/translate"

    const request = new Request(apiUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ text: field.value, locale: selectedLocale }),
    })

    fetch(request, { credentials: "include" }).then((response) => {
      response.json().then((data) => {
        setValue(`${sourceField}.${selectedLocale}`, data["translation"], {
          shouldDirty: true,
        })
      })
    })
  }

  const adornment = {
    endAdornment: (
      <InputAdornment position="end">
        <Button onClick={handleClick}>Translate from English</Button>
      </InputAdornment>
    ),
  }

  return (
    <TextInput
      source={source}
      InputProps={
        selectedLocale != "en" && field.value != null ? adornment : null
      }
      {...rest}
    />
  )
}
