import { FormDataConsumer } from "ra-core"
import { AutocompleteInput, ReferenceInput } from "ra-ui-materialui"

export const OrganizationWithProgramSelect = () => {
  return (
    <>
      <ReferenceInput
        source="organization_id"
        reference="organizations"
        perPage={100}
        sort={{ field: "name", order: "ASC" }}
        alwaysOn
      >
        <AutocompleteInput
          size="medium"
          margin="none"
          label="react.filters.organization"
          fullWidth
        />
      </ReferenceInput>
      <FormDataConsumer source="organization_id" alwaysOn>
        {({ formData }) => {
          if (formData.organization_id == undefined) return null

          return (
            <ReferenceInput
              source="program_id"
              reference="programs"
              perPage={100}
              filter={{ organization_id: formData.organization_id }}
              sort={{ field: "name", order: "ASC" }}
              alwaysOn
            >
              <AutocompleteInput
                size="medium"
                margin="none"
                label="react.select.program"
                fullWidth
              />
            </ReferenceInput>
          )
        }}
      </FormDataConsumer>
    </>
  )
}
