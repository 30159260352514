/* eslint-disable react/prop-types */
import { Avatar, Box, TableCell, TableHead, TableRow } from "@mui/material"

import {
  Datagrid,
  EmailField,
  InfiniteList,
  useRecordContext,
} from "react-admin"

export const EmailReceiverList = ({
  resourceName,
  resourceType,
  userIds,
  ...rest
}) => {
  const record = useRecordContext()

  const DatagridHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell>Receivers</TableCell>
      </TableRow>
    </TableHead>
  )

  return (
    <InfiniteList
      resource={resourceName}
      disableSyncWithLocation
      filter={{ ids: userIds }}
      actions={false}
      perPage={50}
      sx={{
        bgcolor: "#f5f5f5",
        ml: 4,
      }}
      exporter={false}
      title={<></>}
      {...rest}
    >
      <Datagrid bulkActionButtons={false} header={<DatagridHeader />}>
        {record && resourceType == "Program::Membership" && (
          <Box display="flex" alignItems="center" gap={2}>
            <Avatar src={record.avatar_url} alt={record.client_name} />
            <EmailField source="client_email" sortable={false} />
          </Box>
        )}
        {record && resourceType != "Program::Membership" && (
          <Box display="flex">
            <Avatar src={record.avatar_url} alt={record.name} />
            <EmailField source="email" sortable={false} />
          </Box>
        )}
      </Datagrid>
    </InfiniteList>
  )
}
