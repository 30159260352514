import MenuIcon from "@mui/icons-material/Menu"
import { TextField } from "react-admin"
import {
  Datagrid,
  NumberField,
  ReferenceOneField,
  DeleteWithConfirmButton,
} from "ra-ui-materialui"

import { UserField } from "../../components/fields/UserField"
import { AssociateDatagridBody } from "./AssociateDatagridBody"

export const AssociateDatagrid = () => {
  return (
    <Datagrid
      bulkActionButtons={false}
      body={<AssociateDatagridBody />}
      id="associate-users-list"
      sx={{
        '& .column-id': { display: 'none' },
      }}
    >
      <MenuIcon sx={{ cursor: "move" }} className="handle" />
      <TextField source="id" />
      <NumberField source="position" label="Pos" textAlign="center" />
      <ReferenceOneField reference="users" source="user_id" target="id">
        <UserField />
      </ReferenceOneField>
      <DeleteWithConfirmButton
        confirmTitle="react.admin.associate_lists.confirm_delete"
        redirect={false}
      />
    </Datagrid>
  )
}
