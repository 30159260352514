import { useRef, useState } from "react"
import { usePdf } from "@mikecousins/react-pdf"
import { Box, CircularProgress } from "@mui/material"
import { useTranslate } from "ra-core"

export const PdfPreview = ({ url }) => {
  const [page, setPage] = useState(1)
  const canvasRef = useRef(null)
  const translate = useTranslate()

  const { pdfDocument } = usePdf({
    file: url,
    page,
    canvasRef,
    workerSrc: window.location.origin + "/pdf.worker.js",
  })

  return (
    <Box sx={{ textAlign: "center" }}>
      {!pdfDocument && (
        <CircularProgress
          className="align-middle text-unloq-brightgreen"
          color="inherit"
        />
      )}

      {!pdfDocument && (
        <h2>{translate("react.admin.files.preview_loading")}</h2>
      )}

      <canvas className="pdf-renderer" ref={canvasRef} />

      {Boolean(pdfDocument && pdfDocument.numPages) && (
        <Box className="inline-flex rounded-md py-6" role="group">
          <Box>
            <button
              type="button"
              className="btn btn--secondary"
              style={{ borderWidth: 2 }}
              disabled={page === 1}
              onClick={() => setPage(page - 1)}
            >
              {translate("views.pagination.previous")}
            </button>
          </Box>
          <Box className="pl-4">
            <button
              type="button"
              className="btn btn--secondary"
              style={{ borderWidth: 2 }}
              disabled={page === pdfDocument.numPages}
              onClick={() => setPage(page + 1)}
            >
              {translate("views.pagination.next")}
            </button>
          </Box>
        </Box>
      )}
    </Box>
  )
}
