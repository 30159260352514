import { Grid, Typography } from "@mui/material"
import { ReferenceOneInput } from "@react-admin/ra-relationships"
import { TextInput } from "ra-ui-materialui"
import { required, useTranslate } from "react-admin"
import { CountryWithRegionInput } from "../inputs/CountryWithRegionInput"
import { Box } from "@mui/system"
import { IRobinChatBubble } from "../forms/IRobinChatBubble"

const AddressInputs = (props) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextInput
            source={`${props.source}.company`}
            fullWidth
            label="react.coaches.address.company"
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            source="chamber_of_commerce"
            label="react.coaches.chamber_of_commerce"
            helperText="react.coaches.address.helper_texts.chamber_of_commerce"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            source={`${props.source}.bank_account_number`}
            fullWidth
            label="react.coaches.address.bank_account_number"
            helperText="react.coaches.address.helper_texts.bank_account_number"
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            source={`${props.source}.bank_name`}
            fullWidth
            label="react.coaches.address.bank_name"
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            source={`${props.source}.bank_identification`}
            fullWidth
            label="react.coaches.address.bank_identification"
            helperText="react.coaches.address.helper_texts.bank_identification"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            source={`${props.source}.account_holder_name`}
            fullWidth
            label="react.coaches.address.account_holder_name"
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            source={`${props.source}.address`}
            fullWidth
            label="react.admin.business_address"
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            source={`${props.source}.house_number`}
            fullWidth
            label="react.coaches.address.house_number"
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            source={`${props.source}.zip_code`}
            fullWidth
            label="react.coaches.address.zip_code"
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            source={`${props.source}.city`}
            fullWidth
            label="react.coaches.address.city"
            validate={required()}
          />
        </Grid>
        <CountryWithRegionInput {...props} />
      </Grid>
    </>
  )
}

export const AddressFields = () => {
  const translate = useTranslate()

  return (
    <Box mt={2} width="100%">
      <IRobinChatBubble>
        <Typography variant="h6" mb={1} fontWeight="bold">
          {translate("react.admin.addresses")}
        </Typography>

        <Typography my={1}>
          <Box
            component="span"
            dangerouslySetInnerHTML={{
              __html: translate("react.coaches.intro.addresses"),
            }}
          />
        </Typography>
      </IRobinChatBubble>

      <ReferenceOneInput
        label=""
        reference="addresses"
        source="user_id"
        target="user_id"
        sx={{ marginTop: "1.5rem" }}
        fullWidth
      >
        <AddressInputs />
      </ReferenceOneInput>
    </Box>
  )
}
