import { Create } from "ra-ui-materialui"

import { AssociateListForm } from "./AssociateListForm"

export const AssociateListCreate = () => {
  return (
    <Create title="react.admin.associate_lists.create">
      <AssociateListForm />
    </Create>
  )
}
