import { Create, SimpleForm, TranslatableInputs } from "react-admin"
import { DeeplTranslatableTextInput } from "../../components/inputs/DeeplTranslatableTextInput"

export const ProfileTraitCreate = () => (
  <Create>
    <SimpleForm>
      <TranslatableInputs
        locales={["en", "es", "fr", "nl"]}
        defaultLocale="en"
        fullWidth
      >
        <DeeplTranslatableTextInput
          sourceField="name"
          source="name"
          sx={{ marginTop: "2rem" }}
        />
      </TranslatableInputs>
    </SimpleForm>
  </Create>
)
