import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useRecordContext, useTranslate, WithRecord } from "ra-core"
import { IRobinChatBubble } from "../../components/forms/IRobinChatBubble"
import { ProfileBioChangeRequest } from "../../components/fields/ProfileBioChangeRequest"

export const BioFields = () => {
  const translate = useTranslate()
  const record = useRecordContext()

  let bio_intro
  if (record) {
    bio_intro = translate("react.coaches.intro.bio")
  } else {
    bio_intro = translate("react.coaches.intro.bio_no_cv_yet")
  }

  return (
    <Box mt={1} width="100%">
      <IRobinChatBubble>
        <Typography variant="h6" mb={1} fontWeight="bold">
          {translate("react.coaches.cv.fields.approach")}
        </Typography>

        <Typography>
          <Box
            component="span"
            dangerouslySetInnerHTML={{
              __html: bio_intro,
            }}
            sx={{
              marginBottom: "1rem",
              a: {
                color: "#000",
                textUnderlineOffset: "3px",
              },
              ul: {
                marginBottom: "1rem",
                paddingTop: "6px",
                listStyle: "inside",
              },
            }}
          />
        </Typography>
      </IRobinChatBubble>

      <WithRecord
        render={function (curriculumVitae) {
          return <ProfileBioChangeRequest curriculumVitae={curriculumVitae} />
        }}
      />
    </Box>
  )
}
