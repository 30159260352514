import { TabbedShowLayout } from "ra-ui-materialui"
import { ContractType } from "./ContractType"
import { CvAccordion } from "./CvAccordion"
import { ProfileAccordion } from "./ProfileAccordion"

export const TabbedProfileEditor = ({ record }) => {
  return (
    <TabbedShowLayout
      syncWithLocation={false}
      sx={{ "& .RaTabbedShowLayout-content": { padding: "24px 0px 16px 0px" } }}
    >
      <TabbedShowLayout.Tab label="Profile">
        <ProfileAccordion user={record} />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Default CV">
        <CvAccordion user={record} cvType="default" />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Commercial CV">
        <CvAccordion user={record} cvType="commercial" />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Contract">
        <ContractType user={record} />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  )
}
