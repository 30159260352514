import { useGetOne, useInput } from "react-admin"
import { MuiTelInput } from "mui-tel-input"

export const PhoneNumberInput = (props) => {
  const { onChange, onBlur, label, residenceCountryId, ...rest } = props
  const {
    field,
    fieldState: { isTouched, isDirty, invalid, error },
    formState: { isSubmitted },
  } = useInput({
    onChange,
    onBlur,
    ...rest,
  })

  if (residenceCountryId && (isDirty || field.value.length == 0)) {
    return (
      <ResidenceCountryBasedPhoneNumberInput
        residenceCountryId={residenceCountryId}
        field={field}
        label={label}
        isTouched={isTouched}
        isSubmitted={isSubmitted}
        invalid={invalid}
        error={error}
        rest={rest}
      />
    )
  }

  return (
    <MuiTelInput
      {...field}
      label={label}
      error={(isTouched || isSubmitted) && invalid}
      helperText={(isTouched || isSubmitted) && invalid ? error : ""}
      {...rest}
    />
  )
}

const ResidenceCountryBasedPhoneNumberInput = ({
  residenceCountryId,
  field,
  label,
  isTouched,
  isSubmitted,
  invalid,
  error,
  rest,
}) => {
  const {
    data: country,
    isLoading,
    error: countryError,
  } = useGetOne("countries", { id: residenceCountryId })
  if (isLoading) {
    return null
  }
  if (countryError) {
    return <p>ERROR</p>
  }

  return (
    <MuiTelInput
      {...field}
      label={label}
      defaultCountry={country.code}
      error={(isTouched || isSubmitted) && invalid}
      helperText={(isTouched || isSubmitted) && invalid ? error : ""}
      {...rest}
    />
  )
}
