import { Grid, Typography } from "@mui/material"
import { ReferenceManyInput } from "@react-admin/ra-relationships"
import { required, useTranslate } from "ra-core"
import { SimpleFormIterator, TextInput } from "ra-ui-materialui"
import { UnloqRemoveButton } from "../../components/buttons/UnloqRemoveButton"
import { ValidateBeforeAddButton } from "../../components/buttons/ValidateBeforeAddButton"
import { IRobinChatBubble } from "../../components/forms/IRobinChatBubble"
import { Box } from "@mui/system"

export const AffiliationFields = () => {
  const translate = useTranslate()

  return (
    <Box mt={1} width="100%">
      <IRobinChatBubble>
        <Typography variant="h6" mb={1} fontWeight="bold">
          {translate("react.coaches.cv.affiliations")}
        </Typography>

        <Typography mb={1}>
          {translate("react.coaches.cv.intro.affiliations")}
        </Typography>
      </IRobinChatBubble>

      <ReferenceManyInput
        label={false}
        reference="affiliations"
        target="curriculum_vitae_id"
        defaultValue={[
          {
            "@@ra-many/curriculum-vitaes/affiliations/curriculum_vitae_id.0.affiliations.0.acronym":
              "",
          },
        ]}
        sx={{ marginTop: "2rem" }}
      >
        <SimpleFormIterator
          inline
          disableClear
          fullWidth
          sx={{ ["& .RaSimpleFormIterator-line"]: { pb: 2, mb: 2 } }}
          addButton={
            <ValidateBeforeAddButton
              label="react.coaches.cv.fields.affiliation.add"
              sx={{ marginTop: "1rem" }}
            />
          }
          removeButton={
            <UnloqRemoveButton referenceName="react.admin.affiliation" />
          }
        >
          <AffiliationGrid />
        </SimpleFormIterator>
      </ReferenceManyInput>
    </Box>
  )
}

const AffiliationGrid = (props) => {
  return (
    <Grid container columnSpacing={2} sx={{ width: "100%" }}>
      <Grid item xs={12} md={2}>
        <TextInput
          label="react.coaches.cv.fields.affiliation.acronym"
          helperText="react.coaches.cv.examples.affiliation.acronym"
          source={`${props.source}.acronym`}
          validate={required()}
          fullWidth
          sx={{ flexGrow: 1 }}
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <TextInput
          source={`${props.source}.name`}
          label="react.coaches.cv.fields.affiliation.name"
          helperText="react.coaches.cv.examples.affiliation.name"
          validate={required()}
          fullWidth
          sx={{ flexGrow: 1 }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextInput
          source={`${props.source}.number`}
          label="react.coaches.cv.fields.affiliation.number"
          helperText="react.coaches.cv.examples.affiliation.number"
          validate={required()}
          fullWidth
          sx={{ flexGrow: 1 }}
        />
      </Grid>
    </Grid>
  )
}
