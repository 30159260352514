import {
  AutocompleteInput,
  CloneButton,
  Datagrid,
  EditButton,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  ReferenceManyCount,
  TextField,
  WrapperField,
} from "react-admin"
import { ColorField } from "../../components/fields/ColorField"

const filters = [
  <ReferenceInput
    key="learning_path"
    source="learning_path_id"
    reference="learning-paths"
    alwaysOn
  >
    <AutocompleteInput sx={{ width: "400px" }} />
  </ReferenceInput>,
]

export const LearningModuleList = () => (
  <List perPage={25} filters={filters}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <NumberField source="position" textAlign="center" />
      <TextField source="name" />
      <ReferenceField
        label="Path"
        source="learning_path_id"
        reference="learning-paths"
        link="show"
      />
      <ReferenceManyCount
        label="Steps"
        reference="learning-steps"
        target="learning_module_id"
        textAlign="center"
        link
      />
      <WrapperField label="Color (dark)" textAlign="center">
        <ColorField source="color_dark" />
      </WrapperField>
      <WrapperField label="Color (light)" textAlign="center">
        <ColorField source="color_light" />
      </WrapperField>
      <NumberField source="start_days" textAlign="center" />
      <NumberField source="end_days" textAlign="center" />
      <CloneButton />
      <EditButton />
    </Datagrid>
  </List>
)
