/* eslint-disable react/prop-types */
import styled from "@emotion/styled"

import { DefaultEditorOptions, RichTextInput } from "ra-input-rich-text"
import { useEffect, useState } from "react"
import { useDataProvider } from "react-admin"

import Document from "@tiptap/extension-document"
import Heading from "@tiptap/extension-heading"
import Image from "@tiptap/extension-image"
import Paragraph from "@tiptap/extension-paragraph"
import Placeholder from "@tiptap/extension-placeholder"
import Text from "@tiptap/extension-text"
import Mention from "@tiptap/extension-mention"
import HardBreak from "@tiptap/extension-hard-break"
import Bold from "@tiptap/extension-bold"
import Italic from "@tiptap/extension-italic"

import { emailTemplateVariables } from "../tiptap/EmailTemplateVariables"

import { EmailTemplateContentNode } from "../tiptap/nodes/EmailTemplateContentNode"

const CustomDocument = Document.extend({
  content: "emailContent*",
})

const defaultTemplate = `<email-content><span data-type="mention" class="mention" data-id="organization_logo">@organization_logo</span><h1></h1><p></p></email-content>`

export const StyledEmailTemplateInput = styled.div`
  div.RaRichTextInput-editorContent div.ProseMirror {
    padding: 0;
  }
`

export const EmailTemplateInput = ({
  emailTemplateModelId,
  source,
  ...rest
}) => {
  const dataProvider = useDataProvider()
  const [emailVariables, setEmailVariables] = useState([])
  const [tipTapEditorOptions, setTipTapEditorOptions] = useState({})

  useEffect(() => {
    async function fetchData() {
      const response = await dataProvider.getOne("email-template-models", {
        id: emailTemplateModelId,
      })
      if (response.data.email_variables) {
        const emailVariableChoices = response.data.email_variables.map(
          (emailVariable) => emailVariable.id
        )
        setEmailVariables(emailVariableChoices)
        setTipTapEditorOptions({
          extensions: [
            ...DefaultEditorOptions.extensions,
            Bold,
            CustomDocument,
            Paragraph,
            Text,
            HardBreak,
            Heading,
            Italic,
            EmailTemplateContentNode,
            Mention.configure({
              HTMLAttributes: {
                class: "mention",
              },
              suggestion: emailTemplateVariables(emailVariableChoices),
            }),
            Image.configure({
              inline: true,
            }),
            Placeholder.configure({
              includeChildren: true,
              showOnlyCurrent: false,
              placeholder: ({ node }) => {
                if (node.type.name === "heading") {
                  return "Enter a title..."
                }
                if (node.type.name === "paragraph") {
                  return "Type your message here. Type @ to open a list of email variables."
                }
              },
            }),
          ],
        })
      }
    }

    fetchData()
  }, [emailTemplateModelId, dataProvider])

  return (
    <StyledEmailTemplateInput>
      {emailVariables.length > 0 && (
        <RichTextInput
          editorOptions={tipTapEditorOptions}
          source={source}
          format={(v) => {
            if (v) return v
            return defaultTemplate
          }}
          fullWidth
          {...rest}
        />
      )}
    </StyledEmailTemplateInput>
  )
}
