import { FunctionField } from "react-admin"

const values = {
  level_i: "Level I",
  level_ii: "Level II",
  level_iii: "Level III",
}

export const LevelEcoachingField = () => {
  return (
    <FunctionField
      label="react.coaches.cv.level_ecoaching"
      render={(record) => values[record.level_ecoaching]}
    />
  )
}
