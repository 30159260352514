import { DateInput } from "@react-admin/ra-form-layout"
import { useFormContext } from "react-hook-form"

export const EndDateInput = ({
  source,
  startSource,
  label = null,
  helperText = "",
  slotProps = null,
}) => {
  const { watch } = useFormContext()
  let startDate = watch(startSource)

  if (label === null) label = "react.admin.end_date"

  if (startDate && typeof startDate == "number") {
    startDate = new Date(startDate, 0, 1)
  }

  const validateEndDate = (value) => {
    if (!value) return undefined

    if (!startDate) {
      return "react.admin.validation.start_date_required"
    }

    if (value <= startDate) {
      return "react.admin.validation.end_date_after_start_date"
    }

    return undefined
  }

  return (
    <DateInput
      desktopModeMediaQuery="@media(min-width: 90px)"
      source={source}
      label={label}
      helperText={helperText}
      validate={validateEndDate}
      fullWidth
      slotProps={{
        actionBar: {
          actions: ["today", "clear"],
        },
        field: {
          clearable: true,
        },
        ...slotProps,
      }}
    />
  )
}
