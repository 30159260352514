import { Edit, SimpleForm } from "react-admin"
import { EmailTemplateFormFields } from "./EmailTemplateFormFields"

export const EmailTemplateEdit = () => (
  <Edit title="Edit Email Template">
    <SimpleForm>
      <EmailTemplateFormFields />
    </SimpleForm>
  </Edit>
)
