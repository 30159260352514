import { Create, SimpleForm } from "react-admin"
import { LearningStepFormFields } from "./LearningStepFormFields"

export const LearningStepCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <LearningStepFormFields />
    </SimpleForm>
  </Create>
)
