import { useContext } from "react"

import { LanguageContext } from "../../../context/language_context"
import { Language } from "api/models"

export const LanguageField = ({ record }) => {
  const languages = useContext(LanguageContext)

  const matchedLanguage = languages.find(
    (language: Language) => language.id === `${record}`
  )

  return matchedLanguage ? `${matchedLanguage.common_name}` : ""
}
