import { SimpleForm } from "ra-ui-materialui"
import { Edit, useNotify, useRedirect } from "react-admin"

import { ProfileFields } from "../../components/fields/ProfileFields"
import { FormToolbar } from "../../components/forms/FormToolbar"
import { useParams } from "react-router"
import { ProfileTabs } from "./ProfileTabs"

export const PersonalInformationEdit = ({ user = null }) => {
  const { profileId } = useParams()
  const redirect = useRedirect()
  const notify = useNotify()

  const onSuccess = () => {
    notify(`accounts.edit.flash_success`, { type: "success" })
    redirect(false)
  }

  return (
    <Edit
      resource="profiles"
      id={user ? user.profile_id : profileId}
      redirect={false}
      mutationMode="optimistic"
      mutationOptions={{ onSuccess }}
      actions={false}
      sx={profileId && { "& .MuiPaper-root": { overflow: "visible" } }}
    >
      {profileId && <ProfileTabs profileId={profileId} />}
      <SimpleForm
        toolbar={<FormToolbar section="personal-information" />}
        noValidate
        shouldFocusError={false}
        px={1}
      >
        <ProfileFields />
      </SimpleForm>
    </Edit>
  )
}
