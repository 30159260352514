import { Box } from "@mui/material"
import { AutocompleteInput } from "ra-ui-materialui"
import { useRecordContext } from "react-admin"

const OptionRenderer = () => {
  const record = useRecordContext()

  const modelDescriptions = {
    Membership:
      "A membership is the link between a client, the coach and the program. Choose this receiver type if you need to include information about the coach or the program in the template.",
    User: "A user can have many coaches and can be in many programs, meaning that when you create a template for this receiver type you won't be able to use variables like @coach_email or @program_name. Choose this receiver type if you need to include information about the coach or the program in the template.",
  }
  return (
    <Box mb={1}>
      <Box>{record.display_name}</Box>
      <Box
        sx={{ fontSize: "14px", fontStyle: "italic" }}
        color="grey.600"
        mb={1}
      >
        {modelDescriptions[record.display_name]}
      </Box>
      <Box sx={{ fontSize: "12px", fontWeight: "bold" }}>Variables</Box>
      <Box display="flex" flexWrap="wrap" gap={1}>
        {record.email_variables.map((value, index) => (
          <Box
            sx={{
              fontSize: "14px",
              border: "1px solid #000",
              borderRadius: "0.4rem",
              padding: "0.1rem 0.3rem",
              boxDecorationBreak: "clone",
            }}
            key={`ev-${index}`}
          >
            @{value.id}
          </Box>
        ))}
      </Box>
    </Box>
  )
}

const matchSuggestion = (filter, choice) => {
  return (
    choice.first_name.toLowerCase().includes(filter.toLowerCase()) ||
    choice.last_name.toLowerCase().includes(filter.toLowerCase())
  )
}

export const EmailTemplateModelInput = (props) => {
  return (
    <AutocompleteInput
      optionText={<OptionRenderer />}
      inputText={(choice) => choice.display_name}
      matchSuggestion={matchSuggestion}
      {...props}
    />
  )
}
