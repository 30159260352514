import {
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  SavedQueriesList,
} from "react-admin"
import { Card, CardContent } from "@mui/material"

import MailIcon from "@mui/icons-material/MailOutline"

export const CoachListFilterSidebar = () => (
  <Card sx={{ order: -1, mr: 2, width: 230 }}>
    <CardContent>
      <FilterLiveSearch sx={{ marginTop: "0px", width: "100%" }} />

      <SavedQueriesList />

      <FilterList label="Status" icon={<MailIcon />}>
        <FilterListItem label="Active" value={{ active: true }} />
        <FilterListItem label="Inactive" value={{ active: false }} />
      </FilterList>

      <FilterList label="react.coaches.search_in" icon={<MailIcon />}>
        <FilterListItem
          label="react.coaches.default_cv"
          value={{ purposes: "default" }}
        />
        <FilterListItem
          label="react.coaches.commercial_cv"
          value={{ purposes: "commercial" }}
        />
        <FilterListItem
          label="react.coaches.both"
          value={{ purposes: "both" }}
        />
      </FilterList>

      <FilterList label="react.coaches.unloq_only" icon={<MailIcon />}>
        <FilterListItem
          label="react.coaches.unloq"
          value={{ coaches: "associates" }}
        />
        <FilterListItem
          label="react.coaches.core_team"
          value={{ coaches: "core_team" }}
        />
        <FilterListItem label="react.coaches.all" value={{ coaches: "all" }} />
      </FilterList>
    </CardContent>
  </Card>
)
