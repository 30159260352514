import { TourType } from "@react-admin/ra-tour/src/types"
import { useTranslate } from "react-admin"

const styles = { tooltipFooter: { justifyContent: "space-between" } }

const TourStepCoach = () => {
  const translate = useTranslate()

  return (
    <>
      {translate("react.tours.coach_edit.step_coach.text_1")}
      <br />
      <br />
      {translate("react.tours.coach_edit.step_coach.text_2")}
      <br />
      <br />
      {translate("react.tours.coach_edit.step_coach.text_3")}
    </>
  )
}

const TourStepCopy = () => {
  const translate = useTranslate()

  return (
    <>
      <span
        dangerouslySetInnerHTML={{
          __html: translate("react.tours.coach_edit.step_copy.text_1", {
            button_name: translate("react.coaches.cv.copy_from_default_button"),
          }),
        }}
      ></span>
      <br />
      <br />
      {translate("react.tours.coach_edit.step_copy.text_2")}
    </>
  )
}

const TourStepDefaultCV = () => {
  const translate = useTranslate()

  return (
    <>
      {translate("react.tours.coach_edit.step_default_cv.text_1")}
      <br />
      <br />
      {translate("react.tours.coach_edit.step_default_cv.text_2")}
      <br />
      <br />
      {translate("react.tours.coach_edit.step_default_cv.text_3")}
      <br />
      <br />
      {translate("react.tours.coach_edit.step_default_cv.text_4")}
    </>
  )
}

const TourStepCommercialCV = () => {
  const translate = useTranslate()

  return (
    <>
      <span
        dangerouslySetInnerHTML={{
          __html: translate(
            "react.tours.coach_edit.step_commercial_cv.text_1",
            { commercial_cv: translate("react.coaches.commercial_cv") },
          ),
        }}
      ></span>
    </>
  )
}

const TourStepHelp = () => {
  const translate = useTranslate()

  return <>{translate("react.tours.coach_edit.step_help.text_1")}</>
}

export const profileEditTour: TourType = {
  before: () => {
    const copyBtn = document.getElementById("copy-from-default-btn")
    if (copyBtn) {
      profileEditTour.steps.splice(4, 0, {
        target: "#copy-from-default-btn",
        content: <TourStepCopy />,
        joyrideProps: {
          styles: styles,
        },
        disableBeacon: true,
      })
    }
  },
  steps: [
    {
      target: "#tab-coach",
      content: <TourStepCoach />,
      joyrideProps: { styles: styles },
      disableBeacon: true,
    },
    {
      target: "#tab-default-cv",
      content: <TourStepDefaultCV />,
      joyrideProps: { styles: styles },
      event: "click",
      disableBeacon: true,
      after: () => {
        const cvTab = document.getElementById("tab-default-cv")
        cvTab.click()
      },
    },
    {
      before: () => {
        const cvTab = document.getElementById("tab-commercial-cv")
        cvTab.click()
      },
      target: "#tab-commercial-cv",
      content: <TourStepCommercialCV />,
      joyrideProps: {
        styles: styles,
      },
      disableBeacon: true,
    },
    {
      target: "#help",
      content: <TourStepHelp />,
      joyrideProps: {
        styles: styles,
        locale: {
          last: "Finish tour",
        },
      },
      disableBeacon: true,
    },
  ],
}
