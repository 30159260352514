import {
  FormDataConsumer,
  useCreateController,
  useDataProvider,
  useRecordContext,
} from "ra-core"
import {
  AutocompleteInput,
  RadioButtonGroupInput,
  ReferenceInput,
  TextInput,
} from "ra-ui-materialui"
import { useState, useEffect } from "react"
import { EmailTranslatableInput } from "../../components/inputs/EmailTranslatableInput"
import { EmailVariablesPreview } from "../../components/inputs/EmailVariablesPreview"
import { MultiEmailInput } from "../../components/inputs/MultiEmailInput"

export const EmailBatchTemplateForm = () => {
  const createController = useCreateController()
  const record = useRecordContext()
  const dataProvider = useDataProvider()
  const [emailTemplateModelId, setEmailTemplateModelId] = useState(0)

  useEffect(() => {
    async function fetchData() {
      const response = await dataProvider.getOne("email-templates", {
        id: record.emailTemplateId,
      })
      setEmailTemplateModelId(response.data.email_template_model_id)
    }

    async function fetchDefault() {
      const response = await dataProvider.getList("email-template-models", {
        filter: {
          name: createController["record"]["resource_type"],
        },
        pagination: { page: 1, perPage: 1 },
        sort: { field: "name", order: "ASC" },
      })
      if (response.data.length > 0) {
        setEmailTemplateModelId(response.data[0].id)
      }
    }

    if (record && record.emailTemplateId != 0) {
      if (record && emailTemplateModelId == 0) {
        fetchData()
      }
    } else {
      if (createController && createController["record"]) {
        fetchDefault()
      }
    }
  }, [dataProvider, createController, emailTemplateModelId, record])

  return (
    <>
      <RadioButtonGroupInput
        source="use_template"
        label="Use an existing template?"
        choices={[
          { id: "0", name: "Yes" },
          { id: "1", name: "No" },
        ]}
      />
      <FormDataConsumer>
        {({ formData }) =>
          formData.use_template == "0" && (
            <>
              <ReferenceInput
                source="email_template_id"
                reference="email-templates"
                sort={{ field: "created_at", order: "DESC" }}
                sx={{ width: "100%", pt: 0 }}
              >
                <AutocompleteInput
                  sx={{ width: "100%" }}
                  variant="outlined"
                  size="medium"
                  margin="none"
                />
              </ReferenceInput>
            </>
          )
        }
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) =>
          formData.use_template == "1" &&
          emailTemplateModelId != 0 && (
            <>
              <EmailVariablesPreview
                emailTemplateModelId={emailTemplateModelId}
              />
              <MultiEmailInput
                source="cc"
                label="Cc"
                emailTemplateModelId={emailTemplateModelId}
                sx={{ mb: 2, width: "100%" }}
              />
              <MultiEmailInput
                source="bcc"
                label="Bcc"
                emailTemplateModelId={emailTemplateModelId}
                sx={{ mb: 2, width: "100%" }}
              />
              <MultiEmailInput
                source="reply_to"
                label="Reply-To"
                emailTemplateModelId={emailTemplateModelId}
                sx={{ mb: 2, width: "100%" }}
              />
              <EmailTranslatableInput
                emailTemplateModelId={emailTemplateModelId}
              />
            </>
          )
        }
      </FormDataConsumer>

      <TextInput source="resource_name" sx={{ display: "none" }} />
      <TextInput source="resource_type" sx={{ display: "none" }} />
      <TextInput source="resource_ids" sx={{ display: "none" }} />
    </>
  )
}
