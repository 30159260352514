import { Typography } from "@mui/material"
import { Box } from "@mui/system"

export const EmailBatchExplanation = () => {
  return (
    <Box mx={2}>
      <Typography variant="h5" mb={2}>
        Welcome to the Batch email feature.
      </Typography>
      <Typography maxWidth={600}>
        To create a new email batch you will have to follow this wizard.
        <br />
        <br />
        The wizard consists of 4 steps.
        <br />
        <ol>
          <li>Read this explanation</li>
          <li>Select the recipients of your email</li>
          <li>
            Create the email by adding a subject, typing a message and
            optionally add a CC, BCC, Reply-to, etc.
          </li>
          <li>Confirm everything is set correctly</li>
          <li>Send out the email</li>
        </ol>
        If you are ready to create a new batch click the &quot;Next&quot; button
      </Typography>
    </Box>
  )
}
