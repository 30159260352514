import { Create, SimpleForm } from "react-admin"
import { EmailTemplateFormFields } from "./EmailTemplateFormFields"

export const EmailTemplateCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <EmailTemplateFormFields />
    </SimpleForm>
  </Create>
)
