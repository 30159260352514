import {
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  TextField,
  WithRecord,
} from "react-admin"

import { Box } from "@mui/system"
import {
  CreateInDialogButton,
  EditInDialogButton,
} from "@react-admin/ra-form-layout"
import { List } from "@react-admin/ra-rbac"
import { ProgramBreakForm } from "./ProgramBreakForm"

const CreateBreakButton = () => {
  return (
    <Box sx={{ marginBottom: "1rem" }}>
      <WithRecord
        render={(record) => (
          <CreateInDialogButton
            record={{ program_id: record.id }}
            label="program_breaks.create"
          >
            <ProgramBreakForm />
          </CreateInDialogButton>
        )}
      />
    </Box>
  )
}

const EditBreakButton = () => {
  return (
    <EditInDialogButton>
      <ProgramBreakForm />
    </EditInDialogButton>
  )
}

export const ProgramBreakList = (props) => {
  return (
    <List
      resource="program-breaks"
      disableSyncWithLocation
      actions={<CreateBreakButton />}
      {...props}
    >
      <Datagrid rowClick={false} bulkActionButtons={false}>
        <TextField source="name" />
        <DateField source="start_date" />
        <DateField source="end_date" />
        <Box display="flex" justifyContent="end" gap={1}>
          <EditBreakButton />
          <DeleteWithConfirmButton redirect={false} />
        </Box>
      </Datagrid>
    </List>
  )
}
