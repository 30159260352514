import { Autocomplete, Container, Grid, TextField } from "@mui/material"

import {
  ReferenceManyInput,
  ReferenceManyToManyInput,
} from "@react-admin/ra-relationships"
import { Country } from "api/models"
import {
  AutocompleteInput,
  Button,
  DateInput,
  ImageField,
  Labeled,
  ReferenceInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  TranslatableInputs,
} from "ra-ui-materialui"
import { useEffect, useState } from "react"
import {
  AutocompleteArrayInput,
  Edit,
  FormDataConsumer,
  maxValue,
  useDataProvider,
  useLocaleState,
  useRecordContext,
} from "react-admin"
import { YearInput } from "../inputs/YearInput"
import { PhoneNumberInput } from "../inputs/PhoneNumberInput"

export const ProfileShowForm = () => {
  const [countries, setCountries] = useState([])
  const dataProvider = useDataProvider()
  const [locale] = useLocaleState()
  const record = useRecordContext()

  const cvId = record.id

  useEffect(() => {
    if (countries.length == 0) {
      dataProvider
        .getList("countries", {
          filter: {},
          pagination: { page: 1, perPage: 1000 },
          sort: { field: "id", order: "ASC" },
        })
        .then((response) => {
          setCountries(response.data)
        })
    }
  })

  const date = new Date()
  const currentDate = date.toJSON().slice(0, 10)

  function calculateDateDifference(currentDate: string, eventDate: string) {
    if (eventDate == null || currentDate == eventDate) {
      return ""
    }

    const dateObj1 = new Date(currentDate)
    const dateObj2 = new Date(eventDate)

    const diffInMilliseconds = Math.abs(dateObj2.getTime() - dateObj1.getTime())

    const diffInDays = diffInMilliseconds / (1000 * 3600 * 24)

    if (diffInDays >= 365.25) {
      const diffInYears = diffInDays / 365.25
      return `${Math.floor(diffInYears)} years`
    } else {
      const diffInMonths = diffInDays / 30.44
      return `${Math.floor(diffInMonths)} months`
    }
  }

  return (
    <Edit
      resource="profiles"
      redirect={false}
      id={cvId}
      mutationMode="optimistic"
      actions={false}
      sx={{
        width: "100vw",
      }}
    >
      <SimpleForm toolbar={false}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ImageField source="avatar_picture.src" title="title" />
          </Grid>
          <Grid item xs={6}>
            <ImageField source="photo_picture.src" title="title" />
          </Grid>
        </Grid>

        <TextInput source="remarks" fullWidth />

        <TranslatableInputs
          locales={["en", "es", "fr", "nl"]}
          defaultLocale="en"
          fullWidth
        >
          <TextInput source="approach" fullWidth rows={4} multiline />
        </TranslatableInputs>

        <TextInput source="organization_name" fullWidth />

        <ReferenceManyToManyInput
          reference="specializations"
          through="cv-specializations"
          using="curriculum_vitae_id,specialization_id"
        >
          <AutocompleteArrayInput
            label="Specializations"
            optionText={`name.${locale}`}
            sx={{ width: "100%" }}
          />
        </ReferenceManyToManyInput>

        <ReferenceManyToManyInput
          reference="professions"
          through="cv-professions"
          using="curriculum_vitae_id,profession_id"
        >
          <AutocompleteArrayInput
            label="Occupations"
            optionText={`name.${locale}`}
            sx={{ width: "100%" }}
          />
        </ReferenceManyToManyInput>

        <Labeled
          label="Branch experiences"
          component="h2"
          sx={{ width: "100%" }}
        >
          <ReferenceManyInput
            label=""
            reference="curriculum-vitae-branch-experiences"
            target="curriculum_vitae_id"
          >
            <SimpleFormIterator
              inline
              disableClear
              fullWidth
              addButton={
                <Button label="Add branch experience" sx={{ color: "#000" }} />
              }
              removeButton={<Button label="Remove" sx={{ color: "#000" }} />}
            >
              <ReferenceInput
                source="branch_experience_id"
                reference="branch-experiences"
              >
                <AutocompleteInput
                  size="medium"
                  margin="none"
                  label=""
                  sx={{
                    width: "50%",
                    "& .MuiAutocomplete-clearIndicator": {
                      display: "none",
                    },
                  }}
                />
              </ReferenceInput>

              <DateInput
                source="start_date"
                sx={{ width: "20%" }}
                validate={maxValue(currentDate)}
              />

              <FormDataConsumer>
                {({ scopedFormData }) => (
                  <TextField
                    label="Experience"
                    variant="standard"
                    disabled
                    sx={{ width: "20%" }}
                    value={calculateDateDifference(
                      currentDate,
                      scopedFormData.start_date
                    )}
                  />
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ReferenceManyInput>
        </Labeled>

        <Container
          sx={{
            bgcolor: "#f5f5f5",
            marginBottom: "2rem",
            padding: "1rem",
            width: "100%",
          }}
        >
          <Labeled label="Certifications" component="h2" fullWidth>
            <ReferenceManyInput
              label=""
              reference="certifications"
              target="curriculum_vitae_id"
            >
              <SimpleFormIterator
                inline
                disableClear
                fullWidth
                addButton={
                  <Button label="Add certification" sx={{ color: "#000" }} />
                }
                removeButton={<Button label="Remove" sx={{ color: "#000" }} />}
              >
                <TextInput
                  source="topic"
                  label="Topic / Theme"
                  sx={{ width: "30%" }}
                  helperText="Example: ICF"
                />
                <TextInput
                  source="name"
                  label="Certificate name"
                  sx={{ width: "30%" }}
                  helperText="Example: ICF Professional Certified Coach"
                />
                <TextInput
                  source="institution"
                  sx={{ width: "30%" }}
                  helperText="Institute or organisation which issues the certification"
                />

                <ReferenceInput
                  source="certification_level_id"
                  reference="certification-levels"
                  sort={{ field: `name.${locale}`, order: "ASC" }}
                  sx={{ width: "20%" }}
                >
                  <AutocompleteInput
                    sx={{ width: "50%" }}
                    size="medium"
                    margin="none"
                    optionText={`name.${locale}`}
                    label="Level"
                  />
                </ReferenceInput>

                <YearInput
                  source="start_year"
                  helperText="Year from which the certification holds"
                />
                <DateInput
                  source="end_date"
                  helperText="Date until certification was valid"
                />
              </SimpleFormIterator>
            </ReferenceManyInput>
          </Labeled>
        </Container>

        <Container
          sx={{
            bgcolor: "#f5f5f5",
            marginBottom: "2rem",
            padding: "1rem",
            width: "100%",
          }}
        >
          <Labeled label="Affiliations" component="h2" fullWidth>
            <ReferenceManyInput
              label=""
              reference="affiliations"
              target="curriculum_vitae_id"
            >
              <SimpleFormIterator
                inline
                disableClear
                fullWidth
                addButton={
                  <Button label="Add affiliation" sx={{ color: "#000" }} />
                }
                removeButton={<Button label="Remove" sx={{ color: "#000" }} />}
              >
                <TextInput source="acronym" sx={{ width: "15%" }} />
                <TextInput source="name" sx={{ width: "60%" }} />
              </SimpleFormIterator>
            </ReferenceManyInput>
          </Labeled>
        </Container>

        <Grid container spacing={1}>
          <Grid item xs={3}>
            <PhoneNumberInput source="phone_number" label="Phone number" />
          </Grid>
          <Grid item xs={3}>
            <PhoneNumberInput
              source="secondary_phone_number"
              label="Secondary phone number"
            />
          </Grid>
          <Grid item xs={6}>
            <ReferenceInput
              source="timezone_id"
              reference="timezones"
              perPage={200}
            >
              <AutocompleteInput
                sx={{ width: "100%" }}
                size="medium"
                margin="none"
                label="Timezone"
              />
            </ReferenceInput>
          </Grid>
        </Grid>

        <Container
          sx={{
            bgcolor: "#f5f5f5",
            marginBottom: "2rem",
            padding: "1rem",
            width: "100%",
          }}
        >
          <Labeled label="Addresses" component="h2" fullWidth>
            <ReferenceManyInput label="" reference="addresses" target="user_id">
              <SimpleFormIterator
                inline
                disableClear
                fullWidth
                addButton={
                  <Button label="Add address" sx={{ color: "#000" }} />
                }
                removeButton={<Button label="Remove" sx={{ color: "#000" }} />}
                getItemLabel={(index) => `${index + 1}.`}
              >
                <TextInput source="address" sx={{ width: "40%" }} />
                <TextInput source="city" sx={{ width: "40%" }} />
                <TextInput source="zip_code" sx={{ width: "25%" }} />
                <TextInput source="house_number" sx={{ width: "15%" }} />
                <TextInput source="company" sx={{ width: "35%" }} />

                <ReferenceInput
                  source="country_id"
                  reference="countries"
                  sort={{ field: "common_name", order: "ASC" }}
                  sx={{ width: "25%" }}
                >
                  <AutocompleteInput
                    sx={{ width: "50%" }}
                    size="medium"
                    margin="none"
                    label="Country"
                  />
                </ReferenceInput>

                <FormDataConsumer>
                  {({ scopedFormData }) => (
                    <Autocomplete
                      id="region"
                      freeSolo
                      readOnly
                      sx={{ width: "25%" }}
                      options={[]}
                      value={
                        (countries as Country[]).find(
                          (country) =>
                            country.id === `${scopedFormData.country_id}`
                        )?.region_name
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Region"
                          variant="standard"
                        />
                      )}
                    />
                  )}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ReferenceManyInput>
          </Labeled>
        </Container>
      </SimpleForm>
    </Edit>
  )
}
