import { Box } from "@mui/material"
import {
  AutocompleteInput,
  NumberInput,
  ReferenceInput,
  required,
  TextInput,
} from "react-admin"
import { ColorInput } from "react-admin-color-picker"

export const LearningModuleFormFields = () => {
  return (
    <>
      <ReferenceInput
        source="learning_path_id"
        reference="learning-paths"
        sx={{ width: "100%", pt: 0 }}
      >
        <AutocompleteInput sx={{ width: "100%" }} validate={required()} />
      </ReferenceInput>
      <TextInput source="name" fullWidth />
      <TextInput source="presentation" fullWidth />
      <Box sx={{ width: "100%" }} display="flex" gap={2}>
        <NumberInput source="start_days" fullWidth />
        <NumberInput source="end_days" fullWidth />
      </Box>
      <Box sx={{ marginBottom: "20px", width: "100%" }} display="flex" gap={2}>
        <ColorInput source="color_dark" variant="filled" className="w-100" />
        <ColorInput source="color_light" variant="filled" className="w-100" />
      </Box>
    </>
  )
}
