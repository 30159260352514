import { useEffect, useState } from "react"
import { useDataProvider } from "react-admin"

import { LanguageLevelContext } from "../../context/language_level_context"

export const LanguageLevelProvider = ({ children }) => {
  const [languageLevels, setLanguageLevels] = useState([])
  const dataProvider = useDataProvider()

  useEffect(() => {
    if (languageLevels.length === 0) {
      dataProvider
        .getList("language-levels", {
          filter: {},
          pagination: { page: 1, perPage: 1000 },
          sort: { field: "id", order: "ASC" },
        })
        .then(({ data }) => setLanguageLevels(data))
    }
  })

  return (
    <LanguageLevelContext.Provider value={languageLevels}>
      {children}
    </LanguageLevelContext.Provider>
  )
}
